.form-label {width: fit-content; font-size: var(--lbFsize,0.8em); transition: padding .3s linear, font-size .3s linear; z-index: 2;height: var(--lbHe);
  padding-left: var(--inpPdL);padding-right: var(--inpPdR); font-weight: var(--lbWe,300);color:var(--lbCl,var(--inpCl));
  }
.form-label.covering {padding: 0 var(--lbCovPdC,var(--inpPdL, 12px)) !important; font-size: var(--lbCovFsize,1em);font-weight: var(--lbCovWe,300);
  color:var(--lbCovCl,var(--inpCl));background-color: var(--lbCovBkg,var(--inpBkg));width: 100%;height: 100%;}

.form-label .label-left {position: absolute;top: 50%; left: 0; z-index: 2;transform: translateY(-50%);width:min-content; padding: ;padding-left: var(--lbPdL);
  padding-right: var(--lbPdR); transition: padding-top .3s linear,padding-bottom .3s linear;}
.label-top .form-label {position: absolute;top: 0; left: 0;}
.label-out .form-label {padding-left: var(--lbPdC,var(--inpPdL));padding-right: var(--lbPdC, var(--inpPdR));}
.lab-pdS .form-label {padding-top: var(--lbPdS);padding-bottom: var(--lbPdS);}
.lab-pdT.form-label {padding-top:var(--lbPdT); }
.lab-pdB .form-label {padding-bottom: var(--lbPdT);}
