.a-btn-drop-menu {
     --brdRad: 10px; border-radius: var(--brdRad);--maxHeight:70vh; --rowContainer:12px;--rowPdS:0px;--rowBrdRad:0.1;
     --rowFSize: var(--fSize);--rowHeight:2em;
     opacity:0;visibility:hidden; font-size: var(--fSize);
     position: absolute;bottom: 0;
     /*** Drop Down Translation -> Vertical animation from yTransStart to yTransEnd when there is no overflow ***/
     --yTransStart:calc(100% + var(--tStart));  /* starting position of the drop down window is below the button at a distance 0.2 * button height */
     --yTransEnd:calc(100% - ( (var(--height) - var(--fSize)) / 2 / var(--endRatio)) ); /*Drop Down windows is positioned inside the button at a distance from the bottom border euqal to 1/yTransRatio of the bottom padding*/
     --xTrans:0; --yTransArrow: -5px;
     transform: translate(var(--xTrans),var(--yTransStart)); z-index: 100;


}



.a-btn-drop:hover .a-btn-drop-menu-hover,.a-btn-drop .a-btn-drop-menu-clicked  { opacity:1;visibility: visible;}
.a-btn-drop-menu.left-drop {border-radius: 0 var(--brdRad) var(--brdRad) 0;}
.a-btn-drop-overlay {position: fixed;width: 100%;height: 100%;z-index: 50;}
.a-btn-drop .a-btn-drop-menu > div {border-radius: inherit;}


.a-btn-drop-menu-colors,.a-btn-fixed-menu {
  background-color: var(--dropDownBkg);--dropDownBkg:hsla(var(--h),var(--s),var(--l), var(--a, 1));
  color:var(--hex);--bkgHover: hsla(var(--h1),var(--s1),var(--l1), var(--a, 1));

}


/*** Position and translation  ******/

.outward-drop .a-btn-drop-menu {--yTransEnd:calc(100% + var(--tEnd));}
.a-btn-drop-menu.drop-full {width: var(--bodyWidth)!important; transition: opacity .3s ease-in, transform .3s ease-in; border-radius: 0;}
.a-btn-drop-menu.drop-center {left: 50%;  --xTrans:-50%; transition: opacity .3s ease-in, transform .3s ease-in; }
.a-btn-drop-menu.drop-overflow {transition: opacity .3s ease-in;} /* When overflow occurs we need to remove transform transition otherwise the tip position will be calculated at the x starting position and not at the final x position, because use effect is faster the the transition */
.a-btn-drop-menu.drop-center.drop-overflow {--xTrans:calc(-50% + var(--overFlowAdj));}
.a-btn-drop-menu.drop-full.drop-overflow {--xTrans:var(--overFlowAdj);}
.a-btn-drop:hover .a-btn-drop-menu-hover,.a-btn-drop .a-btn-drop-menu-clicked  { transform: translate(var(--xTrans),var(--yTransEnd));}
