.no-hover {cursor: auto !important}
.pointer {cursor: pointer;}
.auto-cursor {cursor: auto !important;}
.no-touch {touch-action: none}  /*Disable touch events on an element even with passive listeners*/
.no-pointer {pointer-events: none}
.noselect,.no-select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
