/* General*/
.a-btn {--pdC:16px;--btnBrd:0px;--height:2.2em;--btnRad:101;--textMrl:0.5em;---arrowSize:18px;--arrowMrT:0px;--arrowMrL:18px;
      display: inline-block;  text-align: center;  border-radius: calc(var(--btnRad) * var(--height) / 100); font-size: var(--fSize);
      height: var(--height);  letter-spacing: 0.5px;padding: 0 var(--pdC);box-sizing: border-box;--tipSize:18px;--endRatio: 4;--tStart:var(--height) * 1.3;
      --iconSize:1em;font-family: "Segoe UI", Arial, sans-serif;  border: var(--btnBrd) solid var(--brdColor);}
.a-btn.icon-only {width:var(--height);padding: 0!important;}


.btn-brd-1 {--btnBrd:1px;}.btn-brd-2 {--btnBrd:2px;}.btn-brd-3 {--btnBrd:3px;}.btn-brd-4 {--btnBrd:4px;}.btn-brd-5 {--btnBrd:5px;}
.btn-brdH-1 {--btnBrdH:1px;}.btn-brdH-2 {--btnBrdH:2px;}.btn-brdH-3 {--btnBrdH:3px;}.btn-brdH-4 {--btnBrdH:4px;}.btn-brdH-5 {--btnBrdH:5px;}
.btn-rad-0 {--btnRad:0} .btn-rad-5 {--btnRad:5} .btn-rad-10 {--btnRad:10} .btn-rad-15 {--btnRad:15} .btn-rad-20 {--btnRad:20} .btn-rad-25 {--btnRad:25}
.btn-rad-30 {--btnRad:30} .btn-rad-35 {--btnRad:35} .btn-rad-40 {--btnRad:40} .btn-rad-45 {--btnRad:45} .btn-rad-50 {--btnRad:50}

@media (hover: hover) {
  .a-btn-brd-hover:hover:not(.no-hover) {--btnBrd:var(--btnBrdH);}
}


.a-btn-drop {position: relative; }

.a-btn-clicked-anim {--disp: min(var(--height) * 0.08, 6px);transform: translate(calc(var(--disp) / 3), var(--disp));box-shadow:  calc(var(--disp)) var(--disp) calc(var(--disp)) rgba(0,0,0,.4)}
/***********Tooltip ************/
.a-btn-tooltip {position: absolute;left:50%;bottom: -7px; display: none; padding: 8px 4px;transform: translate(-50%,100%);border-radius: 5px;
  box-shadow: 0 0 4px rgba(0,0,0,.8); font-size: 11px; line-height: 1.2; z-index: 10;
  width:90px;white-space:normal;text-align: center;}
.a-btn:hover .a-btn-tooltip {display: block;}

.a-btn-tooltip::after {content: " ";position: absolute;bottom: 100%; left: 50% ;border-width: 5px;transform: translateX(-50%);
  border-style: solid;border-color:  transparent transparent black transparent;}
