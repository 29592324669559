.an-icon-size {--iconSize:var(--fSize);}
.an-icon-100 {--iconSize:100%;}
.an-icon {width: var(--iconSize);height: var(--iconSize);line-height: 1;padding: 0;}
.drop-tip-icon.an-icon {width: var(--iconSize);height: calc(var(--iconSize) / 2);}
.an-icon-color {fill: var(--hex);transition: fill .1s linear;}

@media (hover: hover) {
  .an-icon-hover-color:hover {fill: var(--hex1);}
}

/*
.an-icon-container.iconAdj {--iconSize:calc(var(--fSize) * 0.01 * (100 + var(--sign) * var(--e,0)));}
.an-icon-container.an-icon-100pc.iconAdj {--iconSize:calc(100% * 0.01 * (100 + var(--sign) * var(--e,0)));}
.icon-margin-adj {margin-left: calc(-0.01 * var(--f) * var(--iconSize));}
.an-icon-container.iconPad {--tPad: calc(var(--sm) * var(--iconSize) * 0.01);--rPad: calc(var(--sm1) * var(--iconSize) * 0.01);
  --bPad: calc(var(--sm2) * var(--iconSize) * 0.01);--lPad: calc(var(--sm3) * var(--iconSize) * 0.01);width: calc(var(--iconSize) + var(--lPad) + var(--rPad));
  height: calc(var(--iconSize) + var(--tPad) + var(--bPad));padding: var(--tPad,0) var(--rPad) var(--bPad) var(--lPad);}
*/
