
.form-outer-container {--label-fSize:var(--fSize); --labelTopPad: calc(var(--e, 0) * 1px); --labelBotPad: calc(var(--m, 0) * 1px);
  --inputBotPad:calc(var(--g, 0) * 1px);--brdSize:calc(var(--sm1, 0) * 1px);--barSize: calc(var(--sm, 0) * 1px);--outer-label-mrL:0;--labelWidth:0px;}
.outer-label-bar-mr {--outer-label-mrL:calc(var(--barSize) * 1.3);}
.outer-label-brd-mr {--outer-label-mrL:calc(var(--brdSize)* 1.3);}
.form-lBar,.form-rBar,.form-tBar,.form-bBar,.form-brd-rad {--barRad:calc(var(--xs9) * 5 * var(--height) / 100); --oRad:calc(var(--xs10) * 5 * var(--height) / 100);}
.form-brd-rad {--brdRad: var(--oRad);}
.form-lBar {--brdRad:  var(--barRad) var(--oRad) var(--oRad) var(--barRad);} .form-rBar {--brdRad: var(--oRad) var(--barRad) var(--barRad) var(--oRad);}
.form-tBar {--brdRad:  var(--barRad) var(--barRad) var(--oRad) var(--oRad);}.form-bBar {--brdRad: var(--oRad) var(--oRad) var(--barRad) var(--barRad);}
.form-bkg-container {--lHovBlur: var(--sm);--lHovErr: var(--sm2);border-radius: var(--brdRad) ;
}
.form-container {border-radius: var(--brdRad);
  border: var(--brdSize) solid var(--hex);height: var(--height);transition: height var(--tranTime) linear;position: relative;
}
.form-std-bkg .form-container {--lum:var(--l); background-color: hsla(var(--h),var(--s),var(--lum),var(--a,1));}

.frm-no-trans .form-container {transition: none;}

.frm-label-fixed-width, .frm-label-computed-width {--labelWidth: calc(var(--f) * var(--fx) * var(--width-un, 1px));}
.form-focus-bkg .form-container.form-focused,.form-std-bkg .form-container.form-focused:hover
  {background-color: hsla(var(--h1),var(--s1),var(--l1),var(--a,1)) !important;}
.form-error-bkg .form-container.form-err {--lumErr:var(--l2);background-color: hsla(var(--h2),var(--s2),var(--lumErr),var(--a,1))!important;border-color: var(--hex2) !important;}
.form-container.form-focused,.form-container.form-focused:hover { border-color: var(--hex1) ;}
.form-container.form-err {border-color: var(--hex2) !important;}
.form-lBar-width {border-left-width: var(--barSize)  !important;}
.form-rBar-width {border-right-width: var(--barSize)  !important;}
.form-tBar-width {border-top-width: var(--barSize)  !important;}
.form-bBar-width {border-bottom-width: var(--barSize)  !important;}
/*
.form-round-noLab.bordered .form-container  {border: var(--brd) solid hsl(35,95%,51%)}
.form-round-noLab.bordered .form-container:hover, .form-round-noLab.bordered  .form-container:focus
  {border: var(--brd) solid hsl(35,95%,51%)!important}

*/

/* label */
.frm-label {display: block;  width: fit-content;font-size: var(--label-fSize); padding-top: var(--labelTopPad, 0);padding-bottom: var(--labelBotPad, 0);
  transition: padding .3s linear,font-size .3s linear;max-width: calc(100% - var(--outer-label-mrL,0));}
.frm-label-out {margin-left: var(--outer-label-mrL,0);border-radius: 2px;;}
.frm-label-top {position: absolute;top: 0; left: 0; z-index: 2;}
.frm-label-left {position: absolute;top: 50%; left: 0; z-index: 2;transform: translateY(-50%);width:min-content;
  transition: padding-top .3s linear,padding-bottom .3s linear } /* No font size and container transtion because it would screw up useEffect to calculate the label size and use it as input left padding*/
.frm-label-right {position: absolute;top: 50%; right: 0; z-index: 2;transform: translateY(-50%);width:min-content;
  transition: padding-top .3s linear,padding-bottom .3s linear !important; }
.frm-no-trans .frm-label, .frm-no-trans .frm-label-left, .frm-no-trans .frm-label-right {transition: none;}
.frm-label-color {color: var(--hex);}
.form-err .frm-label-color {color: var(--hex2,var(--hex));}
.form-focused .frm-label-color {color: var(--hex1,var(--hex)) !important;}
.frm-label-max-width {max-width: calc(var(--g) * 1%);}
.frm-label-width {width: var(--labelWidth);transition: padding .3s linear,font-size .3s linear !important;}

@media (hover: hover) {
  .form-std-bkg .form-container:hover:not(.no-hover):not(.form-focused):not(.form-err) {--lum:calc(var(--l) + var(--lHovBlur) * 1% * var(--sign, 1));border-color: var(--hex3) ;}
  .form-error-bkg .form-container.form-err:hover:not(.no-hover):not(.form-focused) {--lumErr:calc(var(--l2) + var(--lHovErr) * 1% * var(--sign1, 1));}
  .form-container:hover:not(.no-hover):not(.form-focused):not(.form-err) {border-color: var(--hex3) ;}
  .form-container:hover:not(.no-hover):not(.form-focused):not(.form-err) .label-color {color: var(--hex3,var(--hex));}
}
