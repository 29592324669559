/********* Siz*   *****/
.a-btn-drop-menu,.a-btn-fixed-menu { --btnIconSize: 1.1em;}
.a-btn-drop-row { box-sizing: border-box;overflow: hidden; width: 100%;padding: var(--rowPdS) var(--rowContainer);text-align: left;height: var(--rowHeight);}
.multi-drop-container .a-btn-drop-row {border-radius: calc(var(--rowHeight,calc(var(--rowPdS) * 2 + 1em)) * var(--rowBrdRad) / 100)}
.drop-btn-brd-bottom .single-drop-cont .a-btn-drop-row { border-bottom: var(--dropRowBrd) solid hsl(var(--h),var(--s),max(min(var(--l) + var(--lHov), 100%), 0%));}
.drop-btn-brd-bottom .single-drop-cont .a-btn-drop-row:last-child {--dropRowBrd:0px;}



/*********    Colors   *****/

.drop-row-lTxt.a-btn-drop-row {color:hsl(var(--h2),var(--s2),max(min(var(--l2) + var(--lTxtVar), 100%), 0%));--lTxtVar:0%;}

@media (hover: hover) {
  .drop-row-lTxt.a-btn-drop-row:hover:not(.no-hover) {--lTxtVar:var(--lTxt);}
  .drop-row-txt-hov .a-btn-drop-row:hover:not(.no-hover) {color: var(--hex1);}
  .drop-row-lBkg .a-btn-drop-row:hover:not(.no-hover) {background-color: hsla(var(--h),var(--s),max(min(var(--l) + var(--lBkg), 100%), 0%));}
  .drop-row-bkg-hov .a-btn-drop-row:hover:not(.no-hover) {background-color: var(--bkgHover);}
}
