
.heigh-var-auto {--height: auto !important;}
.max-he-100, .max-height-100 {max-height: 100%;}
.w100 {width: 100%} .h100 {height: 100%}
.width-px {--width-un: 1px}  .width-pc {--width-un: 1%} .width-vw {--width-un: 0.1vw} .width-vh {--width-un: 0.1vh}
.height-px {--heU: 1px}  .height-pc {--heU: 1%} .height-vw {--heU: 0.1vw} .height-vh {--heU: 0.1vh}
.min-height-px {--minHeU: 1px}  .min-height-pc {--minHeU: 1%} .min-height-vw {--minHeU: 0.1vw} .min-height-vh {--minHeU: 0.1vh}
.max-height-px {--maxHeU: 1px}  .max-height-pc {--maxHeU: 1%} .max-height-vw {--maxHeU: 0.1vw} .max-height-vh {--maxHeU: 0.1vh}
.maxWidth-px {--maxWidth-un: 1px}  .maxWidth-pc {--maxWidth-un: 1%} .maxWidth-vw {--maxWidth-un: 0.1vw} .maxWidth-vh {--maxWidth-un: 0.1vh}
.minWidth-px {--minWidth-un: 1px}  .minWidth-pc {--minWidth-un: 1%} .minWidth-vw {--minWidth-un: 0.1vw} .minWidth-vh {--minWidth-un: 0.1vh}


@media (max-width:1200px) {
  .width-px-t {--width-un: 1px}  .width-pc-t {--width-un: 1%} .width-vw-t {--width-un: 0.1vw} .width-vh-t {--width-un: 0.1vh}
  .height-px-t {--heU: 1px}  .height-pc-t {--heU: 1%} .height-vw-t {--heU: 0.1vw} .height-vh-t {--heU: 0.1vh}
  .min-height-px-t {--minHeU: 1px}  .min-height-pc-t {--minHeU: 1%} .min-height-vw-t {--minHeU: 0.1vw} .min-height-vh-t {--minHeU: 0.1vh}
  .max-height-px-t {--maxHeU: 1px}  .max-height-pc-t {--maxHeU: 1%} .max-height-vw-t {--maxHeU: 0.1vw} .max-height-vh-t {--maxHeU: 0.1vh}
  .maxWidth-px-t {--maxWidth-un: 1px}  .maxWidth-pc-t {--maxWidth-un: 1%} .maxWidth-vw-t {--maxWidth-un: 0.1vw} .maxWidth-vh-t {--maxWidth-un: 0.1vh}
  .mminWidth-px-t {--minWidth-un: 1px}  .minWidth-pc-t {--minWidth-un: 1%} .minWidth-vw-t {--minWidth-un: 0.1vw} .minWidth-vh-t {--minWidth-un: 0.1vh}

}

@media (max-width:734px) {
  .width-px-s {--width-un: 1px}  .width-pc-s {--width-un: 1%} .width-vw-s {--width-un: 0.1vw} .width-vh-s {--width-un: 0.1vh}
  .height-px-s {--heU: 1px}  .height-pc-s {--heU: 1%} .height-vw-s {--heU: 0.01vw} .height-vh-s {--heU: 0.01vh}
  .min-height-px-s {--minHeU: 1px}  .min-height-pc-s {--minHeU: 1%} .min-height-vw-s {--minHeU: 0.1vw} .min-height-vh-s {--minHeU: 0.1vh}
  .max-height-px-s {--maxHeU: 1px}  .max-height-pc-s {--maxHeU: 1%} .max-height-vw-s {--maxHeU: 0.1vw} .max-height-vh-s {--maxHeU: 0.1vh}
  .maxWidth-px-s {--maxWidth-un: 1px} .maxWidth-pc-s {--maxWidth-un: 1%} .maxWidth-vw-s {--maxWidth-un: 0.1vw} .maxWidth-vh-s {--maxWidth-un: 0.1vh}
  .minWidth-px-s {--minWidth-un: 1px} .minWidth-pc-s {--minWidth-un: 1%} .minWidth-vw-s {--minWidth-un: 0.1vw} .minWidth-vh-s {--minWidth-un: 0.1vh}

}


.a-width {width: calc(var(--f) * var(--fx) * var(--width-un, 1px))}
.a-height {height: calc(var(--b) * var(--bx) * var(--heU, 1px))}
.a-var-height {--height: calc(var(--b) * var(--bx) * var(--heU, 1px))}
.a-var-height-f {--height: calc(var(--f) * var(--fx) * var(--heU, 1px))}
.a-height-with-var {--height: calc(var(--b) * var(--bx) * var(--heU, 1px)); height: var(--height);}
.a-var-width {--width: calc(var(--f) * var(--fx) * var(--width-un, 1px))}
.a-min-height {min-height: calc(var(--d) * var(--dx) * var(--minHeU, 1px))}
.a-max-height {max-height: calc(var(--c) * var(--cx) * var(--maxHeU, 1px))}
.a-max-width {max-width: calc(var(--e) * var(--ex) * var(--maxWidth-un, 1px))}
.a-min-width {min-width: calc(var(--f) * var(--fx) * var(--mminWidth-un, 1px))}
.a-flex-width {--width: calc(var(--f) * var(--fx) * var(--width-un, 1px)); width: var(--width);flex-basis: var(--width);}
.a-flex-basis {flex-basis: calc(var(--f) * var(--fx) * var(--width-un, 1px));}
