@import url("./gradientFillings.css");

.a-btn {--color:var(--hex);color: var(--color);  --hsla:hsla(var(--h),var(--s),max(min(var(--l) + var(--lHov), 100%), 0%), var(--a, 1));
        --hsl2: hsl(var(--h2),var(--s2),max(min(var(--l2) + var(--lTxtHov), 100%), 0%));  --lHov: 0%; --lTxtHov: 0%;--lIcHov:0%;
        background-color: var(--hsla);  border: var(--btnBrd) solid var(--color);}
.a-btn.btn-test {background-color: var(--btnBkg); color:var(--btnColor);--brdColor:var(--btnColor); --iconColor:var(--btnColor);}
.a-btn-lTxt {--color:var(--hsl2);}

.multi-drop-header .exp-arrow.arrow-custom-colors .icon-btn-color:hover  {fill: var(--hex2);}


.a-btn-filled-white { background-color: white;}
.a-btn-brd-color {border-color: var(--hex1);}
.a-btn-lClick {--lHov:var(--lClick);}
.a-btn-opac,.a-btn-opac-04 {opacity: 0.4} .a-btn-opac-03 {opacity: 0.3} .a-btn-opac-02 {opacity: 0.2}
.a-bkg-transparent {background-color: transparent !important;}
.no-blur-bkg:not(:hover) {background-color: transparent}

/**** Icon Colors *******/
.icon-btn-color {fill: var(--color);}
.icon-btn-custom-hsl.icon-btn-color {fill: hsl(var(--h),var(--s),max(min(var(--l) + var(--lIcHov), 100%), 0%));}
.icon-btn-custom-hex.icon-btn-color {fill: var(--hex);}
.arrow-custom-colors .icon-btn-color  {fill: var(--hex);}
.drop-down-arrow-up.arrow-custom-colors .icon-btn-color  {fill: var(--hex1);}
.btn-arrow.arrow-custom-colors .icon-btn-color:hover  {fill: var(--hex2);}/* We do the hover only on the icon on the button drop arrow because when the drop down is open, the button remains hovered even if we are hovering the dropdown because the dropdown is positioned inside the button ...*/

/**** Icon Colors *******/

@media (hover: hover) {
    /* Text hover colors - if no custom icon colors are set then also icons will use this same hover colors */
    .a-btn-txt-hover:hover:not(.no-hover) {--color: var(--hex3); }
    .a-btn-lTxt:hover:not(.no-hover) {--lTxtHov:var(--lTxt);}
    /* brd colors */
    .a-btn-brd-hover-color:hover:not(.no-hover) {border-color: var(--hex2);}
    /* bkg hover */
    .a-btn-lBkg:hover:not(.no-hover) {--lHov:var(--lBkg);}
    .a-btn-filled-white:hover:not(.no-hover) {background-color: hsla(var(--h2),var(--s2),abs(100% - var(--lBkg)), var(--a, 1));}
    .a-btn-bkg-hover:hover:not(.no-hover) {background-color: hsla(var(--h1),var(--s1),var(--l1), var(--a, 1));}
    /**** Icon hover Colors  *******/
        /* If icons custom colors are not set then hover colors follow the text hover colors, because in icon-btn-color  we set the fill  equal to the text --color var*/
    .a-btn:hover:not(.no-hover) .icon-btn-custom-hsl.icon-btn-color  {--lIcHov:var(--lIcon);}
    .a-btn:hover:not(.no-hover) .icon-btn-custom-hover-color.icon-btn-color {fill: var(--hex1);}
    /**** Icon hover Colors *******/
}





.force-hover {}


/*

.a-btn {color: var(--btnColor); background-color: var(--btnBkg); --brdColor:var(--btnColor); --iconColor:var(--btnColor);}


a-btn-bordered   -> text and border have the same color, on hover the luminosity is increased ( or reduced if -dark); background can be added as a class separately
      text and border: (--h,--s,--l,--a)
      hover opacity: --l-hov for the hover incresead opacity default is 10%
          (.a-btn-bordered-dark  same as before but on hover the opacity in reduced)
      background: transparent unless added with a separate class

.a-btn-bordered-whiteBkg: text and border have the same color, bkg is white, on hover bkg becomes a very light version of text and border color, same h,s value but very hight l; text and border should have a low l value, because on hover bkg should have a very high l to be almost white
      text and border: (--h,--s,--l,--a)
      bkg white
      bkg howr --l-hov -> bagground set to (--h,--s, 100 - llhov)

.a-btn-bordOnHover -> text and border have the same color but the border is displayed only on hover
    text and border: --btn-color
    backgrond: trasnparent unless added with a separate class
.a-btn-textOnHover -> text color changes on hover. Background not defined here, can be added separately as a class
    text: --btn-color
    text hover: --btn-colorH
    backgrond: trasnparent unless added with a separate class

.a-btn-filled, .a-btn-filled-dark -> background luminosity is increased on hover (or decreased if .dark)
    text: --btn-color
    background: (--h,--s,--l,--a) for the background + --l-hov for the hover incresead opacity default is 10%
    hover opacity: --l-hov
*/

.a-btn-HW {border: var(--brdSize, 1px) solid transparent;background-color: rgba(0,0,0,.3); color: white; --iconColor:white;}
.a-btn-HW .a-btn-tooltip {bottom: -7px;}
  .a-btn-HW:hover:not(.no-hover) {border: var(--brdSize, 1px) solid #a9afbc;}
  .a-btn-HW.force-hover {border: var(--brdSize, 1px) solid #a9afbc;}
