.top-bar {--pdC:12px; padding: 0 var(--pdc);--sideWidth:20%; height: var(--top-bar-height);top:0;left: 0;width: 100%;transition: height 0.5s}
.top-bar > div {transition: background-color 0.5s}
.top-bar-padding {padding-top: var(--top-bar-height);}
.top-bar-side {width:var(--sideWidth);}
.top-bar-center {width:calc(100% - 2 * (var(--pdC) + var(--sideWidth)));}
.colorsvg1 {stop-color:#fff33b}
.svgtopbar {--col1:#fff33b}
.svgtopbar:hover {--col1:blue}
.top-bar .a-btn:first-child {margin-left: 0!important}

@media (max-width:1200px) {
}

@media (max-width:734px) {
  .top-bar {--btnSize: calc(var(--n) * var(--nx) * var(--heU, 1px));--pdC:1vw; --sideWidth:calc(2 * var(--btnSize)); }
  .btn-height-em.top-bar {--btnSize:calc(var(--n) * var(--fSize) * 0.1);}
}
