.a-color {color: var(--hex)}
.a-color1 {color: var(--hex1)}
.a-color2 {color: var(--hex2)}

@media (hover: hover) {
  .a-color-h:hover {color: var(--hex) !important;}
  .a-color1-h:hover {color: var(--hex1) !important;}
  .a-color2-h:hover {color: var(--hex2) !important;}
}

.a-bkg {background-color: var(--hex)}
.a-bkg1 {background-color: var(--hex1)}
.a-bkg2 {background-color: var(--hex2)}

.a-hsl-bkg:not(.no-bkg) {background-color: hsla(var(--h),var(--s),var(--l), var(--a, 1)); } /* add sm and plus-sign || minus-sign for hover*/
.a-hsl1-bkg:not(.no-bkg) {background-color: hsla(var(--h1),var(--s1),var(--l1), var(--a, 1));} /* add sm1 and plus-sign1 || minus-sign1 for hover*/
.a-hsl2-bkg:not(.no-bkg) {background-color: hsla(var(--h2),var(--s2),var(--l2), var(--a, 1));} /* add sm2 and plus-sign2 || minus-sign2 for hover*/

@media (hover: hover) {
  .a-bkg-h:hover {background-color: var(--hex) !important;}
  .a-bkg1-h:hover {background-color: var(--hex1) !important;}
  .a-bkg2-h:hover {background-color: var(--hex2) !important;}
  .a-hsl-bkg:hover:not(.no-hover) {background-color:hsla(var(--h),var(--s),calc(var(--l) + var(--sm,1) * 1% * var(--sign, 1)), var(--a, 1)); }
  .a-hsl1-bkg:hover:not(.no-hover) {background-color:hsla(var(--h1),var(--s1),calc(var(--l1) + var(--sm1,1) * 1% * var(--sign1, 1))), var(--a, 1)); }
  .a-hsl2-bkg:hover:not(.no-hover) {background-color:hsla(var(--h2),var(--s2),calc(var(--l2) + var(--sm2,1) * 1% * var(--sign2, 1)), var(--a, 1)); }
  .a-hsl-bkg-h:hover {background-color: hsla(var(--h),var(--s),var(--l), var(--a, 1)) !important; }
  .a-hsl1-bkg-h:hover {background-color: hsla(var(--h1),var(--s1),var(--l1), var(--a, 1)) !important;}
  .a-hsl2-bkg-h:hover {background-color: hsla(var(--h2),var(--s2),var(--l2), var(--a, 1)) !important;}
}
