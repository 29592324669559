/* btn-row icon */
.drop-row-icon-size {--iconSize: var(--btnIconSize) !important; margin-right: var(--drRowGap,0.3em);}
.drop-row-icon-color {fill: var(--drop-btn-color);} /* Default Value is the btn color */
.drop-icon-lTxt.drop-row-icon-color {fill:hsl(var(--h2),var(--s2),max(min(var(--l2) + var(--lTxtVar), 100%), 0%));}  /* There is an lTxt on hover, we use a custom hsl2 color and a custom lTxt or the button lTxt as default*/
.drop-icon-blur {fill: var(--hex2);}  /* We set a custom icon color withhout lTxt on hover*/


@media (hover: hover) {
  .a-btn-drop-row:hover:not(.no-hover) .drop-row-icon-color.drop-icon-hover {fill: var(--hex3);} /* We set a specific icon color on hover*/
  .a-btn-drop-row:hover:not(.no-hover) .drop-row-icon-color.drop-icon-lTxt.custom-lTxt {--lTxtVar: var(--icon-lTxt);}
}


/* Custom icon color set for a specific row */
.a-btn-icon-custom-col.drop-row-icon-color {fill:var(--hex2) ;}
.drop-row-lTxt .a-btn-icon-custom-col.drop-row-icon-color {fill:hsl(var(--h2),var(--s2),max(min(var(--l2) + var(--lTxtVar), 100%), 0%));;}
.a-btn-icon-custom-lTxt.drop-row-icon-color {fill:hsl(var(--h2),var(--s2),max(min(var(--l2) + var(--lTxtVar), 100%), 0%));--lTxtVar:0%;}

@media (hover: hover) {
  .a-btn-drop-row:hover:not(.no-hover) .drop-row-icon-color.a-btn-icon-custom-lTxt{--lTxtVar: var(--icon-lTxt);}
  .a-btn-drop-row:hover:not(.no-hover) .drop-row-icon-color.a-btn-icon-custom-col-hov {fill:var(--hex3);} /* We set a specific icon color on hover*/
}
/* Custom icon color set for a specific row */
