  .nCols-1 {--nCols: 1;} .nCols-2 {--nCols: 2;} .nCols-3 {--nCols: 3;} .nCols-4 {--nCols: 4;} .nCols-5 {--nCols: 5;} .nCols-6 {--nCols: 6;} .nCols-7 {--nCols: 7;} .nCols-8 {--nCols: 8}
  .nCols-9 {--nCols: 9;} .nCols-10 {--nCols: 10;} .nCols-11 {--nCols: 11;} .nCols-12 {--nCols: 12;} .nCols-13 {--nCols: 13;} .nCols-14 {--nCols: 14;} .nCols-15 {--nCols: 15;}
  .a-flex-col {width: calc((100% - ((var(--nCols) - 1) * var(--fxMrR))) / var(--nCols)); margin-right: var(--fxMrR);margin-bottom: var(--fxMrB);}
  .a-pFlex-col {width: calc((100% - ((var(--nCols) - 1) * var(--fxMrR))) * var(--f) / 100); margin-right: var(--fxMrR);margin-bottom: var(--fxMrB);}

  .no-mrR-1 > div:nth-child(1n) {margin-right: 0!important; --displaySeparator: none}
  .no-mrR-2 > div:nth-child(2n) {margin-right: 0!important; --displaySeparator: none}.no-mrR-3 > div:nth-child(3) {margin-right: 0!important; --displaySeparator: none}
  .no-mrR-4 > .a-flex-col:nth-child(4n) {margin-right: 0!important; --displaySeparator: none}
  .no-mrR-5 > div:nth-child(5n) {margin-right: 0!important; --displaySeparator: none}.no-mrR-6 > div:nth-child(6n) {margin-right: 0!important; --displaySeparator: none}.no-mrR-7 > div:nth-child(7n) {margin-right: 0!important; --displaySeparator: none}
  .no-mrR-8 > div:nth-child(8n) {margin-right: 0!important; --displaySeparator: none}.no-mrR-9 > div:nth-child(9n) {margin-right: 0!important; --displaySeparator: none}.no-mrR-10 > div:nth-child(10n) {margin-right: 0!important; --displaySeparator: none}
  .no-mrR-11 > div:nth-child(11n) {margin-right: 0!important; --displaySeparator: none}.no-mrR-12 > div:nth-child(12n) {margin-right: 0!important; --displaySeparator: none}.no-mrR-13 > div:nth-child(13n) {margin-right: 0!important; --displaySeparator: none}

  .no-last-mrB-1 > div:nth-last-child(-n+1) {margin-bottom: 0!important}.no-last-mrB-2 > div:nth-last-child(-n+2) {margin-bottom: 0!important}
  .no-last-mrB-3 > div:nth-last-child(-n+3) {margin-bottom: 0!important}.no-last-mrB-4 > div:nth-last-child(-n+4) {margin-bottom: 0!important}
  .no-last-mrB-5 > div:nth-last-child(-n+5) {margin-bottom: 0!important}.no-last-mrB-6 > div:nth-last-child(-n+6) {margin-bottom: 0!important}
  .no-last-mrB-7 > div:nth-last-child(-n+7) {margin-bottom: 0!important}.no-last-mrB-8 > div:nth-last-child(-n+8) {margin-bottom: 0!important}
  .no-last-mrB-9 > div:nth-last-child(-n+9) {margin-bottom: 0!important}.no-last-mrB-10 > div:nth-last-child(-n+10) {margin-bottom: 0!important}
