

.uld-sld-outer {position: relative;height: 100%;width: 100%;  }
.ul-sld-cont {height: 100%; width:100%;--mrTot: calc(var(--length) * var(--liMr));padding:0 var(--pd);}
.uld-sld-outer.first-uld-index .ul-sld-cont {padding-left: 0;/*padding-right: calc(var(--sliderPd) * 2);*/}
.uld-sld-outer.last-uld-index .ul-sld-cont. {padding-right: 0;/*padding-left: calc(var(--sliderPd) * 2)*/;}
.ul-sld-ul {--liWidth: calc((100% - var(--liMr) * (var(--cols) - 1)) / var(--cols));height: 100%;width: calc((var(--liWidth) + var(--liMr)) * var(--length));
  --touch:var(--movX);
  --final: calc(-1 * (var(--fPos) + 1) * var(--liMr) - 1 * var(--slideWidth) * var(--fPos) + var(--touch));
  --next:calc(var(--cols) * var(--liMr) + var(--slideWidth) * var(--cols) + var(--final) + var(--movX));
  --prev:calc(-1 * var(--cols) * var(--liMr) - var(--slideWidth) * var(--cols) + var(--final) + var(--movX));
  transform: translateX(var(--final)); --slideWidth:calc((100% - var(--mrTot))/ var(--length));}
.ul-sld-ul.ul-sld-anim-next, .ul-sld-ul.ul-sld-anim-prev,.ul-sld-ul.ul-sld-anim-next-2, .ul-sld-ul.ul-sld-anim-prev-2 {--touch:0px;}
.uld-last-iter .ul-sld-ul {--next:calc(var(--last) * var(--liMr) + var(--slideWidth) * var(--last) + var(--final) + var(--movX));
  --prev:calc(-1 * var(--last) * var(--liMr) - var(--slideWidth) * var(--last) + var(--final) + var(--movX));}
ul.ul-sld-ul > li {height: 100%;width: var(--slideWidth); vertical-align: top;margin-left: var(--liMr);}
.ul-sld-anim-next {animation: ul-sld-anim-next 400ms linear;}
.ul-sld-anim-next-2 {animation: ul-sld-anim-next-2 400ms linear;}
.ul-sld-anim-prev {animation: ul-sld-anim-prev 400ms linear;}
.ul-sld-anim-prev-2 {animation: ul-sld-anim-prev-2 400ms linear;}
.right-padded-arrow {right: calc(var(--pdC) * 0.5); top: 50%;transform: translate(-50%,50%);}
.left-padded-arrow {left: calc(var(--pdC) * 0.5); top: 50%;transform: translate(-50%,-50%);}

  @keyframes ul-sld-anim-next {
    from {
        -webkit-transform: translateX(var(--next));
        transform: translateX(var(--next));
    }

    to {
        -webkit-transform: translateX(var(--final));
        transform: translateX(var(--final));

    }
  }
  @keyframes ul-sld-anim-next-2 {
    from {
        -webkit-transform: translateX(var(--next));
        transform: translateX(var(--next));
    }

    to {
        -webkit-transform: translateX(var(--final));
        transform: translateX(var(--final));

    }
  }



  @keyframes ul-sld-anim-prev {
    from {
        -webkit-transform: translateX(var(--prev));
        transform: translateX(var(--prev));
    }

    to {
        -webkit-transform: translateX(var(--final));
        transform: translateX(var(--final));
    }
  }

  @keyframes ul-sld-anim-prev-2 {
    from {
        -webkit-transform: translateX(var(--prev));
        transform: translateX(var(--prev));
    }

    to {
        -webkit-transform: translateX(var(--final));
        transform: translateX(var(--final));
    }
  }
