.sld-pan-cont {  --fx-side-width: calc(var(--f) * var(--fx) * var(--width-un, 1px)) ;}
.sld-pan-abs-cont { position: absolute;top: 0;right: 0;  bottom: 0;  left: 0;  overflow: hidden;  overflow-y: auto;-webkit-font-smoothing: subpixel-antialiased; z-index: 200;}
.sld-pan-abs-cont::-webkit-scrollbar {width: 0;}
.sld-pan-overlay {background-color: rgba(0,0,0,0.5); z-index: 9}
.sld-pan-right {--sld-pan-btn-Xtrasf: 100%}
.sld-pan-left {--sld-pan-btn-Xtrasf: -100%}
.sld-pan-inner {position: absolute; width: var(--fx-side-width); /*right: 0;*/ overflow: visible; height: 100%;-webkit-box-shadow: 0 0 14px #444;box-shadow: 0 0 14px #444;
   z-index: 150; }
.sld-pan-child-cont {width: 100%; overflow-x: hidden;height: 100%;position: relative;z-index: 350;min-height: 100%;background-color: inherit;}
.sld-pan-right .sld-pan-inner {right: 0; --sld-pan-Xtrasf: calc(var(--xs) / 10 * var(--fx-side-width)); }
.sld-pan-left .sld-pan-inner {left: 0; --sld-pan-Xtrasf: calc(-1 * var(--xs) / 10 * var(--fx-side-width)); }
.sld-pan-fade-in {animation: sliding-panel-in calc(var(--b) * 0.1s); opacity: 1;}
.sld-pan-fade-out {animation: sliding-panel-out calc(var(--b) * 0.1s); opacity: 0;transform: translateX(var(--sld-pan-Xtrasf));}


.sld-pan-close-btn {   width: calc(var(--fSize) * 3.3); height:calc(var(--fSize) * 2.3); position: absolute;top: calc(var(--f) * 1%); z-index: 50;border-radius: 5px;cursor: pointer;
  -webkit-box-shadow: 0 0 14px #444;box-shadow: 0 0 14px #444;padding: calc(var(--fSize) * 0.6);}

.sld-pan-right .sld-pan-close-btn { right:calc(100% - var(--fSize) * 1.1);}
.sld-pan-left .sld-pan-close-btn { left: calc(100% - var(--fSize) * 1.1);}



@-webkit-keyframes sliding-panel-in {
  from {
      -webkit-transform: translateX(var(--sld-pan-Xtrasf));
      transform: translateX(var(--sld-pan-Xtrasf));
      opacity:0;
  }

  to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity:1
  }
}

@keyframes sliding-panel-in {
  from {
      -webkit-transform: translateX(var(--sld-pan-Xtrasf));
      transform: translateX(var(--sld-pan-Xtrasf));
      opacity:0
  }

  to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity:1
  }
}

@-webkit-keyframes sliding-panel-out {
  from {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity:1
  }

  to {
      -webkit-transform: translateX(var(--sld-pan-Xtrasf));
      transform: translateX(var(--sld-pan-Xtrasf));
      opacity:0
  }
}

@keyframes sliding-panel-out {
  from {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity:1
  }

  to {
      -webkit-transform: translateX(v);
      transform: translateX(var(--sld-pan-Xtrasf));
      opacity:0
  }
}
