.input-icon-size {--iconSize: calc(var(--fSize) * var(--inputIconEm)) !important; margin-right: calc(var(--iconSize) * var(--iconMrR));}
.input-icon-color {fill: var(--inputColor);}
.input-icon-em {--inputIconEm: calc(var(--d) / 10);--iconMrR: 0.5;}
.input-icon-mr-em {--iconMrR: calc(var(--e) / 10);}


.div-select-inner {--iconEm: calc(var(--d) / 10 * var(--fSize));--iconMrRightEm: calc(var(--iconEm) * 0.5);--checkEm:calc(var(--fSize) * 1.1);}
.select-check-em {--checkEm: calc(var(--c) / 10 * var(--fSize));}
.div-select-acive-check {position: absolute;left: calc(var(--singleLeftPad) * 0.5);top:50%; transform: translate(-45%,-50%);width: var(--checkEm) !important;height:var(--checkEm) !important; }

.select-icon-mr-em {--iconMrRightEm:calc(var(--iconEm) * var(--f) / 10);}
.div-select-icon-size {--iconSize: var(--iconEm) !important; margin-right: var(--iconMrRightEm);}
.div-select-icon-size.iconAdj {--iconSize:calc(var(--iconEm) * 0.01 * (100 + var(--sign) * var(--e,0))) !important;--mrAdj: calc(var(--iconEm) * -0.01 * (var(--sign) * var(--e,0)) * 0.5);margin-left: var(--mrAdj);
  margin-right: calc(var(--iconMrRightEm) + var(--mrAdj));}
.div-select-icon-color {fill: var(--drop-btn-color);}
.div-select-active-icon {--iconActColor: var(--hex);}
.drop-b-icon {--drop-btn-icon-color: var(--hex2);}
.drop-l-icon {--hIcon:var(--h2);--sIcon:var(--s2);--lIcon:var(--l2);}
.drop-l-icon.custom-lTxt {--lTxtVarIcon:calc(var(--sm) * 1% * var(--sign));}
.drop-h-icon {--drop-btn-icon-color-hover: var(--hex3);}
.drop-b-icon .div-select-icon-color {fill:var(--drop-btn-icon-color);}
.drop-l-icon .div-select-icon-color {fill:hsl(var(--hIcon),var(--sIcon),max(min(var(--lIcon) + var(--lIconHov), 100%), 0%));--lIconHov:var(--lTxtVar);}
.div-select-active-icon .select-option-active .div-select-icon-color {fill:var(--iconActColor);}

@media (hover: hover) {
  .drop-h-icon .div-select-option-item:hover:not(.no-hover) .div-select-icon-color {fill: var(--drop-btn-icon-color-hover);}
  .drop-l-icon.custom-lTxt .div-select-option-item:hover:not(.no-hover) .div-select-icon-color {--lIconHov:var(--lTxtVarIcon);}

}
