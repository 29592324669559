.t-px {--t-un: 1px}  .t-pc {--t-un: 1%} .t-vw {--t-un: 0.1vw} .t-vh {--t-un: 0.1vh}
.r-px {--r-un: 1px}  .r-pc {--r-un: 1%} .r-vw {--r-un: 0.1vw} .r-vh {--r-un: 0.1vh}
.b-px {--b-un: 1px}  .b-pc {--b-un: 1%} .b-vw {--b-un: 0.1vw} .b-vh {--b-un: 0.1vh}
.l-px {--l-un: 1px}  .l-pc {--l-un: 1%} .l-vw {--l-un: 0.1vw} .l-vh {--l-un: 0.1vh}

@media (max-width:1200px) {
.t-px-t {--t-un: 1px}  .t-pc-t {--t-un: 1%} .t-vw-t {--t-un: 0.1vw} .t-vh-t {--t-un: 0.1vh}
.r-px- {--r-un: 1px}  .r-pc-t {--r-un: 1%} .r-vw-t {--r-un: 0.1vw} .r-vh-t {--r-un: 0.1vh}
.b-px-t {--b-un: 1px}  .b-pc-t {--b-un: 1%} .b-vw-t {--b-un: 0.1vw} .b-vh-t {--b-un: 0.1vh}
.l-px- {--l-un: 1px}  .l-pc-t {--l-un: 1%} .l-vw-t {--l-un: 0.1vw} .l-vh-t {--l-un: 0.1vh}

}

@media (max-width:734px) {
.t-px-s {--t-un: 1px}  .t-pc-s {--t-un: 1%} .t-vw-s {--t-un: 0.1vw} .t-vh-s {--t-un: 0.1vh}
.r-px-s {--r-un: 1px}  .r-pc-s {--r-un: 1%} .r-vw-s {--r-un: 0.1vw} .r-vh-s {--r-un: 0.1vh}
.b-px-s {--b-un: 1px}  .b-pc-s {--b-un: 1%} .b-vw-s {--b-un: 0.1vw} .b-vh-s {--b-un: 0.1vh}
.l-px-s {--l-un: 1px}  .l-pc-s {--l-un: 1%} .l-vw-s {--l-un: 0.1vw} .l-vh-s {--l-un: 0.1vh}

}

.a-mr-4 {margin: calc(var(--g) * var(--gx) * var(--t-un,1px)) calc(var(--m) * var(--mx) * var(--r-un,1px)) calc(var(--n)  * var(--nx) * var(--b-un,1px)) calc(var(--t) * var(--tx) * var(--l-un,1px));}
.a-mr-1 {margin: calc(var(--g) * var(--gx) * var(--t-un,1px))}
.a-mrS {--mrS: calc(var(--g) * var(--gx) * var(--t-un,1px)); margin-top: var(--mrS); margin-bottom: var(--mrS);}
.a-mrC {--mrC: calc(var(--m) * var(--mx) * var(--l-un,1px)); margin-left: var(--mrC);margin-right: var(--mrC);}
.a-mrT {margin-top: calc(var(--g) * var(--gx) * var(--t-un,1px));}
.a-mrR {margin-right: calc(var(--m) * var(--mx) * var(--r-un,1px));}
.a-mrR-var {--mrR: calc(var(--m) * var(--mx) * var(--r-un,1px));}
.a-mrB {margin-bottom: calc(var(--n) * var(--nx) * var(--b-un,1px));}
.a-mrB-var {--mrB: calc(var(--n) * var(--nx) * var(--b-un,1px));}
.a-mrL {margin-left: calc(var(--t) * var(--tx) * var(--l-un,1px));}

.a-pd-4 {padding: calc(var(--g) * var(--gx) * var(--t-un,1px)) calc(var(--m) * var(--mx) * var(--r-un,1px)) calc(var(--n)  * var(--nx) * var(--b-un,1px)) calc(var(--t) * var(--tx) * var(--l-un,1px));}
.a-pd-1 {padding: calc(var(--g) * var(--gx) * var(--t-un,1px))}
.a-pd-1-var {--pd: calc(var(--g) * var(--gx) * var(--t-un,1px))}
.a-pdS {--pdS: calc(var(--g) * var(--gx) * var(--t-un,1px));padding-top: var(--pdS); padding-bottom: var(--pdS);}
.a-pdC {--pdC: calc(var(--t) * var(--tx) * var(--l-un,1px)); padding-left: var(--pdC); padding-right: var(--pdC);}
.a-pdT {padding-top: calc(var(--b) * var(--bx) * var(--t-un,1px));}
.a-pdR {padding-right: calc(var(--m) * var(--mx) * var(--r-un,1px));}
.a-pdB {padding-bottom: calc(var(--n) * var(--nx) * var(--b-un,1px));;}
.a-pdL {padding-left: calc(var(--t) * var(--tx) * var(--l-un,1px));}
