

.multi-drop-header {font-size: var(--headFsize); padding: 0 var(--headerPdC); font-weight: var(--headWeight); line-height: var(--headLh);text-align: left;
  height: var(--headHe); }
.btn-drop-expanded .multi-drop-header {margin-bottom: var(--expMrB);}
.mukti-drop-container .drop-down-arrow {--dropTipSize:calc(var(--expTipSize) * 1px);
  --tipPosY: calc(var(--headHeight) / 2 + var(--expMrB) - var(--expArrowSize) / 2);}


.multi-drop-header:not(.multi-drop-expandable) {--expArrowSize: 0px;}
.multi-drop-expandable .multi-drop-header-title {--dropHeTiMrR: calc(var(--expArrowSize) * 0.5); margin-right:var(--dropHeTiMrR)}

.multi-drop-header-title {max-width: calc(100% - var(--headIconEm,0px) * 1.5 - var(--expArrowSize) - var(--dropHeTiMrR,0px));}
.multi-drop-header-icon-size {--iconSize: var(--headIcon) !important; margin-right: calc(var(--headGap));}
.m-drop-henoIc.multi-drop-header-title {--headIconEm:0px;}
