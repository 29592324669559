
.btn-head-custom-text {--headerColor:var(--hex);}
.btn-head-custom-icon {--headerIconColor: var(--hex2);}
.btn-head-expanded-txt .btn-drop-expanded {--headerColor:var(--hex1);--headerIconColor:var(--hex1);}
.btn-head-expanded-icon .btn-drop-expanded {--headerIconColor:var(--hex3);}
.multi-drop-expandable.multi-drop-header .multi-drop-header-title:hover {--headerColor:rgb(var(--r1),var(--g1),var(--b1));--headerIconColor:rgb(var(--r1),var(--g1),var(--b1));}
.multi-drop-header-title {color: var(--headerColor);}
.multi-drop-header-icon-color {fill: var(--headerIconColor);}



/***** HR    *******/
.btn-drop-separator {display: var(--displaySeparator,inline-block);position: absolute;right: calc(-0.5 * var(--fxMrR));top:5px;width:var(--md-hrWidth);
  height: calc(100% - 10px);box-sizing: border-box; margin:0;border-left: var(--md-hrWidth) solid var(--drop-hr-color);
  --drop-hr-color: hsl(var(--h),var(--s),max(min(var(--l) + var(--hr-lBkg), 100%), 0%));}
.hex-hr-drop {--drop-hr-color: var(--hex);}
/***** HR    *******/
