.multi-drop-container {
  --multiDropPd:16px;
  --md-hrWidth:1;
  /* Expander Vars */
  --expPd:0px;--expTip:var(--tipSize);--expMrB:0;--expArrowSize:1em;--expArrowMrL:1em;
  /**  Header Vars  */
  --headerColor: black;--headerIconColor: var(--headerColor);
  --headerPdC: var(--rowContainer);--headHe:2em;--headIcon:1em;--headFsize:1em;--headWeight:500;--headLh:1;--headGap:calc(var(--headIcon) / 2);

  padding:var(--multiDropPd);
}
.btn-drop-no-headPdS {--headerPdT: 0;--headerPdB: 0; }

.a-btn-drop-menu { }
