.drop-btns-1 {--nBtns: 1} .drop-btns-2 {--nBtns: 2} .drop-btns-3 {--nBtns: 3} .drop-btns-4 {--nBtns: 4} .drop-btns-5 {--nBtns: 5} .drop-btns-6 {--nBtns: 6} .drop-btns-7 {--nBtns: 7}
.drop-btns-8 {--nBtns: 8} .drop-btns-9 {--nBtns: 9} .drop-btns-10 {--nBtns: 10} .drop-btns-11 {--nBtns: 11} .drop-btns-12 {--nBtns: 12} .drop-btns-13 {--nBtns: 13} .drop-btns-14 {--nBtns: 14} .drop-btns-15 {--nBtns: 15}

.drop-section-buttons {overflow: hidden;padding: var(--expPd);transition: height min(.08s * var(--nBtns),.8s) linear;
  height: calc(var(--rowHeight) * var(--nBtns) + 2 * var(--expPd));border-radius: var(--expRad,10px);}
.drop-section-buttons-closed {height: 0;padding: 0;}
.exp-bkg {--lExp: 5%;}
.exp-bkg.lBkg-exp {--lExp: var(--lBkgExp);}
.exp-bkg .drop-buttons-outer {--lx: max(min(var(--l) + var(--lExp), 100%), 0%);background-color: var(--expButtonBkg);--expButtonBkg: hsl(var(--h),var(--s),var(--lx));border-radius: var(--expRad,10px);}


/* Expander Buttons Tip */

.drop-multi-flex-wrapper {--expTipSize:var(--expTip);}
.btn-drop-expanded .a-multi-drop-tip {position: absolute;bottom: 100%;right:calc(var(--expArrowSize) / 2 + var(--headerPdC)); transform: translateX(50%);z-index: 1000;--iconSize:var(--expTip);}
.btn-drop-expanded .a-multi-drop-tip-color {fill:var(--expButtonBkg);}
.drop-section-buttons-closed .a-multi-drop-tip {display: none;}
