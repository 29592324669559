@import "./css/css/Various/flex.css"; @import "./css/css/Various/colors.css"; @import "./css/css/Various/firsts_lasts.css"; @import "./css/css/Various/vars.css";
@import "./css/css/Various/scroll.css";@import "./css/css/Various/int.css";@import "./css/css/Various/display.css";
@import "./css/css/Various/zIndex.css";@import "./css/css/Various/scrollBar.css";@import "./css/css/Various/transitions.css";@import "./css/css/Various/cursorSelectHover.css";
@import "./css/css/Various/overflow_textAlign.css";@import url("./Buttons/Button/css/gradientFillings.css");@import "./css/cal/css/pd.css";@import "./css/cal/css/mr.css";@import "./css/cal/css/fontSize.css";
/*
html {
  Disables pull-to-refresh but allows overscroll glow effects.
  overscroll-behavior-y: none;
}
*/

.app-container {--btnColor:white;--btnBkg:dodgerblue;--top-bar-height:65px}

h1,h2,h3,h4,h5,h6,p {margin: 0; padding: 0}
input:autofill, input:-webkit-autofill-strong-password, input:-webkit-autofill-strong-password-viewable, input:-internal-autofill-selected, input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active, .w3-input:autofill {
background-color: transparent !important;
background-image:none !important;
color: inherit;
}

body {

  overscroll-behavior-x: none;
}

body {box-sizing: border-box; font-family: "Segoe UI", Arial, sans-serif;--color1:"#fff33b";
      --top-bar-height:0px;
}

input {border: none;outline: none;padding: 0;margin: 0;}
input:focus {border: none;outline: none;}

ul.ul-clear {margin: 0; padding: 0; list-style-type: none;}
ul.ul-clear > li {list-style: none; display: block;}
ul.ul-clear-inline {margin: 0; padding: 0; list-style-type: none;}
ul.ul-clear-inline > li {list-style: none; display: inline-block;}


.animate-opacity-01{animation:opac 0.12s}.animate-opacity-02{animation:opac 0.2s}.animate-opacity-04{animation:opac 0.4s}.animate-opacity-06{animation:opac 0.6s} .animate-opacity-08{animation:opac 0.8s} .animate-opacity {animation:opac 1s}
@keyframes opac{from{opacity:0} to{opacity:1}}


/*
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
