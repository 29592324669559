.form-outer-container {--inpPdL:var(--iPdC, var(--iPdL, 12px));--inpPdR:var(--iPdC, var(--iPdR, 12px));--inpPdT:var(--iPdS, var(--iPdT, 0px));--inpPdB:var(--iPdS, var(--iPdB, 0px));}
.label-top.form-outer-container  {--inpPdT: calc(var(--lbHe) + var(--iPdS, var(--iPdT, 0px)));}
.label-left.form-outer-container {--inpPdL: calc(var(--labelWd) + var(--iPdC, var(--iPdL, 12px)));}
.form-container {border-radius: var(--brdRad);border: var(--brdSize,0) solid var(--brdCl,var(--inpCl));height: var(--height);transition: height var(--tranTime) linear;
  position: relative;color:var(--inpCl);background-color: var(--inpBkg); --bar: var(--barSize,var(--brdSize,0)) solid var(--barCl, var(--brdCl,var(--inpCl)));
}

.bar-left .form-container {border-left: var(--bar);}
.form-container.bar-right {border-right: var(--bar);}
.form-container.bar-bottom {border-bottom: var(--bar);}
.form-container.bar-top {border-top: var(--bar);}
.form-no-trans .form-container {transition: none;}


.x432 {
  --iPdC: 16px; --height:60px;--lbHe:16px;--lbFsize:135x;--brdSize:2px;--barSize:5px;
  --inpCl:white;--lbCl:yellow;
}

@media (hover:hover) {
  .x432:hover {}
  }

.x432.form-focused  { --iPdC: 24px;--lbCl:yellow;}

.x432.form-error {}


@media (hover:hover) {
  .x432.form-error:hover {}
  }
