@import url("./gradientFillings.css");

/**** Blur Colors *******/
.btn-color {--lum:var(--l);background-color: var(--bkg);--bkg: hsla(var(--h),var(--s),var(--lum), var(--a,1)); color:var(--color);
  --brdColor:var(--brdCl,var(--color));--color:var(--cl,white);
  --h:210;--s:100%;--l:56% ;--a:1; --cl:white;--lH:66%;--lC:36%;
      /* --clH:blue; --iconCl:white;--iconClH:yellow; --clC: green;--lT:100%; --lTHov:96%;*/
    }
/**** Blur Colors *******/

.btn-color.btn-pattern {--color:hsl(var(--h),var(--s),var(--lT));}

/**** Icon Colors *******/
.btn-color.icon-pattern .icon-btn-color {--icCl:hsl(var(--h),var(--s),var(--lIc));}
.btn-color.arrow-pattern .icon-btn-color {--arCl:hsl(var(--h),var(--s),var(--lArr));}
.btn-color .icon-btn-color {fill: var(--icCl,var(--color));}
.btn-color .drop-arrow .icon-btn-color {fill: var(--arCl,var(--color));}
/**** Icon Colors *******/

@media (hover: hover) {
  /**** Hover *******/
  .btn-color:hover:not(.no-hover), .btn-color.btn-force-hover {--lum: var(--lH, var(--l));--colorHover:var(--clH,var(--color));color:var(--colorHover);--brdColor:var(--brdClH,var(--brdCl,var(--colorHover)));}
  .btn-bkg-hov.btn-color:hover:not(.no-hover),.btn-bkg-hov.btn-color.btn-color.btn-force-hover {background-color: var(--bkgH);}
  .btn-color:hover.btn-pattern,.btn-color.btn-pattern.btn-color.btn-force-hover {--colorHover:hsl(var(--h),var(--s),var(--lTHov, var(--lT)));}
  /**** Hover *******/

  /**** Icon Hover Colors *******/
  .btn-color:hover:not(.no-hover).icon-pattern .icon-btn-color,.btn-color.btn-force-hover.icon-pattern .icon-btn-color {--icClH:hsl(var(--h),var(--s),var(--lIcH));}
  .btn-color:hover:not(.no-hover).arrow-pattern .icon-btn-color,.btn-color.btn-force-hover.arrow-pattern .icon-btn-color {--arClH:hsl(var(--h),var(--s),var(--lArrH));}
  .btn-color:hover:not(.no-hover) .icon-btn-color,.btn-color.btn-color.btn-force-hover .icon-btn-color {fill: var(--icClH,var(--colorHover));}
  .btn-color:hover:not(.no-hover) .drop-arrow .icon-btn-color,.btn-color.btn-color.btn-force-hover .drop-arrow .icon-btn-color {fill: var(--arClH,var(--colorHover));}
  /**** Icon Hover Colors *******/
}
/**+ Click **/
.btn-clicked {--lum:var(--lC,var(--lH, var(--l)));--color:var(--clC,var(--cl));color:var(--color);--brdColor:var(--brdClCl,var(--brdCl,var(--color)));}
.btn-clicked.btn-color .icon-btn-color,.btn-clicked.btn-color .drop-arrow .icon-btn-color {fill: var(--color);}
.btn-clicked.btn-color.btn-pattern {--color:hsl(var(--h),var(--s),var(--lTCl,var(--lT)));}
/**+ Click **/

/**+ Active **/
.btn-active.btn-color {background-color: var(--bkgA,var(--bkg));color:var(--clA,var(--color));--brdColor:var(--brdClA,var(--brdCl,var(--color)));}
.btn-active.btn-color .icon-btn-color {fill:var(--icClA,var(--clA,var(--color)));}
.btn-active.btn-color .drop-arrow .icon-btn-color {fill:var(--arClA,var(--clA,var(--color)));}
/**+ Active **/

.a-btn-opac,.a-btn-opac-04 {opacity: 0.4} .a-btn-opac-03 {opacity: 0.3} .a-btn-opac-02 {opacity: 0.2}
.a-bkg-transparent {background-color: transparent !important;}
.no-blur-bkg:not(:hover) {background-color: transparent}

.multi-drop-header .exp-arrow.arrow-custom-colors .icon-btn-color:hover  {fill: var(--hex2);}

/*

.a-btn {color: var(--btnColor); background-color: var(--btnBkg); --brdColor:var(--btnColor); --iconColor:var(--btnColor);}


a-btn-bordered   -> text and border have the same color, on hover the luminosity is increased ( or reduced if -dark); background can be added as a class separately
      text and border: (--h,--s,--l,--a)
      hover opacity: --l-hov for the hover incresead opacity default is 10%
          (.a-btn-bordered-dark  same as before but on hover the opacity in reduced)
      background: transparent unless added with a separate class

.a-btn-bordered-whiteBkg: text and border have the same color, bkg is white, on hover bkg becomes a very light version of text and border color, same h,s value but very hight l; text and border should have a low l value, because on hover bkg should have a very high l to be almost white
      text and border: (--h,--s,--l,--a)
      bkg white
      bkg howr --l-hov -> bagground set to (--h,--s, 100 - llhov)

.a-btn-bordOnHover -> text and border have the same color but the border is displayed only on hover
    text and border: --btn-color
    backgrond: trasnparent unless added with a separate class
.a-btn-textOnHover -> text color changes on hover. Background not defined here, can be added separately as a class
    text: --btn-color
    text hover: --btn-colorH
    backgrond: trasnparent unless added with a separate class

.a-btn-filled, .a-btn-filled-dark -> background luminosity is increased on hover (or decreased if .dark)
    text: --btn-color
    background: (--h,--s,--l,--a) for the background + --l-hov for the hover incresead opacity default is 10%
    hover opacity: --l-hov
*/

.a-btn-HW {border: var(--brdSize, 1px) solid transparent;background-color: rgba(0,0,0,.3); color: white; --iconColor:white;}
.a-btn-HW .a-btn-tooltip {bottom: -7px;}
  .a-btn-HW:hover:not(.no-hover) {border: var(--brdSize, 1px) solid #a9afbc;}
  .a-btn-HW.force-hover {border: var(--brdSize, 1px) solid #a9afbc;}
