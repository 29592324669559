.div-select-options{--drop-btn-color: var(--hex);--bkgHover: hsla(var(--h1),var(--s1),var(--l1), var(--a, 1));--brdRad: calc(var(--sm2) * 1px);
  --bkg:hsla(var(--h),var(--s),var(--l), var(--a, 1));--lTxtVar:0%;background-color: var(--bkg);}
.div-select-lTxt {--hTxt:var(--h2);--sTxt:var(--s2);--lTxt:var(--l2);--lTxtHover:calc(var(--sm) * 1% * var(--sign));}
.div-select-lBkg {--lBkgHover:calc(var(--sm) * 1% * var(--sign));}
.div-select-txt-hov {--drop-btn-color-hover:var(--hex1);}
.div-select-active-text {--activeText: var(--hex2);}
.div-select-active-bkg {--activeBkg: var(--hex3);}

.div-select-option-item {--hslaBkg: hsla(var(--h),var(--s),max(min(var(--l) + var(--lHov), 100%), 0%), var(--a, 1));--lHov:0%;color:var(--drop-btn-color);}
.single-drop.div-select-lBkg .div-select-option-item:not(.no-brd) {border-bottom: 1px solid hsla(var(--h),var(--s),max(min(var(--l) + var(--lBkgHover), 100%), 0%), var(--a, 1));}
.div-select-lTxt .div-select-option-item {--drop-btn-color:hsl(var(--hTxt),var(--sTxt),max(min(var(--lTxt) + var(--lTxtVar), 100%), 0%));}
.div-select-active-bkg .select-option-active.div-select-option-item {background-color: var(--activeBkg);}
.div-select-active-text .select-option-active.div-select-option-item {--drop-btn-color: var(--activeText);}
.div-select-drop-tip{--tipColor:var(--bkg);}
.div-select-active-tip .div-select-drop-tip {--tipColor:var(--activeBkg);}

@media (hover: hover) {
  .div-select-lTxt .div-select-option-item:hover:not(.no-hover) {--lTxtVar:var(--lTxtHover);}
  .div-select-txt-hov .div-select-option-item:hover:not(.no-hover) {--drop-btn-color: var(--drop-btn-color-hover);}
  .div-select-lBkg .div-select-option-item:hover:not(.no-hover) {--lHov:var(--lBkgHover);background-color: var(--hslaBkg);}
  .div-select-bkg-hov .div-select-option-item:hover:not(.no-hover) {background-color: var(--bkgHover);}
  .multi-drop.div-select-lBkg .div-select-option-item:hover:not(.no-hover) {border-radius: var(--brdRad);}
}
