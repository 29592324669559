.mr {margin: var(--mr);--mr-xt:var(--mr);--mr-t:var(--mr);--mr-mp:var(--mr);--mr-ml:var(--mr);}
.mrS {margin-top: var(--mrS);margin-bottom: var(--mrS);--mrS-xt:var(--mrS);--mrS-t:var(--mrS);--mrS-mp:var(--mrS);--mrS-ml:var(--mrS);}
.mrC {margin-left: var(--mrC);margin-right: var(--mrC);--mrC-xt:var(--mrC);--mrC-t:var(--mrC);--mrC-mp:var(--mrC);--mrC-ml:var(--mrC);}
.mrT {margin-top: var(--mrT);--mrT-xt:var(--mrT);--mrT-t:var(--mrT);--mrT-mp:var(--mrT);--mrT-ml:var(--mrT);}
.mrB {margin-bottom: var(--mrB);--mrB-xt:var(--mrB);--mrB-t:var(--mrB);--mrB-mp:var(--mrB);--mrB-ml:var(--mrB);}
.mrL {margin-left: var(--mrL);--mrL-xt:var(--mrL);--mrL-t:var(--mrL);--mrL-mp:var(--mrL);--mrL-ml:var(--mrL);}
.mrR {margin-right: var(--mrR);--mrR-xt:var(--mrR);--mrR-t:var(--mrR);--mrR-mp:var(--mrR);--mrR-ml:var(--mrR);}

/*


@media  (max-width: 1450px) AND (min-height: 501px) {
  .mr {margin: var(--mr-xt);}
  .mrS {margin: var(--mrS-xt);margin-bottom: var(--mrS-xt);}
  .mrC {margin-left: var(--mrC-xt);margin-right: var(--mrC-xt);}
  .mrT {margin-top: var(--mrT-xt);}
  .mrB {margin-bottom: var(--mrB-xt);}
  .mrL {margin-left: var(--mrL-xt);}
  .mrR {margin-right: var(--mrR-xt);}
}

@media  (max-width: 1150px) AND (min-height: 501px) {
  .mr {margin: var(--mr-t);}
  .mrS {margin-top: var(--mrS-t);margin-bottom: var(--mrS-t);}
  .mrC {margin-left: var(--mrC-t);margin-right: var(--mrC-t);}
  .mrT {margin-top: var(--mrT-t);}
  .mrB {margin-bottom: var(--mrB-t);}
  .mrL {margin-left: var(--mrL-t);}
  .mrR {margin-right: var(--mrR-t);}
}

@media  (max-height: 500px) AND (max-width: 970px) AND (orientation:landscape) {
  .mr {margin: var(--mr-ml);}
  .mrS {margin-top: var(--mrS-ml);margin-bottom: var(--pdS-ml);}
  .mrC {margin-left: var(--mrC-ml);margin-right: var(--pdC-ml);}
  .mrT {margin-top: var(--mrT-ml);}
  .mrB {margin-bottom: var(--mrB-ml);}
  .mrL {margin-left: var(--mrL-ml);}
  .mrR {margin-right: var(--mrR-ml);}
}

@media  (max-width: 450px) AND (orientation:portrait) {
  .mr {margin: var(--mr-mp);--mr-xm:var(--mr-mp);}
  .mrS {margin-top: var(--mrS-mp);margin-bottom: var(--mrS-mp);--mrS-xm:var(--mrS-mp);}
  .mrC {margin-left: var(--mrC-mp);margin-right: var(--mrC-mp);--mrC-xm: var(--mrC-mp);}
  .mrT {margin-top: var(--mrT-mp);--mrT-xm: var(--mrT-mp);}
  .mrB {margin-bottom: var(--mrB-mp);--mrB-xm: var(--mrB-mp);}
  .mrL {margin-left: var(--mrL-mp);--mrL-xm: var(--mrL-mp);}
  .mrR {margin-right: var(--mrR-mp);--mrR-xm: var(--mrR-mp);}
}

@media  (max-width: 350px) AND (orientation:portrait) {
  .mr {margin: var(--mr-xm);}
  .mrS {margin-top: var(--mrS-xm);margin-bottom: var(--mrS-xm);}
  .mrC {margin-left: var(--mrC-xm);margin-right: var(--mrC-xm);}
  .mrT {margin-top: var(--mrT-xm);}
  .mrB {margin-bottom: var(--mrB-xm);}
  .mrL {margin-left: var(--mrL-xm);}
  .mrR {margin-right: var(--mrR-xm);}
}
*/
