@import "defs.css";


.form-round-noLab.bordered {--brd: 2px !important}

.form-noBlur-bkg {--blurBkgColor: inherit;}

.input-color {--inputColor: var(--hex);color: var(--inputColor);}
.form-container:hover .input-color {--inputColor: var(--hex3,var(--hex));}
.input-color:focus {--inputColor: var(--hex1,var(--hex)) !important;}
.form-err .input-color {--inputColor: var(--hex2,var(--hex)) !important;}
.input-drop-arrow {fill: var(--inputColor);}
.frm-input { background-color: transparent; border: none!important; font-size: inherit;line-height: inherit; width: 100%;height: 100%;
  padding-bottom: var(--inputBotPad);padding-top: calc(var(--labelTopPad) + var(--labelBotPad) + var(--label-fSize)); transition: padding .3s linear,font-size  var(--tranTime) linear;
position: relative;z-index: 10;}
.frm-no-trans .frm-input {transition: none;}
.label-left .frm-input, .no-label .frm-input {padding-top: 0; padding-bottom: 0;}
.frm-input-container {padding-left: calc(var(--m) * var(--mx) * var(--l-un,1px) + var(--labelWidth,0px));padding-right: calc(var(--t) * var(--tx) * var(--l-un,1px))}
.label-right .frm-input {padding-right: 40%;padding-top: 0; padding-bottom: 0;}
.label-out .frm-input {padding-top: 0; padding-bottom: 0;}
.input-top-pad {padding-top: var(--inpPadTop)}
.frm-no-trans .frm-input {transition: none;}
.required {border-radius: 0 7px 0 0}
