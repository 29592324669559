[class*="a-btn-grad"] { --lHover:var(--grVar);
    --col1:  hsla(var(--h),var(--s),var(--l), var(--a, 1)); --col1-rgb:rgba(var(--r1),var(--g1),var(--b1), var(--a, 1));
    --col2: hsla(var(--h1),var(--s1),var(--l1), var(--a, 1)); --gradient:var(--col1) var(--stop1), var(--col2) var(--stop2);
  }
.grSt1-0 {--stop1:0%;}.grSt2-100 {--stop2:100%;}.no-grDir {--grDir: 0deg;}
[class*="a-btn-grad-lin"] {--grad-direction:var(--grDir);background: linear-gradient(var(--grad-direction),var(--gradient));}
[class*="a-btn-grad-rad"] {background: radial-gradient(var(--gradient));}
[class*="a-btn-grad-lin"].grad-3-col, [class*="a-btn-grad-rad"].grad-3-col {--gradient: var(--col2) var(--stop1),var(--col1) , var(--col2) var(--stop2);}
[class*="a-btn-grad-lin"].grad-4-col, [class*="a-btn-grad-rad"].grad-4-col {--gradient: var(--col2),var(--col1) var(--stop1) var(--stop2), var(--col2);}

.a-btn-grad-lin-hh, .a-btn-grad-rad-hh {--col2:hsla(max(min(var(--h) + var(--lHover), 360), 0),var(--s),var(--l), var(--a, 1));}
.a-btn-grad-lin-sh, .a-btn-grad-rad-sh {--col2:hsla(var(--h),max(min(var(--s) + var(--lHover), 100%), 0%),var(--l), var(--a, 1))}
.a-btn-grad-lin-lh, .a-btn-grad-rad-lh {--col2:hsla(var(--h),var(--s),max(min(var(--l) + var(--lHover), 100%), 0%), var(--a, 1))}
.a-btn-grad-lin-rh, .a-btn-grad-rad-rh {--col1: var(--col1-rgb); --col2: rgba(max(min(var(--r1) +  var(--lHover), 255), 0),var(--g1),var(--b1), var(--a, 1));}
.a-btn-grad-lin-gh, .a-btn-grad-rad-gh {--col1: var(--col1-rgb); --col2: rgba(var(--r1),max(min(var(--g1) + var(--lHover), 255), 0),var(--b1), var(--a, 1))}
.a-btn-grad-lin-bh, .a-btn-grad-rad-bh {--col1: var(--col1-rgb); --col2: rgba(var(--r1),var(--g1),max(min(var(--b1) + var(--lHover), 255), 0), var(--a, 1))}


@media (hover: hover) {
  .grad-hover-direction:hover:not(.no-hover){--grad-direction:var(--grDirH);}
  .a-btn-stop1-hover:hover:not(.no-hover) {--stop1:var(--grSt1H) !important;}
  .a-btn-stop2-hover:hover:not(.no-hover) {--stop2: var(--grSt2H) !important;}
  .a-ls-gradient:hover:not(.no-hover),.a-rgbh-gradient:hover:not(.no-hover) {--lHover:var(--grVarHov);}


}

.grDir-0 {--grDir: 0deg;} .grDir-10 {--grDir: 10deg;} .grDir-20 {--grDir: 20deg;} .grDir-30 {--grDir: 30deg;} .grDir-40 {--grDir: 40deg;} .grDir-50 {--grDir: 50deg;}
.grDir-60 {--grDir: 60deg;} .grDir-70 {--grDir: 70deg;} .grDir-80 {--grDir: 80deg;} .grDir-90 {--grDir: 90deg;} .grDir-100 {--grDir: 100deg;} .grDir-110 {--grDir: 110deg;}
.grDir-120 {--grDir: 120deg;} .grDir-130 {--grDir: 130deg;} .grDir-140 {--grDir: 140deg;} .grDir-150 {--grDir: 150deg;} .grDir-160 {--grDir: 160deg;} .grDir-170 {--grDir: 170deg;}.grDir-180 {--grDir: 180deg;}
.grDir-190 {--grDir: 190deg;} .grDir-200 {--grDir: 200deg;} .grDir-210 {--grDir: 210deg;} .grDir-220 {--grDir: 220deg;} .grDir-230 {--grDir: 230deg;}
.grDir-240 {--grDir: 240deg;} .grDir-250 {--grDir: 250deg;} .grDir-260 {--grDir: 260deg;} .grDir-270 {--grDir: 270deg;}.grDir-280 {--grDir: 280deg;} .grDir-290 {--grDir: 290deg;} .grDir-300 {--grDir: 300deg;}
.grDir-310 {--grDir: 310deg;}.grDir-320 {--grDir: 320deg;}.grDir-330 {--grDir: 330deg;}.grDir-340 {--grDir: 340deg;}.grDir-350 {--grDir: 350deg;}.grDir-360 {--grDir: 360deg;}
