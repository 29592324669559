
.a-sticky-col {position: -webkit-sticky; position: sticky; top: 0px;  height: 100%;}

.flex-col {flex-direction: column;}
[class*="flex-"].wrap {flex-wrap: wrap !important;}
[class*="flex-"].no-wrap,[class*="flex-"].nowrap {flex-wrap: nowrap}
[class*="flex-"] {display: flex;}
[class*="flex-start-"] { align-items: flex-start; }[class*="flex-cen-"] {align-items: center;}
[class*="flex-end-"] {align-items: flex-end;}[class*="flex-stretch-"] {align-items: stretch;}
.flex-start-start,.flex-cen-start,.flex-end-start,.flex-stretch-start {justify-content: flex-start;}
.flex-start-cen,.flex-cen-cen,.flex-end-cen,.flex-stretch-cen {justify-content: center;}
.flex-start-end,.flex-cen-end,.flex-end-end,.flex-stretch-end {justify-content: flex-end;}
.flex-start-btw,.flex-cen-btw,.flex-end-btw,.flex-stretch-btw {justify-content: space-between; flex-wrap: nowrap;}
.flx-self-top {align-self: flex-start;}
.no-shrink {flex-shrink: 0;}

.grow-1 {flex-grow: 1}.grow-2 {flex-grow: 2}.grow-3 {flex-grow: 3}.grow-4 {flex-grow: 4}.grow-5 {flex-grow: 5}.grow-6 {flex-grow: 6}.grow-7 {flex-grow: 7}.grow-8 {flex-grow: 8}.grow-9 {flex-grow: 9}.grow-10 {flex-grow: 10}
.flex-1 {order: 1} .flex-2 {order: 2} .flex-3 {order: 3} .flex-4 {order: 4} .flex-5 {order: 5} .flex-6 {order: 6} .flex-7 {order: 7} .flex-8 {order: 8} .flex-9 {order: 9} .flex-10 {order: 10}

/*

.flex-start {display: flex; justify-content: flex-start;}
.flex-center {display: flex; justify-content: flex-start;}
.flex-end {display: flex; justify-content: flex-start;}
.flx-btw {display: flex; align-items: center; justify-content: space-between; flex-wrap: nowrap;}
.flx-cen-btw {display: flex; align-content: space-between; justify-content: center; flex-direction: column;}
.flx-btw-str {display: flex; align-items: stretch; justify-content: space-between; flex-wrap: nowrap;}
.flx-btw-btm {display: flex; align-items: flex-end; justify-content: space-between; }

.a-flex-col-centered {display: flex; flex-direction: column !important; align-items: center;}
.flex-jCenter {justify-content: center}
.flex-jStart {justify-content: flex-start;}
.flex-aTop {align-items: flex-start;}
.flex-aCenter  {align-items: center;}
.flx-cen {display: flex; justify-content: center;}
.flx-cen-btm {display: flex;justify-content: center; align-items: flex-end;}
.flx-al-btm {align-items: flex-end;}.flx-al-top {align-items: flex-start;}.flx-al-cen {align-items: center;}
.flx-aTop-Jcen {display: flex; justify-content: center; align-items: flex-start; flex-wrap: nowrap;}
.flx-top-start {display: flex; justify-content: flex-start; align-items: flex-start; }

*/
/*********************flex ************/
