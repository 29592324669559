/* int 1-20 inc 1*/
.sm-0 {--sm: 0 !important} .sm-1 {--sm: 1} .sm-2 {--sm: 2} .sm-3 {--sm: 3} .sm-4 {--sm: 4} .sm-5 {--sm: 5} .sm-6 {--sm: 6} .sm-7 {--sm: 7} .sm-8 {--sm: 8} .sm-9 {--sm: 9} .sm-10 {--sm: 10} .sm-11 {--sm: 11} .sm-12 {--sm: 12} .sm-13 {--sm: 13} .sm-14 {--sm: 14} .sm-15 {--sm: 15} .sm-16 {--sm: 16} .sm-17 {--sm: 17} .sm-18 {--sm: 18} .sm-19 {--sm: 19} .sm-20 {--sm: 20}
.sm1-0 {--sm1: 0 !important} .sm1-1 {--sm1: 1} .sm1-2 {--sm1: 2} .sm1-3 {--sm1: 3} .sm1-4 {--sm1: 4} .sm1-5 {--sm1: 5} .sm1-6 {--sm1: 6} .sm1-7 {--sm1: 7} .sm1-8 {--sm1: 8} .sm1-9 {--sm1: 9} .sm1-10 {--sm1: 10} .sm1-11 {--sm1: 11} .sm1-12 {--sm1: 12} .sm1-13 {--sm1: 13} .sm1-14 {--sm1: 14} .sm1-15 {--sm1: 15} .sm1-16 {--sm1: 16} .sm1-17 {--sm1: 17} .sm1-18 {--sm1: 18} .sm1-19 {--sm1: 19} .sm1-20 {--sm1: 20}
.sm2-0 {--sm2: 0 !important} .sm2-1 {--sm2: 1} .sm2-2 {--sm2: 2} .sm2-3 {--sm2: 3} .sm2-4 {--sm2: 4} .sm2-5 {--sm2: 5} .sm2-6 {--sm2: 6} .sm2-7 {--sm2: 7} .sm2-8 {--sm2: 8} .sm2-9 {--sm2: 9} .sm2-10 {--sm2: 10} .sm2-11 {--sm2: 11} .sm2-12 {--sm2: 12} .sm2-13 {--sm2: 13} .sm2-14 {--sm2: 14} .sm2-15 {--sm2: 15} .sm2-16 {--sm2: 16} .sm2-17 {--sm2: 17} .sm2-18 {--sm2: 18} .sm2-19 {--sm2: 19} .sm2-20 {--sm2: 20}
.sm3-0 {--sm3: 0 !important} .sm3-1 {--sm3: 1} .sm3-2 {--sm3: 2} .sm3-3 {--sm3: 3} .sm3-4 {--sm3: 4} .sm3-5 {--sm3: 5} .sm3-6 {--sm3: 6} .sm3-7 {--sm3: 7} .sm3-8 {--sm3: 8} .sm3-9 {--sm3: 9} .sm3-10 {--sm3: 10} .sm3-11 {--sm3: 11} .sm3-12 {--sm3: 12} .sm3-13 {--sm3: 13} .sm3-14 {--sm3: 14} .sm3-15 {--sm3: 15} .sm3-16 {--sm3: 16} .sm3-17 {--sm3: 17} .sm3-18 {--sm3: 18} .sm3-19 {--sm3: 19} .sm3-20 {--sm3: 20}
.sm4-0 {--sm4: 0 !important} .sm4-1 {--sm4: 1} .sm4-2 {--sm4: 2} .sm4-3 {--sm4: 3} .sm4-4 {--sm4: 4} .sm4-5 {--sm4: 5} .sm4-6 {--sm4: 6} .sm4-7 {--sm4: 7} .sm4-8 {--sm4: 8} .sm4-9 {--sm4: 9} .sm4-10 {--sm4: 10} .sm4-11 {--sm4: 11} .sm4-12 {--sm4: 12} .sm4-13 {--sm4: 13} .sm4-14 {--sm4: 14} .sm4-15 {--sm4: 15} .sm4-16 {--sm4: 16} .sm4-17 {--sm4: 17} .sm4-18 {--sm4: 18} .sm4-19 {--sm4: 19} .sm4-20 {--sm4: 20}
.sm5-0 {--sm5: 0 !important} .sm5-1 {--sm5: 1} .sm5-2 {--sm5: 2} .sm5-3 {--sm5: 3} .sm5-4 {--sm5: 4} .sm5-5 {--sm5: 5} .sm5-6 {--sm5: 6} .sm5-7 {--sm5: 7} .sm5-8 {--sm5: 8} .sm5-9 {--sm5: 9} .sm5-10 {--sm5: 10} .sm5-11 {--sm5: 11} .sm5-12 {--sm5: 12} .sm5-13 {--sm5: 13} .sm5-14 {--sm5: 14} .sm5-15 {--sm5: 15} .sm5-16 {--sm5: 16} .sm5-17 {--sm5: 17} .sm5-18 {--sm5: 18} .sm5-19 {--sm5: 19} .sm5-20 {--sm5: 20}
.sm6-0 {--sm6: 0 !important} .sm6-1 {--sm6: 1} .sm6-2 {--sm6: 2} .sm6-3 {--sm6: 3} .sm6-4 {--sm6: 4} .sm6-5 {--sm6: 5} .sm6-6 {--sm6: 6} .sm6-7 {--sm6: 7} .sm6-8 {--sm6: 8} .sm6-9 {--sm6: 9} .sm6-10 {--sm6: 10} .sm6-11 {--sm6: 11} .sm6-12 {--sm6: 12} .sm6-13 {--sm6: 13} .sm6-14 {--sm6: 14} .sm6-15 {--sm6: 15} .sm6-16 {--sm6: 16} .sm6-17 {--sm6: 17} .sm6-18 {--sm6: 18} .sm6-19 {--sm6: 19} .sm6-20 {--sm6: 20}
.sm7-0 {--sm7: 0 !important} .sm7-1 {--sm7: 1} .sm7-2 {--sm7: 2} .sm7-3 {--sm7: 3} .sm7-4 {--sm7: 4} .sm7-5 {--sm7: 5} .sm7-6 {--sm7: 6} .sm7-7 {--sm7: 7} .sm7-8 {--sm7: 8} .sm7-9 {--sm7: 9} .sm7-10 {--sm7: 10} .sm7-11 {--sm7: 11} .sm7-12 {--sm7: 12} .sm7-13 {--sm7: 13} .sm7-14 {--sm7: 14} .sm7-15 {--sm7: 15} .sm7-16 {--sm7: 16} .sm7-17 {--sm7: 17} .sm7-18 {--sm7: 18} .sm7-19 {--sm7: 19} .sm7-20 {--sm7: 20}

/* int 1-10 inc 1*/
.xs-0 {--xs: 0 !important} .xs-1 {--xs: 1} .xs-2 {--xs: 2} .xs-3 {--xs: 3} .xs-4 {--xs: 4} .xs-5 {--xs: 5} .xs-6 {--xs: 6} .xs-7 {--xs: 7} .xs-8 {--xs: 8} .xs-9 {--xs: 9} .xs-10 {--xs: 10}
.xs1-0 {--xs1: 0 !important} .xs1-1 {--xs1: 1} .xs1-2 {--xs1: 2} .xs1-3 {--xs1: 3} .xs1-4 {--xs1: 4} .xs1-5 {--xs1: 5} .xs1-6 {--xs1: 6} .xs1-7 {--xs1: 7} .xs1-8 {--xs1: 8} .xs1-9 {--xs1: 9} .xs1-10 {--xs1: 10}
.xs2-0 {--xs2: 0 !important} .xs2-1 {--xs2: 1} .xs2-2 {--xs2: 2} .xs2-3 {--xs2: 3} .xs2-4 {--xs2: 4} .xs2-5 {--xs2: 5} .xs2-6 {--xs2: 6} .xs2-7 {--xs2: 7} .xs2-8 {--xs2: 8} .xs2-9 {--xs2: 9} .xs2-10 {--xs2: 10}
.xs3-0 {--xs3: 0 !important} .xs3-1 {--xs3: 1} .xs3-2 {--xs3: 2} .xs3-3 {--xs3: 3} .xs3-4 {--xs3: 4} .xs3-5 {--xs3: 5} .xs3-6 {--xs3: 6} .xs3-7 {--xs3: 7} .xs3-8 {--xs3: 8} .xs3-9 {--xs3: 9} .xs3-10 {--xs3: 10}
.xs4-0 {--xs4: 0 !important} .xs4-1 {--xs4: 1} .xs4-2 {--xs4: 2} .xs4-3 {--xs4: 3} .xs4-4 {--xs4: 4} .xs4-5 {--xs4: 5} .xs4-6 {--xs4: 6} .xs4-7 {--xs4: 7} .xs4-8 {--xs4: 8} .xs4-9 {--xs4: 9} .xs4-10 {--xs4: 10}
.xs5-0 {--xs5: 0 !important} .xs5-1 {--xs5: 1} .xs5-2 {--xs5: 2} .xs5-3 {--xs5: 3} .xs5-4 {--xs5: 4} .xs5-5 {--xs5: 5} .xs5-6 {--xs5: 6} .xs5-7 {--xs5: 7} .xs5-8 {--xs5: 8} .xs5-9 {--xs5: 9} .xs5-10 {--xs5: 10}
.xs6-0 {--xs6: 0 !important} .xs6-1 {--xs6: 1} .xs6-2 {--xs6: 2} .xs6-3 {--xs6: 3} .xs6-4 {--xs6: 4} .xs6-5 {--xs6: 5} .xs6-6 {--xs6: 6} .xs6-7 {--xs6: 7} .xs6-8 {--xs6: 8} .xs6-9 {--xs6: 9} .xs6-10 {--xs6: 10}
.xs7-0 {--xs7: 0 !important} .xs7-1 {--xs7: 1} .xs7-2 {--xs7: 2} .xs7-3 {--xs7: 3} .xs7-4 {--xs7: 4} .xs7-5 {--xs7: 5} .xs7-6 {--xs7: 6} .xs7-7 {--xs7: 7} .xs7-8 {--xs7: 8} .xs7-9 {--xs7: 9} .xs7-10 {--xs7: 10}
.xs8-0 {--xs8: 0 !important} .xs8-1 {--xs8: 1} .xs8-2 {--xs8: 2} .xs8-3 {--xs8: 3} .xs8-4 {--xs8: 4} .xs8-5 {--xs8: 5} .xs8-6 {--xs8: 6} .xs8-7 {--xs8: 7} .xs8-8 {--xs8: 8} .xs8-9 {--xs8: 9} .xs8-10 {--xs8: 10}
.xs9-0 {--xs9: 0 !important} .xs9-1 {--xs9: 1} .xs9-2 {--xs9: 2} .xs9-3 {--xs9: 3} .xs9-4 {--xs9: 4} .xs9-5 {--xs9: 5} .xs9-6 {--xs9: 6} .xs9-7 {--xs9: 7} .xs9-8 {--xs9: 8} .xs9-9 {--xs9: 9} .xs9-10 {--xs9: 10}
.xs10-0 {--xs10: 0 !important} .xs10-1 {--xs10: 1} .xs10-2 {--xs10: 2} .xs10-3 {--xs10: 3} .xs10-4 {--xs10: 4} .xs10-5 {--xs10: 5} .xs10-6 {--xs10: 6} .xs10-7 {--xs10: 7} .xs10-8 {--xs10: 8} .xs10-9 {--xs10: 9} .xs10-10 {--xs10: 10}
.xs11-0 {--xs11: 0 !important} .xs11-1 {--xs11: 1} .xs11-2 {--xs11: 2} .xs11-3 {--xs11: 3} .xs11-4 {--xs11: 4} .xs11-5 {--xs11: 5} .xs11-6 {--xs11: 6} .xs11-7 {--xs11: 7} .xs11-8 {--xs11: 8} .xs11-9 {--xs11: 9} .xs11-10 {--xs11: 10}
.xs12-0 {--xs12: 0 !important} .xs12-1 {--xs12: 1} .xs12-2 {--xs12: 2} .xs12-3 {--xs12: 3} .xs12-4 {--xs12: 4} .xs12-5 {--xs12: 5} .xs12-6 {--xs12: 6} .xs12-7 {--xs12: 7} .xs12-8 {--xs12: 8} .xs12-9 {--xs12: 9} .xs12-10 {--xs12: 10}
.xs13-0 {--xs13: 0 !important} .xs13-1 {--xs13: 1} .xs13-2 {--xs13: 2} .xs13-3 {--xs13: 3} .xs13-4 {--xs13: 4} .xs13-5 {--xs13: 5} .xs13-6 {--xs13: 6} .xs13-7 {--xs13: 7} .xs13-8 {--xs13: 8} .xs13-9 {--xs13: 9} .xs13-10 {--xs13: 10}



.bx-1 {--bx: 1}.bx-10 {--bx: 10} .bx-20 {--bx: 20} .bx-30 {--bx: 30} .bx-40 {--bx: 40} .bx-50 {--bx: 50}
.cx-1 {--cx: 1}.cx-10 {--cx: 10} .cx-20 {--cx: 20} .cx-30 {--cx: 30} .cx-40 {--cx: 40} .cx-50 {--cx: 50}
.dx-1 {--dx: 1}.dx-10 {--dx: 10} .dx-20 {--dx: 20} .dx-30 {--dx: 30} .dx-40 {--dx: 40} .dx-50 {--dx: 50}
.ex-1 {--ex: 1}.ex-10 {--ex: 10} .ex-20 {--ex: 20} .ex-30 {--ex: 30} .ex-40 {--ex: 40} .ex-50 {--ex: 50}
.fx-1 {--fx: 1}.fx-10 {--fx: 10} .fx-20 {--fx: 20} .fx-30 {--fx: 30} .fx-40 {--fx: 40} .fx-50 {--fx: 50}
.gx-1 {--gx: 1}.gx-10 {--gx: 10} .gx-20 {--gx: 20} .gx-30 {--gx: 30} .gx-40 {--gx: 40} .gx-50 {--gx: 50}
.mx-1 {--mx: 1} .mx-10 {--mx: 10} .mx-20 {--mx: 20} .mx-30 {--mx: 30} .mx-40 {--mx: 40} .mx-50 {--mx: 50}
.nx-1 {--nx: 1} .nx-10 {--nx: 10} .nx-20 {--nx: 20} .nx-30 {--nx: 30} .nx-40 {--nx: 40} .nx-50 {--nx: 50}
.tx-1 {--tx: 1} .tx-10 {--tx: 10} .tx-20 {--tx: 20} .tx-30 {--tx: 30} .tx-40 {--tx: 40} .tx-50 {--tx: 50}

/* b,c,d,e,f,g,m,n,t  0-100*/
.b-0 {--b: 0 !important} .b-1 {--b: 1} .b-2 {--b: 2} .b-3 {--b: 3} .b-4 {--b: 4} .b-5 {--b: 5} .b-6 {--b: 6} .b-7 {--b: 7} .b-8 {--b: 8} .b-9 {--b: 9} .b-10 {--b: 10} .b-11 {--b: 11} .b-12 {--b: 12} .b-13 {--b: 13} .b-14 {--b: 14} .b-15 {--b: 15} .b-16 {--b: 16} .b-17 {--b: 17} .b-18 {--b: 18} .b-19 {--b: 19} .b-20 {--b: 20} .b-21 {--b: 21} .b-22 {--b: 22} .b-23 {--b: 23} .b-24 {--b: 24} .b-25 {--b: 25} .b-26 {--b: 26} .b-27 {--b: 27} .b-28 {--b: 28} .b-29 {--b: 29} .b-30 {--b: 30} .b-31 {--b: 31} .b-32 {--b: 32} .b-33 {--b: 33} .b-34 {--b: 34} .b-35 {--b: 35} .b-36 {--b: 36} .b-37 {--b: 37} .b-38 {--b: 38} .b-39 {--b: 39} .b-40 {--b: 40} .b-41 {--b: 41} .b-42 {--b: 42} .b-43 {--b: 43} .b-44 {--b: 44} .b-45 {--b: 45} .b-46 {--b: 46} .b-47 {--b: 47} .b-48 {--b: 48} .b-49 {--b: 49} .b-50 {--b: 50} .b-51 {--b: 51} .b-52 {--b: 52} .b-53 {--b: 53} .b-54 {--b: 54} .b-55 {--b: 55} .b-56 {--b: 56} .b-57 {--b: 57} .b-58 {--b: 58} .b-59 {--b: 59} .b-60 {--b: 60} .b-61 {--b: 61} .b-62 {--b: 62} .b-63 {--b: 63} .b-64 {--b: 64} .b-65 {--b: 65} .b-66 {--b: 66} .b-67 {--b: 67} .b-68 {--b: 68} .b-69 {--b: 69} .b-70 {--b: 70} .b-71 {--b: 71} .b-72 {--b: 72} .b-73 {--b: 73} .b-74 {--b: 74} .b-75 {--b: 75} .b-76 {--b: 76} .b-77 {--b: 77} .b-78 {--b: 78} .b-79 {--b: 79} .b-80 {--b: 80} .b-81 {--b: 81} .b-82 {--b: 82} .b-83 {--b: 83} .b-84 {--b: 84} .b-85 {--b: 85} .b-86 {--b: 86} .b-87 {--b: 87} .b-88 {--b: 88} .b-89 {--b: 89} .b-90 {--b: 90} .b-91 {--b: 91} .b-92 {--b: 92} .b-93 {--b: 93} .b-94 {--b: 94} .b-95 {--b: 95} .b-96 {--b: 96} .b-97 {--b: 97} .b-98 {--b: 98} .b-99 {--b: 99} .b-100 {--b: 100}
.c-0 {--c: 0 !important} .c-1 {--c: 1} .c-2 {--c: 2} .c-3 {--c: 3} .c-4 {--c: 4} .c-5 {--c: 5} .c-6 {--c: 6} .c-7 {--c: 7} .c-8 {--c: 8} .c-9 {--c: 9} .c-10 {--c: 10} .c-11 {--c: 11} .c-12 {--c: 12} .c-13 {--c: 13} .c-14 {--c: 14} .c-15 {--c: 15} .c-16 {--c: 16} .c-17 {--c: 17} .c-18 {--c: 18} .c-19 {--c: 19} .c-20 {--c: 20} .c-21 {--c: 21} .c-22 {--c: 22} .c-23 {--c: 23} .c-24 {--c: 24} .c-25 {--c: 25} .c-26 {--c: 26} .c-27 {--c: 27} .c-28 {--c: 28} .c-29 {--c: 29} .c-30 {--c: 30} .c-31 {--c: 31} .c-32 {--c: 32} .c-33 {--c: 33} .c-34 {--c: 34} .c-35 {--c: 35} .c-36 {--c: 36} .c-37 {--c: 37} .c-38 {--c: 38} .c-39 {--c: 39} .c-40 {--c: 40} .c-41 {--c: 41} .c-42 {--c: 42} .c-43 {--c: 43} .c-44 {--c: 44} .c-45 {--c: 45} .c-46 {--c: 46} .c-47 {--c: 47} .c-48 {--c: 48} .c-49 {--c: 49} .c-50 {--c: 50} .c-51 {--c: 51} .c-52 {--c: 52} .c-53 {--c: 53} .c-54 {--c: 54} .c-55 {--c: 55} .c-56 {--c: 56} .c-57 {--c: 57} .c-58 {--c: 58} .c-59 {--c: 59} .c-60 {--c: 60} .c-61 {--c: 61} .c-62 {--c: 62} .c-63 {--c: 63} .c-64 {--c: 64} .c-65 {--c: 65} .c-66 {--c: 66} .c-67 {--c: 67} .c-68 {--c: 68} .c-69 {--c: 69} .c-70 {--c: 70} .c-71 {--c: 71} .c-72 {--c: 72} .c-73 {--c: 73} .c-74 {--c: 74} .c-75 {--c: 75} .c-76 {--c: 76} .c-77 {--c: 77} .c-78 {--c: 78} .c-79 {--c: 79} .c-80 {--c: 80} .c-81 {--c: 81} .c-82 {--c: 82} .c-83 {--c: 83} .c-84 {--c: 84} .c-85 {--c: 85} .c-86 {--c: 86} .c-87 {--c: 87} .c-88 {--c: 88} .c-89 {--c: 89} .c-90 {--c: 90} .c-91 {--c: 91} .c-92 {--c: 92} .c-93 {--c: 93} .c-94 {--c: 94} .c-95 {--c: 95} .c-96 {--c: 96} .c-97 {--c: 97} .c-98 {--c: 98} .c-99 {--c: 99} .c-100 {--c: 100}
.d-0 {--d: 0 !important} .d-1 {--d: 1} .d-2 {--d: 2} .d-3 {--d: 3} .d-4 {--d: 4} .d-5 {--d: 5} .d-6 {--d: 6} .d-7 {--d: 7} .d-8 {--d: 8} .d-9 {--d: 9} .d-10 {--d: 10} .d-11 {--d: 11} .d-12 {--d: 12} .d-13 {--d: 13} .d-14 {--d: 14} .d-15 {--d: 15} .d-16 {--d: 16} .d-17 {--d: 17} .d-18 {--d: 18} .d-19 {--d: 19} .d-20 {--d: 20} .d-21 {--d: 21} .d-22 {--d: 22} .d-23 {--d: 23} .d-24 {--d: 24} .d-25 {--d: 25} .d-26 {--d: 26} .d-27 {--d: 27} .d-28 {--d: 28} .d-29 {--d: 29} .d-30 {--d: 30} .d-31 {--d: 31} .d-32 {--d: 32} .d-33 {--d: 33} .d-34 {--d: 34} .d-35 {--d: 35} .d-36 {--d: 36} .d-37 {--d: 37} .d-38 {--d: 38} .d-39 {--d: 39} .d-40 {--d: 40} .d-41 {--d: 41} .d-42 {--d: 42} .d-43 {--d: 43} .d-44 {--d: 44} .d-45 {--d: 45} .d-46 {--d: 46} .d-47 {--d: 47} .d-48 {--d: 48} .d-49 {--d: 49} .d-50 {--d: 50} .d-51 {--d: 51} .d-52 {--d: 52} .d-53 {--d: 53} .d-54 {--d: 54} .d-55 {--d: 55} .d-56 {--d: 56} .d-57 {--d: 57} .d-58 {--d: 58} .d-59 {--d: 59} .d-60 {--d: 60} .d-61 {--d: 61} .d-62 {--d: 62} .d-63 {--d: 63} .d-64 {--d: 64} .d-65 {--d: 65} .d-66 {--d: 66} .d-67 {--d: 67} .d-68 {--d: 68} .d-69 {--d: 69} .d-70 {--d: 70} .d-71 {--d: 71} .d-72 {--d: 72} .d-73 {--d: 73} .d-74 {--d: 74} .d-75 {--d: 75} .d-76 {--d: 76} .d-77 {--d: 77} .d-78 {--d: 78} .d-79 {--d: 79} .d-80 {--d: 80} .d-81 {--d: 81} .d-82 {--d: 82} .d-83 {--d: 83} .d-84 {--d: 84} .d-85 {--d: 85} .d-86 {--d: 86} .d-87 {--d: 87} .d-88 {--d: 88} .d-89 {--d: 89} .d-90 {--d: 90} .d-91 {--d: 91} .d-92 {--d: 92} .d-93 {--d: 93} .d-94 {--d: 94} .d-95 {--d: 95} .d-96 {--d: 96} .d-97 {--d: 97} .d-98 {--d: 98} .d-99 {--d: 99} .d-100 {--d: 100}
.e-0 {--e: 0 !important} .e-1 {--e: 1} .e-2 {--e: 2} .e-3 {--e: 3} .e-4 {--e: 4} .e-5 {--e: 5} .e-6 {--e: 6} .e-7 {--e: 7} .e-8 {--e: 8} .e-9 {--e: 9} .e-10 {--e: 10} .e-11 {--e: 11} .e-12 {--e: 12} .e-13 {--e: 13} .e-14 {--e: 14} .e-15 {--e: 15} .e-16 {--e: 16} .e-17 {--e: 17} .e-18 {--e: 18} .e-19 {--e: 19} .e-20 {--e: 20} .e-21 {--e: 21} .e-22 {--e: 22} .e-23 {--e: 23} .e-24 {--e: 24} .e-25 {--e: 25} .e-26 {--e: 26} .e-27 {--e: 27} .e-28 {--e: 28} .e-29 {--e: 29} .e-30 {--e: 30} .e-31 {--e: 31} .e-32 {--e: 32} .e-33 {--e: 33} .e-34 {--e: 34} .e-35 {--e: 35} .e-36 {--e: 36} .e-37 {--e: 37} .e-38 {--e: 38} .e-39 {--e: 39} .e-40 {--e: 40} .e-41 {--e: 41} .e-42 {--e: 42} .e-43 {--e: 43} .e-44 {--e: 44} .e-45 {--e: 45} .e-46 {--e: 46} .e-47 {--e: 47} .e-48 {--e: 48} .e-49 {--e: 49} .e-50 {--e: 50} .e-51 {--e: 51} .e-52 {--e: 52} .e-53 {--e: 53} .e-54 {--e: 54} .e-55 {--e: 55} .e-56 {--e: 56} .e-57 {--e: 57} .e-58 {--e: 58} .e-59 {--e: 59} .e-60 {--e: 60} .e-61 {--e: 61} .e-62 {--e: 62} .e-63 {--e: 63} .e-64 {--e: 64} .e-65 {--e: 65} .e-66 {--e: 66} .e-67 {--e: 67} .e-68 {--e: 68} .e-69 {--e: 69} .e-70 {--e: 70} .e-71 {--e: 71} .e-72 {--e: 72} .e-73 {--e: 73} .e-74 {--e: 74} .e-75 {--e: 75} .e-76 {--e: 76} .e-77 {--e: 77} .e-78 {--e: 78} .e-79 {--e: 79} .e-80 {--e: 80} .e-81 {--e: 81} .e-82 {--e: 82} .e-83 {--e: 83} .e-84 {--e: 84} .e-85 {--e: 85} .e-86 {--e: 86} .e-87 {--e: 87} .e-88 {--e: 88} .e-89 {--e: 89} .e-90 {--e: 90} .e-91 {--e: 91} .e-92 {--e: 92} .e-93 {--e: 93} .e-94 {--e: 94} .e-95 {--e: 95} .e-96 {--e: 96} .e-97 {--e: 97} .e-98 {--e: 98} .e-99 {--e: 99} .e-100 {--e: 100}
.f-0 {--f: 0 !important} .f-1 {--f: 1} .f-2 {--f: 2} .f-3 {--f: 3} .f-4 {--f: 4} .f-5 {--f: 5} .f-6 {--f: 6} .f-7 {--f: 7} .f-8 {--f: 8} .f-9 {--f: 9} .f-10 {--f: 10} .f-11 {--f: 11} .f-12 {--f: 12} .f-13 {--f: 13} .f-14 {--f: 14} .f-15 {--f: 15} .f-16 {--f: 16} .f-17 {--f: 17} .f-18 {--f: 18} .f-19 {--f: 19} .f-20 {--f: 20} .f-21 {--f: 21} .f-22 {--f: 22} .f-23 {--f: 23} .f-24 {--f: 24} .f-25 {--f: 25} .f-26 {--f: 26} .f-27 {--f: 27} .f-28 {--f: 28} .f-29 {--f: 29} .f-30 {--f: 30} .f-31 {--f: 31} .f-32 {--f: 32} .f-33 {--f: 33} .f-34 {--f: 34} .f-35 {--f: 35} .f-36 {--f: 36} .f-37 {--f: 37} .f-38 {--f: 38} .f-39 {--f: 39} .f-40 {--f: 40} .f-41 {--f: 41} .f-42 {--f: 42} .f-43 {--f: 43} .f-44 {--f: 44} .f-45 {--f: 45} .f-46 {--f: 46} .f-47 {--f: 47} .f-48 {--f: 48} .f-49 {--f: 49} .f-50 {--f: 50} .f-51 {--f: 51} .f-52 {--f: 52} .f-53 {--f: 53} .f-54 {--f: 54} .f-55 {--f: 55} .f-56 {--f: 56} .f-57 {--f: 57} .f-58 {--f: 58} .f-59 {--f: 59} .f-60 {--f: 60} .f-61 {--f: 61} .f-62 {--f: 62} .f-63 {--f: 63} .f-64 {--f: 64} .f-65 {--f: 65} .f-66 {--f: 66} .f-67 {--f: 67} .f-68 {--f: 68} .f-69 {--f: 69} .f-70 {--f: 70} .f-71 {--f: 71} .f-72 {--f: 72} .f-73 {--f: 73} .f-74 {--f: 74} .f-75 {--f: 75} .f-76 {--f: 76} .f-77 {--f: 77} .f-78 {--f: 78} .f-79 {--f: 79} .f-80 {--f: 80} .f-81 {--f: 81} .f-82 {--f: 82} .f-83 {--f: 83} .f-84 {--f: 84} .f-85 {--f: 85} .f-86 {--f: 86} .f-87 {--f: 87} .f-88 {--f: 88} .f-89 {--f: 89} .f-90 {--f: 90} .f-91 {--f: 91} .f-92 {--f: 92} .f-93 {--f: 93} .f-94 {--f: 94} .f-95 {--f: 95} .f-96 {--f: 96} .f-97 {--f: 97} .f-98 {--f: 98} .f-99 {--f: 99} .f-100 {--f: 100}
.g-0 {--g: 0 !important} .g-1 {--g: 1} .g-2 {--g: 2} .g-3 {--g: 3} .g-4 {--g: 4} .g-5 {--g: 5} .g-6 {--g: 6} .g-7 {--g: 7} .g-8 {--g: 8} .g-9 {--g: 9} .g-10 {--g: 10} .g-11 {--g: 11} .g-12 {--g: 12} .g-13 {--g: 13} .g-14 {--g: 14} .g-15 {--g: 15} .g-16 {--g: 16} .g-17 {--g: 17} .g-18 {--g: 18} .g-19 {--g: 19} .g-20 {--g: 20} .g-21 {--g: 21} .g-22 {--g: 22} .g-23 {--g: 23} .g-24 {--g: 24} .g-25 {--g: 25} .g-26 {--g: 26} .g-27 {--g: 27} .g-28 {--g: 28} .g-29 {--g: 29} .g-30 {--g: 30} .g-31 {--g: 31} .g-32 {--g: 32} .g-33 {--g: 33} .g-34 {--g: 34} .g-35 {--g: 35} .g-36 {--g: 36} .g-37 {--g: 37} .g-38 {--g: 38} .g-39 {--g: 39} .g-40 {--g: 40} .g-41 {--g: 41} .g-42 {--g: 42} .g-43 {--g: 43} .g-44 {--g: 44} .g-45 {--g: 45} .g-46 {--g: 46} .g-47 {--g: 47} .g-48 {--g: 48} .g-49 {--g: 49} .g-50 {--g: 50} .g-51 {--g: 51} .g-52 {--g: 52} .g-53 {--g: 53} .g-54 {--g: 54} .g-55 {--g: 55} .g-56 {--g: 56} .g-57 {--g: 57} .g-58 {--g: 58} .g-59 {--g: 59} .g-60 {--g: 60} .g-61 {--g: 61} .g-62 {--g: 62} .g-63 {--g: 63} .g-64 {--g: 64} .g-65 {--g: 65} .g-66 {--g: 66} .g-67 {--g: 67} .g-68 {--g: 68} .g-69 {--g: 69} .g-70 {--g: 70} .g-71 {--g: 71} .g-72 {--g: 72} .g-73 {--g: 73} .g-74 {--g: 74} .g-75 {--g: 75} .g-76 {--g: 76} .g-77 {--g: 77} .g-78 {--g: 78} .g-79 {--g: 79} .g-80 {--g: 80} .g-81 {--g: 81} .g-82 {--g: 82} .g-83 {--g: 83} .g-84 {--g: 84} .g-85 {--g: 85} .g-86 {--g: 86} .g-87 {--g: 87} .g-88 {--g: 88} .g-89 {--g: 89} .g-90 {--g: 90} .g-91 {--g: 91} .g-92 {--g: 92} .g-93 {--g: 93} .g-94 {--g: 94} .g-95 {--g: 95} .g-96 {--g: 96} .g-97 {--g: 97} .g-98 {--g: 98} .g-99 {--g: 99} .g-100 {--g: 100}
.m-0 {--m: 0 !important} .m-1 {--m: 1} .m-2 {--m: 2} .m-3 {--m: 3} .m-4 {--m: 4} .m-5 {--m: 5} .m-6 {--m: 6} .m-7 {--m: 7} .m-8 {--m: 8} .m-9 {--m: 9} .m-10 {--m: 10} .m-11 {--m: 11} .m-12 {--m: 12} .m-13 {--m: 13} .m-14 {--m: 14} .m-15 {--m: 15} .m-16 {--m: 16} .m-17 {--m: 17} .m-18 {--m: 18} .m-19 {--m: 19} .m-20 {--m: 20} .m-21 {--m: 21} .m-22 {--m: 22} .m-23 {--m: 23} .m-24 {--m: 24} .m-25 {--m: 25} .m-26 {--m: 26} .m-27 {--m: 27} .m-28 {--m: 28} .m-29 {--m: 29} .m-30 {--m: 30} .m-31 {--m: 31} .m-32 {--m: 32} .m-33 {--m: 33} .m-34 {--m: 34} .m-35 {--m: 35} .m-36 {--m: 36} .m-37 {--m: 37} .m-38 {--m: 38} .m-39 {--m: 39} .m-40 {--m: 40} .m-41 {--m: 41} .m-42 {--m: 42} .m-43 {--m: 43} .m-44 {--m: 44} .m-45 {--m: 45} .m-46 {--m: 46} .m-47 {--m: 47} .m-48 {--m: 48} .m-49 {--m: 49} .m-50 {--m: 50} .m-51 {--m: 51} .m-52 {--m: 52} .m-53 {--m: 53} .m-54 {--m: 54} .m-55 {--m: 55} .m-56 {--m: 56} .m-57 {--m: 57} .m-58 {--m: 58} .m-59 {--m: 59} .m-60 {--m: 60} .m-61 {--m: 61} .m-62 {--m: 62} .m-63 {--m: 63} .m-64 {--m: 64} .m-65 {--m: 65} .m-66 {--m: 66} .m-67 {--m: 67} .m-68 {--m: 68} .m-69 {--m: 69} .m-70 {--m: 70} .m-71 {--m: 71} .m-72 {--m: 72} .m-73 {--m: 73} .m-74 {--m: 74} .m-75 {--m: 75} .m-76 {--m: 76} .m-77 {--m: 77} .m-78 {--m: 78} .m-79 {--m: 79} .m-80 {--m: 80} .m-81 {--m: 81} .m-82 {--m: 82} .m-83 {--m: 83} .m-84 {--m: 84} .m-85 {--m: 85} .m-86 {--m: 86} .m-87 {--m: 87} .m-88 {--m: 88} .m-89 {--m: 89} .m-90 {--m: 90} .m-91 {--m: 91} .m-92 {--m: 92} .m-93 {--m: 93} .m-94 {--m: 94} .m-95 {--m: 95} .m-96 {--m: 96} .m-97 {--m: 97} .m-98 {--m: 98} .m-99 {--m: 99} .m-100 {--m: 100}
.n-0 {--n: 0 !important} .n-1 {--n: 1} .n-2 {--n: 2} .n-3 {--n: 3} .n-4 {--n: 4} .n-5 {--n: 5} .n-6 {--n: 6} .n-7 {--n: 7} .n-8 {--n: 8} .n-9 {--n: 9} .n-10 {--n: 10} .n-11 {--n: 11} .n-12 {--n: 12} .n-13 {--n: 13} .n-14 {--n: 14} .n-15 {--n: 15} .n-16 {--n: 16} .n-17 {--n: 17} .n-18 {--n: 18} .n-19 {--n: 19} .n-20 {--n: 20} .n-21 {--n: 21} .n-22 {--n: 22} .n-23 {--n: 23} .n-24 {--n: 24} .n-25 {--n: 25} .n-26 {--n: 26} .n-27 {--n: 27} .n-28 {--n: 28} .n-29 {--n: 29} .n-30 {--n: 30} .n-31 {--n: 31} .n-32 {--n: 32} .n-33 {--n: 33} .n-34 {--n: 34} .n-35 {--n: 35} .n-36 {--n: 36} .n-37 {--n: 37} .n-38 {--n: 38} .n-39 {--n: 39} .n-40 {--n: 40} .n-41 {--n: 41} .n-42 {--n: 42} .n-43 {--n: 43} .n-44 {--n: 44} .n-45 {--n: 45} .n-46 {--n: 46} .n-47 {--n: 47} .n-48 {--n: 48} .n-49 {--n: 49} .n-50 {--n: 50} .n-51 {--n: 51} .n-52 {--n: 52} .n-53 {--n: 53} .n-54 {--n: 54} .n-55 {--n: 55} .n-56 {--n: 56} .n-57 {--n: 57} .n-58 {--n: 58} .n-59 {--n: 59} .n-60 {--n: 60} .n-61 {--n: 61} .n-62 {--n: 62} .n-63 {--n: 63} .n-64 {--n: 64} .n-65 {--n: 65} .n-66 {--n: 66} .n-67 {--n: 67} .n-68 {--n: 68} .n-69 {--n: 69} .n-70 {--n: 70} .n-71 {--n: 71} .n-72 {--n: 72} .n-73 {--n: 73} .n-74 {--n: 74} .n-75 {--n: 75} .n-76 {--n: 76} .n-77 {--n: 77} .n-78 {--n: 78} .n-79 {--n: 79} .n-80 {--n: 80} .n-81 {--n: 81} .n-82 {--n: 82} .n-83 {--n: 83} .n-84 {--n: 84} .n-85 {--n: 85} .n-86 {--n: 86} .n-87 {--n: 87} .n-88 {--n: 88} .n-89 {--n: 89} .n-90 {--n: 90} .n-91 {--n: 91} .n-92 {--n: 92} .n-93 {--n: 93} .n-94 {--n: 94} .n-95 {--n: 95} .n-96 {--n: 96} .n-97 {--n: 97} .n-98 {--n: 98} .n-99 {--n: 99} .n-100 {--n: 100}
.t-0 {--t: 0 !important} .t-1 {--t: 1} .t-2 {--t: 2} .t-3 {--t: 3} .t-4 {--t: 4} .t-5 {--t: 5} .t-6 {--t: 6} .t-7 {--t: 7} .t-8 {--t: 8} .t-9 {--t: 9} .t-10 {--t: 10} .t-11 {--t: 11} .t-12 {--t: 12} .t-13 {--t: 13} .t-14 {--t: 14} .t-15 {--t: 15} .t-16 {--t: 16} .t-17 {--t: 17} .t-18 {--t: 18} .t-19 {--t: 19} .t-20 {--t: 20} .t-21 {--t: 21} .t-22 {--t: 22} .t-23 {--t: 23} .t-24 {--t: 24} .t-25 {--t: 25} .t-26 {--t: 26} .t-27 {--t: 27} .t-28 {--t: 28} .t-29 {--t: 29} .t-30 {--t: 30} .t-31 {--t: 31} .t-32 {--t: 32} .t-33 {--t: 33} .t-34 {--t: 34} .t-35 {--t: 35} .t-36 {--t: 36} .t-37 {--t: 37} .t-38 {--t: 38} .t-39 {--t: 39} .t-40 {--t: 40} .t-41 {--t: 41} .t-42 {--t: 42} .t-43 {--t: 43} .t-44 {--t: 44} .t-45 {--t: 45} .t-46 {--t: 46} .t-47 {--t: 47} .t-48 {--t: 48} .t-49 {--t: 49} .t-50 {--t: 50} .t-51 {--t: 51} .t-52 {--t: 52} .t-53 {--t: 53} .t-54 {--t: 54} .t-55 {--t: 55} .t-56 {--t: 56} .t-57 {--t: 57} .t-58 {--t: 58} .t-59 {--t: 59} .t-60 {--t: 60} .t-61 {--t: 61} .t-62 {--t: 62} .t-63 {--t: 63} .t-64 {--t: 64} .t-65 {--t: 65} .t-66 {--t: 66} .t-67 {--t: 67} .t-68 {--t: 68} .t-69 {--t: 69} .t-70 {--t: 70} .t-71 {--t: 71} .t-72 {--t: 72} .t-73 {--t: 73} .t-74 {--t: 74} .t-75 {--t: 75} .t-76 {--t: 76} .t-77 {--t: 77} .t-78 {--t: 78} .t-79 {--t: 79} .t-80 {--t: 80} .t-81 {--t: 81} .t-82 {--t: 82} .t-83 {--t: 83} .t-84 {--t: 84} .t-85 {--t: 85} .t-86 {--t: 86} .t-87 {--t: 87} .t-88 {--t: 88} .t-89 {--t: 89} .t-90 {--t: 90} .t-91 {--t: 91} .t-92 {--t: 92} .t-93 {--t: 93} .t-94 {--t: 94} .t-95 {--t: 95} .t-96 {--t: 96} .t-97 {--t: 97} .t-98 {--t: 98} .t-99 {--t: 99} .t-100 {--t: 100}


@media (max-width:1450px) {
  .b-0-xm {--b: 0 !important} .b-1-xm {--b: 1} .b-2-xm {--b: 2} .b-3-xm {--b: 3} .b-4-xm {--b: 4} .b-5-xm {--b: 5} .b-6-xm {--b: 6} .b-7-xm {--b: 7} .b-8-xm {--b: 8} .b-9-xm {--b: 9} .b-10-xm {--b: 10} .b-11-xm {--b: 11} .b-12-xm {--b: 12} .b-13-xm {--b: 13} .b-14-xm {--b: 14} .b-15-xm {--b: 15} .b-16-xm {--b: 16} .b-17-xm {--b: 17} .b-18-xm {--b: 18} .b-19-xm {--b: 19} .b-20-xm {--b: 20} .b-21-xm {--b: 21} .b-22-xm {--b: 22} .b-23-xm {--b: 23} .b-24-xm {--b: 24} .b-25-xm {--b: 25} .b-26-xm {--b: 26} .b-27-xm {--b: 27} .b-28-xm {--b: 28} .b-29-xm {--b: 29} .b-30-xm {--b: 30} .b-31-xm {--b: 31} .b-32-xm {--b: 32} .b-33-xm {--b: 33} .b-34-xm {--b: 34} .b-35-xm {--b: 35} .b-36-xm {--b: 36} .b-37-xm {--b: 37} .b-38-xm {--b: 38} .b-39-xm {--b: 39} .b-40-xm {--b: 40} .b-41-xm {--b: 41} .b-42-xm {--b: 42} .b-43-xm {--b: 43} .b-44-xm {--b: 44} .b-45-xm {--b: 45} .b-46-xm {--b: 46} .b-47-xm {--b: 47} .b-48-xm {--b: 48} .b-49-xm {--b: 49} .b-50-xm {--b: 50} .b-51-xm {--b: 51} .b-52-xm {--b: 52} .b-53-xm {--b: 53} .b-54-xm {--b: 54} .b-55-xm {--b: 55} .b-56-xm {--b: 56} .b-57-xm {--b: 57} .b-58-xm {--b: 58} .b-59-xm {--b: 59} .b-60-xm {--b: 60} .b-61-xm {--b: 61} .b-62-xm {--b: 62} .b-63-xm {--b: 63} .b-64-xm {--b: 64} .b-65-xm {--b: 65} .b-66-xm {--b: 66} .b-67-xm {--b: 67} .b-68-xm {--b: 68} .b-69-xm {--b: 69} .b-70-xm {--b: 70} .b-71-xm {--b: 71} .b-72-xm {--b: 72} .b-73-xm {--b: 73} .b-74-xm {--b: 74} .b-75-xm {--b: 75} .b-76-xm {--b: 76} .b-77-xm {--b: 77} .b-78-xm {--b: 78} .b-79-xm {--b: 79} .b-80-xm {--b: 80} .b-81-xm {--b: 81} .b-82-xm {--b: 82} .b-83-xm {--b: 83} .b-84-xm {--b: 84} .b-85-xm {--b: 85} .b-86-xm {--b: 86} .b-87-xm {--b: 87} .b-88-xm {--b: 88} .b-89-xm {--b: 89} .b-90-xm {--b: 90} .b-91-xm {--b: 91} .b-92-xm {--b: 92} .b-93-xm {--b: 93} .b-94-xm {--b: 94} .b-95-xm {--b: 95} .b-96-xm {--b: 96} .b-97-xm {--b: 97} .b-98-xm {--b: 98} .b-99-xm {--b: 99} .b-100-xm {--b: 100}
  .c-0-xm {--c: 0 !important} .c-1-xm {--c: 1} .c-2-xm {--c: 2} .c-3-xm {--c: 3} .c-4-xm {--c: 4} .c-5-xm {--c: 5} .c-6-xm {--c: 6} .c-7-xm {--c: 7} .c-8-xm {--c: 8} .c-9-xm {--c: 9} .c-10-xm {--c: 10} .c-11-xm {--c: 11} .c-12-xm {--c: 12} .c-13-xm {--c: 13} .c-14-xm {--c: 14} .c-15-xm {--c: 15} .c-16-xm {--c: 16} .c-17-xm {--c: 17} .c-18-xm {--c: 18} .c-19-xm {--c: 19} .c-20-xm {--c: 20} .c-21-xm {--c: 21} .c-22-xm {--c: 22} .c-23-xm {--c: 23} .c-24-xm {--c: 24} .c-25-xm {--c: 25} .c-26-xm {--c: 26} .c-27-xm {--c: 27} .c-28-xm {--c: 28} .c-29-xm {--c: 29} .c-30-xm {--c: 30} .c-31-xm {--c: 31} .c-32-xm {--c: 32} .c-33-xm {--c: 33} .c-34-xm {--c: 34} .c-35-xm {--c: 35} .c-36-xm {--c: 36} .c-37-xm {--c: 37} .c-38-xm {--c: 38} .c-39-xm {--c: 39} .c-40-xm {--c: 40} .c-41-xm {--c: 41} .c-42-xm {--c: 42} .c-43-xm {--c: 43} .c-44-xm {--c: 44} .c-45-xm {--c: 45} .c-46-xm {--c: 46} .c-47-xm {--c: 47} .c-48-xm {--c: 48} .c-49-xm {--c: 49} .c-50-xm {--c: 50} .c-51-xm {--c: 51} .c-52-xm {--c: 52} .c-53-xm {--c: 53} .c-54-xm {--c: 54} .c-55-xm {--c: 55} .c-56-xm {--c: 56} .c-57-xm {--c: 57} .c-58-xm {--c: 58} .c-59-xm {--c: 59} .c-60-xm {--c: 60} .c-61-xm {--c: 61} .c-62-xm {--c: 62} .c-63-xm {--c: 63} .c-64-xm {--c: 64} .c-65-xm {--c: 65} .c-66-xm {--c: 66} .c-67-xm {--c: 67} .c-68-xm {--c: 68} .c-69-xm {--c: 69} .c-70-xm {--c: 70} .c-71-xm {--c: 71} .c-72-xm {--c: 72} .c-73-xm {--c: 73} .c-74-xm {--c: 74} .c-75-xm {--c: 75} .c-76-xm {--c: 76} .c-77-xm {--c: 77} .c-78-xm {--c: 78} .c-79-xm {--c: 79} .c-80-xm {--c: 80} .c-81-xm {--c: 81} .c-82-xm {--c: 82} .c-83-xm {--c: 83} .c-84-xm {--c: 84} .c-85-xm {--c: 85} .c-86-xm {--c: 86} .c-87-xm {--c: 87} .c-88-xm {--c: 88} .c-89-xm {--c: 89} .c-90-xm {--c: 90} .c-91-xm {--c: 91} .c-92-xm {--c: 92} .c-93-xm {--c: 93} .c-94-xm {--c: 94} .c-95-xm {--c: 95} .c-96-xm {--c: 96} .c-97-xm {--c: 97} .c-98-xm {--c: 98} .c-99-xm {--c: 99} .c-100-xm {--c: 100}
  .d-0-xm {--d: 0 !important} .d-1-xm {--d: 1} .d-2-xm {--d: 2} .d-3-xm {--d: 3} .d-4-xm {--d: 4} .d-5-xm {--d: 5} .d-6-xm {--d: 6} .d-7-xm {--d: 7} .d-8-xm {--d: 8} .d-9-xm {--d: 9} .d-10-xm {--d: 10} .d-11-xm {--d: 11} .d-12-xm {--d: 12} .d-13-xm {--d: 13} .d-14-xm {--d: 14} .d-15-xm {--d: 15} .d-16-xm {--d: 16} .d-17-xm {--d: 17} .d-18-xm {--d: 18} .d-19-xm {--d: 19} .d-20-xm {--d: 20} .d-21-xm {--d: 21} .d-22-xm {--d: 22} .d-23-xm {--d: 23} .d-24-xm {--d: 24} .d-25-xm {--d: 25} .d-26-xm {--d: 26} .d-27-xm {--d: 27} .d-28-xm {--d: 28} .d-29-xm {--d: 29} .d-30-xm {--d: 30} .d-31-xm {--d: 31} .d-32-xm {--d: 32} .d-33-xm {--d: 33} .d-34-xm {--d: 34} .d-35-xm {--d: 35} .d-36-xm {--d: 36} .d-37-xm {--d: 37} .d-38-xm {--d: 38} .d-39-xm {--d: 39} .d-40-xm {--d: 40} .d-41-xm {--d: 41} .d-42-xm {--d: 42} .d-43-xm {--d: 43} .d-44-xm {--d: 44} .d-45-xm {--d: 45} .d-46-xm {--d: 46} .d-47-xm {--d: 47} .d-48-xm {--d: 48} .d-49-xm {--d: 49} .d-50-xm {--d: 50} .d-51-xm {--d: 51} .d-52-xm {--d: 52} .d-53-xm {--d: 53} .d-54-xm {--d: 54} .d-55-xm {--d: 55} .d-56-xm {--d: 56} .d-57-xm {--d: 57} .d-58-xm {--d: 58} .d-59-xm {--d: 59} .d-60-xm {--d: 60} .d-61-xm {--d: 61} .d-62-xm {--d: 62} .d-63-xm {--d: 63} .d-64-xm {--d: 64} .d-65-xm {--d: 65} .d-66-xm {--d: 66} .d-67-xm {--d: 67} .d-68-xm {--d: 68} .d-69-xm {--d: 69} .d-70-xm {--d: 70} .d-71-xm {--d: 71} .d-72-xm {--d: 72} .d-73-xm {--d: 73} .d-74-xm {--d: 74} .d-75-xm {--d: 75} .d-76-xm {--d: 76} .d-77-xm {--d: 77} .d-78-xm {--d: 78} .d-79-xm {--d: 79} .d-80-xm {--d: 80} .d-81-xm {--d: 81} .d-82-xm {--d: 82} .d-83-xm {--d: 83} .d-84-xm {--d: 84} .d-85-xm {--d: 85} .d-86-xm {--d: 86} .d-87-xm {--d: 87} .d-88-xm {--d: 88} .d-89-xm {--d: 89} .d-90-xm {--d: 90} .d-91-xm {--d: 91} .d-92-xm {--d: 92} .d-93-xm {--d: 93} .d-94-xm {--d: 94} .d-95-xm {--d: 95} .d-96-xm {--d: 96} .d-97-xm {--d: 97} .d-98-xm {--d: 98} .d-99-xm {--d: 99} .d-100-xm {--d: 100}

}


@media (max-width:1200px) {
  /* int 1-20 inc 1*/
  .sm-0-t {--sm: 0 !important} .sm-1-t {--sm: 1} .sm-2-t {--sm: 2} .sm-3-t {--sm: 3} .sm-4-t {--sm: 4} .sm-5-t {--sm: 5} .sm-6-t {--sm: 6} .sm-7-t {--sm: 7} .sm-8-t {--sm: 8} .sm-9-t {--sm: 9} .sm-10-t {--sm: 10} .sm-11-t {--sm: 11} .sm-12-t {--sm: 12} .sm-13-t {--sm: 13} .sm-14-t {--sm: 14} .sm-15-t {--sm: 15} .sm-16-t {--sm: 16} .sm-17-t {--sm: 17} .sm-18-t {--sm: 18} .sm-19-t {--sm: 19} .sm-20-t {--sm: 20}
  .sm1-0-t {--sm1: 0 !important} .sm1-1-t {--sm1: 1} .sm1-2-t {--sm1: 2} .sm1-3-t {--sm1: 3} .sm1-4-t {--sm1: 4} .sm1-5-t {--sm1: 5} .sm1-6-t {--sm1: 6} .sm1-7-t {--sm1: 7} .sm1-8-t {--sm1: 8} .sm1-9-t {--sm1: 9} .sm1-10-t {--sm1: 10} .sm1-11-t {--sm1: 11} .sm1-12-t {--sm1: 12} .sm1-13-t {--sm1: 13} .sm1-14-t {--sm1: 14} .sm1-15-t {--sm1: 15} .sm1-16-t {--sm1: 16} .sm1-17-t {--sm1: 17} .sm1-18-t {--sm1: 18} .sm1-19-t {--sm1: 19} .sm1-20-t {--sm1: 20}
  .sm2-0-t {--sm2: 0 !important} .sm2-1-t {--sm2: 1} .sm2-2-t {--sm2: 2} .sm2-3-t {--sm2: 3} .sm2-4-t {--sm2: 4} .sm2-5-t {--sm2: 5} .sm2-6-t {--sm2: 6} .sm2-7-t {--sm2: 7} .sm2-8-t {--sm2: 8} .sm2-9-t {--sm2: 9} .sm2-10-t {--sm2: 10} .sm2-11-t {--sm2: 11} .sm2-12-t {--sm2: 12} .sm2-13-t {--sm2: 13} .sm2-14-t {--sm2: 14} .sm2-15-t {--sm2: 15} .sm2-16-t {--sm2: 16} .sm2-17-t {--sm2: 17} .sm2-18-t {--sm2: 18} .sm2-19-t {--sm2: 19} .sm2-20-t {--sm2: 20}
  .sm3-0-t {--sm3: 0 !important} .sm3-1-t {--sm3: 1} .sm3-2-t {--sm3: 2} .sm3-3-t {--sm3: 3} .sm3-4-t {--sm3: 4} .sm3-5-t {--sm3: 5} .sm3-6-t {--sm3: 6} .sm3-7-t {--sm3: 7} .sm3-8-t {--sm3: 8} .sm3-9-t {--sm3: 9} .sm3-10-t {--sm3: 10} .sm3-11-t {--sm3: 11} .sm3-12-t {--sm3: 12} .sm3-13-t {--sm3: 13} .sm3-14-t {--sm3: 14} .sm3-15-t {--sm3: 15} .sm3-16-t {--sm3: 16} .sm3-17-t {--sm3: 17} .sm3-18-t {--sm3: 18} .sm3-19-t {--sm3: 19} .sm3-20-t {--sm3: 20}
  .sm4-0-t {--sm4: 0 !important} .sm4-1-t {--sm4: 1} .sm4-2-t {--sm4: 2} .sm4-3-t {--sm4: 3} .sm4-4-t {--sm4: 4} .sm4-5-t {--sm4: 5} .sm4-6-t {--sm4: 6} .sm4-7-t {--sm4: 7} .sm4-8-t {--sm4: 8} .sm4-9-t {--sm4: 9} .sm4-10-t {--sm4: 10} .sm4-11-t {--sm4: 11} .sm4-12-t {--sm4: 12} .sm4-13-t {--sm4: 13} .sm4-14-t {--sm4: 14} .sm4-15-t {--sm4: 15} .sm4-16-t {--sm4: 16} .sm4-17-t {--sm4: 17} .sm4-18-t {--sm4: 18} .sm4-19-t {--sm4: 19} .sm4-20-t {--sm4: 20}
  .sm5-0-t {--sm5: 0 !important} .sm5-1-t {--sm5: 1} .sm5-2-t {--sm5: 2} .sm5-3-t {--sm5: 3} .sm5-4-t {--sm5: 4} .sm5-5-t {--sm5: 5} .sm5-6-t {--sm5: 6} .sm5-7-t {--sm5: 7} .sm5-8-t {--sm5: 8} .sm5-9-t {--sm5: 9} .sm5-10-t {--sm5: 10} .sm5-11-t {--sm5: 11} .sm5-12-t {--sm5: 12} .sm5-13-t {--sm5: 13} .sm5-14-t {--sm5: 14} .sm5-15-t {--sm5: 15} .sm5-16-t {--sm5: 16} .sm5-17-t {--sm5: 17} .sm5-18-t {--sm5: 18} .sm5-19-t {--sm5: 19} .sm5-20-t {--sm5: 20}
  .sm6-0-t {--sm6: 0 !important} .sm6-1-t {--sm6: 1} .sm6-2-t {--sm6: 2} .sm6-3-t {--sm6: 3} .sm6-4-t {--sm6: 4} .sm6-5-t {--sm6: 5} .sm6-6-t {--sm6: 6} .sm6-7-t {--sm6: 7} .sm6-8-t {--sm6: 8} .sm6-9-t {--sm6: 9} .sm6-10-t {--sm6: 10} .sm6-11-t {--sm6: 11} .sm6-12-t {--sm6: 12} .sm6-13-t {--sm6: 13} .sm6-14-t {--sm6: 14} .sm6-15-t {--sm6: 15} .sm6-16-t {--sm6: 16} .sm6-17-t {--sm6: 17} .sm6-18-t {--sm6: 18} .sm6-19-t {--sm6: 19} .sm6-20-t {--sm6: 20}
  .sm7-0-t {--sm7: 0 !important} .sm7-1-t {--sm7: 1} .sm7-2-t {--sm7: 2} .sm7-3-t {--sm7: 3} .sm7-4-t {--sm7: 4} .sm7-5-t {--sm7: 5} .sm7-6-t {--sm7: 6} .sm7-7-t {--sm7: 7} .sm7-8-t {--sm7: 8} .sm7-9-t {--sm7: 9} .sm7-10-t {--sm7: 10} .sm7-11-t {--sm7: 11} .sm7-12-t {--sm7: 12} .sm7-13-t {--sm7: 13} .sm7-14-t {--sm7: 14} .sm7-15-t {--sm7: 15} .sm7-16-t {--sm7: 16} .sm7-17-t {--sm7: 17} .sm7-18-t {--sm7: 18} .sm7-19-t {--sm7: 19} .sm7-20-t {--sm7: 20}


  .xs-0-t {--xs: 0 !important} .xs-1-t {--xs: 1} .xs-2-t {--xs: 2} .xs-3-t {--xs: 3} .xs-4-t {--xs: 4} .xs-5-t {--xs: 5} .xs-6-t {--xs: 6} .xs-7-t {--xs: 7} .xs-8-t {--xs: 8} .xs-9-t {--xs: 9} .xs-10-t {--xs: 10}
  .xs1-0-t {--xs1: 0 !important} .xs1-1-t {--xs1: 1} .xs1-2-t {--xs1: 2} .xs1-3-t {--xs1: 3} .xs1-4-t {--xs1: 4} .xs1-5-t {--xs1: 5} .xs1-6-t {--xs1: 6} .xs1-7-t {--xs1: 7} .xs1-8-t {--xs1: 8} .xs1-9-t {--xs1: 9} .xs1-10-t {--xs1: 10}
  .xs2-0-t {--xs2: 0 !important} .xs2-1-t {--xs2: 1} .xs2-2-t {--xs2: 2} .xs2-3-t {--xs2: 3} .xs2-4-t {--xs2: 4} .xs2-5-t {--xs2: 5} .xs2-6-t {--xs2: 6} .xs2-7-t {--xs2: 7} .xs2-8-t {--xs2: 8} .xs2-9-t {--xs2: 9} .xs2-10-t {--xs2: 10}
  .xs3-0-t {--xs3: 0 !important} .xs3-1-t {--xs3: 1} .xs3-2-t {--xs3: 2} .xs3-3-t {--xs3: 3} .xs3-4-t {--xs3: 4} .xs3-5-t {--xs3: 5} .xs3-6-t {--xs3: 6} .xs3-7-t {--xs3: 7} .xs3-8-t {--xs3: 8} .xs3-9-t {--xs3: 9} .xs3-10-t {--xs3: 10}
  .xs4-0-t {--xs4: 0 !important} .xs4-1-t {--xs4: 1} .xs4-2-t {--xs4: 2} .xs4-3-t {--xs4: 3} .xs4-4-t {--xs4: 4} .xs4-5-t {--xs4: 5} .xs4-6-t {--xs4: 6} .xs4-7-t {--xs4: 7} .xs4-8-t {--xs4: 8} .xs4-9-t {--xs4: 9} .xs4-10-t {--xs4: 10}
  .xs5-0-t {--xs5: 0 !important} .xs5-1-t {--xs5: 1} .xs5-2-t {--xs5: 2} .xs5-3-t {--xs5: 3} .xs5-4-t {--xs5: 4} .xs5-5-t {--xs5: 5} .xs5-6-t {--xs5: 6} .xs5-7-t {--xs5: 7} .xs5-8-t {--xs5: 8} .xs5-9-t {--xs5: 9} .xs5-10-t {--xs5: 10}
  .xs6-0-t {--xs6: 0 !important} .xs6-1-t {--xs6: 1} .xs6-2-t {--xs6: 2} .xs6-3-t {--xs6: 3} .xs6-4-t {--xs6: 4} .xs6-5-t {--xs6: 5} .xs6-6-t {--xs6: 6} .xs6-7-t {--xs6: 7} .xs6-8-t {--xs6: 8} .xs6-9-t {--xs6: 9} .xs6-10-t {--xs6: 10}
  .xs7-0-t {--xs7: 0 !important} .xs7-1-t {--xs7: 1} .xs7-2-t {--xs7: 2} .xs7-3-t {--xs7: 3} .xs7-4-t {--xs7: 4} .xs7-5-t {--xs7: 5} .xs7-6-t {--xs7: 6} .xs7-7-t {--xs7: 7} .xs7-8-t {--xs7: 8} .xs7-9-t {--xs7: 9} .xs7-10-t {--xs7: 10}
  .xs8-0-t {--xs8: 0 !important} .xs8-1-t {--xs8: 1} .xs8-2-t {--xs8: 2} .xs8-3-t {--xs8: 3} .xs8-4-t {--xs8: 4} .xs8-5-t {--xs8: 5} .xs8-6-t {--xs8: 6} .xs8-7-t {--xs8: 7} .xs8-8-t {--xs8: 8} .xs8-9-t {--xs8: 9} .xs8-10-t {--xs8: 10}
  .xs9-0-t {--xs9: 0 !important} .xs9-1-t {--xs9: 1} .xs9-2-t {--xs9: 2} .xs9-3-t {--xs9: 3} .xs9-4-t {--xs9: 4} .xs9-5-t {--xs9: 5} .xs9-6-t {--xs9: 6} .xs9-7-t {--xs9: 7} .xs9-8-t {--xs9: 8} .xs9-9-t {--xs9: 9} .xs9-10-t {--xs9: 10}
  .xs10-0-t {--xs10: 0 !important} .xs10-1-t {--xs10: 1} .xs10-2-t {--xs10: 2} .xs10-3-t {--xs10: 3} .xs10-4-t {--xs10: 4} .xs10-5-t {--xs10: 5} .xs10-6-t {--xs10: 6} .xs10-7-t {--xs10: 7} .xs10-8-t {--xs10: 8} .xs10-9-t {--xs10: 9} .xs10-10-t {--xs10: 10}
  .xs11-0-t {--xs11: 0 !important} .xs11-1-t {--xs11: 1} .xs11-2-t {--xs11: 2} .xs11-3-t {--xs11: 3} .xs11-4-t {--xs11: 4} .xs11-5-t {--xs11: 5} .xs11-6-t {--xs11: 6} .xs11-7-t {--xs11: 7} .xs11-8-t {--xs11: 8} .xs11-9-t {--xs11: 9} .xs11-10-t {--xs11: 10}
  .xs12-0-t {--xs12: 0 !important} .xs12-1-t {--xs12: 1} .xs12-2-t {--xs12: 2} .xs12-3-t {--xs12: 3} .xs12-4-t {--xs12: 4} .xs12-5-t {--xs12: 5} .xs12-6-t {--xs12: 6} .xs12-7-t {--xs12: 7} .xs12-8-t {--xs12: 8} .xs12-9-t {--xs12: 9} .xs12-10-t {--xs12: 10}
  .xs13-0-t {--xs13: 0 !important} .xs13-1-t {--xs13: 1} .xs13-2-t {--xs13: 2} .xs13-3-t {--xs13: 3} .xs13-4-t {--xs13: 4} .xs13-5-t {--xs13: 5} .xs13-6-t {--xs13: 6} .xs13-7-t {--xs13: 7} .xs13-8-t {--xs13: 8} .xs13-9-t {--xs13: 9} .xs13-10-t {--xs13: 10}


  .bx-1-t {--bx: 1} .bx-10-t {--bx: 10} .bx-20-t {--bx: 20} .bx-30-t {--bx: 30} .bx-40-t {--bx: 40} .bx-50-t {--bx: 50}
  .cx-1-t {--cx: 1}.cx-10-t {--cx: 10} .cx-20-t {--cx: 20} .cx-30-t {--cx: 30} .cx-40-t {--cx: 40} .cx-50-t {--cx: 50}
  .dx-1-t {--dx: 1}.dx-10-t {--dx: 10} .dx-20-t {--dx: 20} .dx-30-t {--dx: 30} .dx-40-t {--dx: 40} .dx-50-t {--dx: 50}
  .ex-1-t {--ex: 1}.ex-10-t {--ex: 10} .ex-20-t {--ex: 20} .ex-30-t {--ex: 30} .ex-40-t {--ex: 40} .ex-50-t {--ex: 50}
  .fx-1-t {--fx: 1}.fx-10-t {--fx: 10} .fx-20-t {--fx: 20} .fx-30-t {--fx: 30} .fx-40-t {--fx: 40} .fx-50-t {--fx: 50}
  .gx-1-t {--gx: 1}.gx-10-t {--gx: 10} .gx-20-t {--gx: 20} .gx-30-t {--gx: 30} .gx-40-t {--gx: 40} .gx-50-t {--gx: 50}
  .mx-1-t {--mx: 1} .mx-10-t {--mx: 10} .mx-20-t {--mx: 20} .mx-30 {--mx: 30} .tx-40 {--tx: 40} .tx-50 {--tx: 50}
  .nx-1-t {--nx: 1}.nx-10-t {--nx: 10} .nx-20-t {--nx: 20} .nx-30-t {--nx: 30} .nx-40-t {--nx: 40} .nx-50-t {--nx: 50}
  .tx-1-t {--tx: 1}.tx-10-t {--tx: 10} .tx-20-t {--tx: 20} .tx-30-t {--tx: 30} .tx-40-t {--tx: 40} .tx-50-t {--tx: 50}

  /* b,c,d,e,f,g  0-100*/
  .b-0-t {--b: 0 !important} .b-1-t {--b: 1} .b-2-t {--b: 2} .b-3-t {--b: 3} .b-4-t {--b: 4} .b-5-t {--b: 5} .b-6-t {--b: 6} .b-7-t {--b: 7} .b-8-t {--b: 8} .b-9-t {--b: 9} .b-10-t {--b: 10} .b-11-t {--b: 11} .b-12-t {--b: 12} .b-13-t {--b: 13} .b-14-t {--b: 14} .b-15-t {--b: 15} .b-16-t {--b: 16} .b-17-t {--b: 17} .b-18-t {--b: 18} .b-19-t {--b: 19} .b-20-t {--b: 20} .b-21-t {--b: 21} .b-22-t {--b: 22} .b-23-t {--b: 23} .b-24-t {--b: 24} .b-25-t {--b: 25} .b-26-t {--b: 26} .b-27-t {--b: 27} .b-28-t {--b: 28} .b-29-t {--b: 29} .b-30-t {--b: 30} .b-31-t {--b: 31} .b-32-t {--b: 32} .b-33-t {--b: 33} .b-34-t {--b: 34} .b-35-t {--b: 35} .b-36-t {--b: 36} .b-37-t {--b: 37} .b-38-t {--b: 38} .b-39-t {--b: 39} .b-40-t {--b: 40} .b-41-t {--b: 41} .b-42-t {--b: 42} .b-43-t {--b: 43} .b-44-t {--b: 44} .b-45-t {--b: 45} .b-46-t {--b: 46} .b-47-t {--b: 47} .b-48-t {--b: 48} .b-49-t {--b: 49} .b-50-t {--b: 50} .b-51-t {--b: 51} .b-52-t {--b: 52} .b-53-t {--b: 53} .b-54-t {--b: 54} .b-55-t {--b: 55} .b-56-t {--b: 56} .b-57-t {--b: 57} .b-58-t {--b: 58} .b-59-t {--b: 59} .b-60-t {--b: 60} .b-61-t {--b: 61} .b-62-t {--b: 62} .b-63-t {--b: 63} .b-64-t {--b: 64} .b-65-t {--b: 65} .b-66-t {--b: 66} .b-67-t {--b: 67} .b-68-t {--b: 68} .b-69-t {--b: 69} .b-70-t {--b: 70} .b-71-t {--b: 71} .b-72-t {--b: 72} .b-73-t {--b: 73} .b-74-t {--b: 74} .b-75-t {--b: 75} .b-76-t {--b: 76} .b-77-t {--b: 77} .b-78-t {--b: 78} .b-79-t {--b: 79} .b-80-t {--b: 80} .b-81-t {--b: 81} .b-82-t {--b: 82} .b-83-t {--b: 83} .b-84-t {--b: 84} .b-85-t {--b: 85} .b-86-t {--b: 86} .b-87-t {--b: 87} .b-88-t {--b: 88} .b-89-t {--b: 89} .b-90-t {--b: 90} .b-91-t {--b: 91} .b-92-t {--b: 92} .b-93-t {--b: 93} .b-94-t {--b: 94} .b-95-t {--b: 95} .b-96-t {--b: 96} .b-97-t {--b: 97} .b-98-t {--b: 98} .b-99-t {--b: 99} .b-100-t {--b: 100}
  .c-0-t {--c: 0 !important} .c-1-t {--c: 1} .c-2-t {--c: 2} .c-3-t {--c: 3} .c-4-t {--c: 4} .c-5-t {--c: 5} .c-6-t {--c: 6} .c-7-t {--c: 7} .c-8-t {--c: 8} .c-9-t {--c: 9} .c-10-t {--c: 10} .c-11-t {--c: 11} .c-12-t {--c: 12} .c-13-t {--c: 13} .c-14-t {--c: 14} .c-15-t {--c: 15} .c-16-t {--c: 16} .c-17-t {--c: 17} .c-18-t {--c: 18} .c-19-t {--c: 19} .c-20-t {--c: 20} .c-21-t {--c: 21} .c-22-t {--c: 22} .c-23-t {--c: 23} .c-24-t {--c: 24} .c-25-t {--c: 25} .c-26-t {--c: 26} .c-27-t {--c: 27} .c-28-t {--c: 28} .c-29-t {--c: 29} .c-30-t {--c: 30} .c-31-t {--c: 31} .c-32-t {--c: 32} .c-33-t {--c: 33} .c-34-t {--c: 34} .c-35-t {--c: 35} .c-36-t {--c: 36} .c-37-t {--c: 37} .c-38-t {--c: 38} .c-39-t {--c: 39} .c-40-t {--c: 40} .c-41-t {--c: 41} .c-42-t {--c: 42} .c-43-t {--c: 43} .c-44-t {--c: 44} .c-45-t {--c: 45} .c-46-t {--c: 46} .c-47-t {--c: 47} .c-48-t {--c: 48} .c-49-t {--c: 49} .c-50-t {--c: 50} .c-51-t {--c: 51} .c-52-t {--c: 52} .c-53-t {--c: 53} .c-54-t {--c: 54} .c-55-t {--c: 55} .c-56-t {--c: 56} .c-57-t {--c: 57} .c-58-t {--c: 58} .c-59-t {--c: 59} .c-60-t {--c: 60} .c-61-t {--c: 61} .c-62-t {--c: 62} .c-63-t {--c: 63} .c-64-t {--c: 64} .c-65-t {--c: 65} .c-66-t {--c: 66} .c-67-t {--c: 67} .c-68-t {--c: 68} .c-69-t {--c: 69} .c-70-t {--c: 70} .c-71-t {--c: 71} .c-72-t {--c: 72} .c-73-t {--c: 73} .c-74-t {--c: 74} .c-75-t {--c: 75} .c-76-t {--c: 76} .c-77-t {--c: 77} .c-78-t {--c: 78} .c-79-t {--c: 79} .c-80-t {--c: 80} .c-81-t {--c: 81} .c-82-t {--c: 82} .c-83-t {--c: 83} .c-84-t {--c: 84} .c-85-t {--c: 85} .c-86-t {--c: 86} .c-87-t {--c: 87} .c-88-t {--c: 88} .c-89-t {--c: 89} .c-90-t {--c: 90} .c-91-t {--c: 91} .c-92-t {--c: 92} .c-93-t {--c: 93} .c-94-t {--c: 94} .c-95-t {--c: 95} .c-96-t {--c: 96} .c-97-t {--c: 97} .c-98-t {--c: 98} .c-99-t {--c: 99} .c-100-t {--c: 100}
  .d-0-t {--d: 0 !important} .d-1-t {--d: 1} .d-2-t {--d: 2} .d-3-t {--d: 3} .d-4-t {--d: 4} .d-5-t {--d: 5} .d-6-t {--d: 6} .d-7-t {--d: 7} .d-8-t {--d: 8} .d-9-t {--d: 9} .d-10-t {--d: 10} .d-11-t {--d: 11} .d-12-t {--d: 12} .d-13-t {--d: 13} .d-14-t {--d: 14} .d-15-t {--d: 15} .d-16-t {--d: 16} .d-17-t {--d: 17} .d-18-t {--d: 18} .d-19-t {--d: 19} .d-20-t {--d: 20} .d-21-t {--d: 21} .d-22-t {--d: 22} .d-23-t {--d: 23} .d-24-t {--d: 24} .d-25-t {--d: 25} .d-26-t {--d: 26} .d-27-t {--d: 27} .d-28-t {--d: 28} .d-29-t {--d: 29} .d-30-t {--d: 30} .d-31-t {--d: 31} .d-32-t {--d: 32} .d-33-t {--d: 33} .d-34-t {--d: 34} .d-35-t {--d: 35} .d-36-t {--d: 36} .d-37-t {--d: 37} .d-38-t {--d: 38} .d-39-t {--d: 39} .d-40-t {--d: 40} .d-41-t {--d: 41} .d-42-t {--d: 42} .d-43-t {--d: 43} .d-44-t {--d: 44} .d-45-t {--d: 45} .d-46-t {--d: 46} .d-47-t {--d: 47} .d-48-t {--d: 48} .d-49-t {--d: 49} .d-50-t {--d: 50} .d-51-t {--d: 51} .d-52-t {--d: 52} .d-53-t {--d: 53} .d-54-t {--d: 54} .d-55-t {--d: 55} .d-56-t {--d: 56} .d-57-t {--d: 57} .d-58-t {--d: 58} .d-59-t {--d: 59} .d-60-t {--d: 60} .d-61-t {--d: 61} .d-62-t {--d: 62} .d-63-t {--d: 63} .d-64-t {--d: 64} .d-65-t {--d: 65} .d-66-t {--d: 66} .d-67-t {--d: 67} .d-68-t {--d: 68} .d-69-t {--d: 69} .d-70-t {--d: 70} .d-71-t {--d: 71} .d-72-t {--d: 72} .d-73-t {--d: 73} .d-74-t {--d: 74} .d-75-t {--d: 75} .d-76-t {--d: 76} .d-77-t {--d: 77} .d-78-t {--d: 78} .d-79-t {--d: 79} .d-80-t {--d: 80} .d-81-t {--d: 81} .d-82-t {--d: 82} .d-83-t {--d: 83} .d-84-t {--d: 84} .d-85-t {--d: 85} .d-86-t {--d: 86} .d-87-t {--d: 87} .d-88-t {--d: 88} .d-89-t {--d: 89} .d-90-t {--d: 90} .d-91-t {--d: 91} .d-92-t {--d: 92} .d-93-t {--d: 93} .d-94-t {--d: 94} .d-95-t {--d: 95} .d-96-t {--d: 96} .d-97-t {--d: 97} .d-98-t {--d: 98} .d-99-t {--d: 99} .d-100-t {--d: 100}
  .e-0-t {--e: 0 !important} .e-1-t {--e: 1} .e-2-t {--e: 2} .e-3-t {--e: 3} .e-4-t {--e: 4} .e-5-t {--e: 5} .e-6-t {--e: 6} .e-7-t {--e: 7} .e-8-t {--e: 8} .e-9-t {--e: 9} .e-10-t {--e: 10} .e-11-t {--e: 11} .e-12-t {--e: 12} .e-13-t {--e: 13} .e-14-t {--e: 14} .e-15-t {--e: 15} .e-16-t {--e: 16} .e-17-t {--e: 17} .e-18-t {--e: 18} .e-19-t {--e: 19} .e-20-t {--e: 20} .e-21-t {--e: 21} .e-22-t {--e: 22} .e-23-t {--e: 23} .e-24-t {--e: 24} .e-25-t {--e: 25} .e-26-t {--e: 26} .e-27-t {--e: 27} .e-28-t {--e: 28} .e-29-t {--e: 29} .e-30-t {--e: 30} .e-31-t {--e: 31} .e-32-t {--e: 32} .e-33-t {--e: 33} .e-34-t {--e: 34} .e-35-t {--e: 35} .e-36-t {--e: 36} .e-37-t {--e: 37} .e-38-t {--e: 38} .e-39-t {--e: 39} .e-40-t {--e: 40} .e-41-t {--e: 41} .e-42-t {--e: 42} .e-43-t {--e: 43} .e-44-t {--e: 44} .e-45-t {--e: 45} .e-46-t {--e: 46} .e-47-t {--e: 47} .e-48-t {--e: 48} .e-49-t {--e: 49} .e-50-t {--e: 50} .e-51-t {--e: 51} .e-52-t {--e: 52} .e-53-t {--e: 53} .e-54-t {--e: 54} .e-55-t {--e: 55} .e-56-t {--e: 56} .e-57-t {--e: 57} .e-58-t {--e: 58} .e-59-t {--e: 59} .e-60-t {--e: 60} .e-61-t {--e: 61} .e-62-t {--e: 62} .e-63-t {--e: 63} .e-64-t {--e: 64} .e-65-t {--e: 65} .e-66-t {--e: 66} .e-67-t {--e: 67} .e-68-t {--e: 68} .e-69-t {--e: 69} .e-70-t {--e: 70} .e-71-t {--e: 71} .e-72-t {--e: 72} .e-73-t {--e: 73} .e-74-t {--e: 74} .e-75-t {--e: 75} .e-76-t {--e: 76} .e-77-t {--e: 77} .e-78-t {--e: 78} .e-79-t {--e: 79} .e-80-t {--e: 80} .e-81-t {--e: 81} .e-82-t {--e: 82} .e-83-t {--e: 83} .e-84-t {--e: 84} .e-85-t {--e: 85} .e-86-t {--e: 86} .e-87-t {--e: 87} .e-88-t {--e: 88} .e-89-t {--e: 89} .e-90-t {--e: 90} .e-91-t {--e: 91} .e-92-t {--e: 92} .e-93-t {--e: 93} .e-94-t {--e: 94} .e-95-t {--e: 95} .e-96-t {--e: 96} .e-97-t {--e: 97} .e-98-t {--e: 98} .e-99-t {--e: 99} .e-100-t {--e: 100}
  .f-0-t {--f: 0 !important} .f-1-t {--f: 1} .f-2-t {--f: 2} .f-3-t {--f: 3} .f-4-t {--f: 4} .f-5-t {--f: 5} .f-6-t {--f: 6} .f-7-t {--f: 7} .f-8-t {--f: 8} .f-9-t {--f: 9} .f-10-t {--f: 10} .f-11-t {--f: 11} .f-12-t {--f: 12} .f-13-t {--f: 13} .f-14-t {--f: 14} .f-15-t {--f: 15} .f-16-t {--f: 16} .f-17-t {--f: 17} .f-18-t {--f: 18} .f-19-t {--f: 19} .f-20-t {--f: 20} .f-21-t {--f: 21} .f-22-t {--f: 22} .f-23-t {--f: 23} .f-24-t {--f: 24} .f-25-t {--f: 25} .f-26-t {--f: 26} .f-27-t {--f: 27} .f-28-t {--f: 28} .f-29-t {--f: 29} .f-30-t {--f: 30} .f-31-t {--f: 31} .f-32-t {--f: 32} .f-33-t {--f: 33} .f-34-t {--f: 34} .f-35-t {--f: 35} .f-36-t {--f: 36} .f-37-t {--f: 37} .f-38-t {--f: 38} .f-39-t {--f: 39} .f-40-t {--f: 40} .f-41-t {--f: 41} .f-42-t {--f: 42} .f-43-t {--f: 43} .f-44-t {--f: 44} .f-45-t {--f: 45} .f-46-t {--f: 46} .f-47-t {--f: 47} .f-48-t {--f: 48} .f-49-t {--f: 49} .f-50-t {--f: 50} .f-51-t {--f: 51} .f-52-t {--f: 52} .f-53-t {--f: 53} .f-54-t {--f: 54} .f-55-t {--f: 55} .f-56-t {--f: 56} .f-57-t {--f: 57} .f-58-t {--f: 58} .f-59-t {--f: 59} .f-60-t {--f: 60} .f-61-t {--f: 61} .f-62-t {--f: 62} .f-63-t {--f: 63} .f-64-t {--f: 64} .f-65-t {--f: 65} .f-66-t {--f: 66} .f-67-t {--f: 67} .f-68-t {--f: 68} .f-69-t {--f: 69} .f-70-t {--f: 70} .f-71-t {--f: 71} .f-72-t {--f: 72} .f-73-t {--f: 73} .f-74-t {--f: 74} .f-75-t {--f: 75} .f-76-t {--f: 76} .f-77-t {--f: 77} .f-78-t {--f: 78} .f-79-t {--f: 79} .f-80-t {--f: 80} .f-81-t {--f: 81} .f-82-t {--f: 82} .f-83-t {--f: 83} .f-84-t {--f: 84} .f-85-t {--f: 85} .f-86-t {--f: 86} .f-87-t {--f: 87} .f-88-t {--f: 88} .f-89-t {--f: 89} .f-90-t {--f: 90} .f-91-t {--f: 91} .f-92-t {--f: 92} .f-93-t {--f: 93} .f-94-t {--f: 94} .f-95-t {--f: 95} .f-96-t {--f: 96} .f-97-t {--f: 97} .f-98-t {--f: 98} .f-99-t {--f: 99} .f-100-t {--f: 100}
  .g-0-t {--g: 0 !important} .g-1-t {--g: 1} .g-2-t {--g: 2} .g-3-t {--g: 3} .g-4-t {--g: 4} .g-5-t {--g: 5} .g-6-t {--g: 6} .g-7-t {--g: 7} .g-8-t {--g: 8} .g-9-t {--g: 9} .g-10-t {--g: 10} .g-11-t {--g: 11} .g-12-t {--g: 12} .g-13-t {--g: 13} .g-14-t {--g: 14} .g-15-t {--g: 15} .g-16-t {--g: 16} .g-17-t {--g: 17} .g-18-t {--g: 18} .g-19-t {--g: 19} .g-20-t {--g: 20} .g-21-t {--g: 21} .g-22-t {--g: 22} .g-23-t {--g: 23} .g-24-t {--g: 24} .g-25-t {--g: 25} .g-26-t {--g: 26} .g-27-t {--g: 27} .g-28-t {--g: 28} .g-29-t {--g: 29} .g-30-t {--g: 30} .g-31-t {--g: 31} .g-32-t {--g: 32} .g-33-t {--g: 33} .g-34-t {--g: 34} .g-35-t {--g: 35} .g-36-t {--g: 36} .g-37-t {--g: 37} .g-38-t {--g: 38} .g-39-t {--g: 39} .g-40-t {--g: 40} .g-41-t {--g: 41} .g-42-t {--g: 42} .g-43-t {--g: 43} .g-44-t {--g: 44} .g-45-t {--g: 45} .g-46-t {--g: 46} .g-47-t {--g: 47} .g-48-t {--g: 48} .g-49-t {--g: 49} .g-50-t {--g: 50} .g-51-t {--g: 51} .g-52-t {--g: 52} .g-53-t {--g: 53} .g-54-t {--g: 54} .g-55-t {--g: 55} .g-56-t {--g: 56} .g-57-t {--g: 57} .g-58-t {--g: 58} .g-59-t {--g: 59} .g-60-t {--g: 60} .g-61-t {--g: 61} .g-62-t {--g: 62} .g-63-t {--g: 63} .g-64-t {--g: 64} .g-65-t {--g: 65} .g-66-t {--g: 66} .g-67-t {--g: 67} .g-68-t {--g: 68} .g-69-t {--g: 69} .g-70-t {--g: 70} .g-71-t {--g: 71} .g-72-t {--g: 72} .g-73-t {--g: 73} .g-74-t {--g: 74} .g-75-t {--g: 75} .g-76-t {--g: 76} .g-77-t {--g: 77} .g-78-t {--g: 78} .g-79-t {--g: 79} .g-80-t {--g: 80} .g-81-t {--g: 81} .g-82-t {--g: 82} .g-83-t {--g: 83} .g-84-t {--g: 84} .g-85-t {--g: 85} .g-86-t {--g: 86} .g-87-t {--g: 87} .g-88-t {--g: 88} .g-89-t {--g: 89} .g-90-t {--g: 90} .g-91-t {--g: 91} .g-92-t {--g: 92} .g-93-t {--g: 93} .g-94-t {--g: 94} .g-95-t {--g: 95} .g-96-t {--g: 96} .g-97-t {--g: 97} .g-98-t {--g: 98} .g-99-t {--g: 99} .g-100-t {--g: 100}
  .m-0-t {--m: 0 !important} .m-1-t {--m: 1} .m-2-t {--m: 2} .m-3-t {--m: 3} .m-4-t {--m: 4} .m-5-t {--m: 5} .m-6-t {--m: 6} .m-7-t {--m: 7} .m-8-t {--m: 8} .m-9-t {--m: 9} .m-10-t {--m: 10} .m-11-t {--m: 11} .m-12-t {--m: 12} .m-13-t {--m: 13} .m-14-t {--m: 14} .m-15-t {--m: 15} .m-16-t {--m: 16} .m-17-t {--m: 17} .m-18-t {--m: 18} .m-19-t {--m: 19} .m-20-t {--m: 20} .m-21-t {--m: 21} .m-22-t {--m: 22} .m-23-t {--m: 23} .m-24-t {--m: 24} .m-25-t {--m: 25} .m-26-t {--m: 26} .m-27-t {--m: 27} .m-28-t {--m: 28} .m-29-t {--m: 29} .m-30-t {--m: 30} .m-31-t {--m: 31} .m-32-t {--m: 32} .m-33-t {--m: 33} .m-34-t {--m: 34} .m-35-t {--m: 35} .m-36-t {--m: 36} .m-37-t {--m: 37} .m-38-t {--m: 38} .m-39-t {--m: 39} .m-40-t {--m: 40} .m-41-t {--m: 41} .m-42-t {--m: 42} .m-43-t {--m: 43} .m-44-t {--m: 44} .m-45-t {--m: 45} .m-46-t {--m: 46} .m-47-t {--m: 47} .m-48-t {--m: 48} .m-49-t {--m: 49} .m-50-t {--m: 50} .m-51-t {--m: 51} .m-52-t {--m: 52} .m-53-t {--m: 53} .m-54-t {--m: 54} .m-55-t {--m: 55} .m-56-t {--m: 56} .m-57-t {--m: 57} .m-58-t {--m: 58} .m-59-t {--m: 59} .m-60-t {--m: 60} .m-61-t {--m: 61} .m-62-t {--m: 62} .m-63-t {--m: 63} .m-64-t {--m: 64} .m-65-t {--m: 65} .m-66-t {--m: 66} .m-67-t {--m: 67} .m-68-t {--m: 68} .m-69-t {--m: 69} .m-70-t {--m: 70} .m-71-t {--m: 71} .m-72-t {--m: 72} .m-73-t {--m: 73} .m-74-t {--m: 74} .m-75-t {--m: 75} .m-76-t {--m: 76} .m-77-t {--m: 77} .m-78-t {--m: 78} .m-79-t {--m: 79} .m-80-t {--m: 80} .m-81-t {--m: 81} .m-82-t {--m: 82} .m-83-t {--m: 83} .m-84-t {--m: 84} .m-85-t {--m: 85} .m-86-t {--m: 86} .m-87-t {--m: 87} .m-88-t {--m: 88} .m-89-t {--m: 89} .m-90-t {--m: 90} .m-91-t {--m: 91} .m-92-t {--m: 92} .m-93-t {--m: 93} .m-94-t {--m: 94} .m-95-t {--m: 95} .m-96-t {--m: 96} .m-97-t {--m: 97} .m-98-t {--m: 98} .m-99-t {--m: 99} .m-100-t {--m: 100}
  .n-0-t {--n: 0 !important} .n-1-t {--n: 1} .n-2-t {--n: 2} .n-3-t {--n: 3} .n-4-t {--n: 4} .n-5-t {--n: 5} .n-6-t {--n: 6} .n-7-t {--n: 7} .n-8-t {--n: 8} .n-9-t {--n: 9} .n-10-t {--n: 10} .n-11-t {--n: 11} .n-12-t {--n: 12} .n-13-t {--n: 13} .n-14-t {--n: 14} .n-15-t {--n: 15} .n-16-t {--n: 16} .n-17-t {--n: 17} .n-18-t {--n: 18} .n-19-t {--n: 19} .n-20-t {--n: 20} .n-21-t {--n: 21} .n-22-t {--n: 22} .n-23-t {--n: 23} .n-24-t {--n: 24} .n-25-t {--n: 25} .n-26-t {--n: 26} .n-27-t {--n: 27} .n-28-t {--n: 28} .n-29-t {--n: 29} .n-30-t {--n: 30} .n-31-t {--n: 31} .n-32-t {--n: 32} .n-33-t {--n: 33} .n-34-t {--n: 34} .n-35-t {--n: 35} .n-36-t {--n: 36} .n-37-t {--n: 37} .n-38-t {--n: 38} .n-39-t {--n: 39} .n-40-t {--n: 40} .n-41-t {--n: 41} .n-42-t {--n: 42} .n-43-t {--n: 43} .n-44-t {--n: 44} .n-45-t {--n: 45} .n-46-t {--n: 46} .n-47-t {--n: 47} .n-48-t {--n: 48} .n-49-t {--n: 49} .n-50-t {--n: 50} .n-51-t {--n: 51} .n-52-t {--n: 52} .n-53-t {--n: 53} .n-54-t {--n: 54} .n-55-t {--n: 55} .n-56-t {--n: 56} .n-57-t {--n: 57} .n-58-t {--n: 58} .n-59-t {--n: 59} .n-60-t {--n: 60} .n-61-t {--n: 61} .n-62-t {--n: 62} .n-63-t {--n: 63} .n-64-t {--n: 64} .n-65-t {--n: 65} .n-66-t {--n: 66} .n-67-t {--n: 67} .n-68-t {--n: 68} .n-69-t {--n: 69} .n-70-t {--n: 70} .n-71-t {--n: 71} .n-72-t {--n: 72} .n-73-t {--n: 73} .n-74-t {--n: 74} .n-75-t {--n: 75} .n-76-t {--n: 76} .n-77-t {--n: 77} .n-78-t {--n: 78} .n-79-t {--n: 79} .n-80-t {--n: 80} .n-81-t {--n: 81} .n-82-t {--n: 82} .n-83-t {--n: 83} .n-84-t {--n: 84} .n-85-t {--n: 85} .n-86-t {--n: 86} .n-87-t {--n: 87} .n-88-t {--n: 88} .n-89-t {--n: 89} .n-90-t {--n: 90} .n-91-t {--n: 91} .n-92-t {--n: 92} .n-93-t {--n: 93} .n-94-t {--n: 94} .n-95-t {--n: 95} .n-96-t {--n: 96} .n-97-t {--n: 97} .n-98-t {--n: 98} .n-99-t {--n: 99} .n-100-t {--n: 100}
  .t-0-t {--t: 0 !important} .t-1-t {--t: 1} .t-2-t {--t: 2} .t-3-t {--t: 3} .t-4-t {--t: 4} .t-5-t {--t: 5} .t-6-t {--t: 6} .t-7-t {--t: 7} .t-8-t {--t: 8} .t-9-t {--t: 9} .t-10-t {--t: 10} .t-11-t {--t: 11} .t-12-t {--t: 12} .t-13-t {--t: 13} .t-14-t {--t: 14} .t-15-t {--t: 15} .t-16-t {--t: 16} .t-17-t {--t: 17} .t-18-t {--t: 18} .t-19-t {--t: 19} .t-20-t {--t: 20} .t-21-t {--t: 21} .t-22-t {--t: 22} .t-23-t {--t: 23} .t-24-t {--t: 24} .t-25-t {--t: 25} .t-26-t {--t: 26} .t-27-t {--t: 27} .t-28-t {--t: 28} .t-29-t {--t: 29} .t-30-t {--t: 30} .t-31-t {--t: 31} .t-32-t {--t: 32} .t-33-t {--t: 33} .t-34-t {--t: 34} .t-35-t {--t: 35} .t-36-t {--t: 36} .t-37-t {--t: 37} .t-38-t {--t: 38} .t-39-t {--t: 39} .t-40-t {--t: 40} .t-41-t {--t: 41} .t-42-t {--t: 42} .t-43-t {--t: 43} .t-44-t {--t: 44} .t-45-t {--t: 45} .t-46-t {--t: 46} .t-47-t {--t: 47} .t-48-t {--t: 48} .t-49-t {--t: 49} .t-50-t {--t: 50} .t-51-t {--t: 51} .t-52-t {--t: 52} .t-53-t {--t: 53} .t-54-t {--t: 54} .t-55-t {--t: 55} .t-56-t {--t: 56} .t-57-t {--t: 57} .t-58-t {--t: 58} .t-59-t {--t: 59} .t-60-t {--t: 60} .t-61-t {--t: 61} .t-62-t {--t: 62} .t-63-t {--t: 63} .t-64-t {--t: 64} .t-65-t {--t: 65} .t-66-t {--t: 66} .t-67-t {--t: 67} .t-68-t {--t: 68} .t-69-t {--t: 69} .t-70-t {--t: 70} .t-71-t {--t: 71} .t-72-t {--t: 72} .t-73-t {--t: 73} .t-74-t {--t: 74} .t-75-t {--t: 75} .t-76-t {--t: 76} .t-77-t {--t: 77} .t-78-t {--t: 78} .t-79-t {--t: 79} .t-80-t {--t: 80} .t-81-t {--t: 81} .t-82-t {--t: 82} .t-83-t {--t: 83} .t-84-t {--t: 84} .t-85-t {--t: 85} .t-86-t {--t: 86} .t-87-t {--t: 87} .t-88-t {--t: 88} .t-89-t {--t: 89} .t-90-t {--t: 90} .t-91-t {--t: 91} .t-92-t {--t: 92} .t-93-t {--t: 93} .t-94-t {--t: 94} .t-95-t {--t: 95} .t-96-t {--t: 96} .t-97-t {--t: 97} .t-98-t {--t: 98} .t-99-t {--t: 99} .t-100-t {--t: 100}


}


@media (max-width:734px) {
  /* int 1-20 inc 1*/
  .sm-0-s {--sm: 0 !important} .sm-1-s {--sm: 1} .sm-2-s {--sm: 2} .sm-3-s {--sm: 3} .sm-4-s {--sm: 4} .sm-5-s {--sm: 5} .sm-6-s {--sm: 6} .sm-7-s {--sm: 7} .sm-8-s {--sm: 8} .sm-9-s {--sm: 9} .sm-10-s {--sm: 10} .sm-11-s {--sm: 11} .sm-12-s {--sm: 12} .sm-13-s {--sm: 13} .sm-14-s {--sm: 14} .sm-15-s {--sm: 15} .sm-16-s {--sm: 16} .sm-17-s {--sm: 17} .sm-18-s {--sm: 18} .sm-19-s {--sm: 19} .sm-20-s {--sm: 20}
  .sm1-0-s {--sm1: 0 !important} .sm1-1-s {--sm1: 1} .sm1-2-s {--sm1: 2} .sm1-3-s {--sm1: 3} .sm1-4-s {--sm1: 4} .sm1-5-s {--sm1: 5} .sm1-6-s {--sm1: 6} .sm1-7-s {--sm1: 7} .sm1-8-s {--sm1: 8} .sm1-9-s {--sm1: 9} .sm1-10-s {--sm1: 10} .sm1-11-s {--sm1: 11} .sm1-12-s {--sm1: 12} .sm1-13-s {--sm1: 13} .sm1-14-s {--sm1: 14} .sm1-15-s {--sm1: 15} .sm1-16-s {--sm1: 16} .sm1-17-s {--sm1: 17} .sm1-18-s {--sm1: 18} .sm1-19-s {--sm1: 19} .sm1-20-s {--sm1: 20}
  .sm2-0-s {--sm2: 0 !important} .sm2-1-s {--sm2: 1} .sm2-2-s {--sm2: 2} .sm2-3-s {--sm2: 3} .sm2-4-s {--sm2: 4} .sm2-5-s {--sm2: 5} .sm2-6-s {--sm2: 6} .sm2-7-s {--sm2: 7} .sm2-8-s {--sm2: 8} .sm2-9-s {--sm2: 9} .sm2-10-s {--sm2: 10} .sm2-11-s {--sm2: 11} .sm2-12-s {--sm2: 12} .sm2-13-s {--sm2: 13} .sm2-14-s {--sm2: 14} .sm2-15-s {--sm2: 15} .sm2-16-s {--sm2: 16} .sm2-17-s {--sm2: 17} .sm2-18-s {--sm2: 18} .sm2-19-s {--sm2: 19} .sm2-20-s {--sm2: 20}
  .sm3-0-s {--sm3: 0 !important} .sm3-1-s {--sm3: 1} .sm3-2-s {--sm3: 2} .sm3-3-s {--sm3: 3} .sm3-4-s {--sm3: 4} .sm3-5-s {--sm3: 5} .sm3-6-s {--sm3: 6} .sm3-7-s {--sm3: 7} .sm3-8-s {--sm3: 8} .sm3-9-s {--sm3: 9} .sm3-10-s {--sm3: 10} .sm3-11-s {--sm3: 11} .sm3-12-s {--sm3: 12} .sm3-13-s {--sm3: 13} .sm3-14-s {--sm3: 14} .sm3-15-s {--sm3: 15} .sm3-16-s {--sm3: 16} .sm3-17-s {--sm3: 17} .sm3-18-s {--sm3: 18} .sm3-19-s {--sm3: 19} .sm3-20-s {--sm3: 20}
  .sm4-0-s {--sm4: 0 !important} .sm4-1-s {--sm4: 1} .sm4-2-s {--sm4: 2} .sm4-3-s {--sm4: 3} .sm4-4-s {--sm4: 4} .sm4-5-s {--sm4: 5} .sm4-6-s {--sm4: 6} .sm4-7-s {--sm4: 7} .sm4-8-s {--sm4: 8} .sm4-9-s {--sm4: 9} .sm4-10-s {--sm4: 10} .sm4-11-s {--sm4: 11} .sm4-12-s {--sm4: 12} .sm4-13-s {--sm4: 13} .sm4-14-s {--sm4: 14} .sm4-15-s {--sm4: 15} .sm4-16-s {--sm4: 16} .sm4-17-s {--sm4: 17} .sm4-18-s {--sm4: 18} .sm4-19-s {--sm4: 19} .sm4-20-s {--sm4: 20}
  .sm5-0-s {--sm5: 0 !important} .sm5-1-s {--sm5: 1} .sm5-2-s {--sm5: 2} .sm5-3-s {--sm5: 3} .sm5-4-s {--sm5: 4} .sm5-5-s {--sm5: 5} .sm5-6-s {--sm5: 6} .sm5-7-s {--sm5: 7} .sm5-8-s {--sm5: 8} .sm5-9-s {--sm5: 9} .sm5-10-s {--sm5: 10} .sm5-11-s {--sm5: 11} .sm5-12-s {--sm5: 12} .sm5-13-s {--sm5: 13} .sm5-14-s {--sm5: 14} .sm5-15-s {--sm5: 15} .sm5-16-s {--sm5: 16} .sm5-17-s {--sm5: 17} .sm5-18-s {--sm5: 18} .sm5-19-s {--sm5: 19} .sm5-20-s {--sm5: 20}
  .sm6-0-s {--sm6: 0 !important} .sm6-1-s {--sm6: 1} .sm6-2-s {--sm6: 2} .sm6-3-s {--sm6: 3} .sm6-4-s {--sm6: 4} .sm6-5-s {--sm6: 5} .sm6-6-s {--sm6: 6} .sm6-7-s {--sm6: 7} .sm6-8-s {--sm6: 8} .sm6-9-s {--sm6: 9} .sm6-10-s {--sm6: 10} .sm6-11-s {--sm6: 11} .sm6-12-s {--sm6: 12} .sm6-13-s {--sm6: 13} .sm6-14-s {--sm6: 14} .sm6-15-s {--sm6: 15} .sm6-16-s {--sm6: 16} .sm6-17-s {--sm6: 17} .sm6-18-s {--sm6: 18} .sm6-19-s {--sm6: 19} .sm6-20-s {--sm6: 20}
  .sm7-0-s {--sm7: 0 !important} .sm7-1-s {--sm7: 1} .sm7-2-s {--sm7: 2} .sm7-3-s {--sm7: 3} .sm7-4-s {--sm7: 4} .sm7-5-s {--sm7: 5} .sm7-6-s {--sm7: 6} .sm7-7-s {--sm7: 7} .sm7-8-s {--sm7: 8} .sm7-9-s {--sm7: 9} .sm7-10-s {--sm7: 10} .sm7-11-s {--sm7: 11} .sm7-12-s {--sm7: 12} .sm7-13-s {--sm7: 13} .sm7-14-s {--sm7: 14} .sm7-15-s {--sm7: 15} .sm7-16-s {--sm7: 16} .sm7-17-s {--sm7: 17} .sm7-18-s {--sm7: 18} .sm7-19-s {--sm7: 19} .sm7-20-s {--sm7: 20}


  .xs-0-s {--xs: 0 !important} .xs-1-s {--xs: 1} .xs-2-s {--xs: 2} .xs-3-s {--xs: 3} .xs-4-s {--xs: 4} .xs-5-s {--xs: 5} .xs-6-s {--xs: 6} .xs-7-s {--xs: 7} .xs-8-s {--xs: 8} .xs-9-s {--xs: 9} .xs-10-s {--xs: 10}
  .xs1-0-s {--xs1: 0 !important} .xs1-1-s {--xs1: 1} .xs1-2-s {--xs1: 2} .xs1-3-s {--xs1: 3} .xs1-4-s {--xs1: 4} .xs1-5-s {--xs1: 5} .xs1-6-s {--xs1: 6} .xs1-7-s {--xs1: 7} .xs1-8-s {--xs1: 8} .xs1-9-s {--xs1: 9} .xs1-10-s {--xs1: 10}
  .xs2-0-s {--xs2: 0 !important} .xs2-1-s {--xs2: 1} .xs2-2-s {--xs2: 2} .xs2-3-s {--xs2: 3} .xs2-4-s {--xs2: 4} .xs2-5-s {--xs2: 5} .xs2-6-s {--xs2: 6} .xs2-7-s {--xs2: 7} .xs2-8-s {--xs2: 8} .xs2-9-s {--xs2: 9} .xs2-10-s {--xs2: 10}
  .xs3-0-s {--xs3: 0 !important} .xs3-1-s {--xs3: 1} .xs3-2-s {--xs3: 2} .xs3-3-s {--xs3: 3} .xs3-4-s {--xs3: 4} .xs3-5-s {--xs3: 5} .xs3-6-s {--xs3: 6} .xs3-7-s {--xs3: 7} .xs3-8-s {--xs3: 8} .xs3-9-s {--xs3: 9} .xs3-10-s {--xs3: 10}
  .xs4-0-s {--xs4: 0 !important} .xs4-1-s {--xs4: 1} .xs4-2-s {--xs4: 2} .xs4-3-s {--xs4: 3} .xs4-4-s {--xs4: 4} .xs4-5-s {--xs4: 5} .xs4-6-s {--xs4: 6} .xs4-7-s {--xs4: 7} .xs4-8-s {--xs4: 8} .xs4-9-s {--xs4: 9} .xs4-10-s {--xs4: 10}
  .xs5-0-s {--xs5: 0 !important} .xs5-1-s {--xs5: 1} .xs5-2-s {--xs5: 2} .xs5-3-s {--xs5: 3} .xs5-4-s {--xs5: 4} .xs5-5-s {--xs5: 5} .xs5-6-s {--xs5: 6} .xs5-7-s {--xs5: 7} .xs5-8-s {--xs5: 8} .xs5-9-s {--xs5: 9} .xs5-10-s {--xs5: 10}
  .xs6-0-s {--xs6: 0 !important} .xs6-1-s {--xs6: 1} .xs6-2-s {--xs6: 2} .xs6-3-s {--xs6: 3} .xs6-4-s {--xs6: 4} .xs6-5-s {--xs6: 5} .xs6-6-s {--xs6: 6} .xs6-7-s {--xs6: 7} .xs6-8-s {--xs6: 8} .xs6-9-s {--xs6: 9} .xs6-10-s {--xs6: 10}
  .xs7-0-s {--xs7: 0 !important} .xs7-1-s {--xs7: 1} .xs7-2-s {--xs7: 2} .xs7-3-s {--xs7: 3} .xs7-4-s {--xs7: 4} .xs7-5-s {--xs7: 5} .xs7-6-s {--xs7: 6} .xs7-7-s {--xs7: 7} .xs7-8-s {--xs7: 8} .xs7-9-s {--xs7: 9} .xs7-10-s {--xs7: 10}
  .xs8-0-s {--xs8: 0 !important} .xs8-1-s {--xs8: 1} .xs8-2-s {--xs8: 2} .xs8-3-s {--xs8: 3} .xs8-4-s {--xs8: 4} .xs8-5-s {--xs8: 5} .xs8-6-s {--xs8: 6} .xs8-7-s {--xs8: 7} .xs8-8-s {--xs8: 8} .xs8-9-s {--xs8: 9} .xs8-10-s {--xs8: 10}
  .xs9-0-s {--xs9: 0 !important} .xs9-1-s {--xs9: 1} .xs9-2-s {--xs9: 2} .xs9-3-s {--xs9: 3} .xs9-4-s {--xs9: 4} .xs9-5-s {--xs9: 5} .xs9-6-s {--xs9: 6} .xs9-7-s {--xs9: 7} .xs9-8-s {--xs9: 8} .xs9-9-s {--xs9: 9} .xs9-10-s {--xs9: 10}
  .xs10-0-s {--xs10: 0 !important} .xs10-1-s {--xs10: 1} .xs10-2-s {--xs10: 2} .xs10-3-s {--xs10: 3} .xs10-4-s {--xs10: 4} .xs10-5-s {--xs10: 5} .xs10-6-s {--xs10: 6} .xs10-7-s {--xs10: 7} .xs10-8-s {--xs10: 8} .xs10-9-s {--xs10: 9} .xs10-10-s {--xs10: 10}
  .xs11-0-s {--xs11: 0 !important} .xs11-1-s {--xs11: 1} .xs11-2-s {--xs11: 2} .xs11-3-s {--xs11: 3} .xs11-4-s {--xs11: 4} .xs11-5-s {--xs11: 5} .xs11-6-s {--xs11: 6} .xs11-7-s {--xs11: 7} .xs11-8-s {--xs11: 8} .xs11-9-s {--xs11: 9} .xs11-10-s {--xs11: 10}
  .xs12-0-s {--xs12: 0 !important} .xs12-1-s {--xs12: 1} .xs12-2-s {--xs12: 2} .xs12-3-s {--xs12: 3} .xs12-4-s {--xs12: 4} .xs12-5-s {--xs12: 5} .xs12-6-s {--xs12: 6} .xs12-7-s {--xs12: 7} .xs12-8-s {--xs12: 8} .xs12-9-s {--xs12: 9} .xs12-10-s {--xs12: 10}
  .xs13-0-s {--xs13: 0 !important} .xs13-1-s {--xs13: 1} .xs13-2-s {--xs13: 2} .xs13-3-s {--xs13: 3} .xs13-4-s {--xs13: 4} .xs13-5-s {--xs13: 5} .xs13-6-s {--xs13: 6} .xs13-7-s {--xs13: 7} .xs13-8-s {--xs13: 8} .xs13-9-s {--xs13: 9} .xs13-10-s {--xs13: 10}


  .bx-1-s {--bx: 1}.bx-10-s {--bx: 10} .bx-20-s {--bx: 20} .bx-30-s {--bx: 30} .bx-40-s {--bx: 40} .bx-50-s {--bx: 50}
  .cx-1-s {--cx: 1}.cx-10-s {--cx: 10} .cx-20-s {--cx: 20} .cx-30-s {--cx: 30} .cx-40-s {--cx: 40} .cx-50-s {--cx: 50}
  .dx-1-s {--dx: 1}.dx-10-s {--dx: 10} .dx-20-s {--dx: 20} .dx-30-s {--dx: 30} .dx-40-s {--dx: 40} .dx-50-s {--dx: 50}
  .ex-1-s {--ex: 1}.ex-10-s {--ex: 10} .ex-20-s {--ex: 20} .ex-30-s {--ex: 30} .ex-40-s {--ex: 40} .ex-50-s {--ex: 50}
  .fx-1-s {--fx: 1}.fx-10-s {--fx: 10} .fx-20-s {--fx: 20} .fx-30-s {--fx: 30} .fx-40-s {--fx: 40} .fx-50-s {--fx: 50}
  .gx-1-s {--gx: 1}.gx-10-s {--gx: 10} .gx-20-s {--gx: 20} .gx-30-s {--gx: 30} .gx-40-s {--gx: 40} .gx-50-s {--gx: 50}
  .mx-1-s {--mx: 1}.mx-10-s {--mx: 10} .mx-20-s {--mx: 20} .mx-30-s {--mx: 30} .mx-40-s {--mx: 40} .mx-50-s {--mx: 50}
  .nx-1-s {--nx: 1}.nx-10-s {--nx: 10} .nx-20-s {--nx: 20} .nx-30-s {--nx: 30} .nx-40-s {--nx: 40} .nx-50-s {--nx: 50}
  .tx-1-s {--tx: 1} .tx-10-s {--tx: 10} .tx-20-s {--tx: 20} .tx-30-s {--tx: 30} .tx-40-s {--tx: 40} .tx-50-s {--tx: 50}
    /* b,c,d,e,f,g  0-100*/
  .b-0-s {--b: 0 !important} .b-1-s {--b: 1} .b-2-s {--b: 2} .b-3-s {--b: 3} .b-4-s {--b: 4} .b-5-s {--b: 5} .b-6-s {--b: 6} .b-7-s {--b: 7} .b-8-s {--b: 8} .b-9-s {--b: 9} .b-10-s {--b: 10} .b-11-s {--b: 11} .b-12-s {--b: 12} .b-13-s {--b: 13} .b-14-s {--b: 14} .b-15-s {--b: 15} .b-16-s {--b: 16} .b-17-s {--b: 17} .b-18-s {--b: 18} .b-19-s {--b: 19} .b-20-s {--b: 20} .b-21-s {--b: 21} .b-22-s {--b: 22} .b-23-s {--b: 23} .b-24-s {--b: 24} .b-25-s {--b: 25} .b-26-s {--b: 26} .b-27-s {--b: 27} .b-28-s {--b: 28} .b-29-s {--b: 29} .b-30-s {--b: 30} .b-31-s {--b: 31} .b-32-s {--b: 32} .b-33-s {--b: 33} .b-34-s {--b: 34} .b-35-s {--b: 35} .b-36-s {--b: 36} .b-37-s {--b: 37} .b-38-s {--b: 38} .b-39-s {--b: 39} .b-40-s {--b: 40} .b-41-s {--b: 41} .b-42-s {--b: 42} .b-43-s {--b: 43} .b-44-s {--b: 44} .b-45-s {--b: 45} .b-46-s {--b: 46} .b-47-s {--b: 47} .b-48-s {--b: 48} .b-49-s {--b: 49} .b-50-s {--b: 50} .b-51-s {--b: 51} .b-52-s {--b: 52} .b-53-s {--b: 53} .b-54-s {--b: 54} .b-55-s {--b: 55} .b-56-s {--b: 56} .b-57-s {--b: 57} .b-58-s {--b: 58} .b-59-s {--b: 59} .b-60-s {--b: 60} .b-61-s {--b: 61} .b-62-s {--b: 62} .b-63-s {--b: 63} .b-64-s {--b: 64} .b-65-s {--b: 65} .b-66-s {--b: 66} .b-67-s {--b: 67} .b-68-s {--b: 68} .b-69-s {--b: 69} .b-70-s {--b: 70} .b-71-s {--b: 71} .b-72-s {--b: 72} .b-73-s {--b: 73} .b-74-s {--b: 74} .b-75-s {--b: 75} .b-76-s {--b: 76} .b-77-s {--b: 77} .b-78-s {--b: 78} .b-79-s {--b: 79} .b-80-s {--b: 80} .b-81-s {--b: 81} .b-82-s {--b: 82} .b-83-s {--b: 83} .b-84-s {--b: 84} .b-85-s {--b: 85} .b-86-s {--b: 86} .b-87-s {--b: 87} .b-88-s {--b: 88} .b-89-s {--b: 89} .b-90-s {--b: 90} .b-91-s {--b: 91} .b-92-s {--b: 92} .b-93-s {--b: 93} .b-94-s {--b: 94} .b-95-s {--b: 95} .b-96-s {--b: 96} .b-97-s {--b: 97} .b-98-s {--b: 98} .b-99-s {--b: 99} .b-100-s {--b: 100}
  .c-0-s {--c: 0 !important} .c-1-s {--c: 1} .c-2-s {--c: 2} .c-3-s {--c: 3} .c-4-s {--c: 4} .c-5-s {--c: 5} .c-6-s {--c: 6} .c-7-s {--c: 7} .c-8-s {--c: 8} .c-9-s {--c: 9} .c-10-s {--c: 10} .c-11-s {--c: 11} .c-12-s {--c: 12} .c-13-s {--c: 13} .c-14-s {--c: 14} .c-15-s {--c: 15} .c-16-s {--c: 16} .c-17-s {--c: 17} .c-18-s {--c: 18} .c-19-s {--c: 19} .c-20-s {--c: 20} .c-21-s {--c: 21} .c-22-s {--c: 22} .c-23-s {--c: 23} .c-24-s {--c: 24} .c-25-s {--c: 25} .c-26-s {--c: 26} .c-27-s {--c: 27} .c-28-s {--c: 28} .c-29-s {--c: 29} .c-30-s {--c: 30} .c-31-s {--c: 31} .c-32-s {--c: 32} .c-33-s {--c: 33} .c-34-s {--c: 34} .c-35-s {--c: 35} .c-36-s {--c: 36} .c-37-s {--c: 37} .c-38-s {--c: 38} .c-39-s {--c: 39} .c-40-s {--c: 40} .c-41-s {--c: 41} .c-42-s {--c: 42} .c-43-s {--c: 43} .c-44-s {--c: 44} .c-45-s {--c: 45} .c-46-s {--c: 46} .c-47-s {--c: 47} .c-48-s {--c: 48} .c-49-s {--c: 49} .c-50-s {--c: 50} .c-51-s {--c: 51} .c-52-s {--c: 52} .c-53-s {--c: 53} .c-54-s {--c: 54} .c-55-s {--c: 55} .c-56-s {--c: 56} .c-57-s {--c: 57} .c-58-s {--c: 58} .c-59-s {--c: 59} .c-60-s {--c: 60} .c-61-s {--c: 61} .c-62-s {--c: 62} .c-63-s {--c: 63} .c-64-s {--c: 64} .c-65-s {--c: 65} .c-66-s {--c: 66} .c-67-s {--c: 67} .c-68-s {--c: 68} .c-69-s {--c: 69} .c-70-s {--c: 70} .c-71-s {--c: 71} .c-72-s {--c: 72} .c-73-s {--c: 73} .c-74-s {--c: 74} .c-75-s {--c: 75} .c-76-s {--c: 76} .c-77-s {--c: 77} .c-78-s {--c: 78} .c-79-s {--c: 79} .c-80-s {--c: 80} .c-81-s {--c: 81} .c-82-s {--c: 82} .c-83-s {--c: 83} .c-84-s {--c: 84} .c-85-s {--c: 85} .c-86-s {--c: 86} .c-87-s {--c: 87} .c-88-s {--c: 88} .c-89-s {--c: 89} .c-90-s {--c: 90} .c-91-s {--c: 91} .c-92-s {--c: 92} .c-93-s {--c: 93} .c-94-s {--c: 94} .c-95-s {--c: 95} .c-96-s {--c: 96} .c-97-s {--c: 97} .c-98-s {--c: 98} .c-99-s {--c: 99} .c-100-s {--c: 100}
  .d-0-s {--d: 0 !important} .d-1-s {--d: 1} .d-2-s {--d: 2} .d-3-s {--d: 3} .d-4-s {--d: 4} .d-5-s {--d: 5} .d-6-s {--d: 6} .d-7-s {--d: 7} .d-8-s {--d: 8} .d-9-s {--d: 9} .d-10-s {--d: 10} .d-11-s {--d: 11} .d-12-s {--d: 12} .d-13-s {--d: 13} .d-14-s {--d: 14} .d-15-s {--d: 15} .d-16-s {--d: 16} .d-17-s {--d: 17} .d-18-s {--d: 18} .d-19-s {--d: 19} .d-20-s {--d: 20} .d-21-s {--d: 21} .d-22-s {--d: 22} .d-23-s {--d: 23} .d-24-s {--d: 24} .d-25-s {--d: 25} .d-26-s {--d: 26} .d-27-s {--d: 27} .d-28-s {--d: 28} .d-29-s {--d: 29} .d-30-s {--d: 30} .d-31-s {--d: 31} .d-32-s {--d: 32} .d-33-s {--d: 33} .d-34-s {--d: 34} .d-35-s {--d: 35} .d-36-s {--d: 36} .d-37-s {--d: 37} .d-38-s {--d: 38} .d-39-s {--d: 39} .d-40-s {--d: 40} .d-41-s {--d: 41} .d-42-s {--d: 42} .d-43-s {--d: 43} .d-44-s {--d: 44} .d-45-s {--d: 45} .d-46-s {--d: 46} .d-47-s {--d: 47} .d-48-s {--d: 48} .d-49-s {--d: 49} .d-50-s {--d: 50} .d-51-s {--d: 51} .d-52-s {--d: 52} .d-53-s {--d: 53} .d-54-s {--d: 54} .d-55-s {--d: 55} .d-56-s {--d: 56} .d-57-s {--d: 57} .d-58-s {--d: 58} .d-59-s {--d: 59} .d-60-s {--d: 60} .d-61-s {--d: 61} .d-62-s {--d: 62} .d-63-s {--d: 63} .d-64-s {--d: 64} .d-65-s {--d: 65} .d-66-s {--d: 66} .d-67-s {--d: 67} .d-68-s {--d: 68} .d-69-s {--d: 69} .d-70-s {--d: 70} .d-71-s {--d: 71} .d-72-s {--d: 72} .d-73-s {--d: 73} .d-74-s {--d: 74} .d-75-s {--d: 75} .d-76-s {--d: 76} .d-77-s {--d: 77} .d-78-s {--d: 78} .d-79-s {--d: 79} .d-80-s {--d: 80} .d-81-s {--d: 81} .d-82-s {--d: 82} .d-83-s {--d: 83} .d-84-s {--d: 84} .d-85-s {--d: 85} .d-86-s {--d: 86} .d-87-s {--d: 87} .d-88-s {--d: 88} .d-89-s {--d: 89} .d-90-s {--d: 90} .d-91-s {--d: 91} .d-92-s {--d: 92} .d-93-s {--d: 93} .d-94-s {--d: 94} .d-95-s {--d: 95} .d-96-s {--d: 96} .d-97-s {--d: 97} .d-98-s {--d: 98} .d-99-s {--d: 99} .d-100-s {--d: 100}
  .e-0-s {--e: 0 !important} .e-1-s {--e: 1} .e-2-s {--e: 2} .e-3-s {--e: 3} .e-4-s {--e: 4} .e-5-s {--e: 5} .e-6-s {--e: 6} .e-7-s {--e: 7} .e-8-s {--e: 8} .e-9-s {--e: 9} .e-10-s {--e: 10} .e-11-s {--e: 11} .e-12-s {--e: 12} .e-13-s {--e: 13} .e-14-s {--e: 14} .e-15-s {--e: 15} .e-16-s {--e: 16} .e-17-s {--e: 17} .e-18-s {--e: 18} .e-19-s {--e: 19} .e-20-s {--e: 20} .e-21-s {--e: 21} .e-22-s {--e: 22} .e-23-s {--e: 23} .e-24-s {--e: 24} .e-25-s {--e: 25} .e-26-s {--e: 26} .e-27-s {--e: 27} .e-28-s {--e: 28} .e-29-s {--e: 29} .e-30-s {--e: 30} .e-31-s {--e: 31} .e-32-s {--e: 32} .e-33-s {--e: 33} .e-34-s {--e: 34} .e-35-s {--e: 35} .e-36-s {--e: 36} .e-37-s {--e: 37} .e-38-s {--e: 38} .e-39-s {--e: 39} .e-40-s {--e: 40} .e-41-s {--e: 41} .e-42-s {--e: 42} .e-43-s {--e: 43} .e-44-s {--e: 44} .e-45-s {--e: 45} .e-46-s {--e: 46} .e-47-s {--e: 47} .e-48-s {--e: 48} .e-49-s {--e: 49} .e-50-s {--e: 50} .e-51-s {--e: 51} .e-52-s {--e: 52} .e-53-s {--e: 53} .e-54-s {--e: 54} .e-55-s {--e: 55} .e-56-s {--e: 56} .e-57-s {--e: 57} .e-58-s {--e: 58} .e-59-s {--e: 59} .e-60-s {--e: 60} .e-61-s {--e: 61} .e-62-s {--e: 62} .e-63-s {--e: 63} .e-64-s {--e: 64} .e-65-s {--e: 65} .e-66-s {--e: 66} .e-67-s {--e: 67} .e-68-s {--e: 68} .e-69-s {--e: 69} .e-70-s {--e: 70} .e-71-s {--e: 71} .e-72-s {--e: 72} .e-73-s {--e: 73} .e-74-s {--e: 74} .e-75-s {--e: 75} .e-76-s {--e: 76} .e-77-s {--e: 77} .e-78-s {--e: 78} .e-79-s {--e: 79} .e-80-s {--e: 80} .e-81-s {--e: 81} .e-82-s {--e: 82} .e-83-s {--e: 83} .e-84-s {--e: 84} .e-85-s {--e: 85} .e-86-s {--e: 86} .e-87-s {--e: 87} .e-88-s {--e: 88} .e-89-s {--e: 89} .e-90-s {--e: 90} .e-91-s {--e: 91} .e-92-s {--e: 92} .e-93-s {--e: 93} .e-94-s {--e: 94} .e-95-s {--e: 95} .e-96-s {--e: 96} .e-97-s {--e: 97} .e-98-s {--e: 98} .e-99-s {--e: 99} .e-100-s {--e: 100}
  .f-0-s {--f: 0 !important} .f-1-s {--f: 1} .f-2-s {--f: 2} .f-3-s {--f: 3} .f-4-s {--f: 4} .f-5-s {--f: 5} .f-6-s {--f: 6} .f-7-s {--f: 7} .f-8-s {--f: 8} .f-9-s {--f: 9} .f-10-s {--f: 10} .f-11-s {--f: 11} .f-12-s {--f: 12} .f-13-s {--f: 13} .f-14-s {--f: 14} .f-15-s {--f: 15} .f-16-s {--f: 16} .f-17-s {--f: 17} .f-18-s {--f: 18} .f-19-s {--f: 19} .f-20-s {--f: 20} .f-21-s {--f: 21} .f-22-s {--f: 22} .f-23-s {--f: 23} .f-24-s {--f: 24} .f-25-s {--f: 25} .f-26-s {--f: 26} .f-27-s {--f: 27} .f-28-s {--f: 28} .f-29-s {--f: 29} .f-30-s {--f: 30} .f-31-s {--f: 31} .f-32-s {--f: 32} .f-33-s {--f: 33} .f-34-s {--f: 34} .f-35-s {--f: 35} .f-36-s {--f: 36} .f-37-s {--f: 37} .f-38-s {--f: 38} .f-39-s {--f: 39} .f-40-s {--f: 40} .f-41-s {--f: 41} .f-42-s {--f: 42} .f-43-s {--f: 43} .f-44-s {--f: 44} .f-45-s {--f: 45} .f-46-s {--f: 46} .f-47-s {--f: 47} .f-48-s {--f: 48} .f-49-s {--f: 49} .f-50-s {--f: 50} .f-51-s {--f: 51} .f-52-s {--f: 52} .f-53-s {--f: 53} .f-54-s {--f: 54} .f-55-s {--f: 55} .f-56-s {--f: 56} .f-57-s {--f: 57} .f-58-s {--f: 58} .f-59-s {--f: 59} .f-60-s {--f: 60} .f-61-s {--f: 61} .f-62-s {--f: 62} .f-63-s {--f: 63} .f-64-s {--f: 64} .f-65-s {--f: 65} .f-66-s {--f: 66} .f-67-s {--f: 67} .f-68-s {--f: 68} .f-69-s {--f: 69} .f-70-s {--f: 70} .f-71-s {--f: 71} .f-72-s {--f: 72} .f-73-s {--f: 73} .f-74-s {--f: 74} .f-75-s {--f: 75} .f-76-s {--f: 76} .f-77-s {--f: 77} .f-78-s {--f: 78} .f-79-s {--f: 79} .f-80-s {--f: 80} .f-81-s {--f: 81} .f-82-s {--f: 82} .f-83-s {--f: 83} .f-84-s {--f: 84} .f-85-s {--f: 85} .f-86-s {--f: 86} .f-87-s {--f: 87} .f-88-s {--f: 88} .f-89-s {--f: 89} .f-90-s {--f: 90} .f-91-s {--f: 91} .f-92-s {--f: 92} .f-93-s {--f: 93} .f-94-s {--f: 94} .f-95-s {--f: 95} .f-96-s {--f: 96} .f-97-s {--f: 97} .f-98-s {--f: 98} .f-99-s {--f: 99} .f-100-s {--f: 100}
  .g-0-s {--g: 0 !important} .g-1-s {--g: 1} .g-2-s {--g: 2} .g-3-s {--g: 3} .g-4-s {--g: 4} .g-5-s {--g: 5} .g-6-s {--g: 6} .g-7-s {--g: 7} .g-8-s {--g: 8} .g-9-s {--g: 9} .g-10-s {--g: 10} .g-11-s {--g: 11} .g-12-s {--g: 12} .g-13-s {--g: 13} .g-14-s {--g: 14} .g-15-s {--g: 15} .g-16-s {--g: 16} .g-17-s {--g: 17} .g-18-s {--g: 18} .g-19-s {--g: 19} .g-20-s {--g: 20} .g-21-s {--g: 21} .g-22-s {--g: 22} .g-23-s {--g: 23} .g-24-s {--g: 24} .g-25-s {--g: 25} .g-26-s {--g: 26} .g-27-s {--g: 27} .g-28-s {--g: 28} .g-29-s {--g: 29} .g-30-s {--g: 30} .g-31-s {--g: 31} .g-32-s {--g: 32} .g-33-s {--g: 33} .g-34-s {--g: 34} .g-35-s {--g: 35} .g-36-s {--g: 36} .g-37-s {--g: 37} .g-38-s {--g: 38} .g-39-s {--g: 39} .g-40-s {--g: 40} .g-41-s {--g: 41} .g-42-s {--g: 42} .g-43-s {--g: 43} .g-44-s {--g: 44} .g-45-s {--g: 45} .g-46-s {--g: 46} .g-47-s {--g: 47} .g-48-s {--g: 48} .g-49-s {--g: 49} .g-50-s {--g: 50} .g-51-s {--g: 51} .g-52-s {--g: 52} .g-53-s {--g: 53} .g-54-s {--g: 54} .g-55-s {--g: 55} .g-56-s {--g: 56} .g-57-s {--g: 57} .g-58-s {--g: 58} .g-59-s {--g: 59} .g-60-s {--g: 60} .g-61-s {--g: 61} .g-62-s {--g: 62} .g-63-s {--g: 63} .g-64-s {--g: 64} .g-65-s {--g: 65} .g-66-s {--g: 66} .g-67-s {--g: 67} .g-68-s {--g: 68} .g-69-s {--g: 69} .g-70-s {--g: 70} .g-71-s {--g: 71} .g-72-s {--g: 72} .g-73-s {--g: 73} .g-74-s {--g: 74} .g-75-s {--g: 75} .g-76-s {--g: 76} .g-77-s {--g: 77} .g-78-s {--g: 78} .g-79-s {--g: 79} .g-80-s {--g: 80} .g-81-s {--g: 81} .g-82-s {--g: 82} .g-83-s {--g: 83} .g-84-s {--g: 84} .g-85-s {--g: 85} .g-86-s {--g: 86} .g-87-s {--g: 87} .g-88-s {--g: 88} .g-89-s {--g: 89} .g-90-s {--g: 90} .g-91-s {--g: 91} .g-92-s {--g: 92} .g-93-s {--g: 93} .g-94-s {--g: 94} .g-95-s {--g: 95} .g-96-s {--g: 96} .g-97-s {--g: 97} .g-98-s {--g: 98} .g-99-s {--g: 99} .g-100-s {--g: 100}
  .m-0-s {--m: 0 !important} .m-1-s {--m: 1} .m-2-s {--m: 2} .m-3-s {--m: 3} .m-4-s {--m: 4} .m-5-s {--m: 5} .m-6-s {--m: 6} .m-7-s {--m: 7} .m-8-s {--m: 8} .m-9-s {--m: 9} .m-10-s {--m: 10} .m-11-s {--m: 11} .m-12-s {--m: 12} .m-13-s {--m: 13} .m-14-s {--m: 14} .m-15-s {--m: 15} .m-16-s {--m: 16} .m-17-s {--m: 17} .m-18-s {--m: 18} .m-19-s {--m: 19} .m-20-s {--m: 20} .m-21-s {--m: 21} .m-22-s {--m: 22} .m-23-s {--m: 23} .m-24-s {--m: 24} .m-25-s {--m: 25} .m-26-s {--m: 26} .m-27-s {--m: 27} .m-28-s {--m: 28} .m-29-s {--m: 29} .m-30-s {--m: 30} .m-31-s {--m: 31} .m-32-s {--m: 32} .m-33-s {--m: 33} .m-34-s {--m: 34} .m-35-s {--m: 35} .m-36-s {--m: 36} .m-37-s {--m: 37} .m-38-s {--m: 38} .m-39-s {--m: 39} .m-40-s {--m: 40} .m-41-s {--m: 41} .m-42-s {--m: 42} .m-43-s {--m: 43} .m-44-s {--m: 44} .m-45-s {--m: 45} .m-46-s {--m: 46} .m-47-s {--m: 47} .m-48-s {--m: 48} .m-49-s {--m: 49} .m-50-s {--m: 50} .m-51-s {--m: 51} .m-52-s {--m: 52} .m-53-s {--m: 53} .m-54-s {--m: 54} .m-55-s {--m: 55} .m-56-s {--m: 56} .m-57-s {--m: 57} .m-58-s {--m: 58} .m-59-s {--m: 59} .m-60-s {--m: 60} .m-61-s {--m: 61} .m-62-s {--m: 62} .m-63-s {--m: 63} .m-64-s {--m: 64} .m-65-s {--m: 65} .m-66-s {--m: 66} .m-67-s {--m: 67} .m-68-s {--m: 68} .m-69-s {--m: 69} .m-70-s {--m: 70} .m-71-s {--m: 71} .m-72-s {--m: 72} .m-73-s {--m: 73} .m-74-s {--m: 74} .m-75-s {--m: 75} .m-76-s {--m: 76} .m-77-s {--m: 77} .m-78-s {--m: 78} .m-79-s {--m: 79} .m-80-s {--m: 80} .m-81-s {--m: 81} .m-82-s {--m: 82} .m-83-s {--m: 83} .m-84-s {--m: 84} .m-85-s {--m: 85} .m-86-s {--m: 86} .m-87-s {--m: 87} .m-88-s {--m: 88} .m-89-s {--m: 89} .m-90-s {--m: 90} .m-91-s {--m: 91} .m-92-s {--m: 92} .m-93-s {--m: 93} .m-94-s {--m: 94} .m-95-s {--m: 95} .m-96-s {--m: 96} .m-97-s {--m: 97} .m-98-s {--m: 98} .m-99-s {--m: 99} .m-100-s {--m: 100}
  .n-0-s {--n: 0 !important} .n-1-s {--n: 1} .n-2-s {--n: 2} .n-3-s {--n: 3} .n-4-s {--n: 4} .n-5-s {--n: 5} .n-6-s {--n: 6} .n-7-s {--n: 7} .n-8-s {--n: 8} .n-9-s {--n: 9} .n-10-s {--n: 10} .n-11-s {--n: 11} .n-12-s {--n: 12} .n-13-s {--n: 13} .n-14-s {--n: 14} .n-15-s {--n: 15} .n-16-s {--n: 16} .n-17-s {--n: 17} .n-18-s {--n: 18} .n-19-s {--n: 19} .n-20-s {--n: 20} .n-21-s {--n: 21} .n-22-s {--n: 22} .n-23-s {--n: 23} .n-24-s {--n: 24} .n-25-s {--n: 25} .n-26-s {--n: 26} .n-27-s {--n: 27} .n-28-s {--n: 28} .n-29-s {--n: 29} .n-30-s {--n: 30} .n-31-s {--n: 31} .n-32-s {--n: 32} .n-33-s {--n: 33} .n-34-s {--n: 34} .n-35-s {--n: 35} .n-36-s {--n: 36} .n-37-s {--n: 37} .n-38-s {--n: 38} .n-39-s {--n: 39} .n-40-s {--n: 40} .n-41-s {--n: 41} .n-42-s {--n: 42} .n-43-s {--n: 43} .n-44-s {--n: 44} .n-45-s {--n: 45} .n-46-s {--n: 46} .n-47-s {--n: 47} .n-48-s {--n: 48} .n-49-s {--n: 49} .n-50-s {--n: 50} .n-51-s {--n: 51} .n-52-s {--n: 52} .n-53-s {--n: 53} .n-54-s {--n: 54} .n-55-s {--n: 55} .n-56-s {--n: 56} .n-57-s {--n: 57} .n-58-s {--n: 58} .n-59-s {--n: 59} .n-60-s {--n: 60} .n-61-s {--n: 61} .n-62-s {--n: 62} .n-63-s {--n: 63} .n-64-s {--n: 64} .n-65-s {--n: 65} .n-66-s {--n: 66} .n-67-s {--n: 67} .n-68-s {--n: 68} .n-69-s {--n: 69} .n-70-s {--n: 70} .n-71-s {--n: 71} .n-72-s {--n: 72} .n-73-s {--n: 73} .n-74-s {--n: 74} .n-75-s {--n: 75} .n-76-s {--n: 76} .n-77-s {--n: 77} .n-78-s {--n: 78} .n-79-s {--n: 79} .n-80-s {--n: 80} .n-81-s {--n: 81} .n-82-s {--n: 82} .n-83-s {--n: 83} .n-84-s {--n: 84} .n-85-s {--n: 85} .n-86-s {--n: 86} .n-87-s {--n: 87} .n-88-s {--n: 88} .n-89-s {--n: 89} .n-90-s {--n: 90} .n-91-s {--n: 91} .n-92-s {--n: 92} .n-93-s {--n: 93} .n-94-s {--n: 94} .n-95-s {--n: 95} .n-96-s {--n: 96} .n-97-s {--n: 97} .n-98-s {--n: 98} .n-99-s {--n: 99} .n-100-s {--n: 100}
  .t-0-s {--t: 0 !important} .t-1-s {--t: 1} .t-2-s {--t: 2} .t-3-s {--t: 3} .t-4-s {--t: 4} .t-5-s {--t: 5} .t-6-s {--t: 6} .t-7-s {--t: 7} .t-8-s {--t: 8} .t-9-s {--t: 9} .t-10-s {--t: 10} .t-11-s {--t: 11} .t-12-s {--t: 12} .t-13-s {--t: 13} .t-14-s {--t: 14} .t-15-s {--t: 15} .t-16-s {--t: 16} .t-17-s {--t: 17} .t-18-s {--t: 18} .t-19-s {--t: 19} .t-20-s {--t: 20} .t-21-s {--t: 21} .t-22-s {--t: 22} .t-23-s {--t: 23} .t-24-s {--t: 24} .t-25-s {--t: 25} .t-26-s {--t: 26} .t-27-s {--t: 27} .t-28-s {--t: 28} .t-29-s {--t: 29} .t-30-s {--t: 30} .t-31-s {--t: 31} .t-32-s {--t: 32} .t-33-s {--t: 33} .t-34-s {--t: 34} .t-35-s {--t: 35} .t-36-s {--t: 36} .t-37-s {--t: 37} .t-38-s {--t: 38} .t-39-s {--t: 39} .t-40-s {--t: 40} .t-41-s {--t: 41} .t-42-s {--t: 42} .t-43-s {--t: 43} .t-44-s {--t: 44} .t-45-s {--t: 45} .t-46-s {--t: 46} .t-47-s {--t: 47} .t-48-s {--t: 48} .t-49-s {--t: 49} .t-50-s {--t: 50} .t-51-s {--t: 51} .t-52-s {--t: 52} .t-53-s {--t: 53} .t-54-s {--t: 54} .t-55-s {--t: 55} .t-56-s {--t: 56} .t-57-s {--t: 57} .t-58-s {--t: 58} .t-59-s {--t: 59} .t-60-s {--t: 60} .t-61-s {--t: 61} .t-62-s {--t: 62} .t-63-s {--t: 63} .t-64-s {--t: 64} .t-65-s {--t: 65} .t-66-s {--t: 66} .t-67-s {--t: 67} .t-68-s {--t: 68} .t-69-s {--t: 69} .t-70-s {--t: 70} .t-71-s {--t: 71} .t-72-s {--t: 72} .t-73-s {--t: 73} .t-74-s {--t: 74} .t-75-s {--t: 75} .t-76-s {--t: 76} .t-77-s {--t: 77} .t-78-s {--t: 78} .t-79-s {--t: 79} .t-80-s {--t: 80} .t-81-s {--t: 81} .t-82-s {--t: 82} .t-83-s {--t: 83} .t-84-s {--t: 84} .t-85-s {--t: 85} .t-86-s {--t: 86} .t-87-s {--t: 87} .t-88-s {--t: 88} .t-89-s {--t: 89} .t-90-s {--t: 90} .t-91-s {--t: 91} .t-92-s {--t: 92} .t-93-s {--t: 93} .t-94-s {--t: 94} .t-95-s {--t: 95} .t-96-s {--t: 96} .t-97-s {--t: 97} .t-98-s {--t: 98} .t-99-s {--t: 99} .t-100-s {--t: 100}


  }

  @media (max-width:340px) {
    .b-0-xs {--b: 0 !important} .b-1-xs {--b: 1} .b-2-xs {--b: 2} .b-3-xs {--b: 3} .b-4-xs {--b: 4} .b-5-xs {--b: 5} .b-6-xs {--b: 6} .b-7-xs {--b: 7} .b-8-xs {--b: 8} .b-9-xs {--b: 9} .b-10-xs {--b: 10} .b-11-xs {--b: 11} .b-12-xs {--b: 12} .b-13-xs {--b: 13} .b-14-xs {--b: 14} .b-15-xs {--b: 15} .b-16-xs {--b: 16} .b-17-xs {--b: 17} .b-18-xs {--b: 18} .b-19-xs {--b: 19} .b-20-xs {--b: 20} .b-21-xs {--b: 21} .b-22-xs {--b: 22} .b-23-xs {--b: 23} .b-24-xs {--b: 24} .b-25-xs {--b: 25} .b-26-xs {--b: 26} .b-27-xs {--b: 27} .b-28-xs {--b: 28} .b-29-xs {--b: 29} .b-30-xs {--b: 30} .b-31-xs {--b: 31} .b-32-xs {--b: 32} .b-33-xs {--b: 33} .b-34-xs {--b: 34} .b-35-xs {--b: 35} .b-36-xs {--b: 36} .b-37-xs {--b: 37} .b-38-xs {--b: 38} .b-39-xs {--b: 39} .b-40-xs {--b: 40} .b-41-xs {--b: 41} .b-42-xs {--b: 42} .b-43-xs {--b: 43} .b-44-xs {--b: 44} .b-45-xs {--b: 45} .b-46-xs {--b: 46} .b-47-xs {--b: 47} .b-48-xs {--b: 48} .b-49-xs {--b: 49} .b-50-xs {--b: 50} .b-51-xs {--b: 51} .b-52-xs {--b: 52} .b-53-xs {--b: 53} .b-54-xs {--b: 54} .b-55-xs {--b: 55} .b-56-xs {--b: 56} .b-57-xs {--b: 57} .b-58-xs {--b: 58} .b-59-xs {--b: 59} .b-60-xs {--b: 60} .b-61-xs {--b: 61} .b-62-xs {--b: 62} .b-63-xs {--b: 63} .b-64-xs {--b: 64} .b-65-xs {--b: 65} .b-66-xs {--b: 66} .b-67-xs {--b: 67} .b-68-xs {--b: 68} .b-69-xs {--b: 69} .b-70-xs {--b: 70} .b-71-xs {--b: 71} .b-72-xs {--b: 72} .b-73-xs {--b: 73} .b-74-xs {--b: 74} .b-75-xs {--b: 75} .b-76-xs {--b: 76} .b-77-xs {--b: 77} .b-78-xs {--b: 78} .b-79-xs {--b: 79} .b-80-xs {--b: 80} .b-81-xs {--b: 81} .b-82-xs {--b: 82} .b-83-xs {--b: 83} .b-84-xs {--b: 84} .b-85-xs {--b: 85} .b-86-xs {--b: 86} .b-87-xs {--b: 87} .b-88-xs {--b: 88} .b-89-xs {--b: 89} .b-90-xs {--b: 90} .b-91-xs {--b: 91} .b-92-xs {--b: 92} .b-93-xs {--b: 93} .b-94-xs {--b: 94} .b-95-xs {--b: 95} .b-96-xs {--b: 96} .b-97-xs {--b: 97} .b-98-xs {--b: 98} .b-99-xs {--b: 99} .b-100-xs {--b: 100}
    .c-0-xs {--c: 0 !important} .c-1-xs {--c: 1} .c-2-xs {--c: 2} .c-3-xs {--c: 3} .c-4-xs {--c: 4} .c-5-xs {--c: 5} .c-6-xs {--c: 6} .c-7-xs {--c: 7} .c-8-xs {--c: 8} .c-9-xs {--c: 9} .c-10-xs {--c: 10} .c-11-xs {--c: 11} .c-12-xs {--c: 12} .c-13-xs {--c: 13} .c-14-xs {--c: 14} .c-15-xs {--c: 15} .c-16-xs {--c: 16} .c-17-xs {--c: 17} .c-18-xs {--c: 18} .c-19-xs {--c: 19} .c-20-xs {--c: 20} .c-21-xs {--c: 21} .c-22-xs {--c: 22} .c-23-xs {--c: 23} .c-24-xs {--c: 24} .c-25-xs {--c: 25} .c-26-xs {--c: 26} .c-27-xs {--c: 27} .c-28-xs {--c: 28} .c-29-xs {--c: 29} .c-30-xs {--c: 30} .c-31-xs {--c: 31} .c-32-xs {--c: 32} .c-33-xs {--c: 33} .c-34-xs {--c: 34} .c-35-xs {--c: 35} .c-36-xs {--c: 36} .c-37-xs {--c: 37} .c-38-xs {--c: 38} .c-39-xs {--c: 39} .c-40-xs {--c: 40} .c-41-xs {--c: 41} .c-42-xs {--c: 42} .c-43-xs {--c: 43} .c-44-xs {--c: 44} .c-45-xs {--c: 45} .c-46-xs {--c: 46} .c-47-xs {--c: 47} .c-48-xs {--c: 48} .c-49-xs {--c: 49} .c-50-xs {--c: 50} .c-51-xs {--c: 51} .c-52-xs {--c: 52} .c-53-xs {--c: 53} .c-54-xs {--c: 54} .c-55-xs {--c: 55} .c-56-xs {--c: 56} .c-57-xs {--c: 57} .c-58-xs {--c: 58} .c-59-xs {--c: 59} .c-60-xs {--c: 60} .c-61-xs {--c: 61} .c-62-xs {--c: 62} .c-63-xs {--c: 63} .c-64-xs {--c: 64} .c-65-xs {--c: 65} .c-66-xs {--c: 66} .c-67-xs {--c: 67} .c-68-xs {--c: 68} .c-69-xs {--c: 69} .c-70-xs {--c: 70} .c-71-xs {--c: 71} .c-72-xs {--c: 72} .c-73-xs {--c: 73} .c-74-xs {--c: 74} .c-75-xs {--c: 75} .c-76-xs {--c: 76} .c-77-xs {--c: 77} .c-78-xs {--c: 78} .c-79-xs {--c: 79} .c-80-xs {--c: 80} .c-81-xs {--c: 81} .c-82-xs {--c: 82} .c-83-xs {--c: 83} .c-84-xs {--c: 84} .c-85-xs {--c: 85} .c-86-xs {--c: 86} .c-87-xs {--c: 87} .c-88-xs {--c: 88} .c-89-xs {--c: 89} .c-90-xs {--c: 90} .c-91-xs {--c: 91} .c-92-xs {--c: 92} .c-93-xs {--c: 93} .c-94-xs {--c: 94} .c-95-xs {--c: 95} .c-96-xs {--c: 96} .c-97-xs {--c: 97} .c-98-xs {--c: 98} .c-99-xs {--c: 99} .c-100-xs {--c: 100}
    .d-0-xs {--d: 0 !important} .d-1-xs {--d: 1} .d-2-xs {--d: 2} .d-3-xs {--d: 3} .d-4-xs {--d: 4} .d-5-xs {--d: 5} .d-6-xs {--d: 6} .d-7-xs {--d: 7} .d-8-xs {--d: 8} .d-9-xs {--d: 9} .d-10-xs {--d: 10} .d-11-xs {--d: 11} .d-12-xs {--d: 12} .d-13-xs {--d: 13} .d-14-xs {--d: 14} .d-15-xs {--d: 15} .d-16-xs {--d: 16} .d-17-xs {--d: 17} .d-18-xs {--d: 18} .d-19-xs {--d: 19} .d-20-xs {--d: 20} .d-21-xs {--d: 21} .d-22-xs {--d: 22} .d-23-xs {--d: 23} .d-24-xs {--d: 24} .d-25-xs {--d: 25} .d-26-xs {--d: 26} .d-27-xs {--d: 27} .d-28-xs {--d: 28} .d-29-xs {--d: 29} .d-30-xs {--d: 30} .d-31-xs {--d: 31} .d-32-xs {--d: 32} .d-33-xs {--d: 33} .d-34-xs {--d: 34} .d-35-xs {--d: 35} .d-36-xs {--d: 36} .d-37-xs {--d: 37} .d-38-xs {--d: 38} .d-39-xs {--d: 39} .d-40-xs {--d: 40} .d-41-xs {--d: 41} .d-42-xs {--d: 42} .d-43-xs {--d: 43} .d-44-xs {--d: 44} .d-45-xs {--d: 45} .d-46-xs {--d: 46} .d-47-xs {--d: 47} .d-48-xs {--d: 48} .d-49-xs {--d: 49} .d-50-xs {--d: 50} .d-51-xs {--d: 51} .d-52-xs {--d: 52} .d-53-xs {--d: 53} .d-54-xs {--d: 54} .d-55-xs {--d: 55} .d-56-xs {--d: 56} .d-57-xs {--d: 57} .d-58-xs {--d: 58} .d-59-xs {--d: 59} .d-60-xs {--d: 60} .d-61-xs {--d: 61} .d-62-xs {--d: 62} .d-63-xs {--d: 63} .d-64-xs {--d: 64} .d-65-xs {--d: 65} .d-66-xs {--d: 66} .d-67-xs {--d: 67} .d-68-xs {--d: 68} .d-69-xs {--d: 69} .d-70-xs {--d: 70} .d-71-xs {--d: 71} .d-72-xs {--d: 72} .d-73-xs {--d: 73} .d-74-xs {--d: 74} .d-75-xs {--d: 75} .d-76-xs {--d: 76} .d-77-xs {--d: 77} .d-78-xs {--d: 78} .d-79-xs {--d: 79} .d-80-xs {--d: 80} .d-81-xs {--d: 81} .d-82-xs {--d: 82} .d-83-xs {--d: 83} .d-84-xs {--d: 84} .d-85-xs {--d: 85} .d-86-xs {--d: 86} .d-87-xs {--d: 87} .d-88-xs {--d: 88} .d-89-xs {--d: 89} .d-90-xs {--d: 90} .d-91-xs {--d: 91} .d-92-xs {--d: 92} .d-93-xs {--d: 93} .d-94-xs {--d: 94} .d-95-xs {--d: 95} .d-96-xs {--d: 96} .d-97-xs {--d: 97} .d-98-xs {--d: 98} .d-99-xs {--d: 99} .d-100-xs {--d: 100}

  }
