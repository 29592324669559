@import './dimesions.css'; @import "./position.css";@import "./colors.css";

.pd {padding: var(--pd);/*--pd-xt:var(--pd);--pd-t:var(--pd);--pd-mp:var(--pd);--pd-ml:var(--pd);*/}
.pdS {padding-top: var(--pdS);padding-bottom: var(--pdS);/*--pdS-xt:var(--pdS);--pdS-t:var(--pdS);--pdS-mp:var(--pdS);--pdS-ml:var(--pdS);*/}
.pdC {padding-left: var(--pdC);padding-right: var(--pdC);/*--pdC-xt:var(--pdC);--pdC-t:var(--pdC);--pdC-mp:var(--pdC);--pdC-ml:var(--pdC);*/}
.pdT {padding-top: var(--pdT);/*--pdT-xt:var(--pdT);--pdT-t:var(--pdT);--pdT-mp:var(--pdT);--pdT-ml:var(--pdT);*/}
.pdB {padding-bottom: var(--pdB);/*--pdB-xt:var(--pdB);--pdB-t:var(--pdB);--pdB-mp:var(--pdB);--pdB-ml:var(--pdB);*/}
.pdL {padding-left: var(--pdL);/*--pdL-xt:var(--pdL);--pdL-t:var(--pdL);--pdL-mp:var(--pdL);--pdL-ml:var(--pdL);*/}
.pdR {padding-right: var(--pdR);/*--pdR-xt:var(--pdR);--pdR-t:var(--pdR);--pdR-mp:var(--pdR);--pdR-ml:var(--pdR);*/}
/*

@media  (max-width: 1450px) AND (min-height: 501px) {
  .pd {padding: var(--pd-xt);}
  .pdS {padding-top: var(--pdS-xt);padding-bottom: var(--pdS-xt);}
  .pdC {padding-left: var(--pdC-xt);padding-right: var(--pdC-xt);}
  .pdT {padding-top: var(--pdT-xt);}
  .pdB {padding-bottom: var(--pdB-xt);}
  .pdL {padding-left: var(--pdL-xt);}
  .pdR {padding-right: var(--pdR-xt);}
}

@media  (max-width: 1150px) AND (min-height: 501px) {
  .pd {padding: var(--pd-t);}
  .pdS {padding-top: var(--pdS-t);padding-bottom: var(--pdS-t);}
  .pdC {padding-left: var(--pdC-t);padding-right: var(--pdC-t);}
  .pdT {padding-top: var(--pdT-t);}
  .pdB {padding-bottom: var(--pdB-t);}
  .pdL {padding-left: var(--pdL-t);}
  .pdR {padding-right: var(--pdR-t);}
}

@media  (max-height: 500px) AND (max-width: 970px) AND (orientation:landscape) {
  .pd {padding: var(--pd-ml);}
  .pdS {padding-top: var(--pdS-ml);padding-bottom: var(--pdS-ml);}
  .pdC {padding-left: var(--pdC-ml);padding-right: var(--pdC-ml);}
  .pdT {padding-top: var(--pdT-ml);}
  .pdB {padding-bottom: var(--pdB-ml);}
  .pdL {padding-left: var(--pdL-ml);}
  .pdR {padding-right: var(--pdR-ml);}
}

@media  (max-width: 450px) AND (orientation:portrait) {
  .pd {padding: var(--pd-mp);--pd-xm:var(--pd-mp);}
  .pdS {padding-top: var(--pdS-mp);padding-bottom: var(--pdS-mp);--pdS-xm:var(--pdS-mp);}
  .pdC {padding-left: var(--pdC-mp);padding-right: var(--pdC-mp);--pdC-xm: var(--pdC-mp);}
  .pdT {padding-top: var(--pdT-mp);--pdT-xm:var(--pdT-mp);}
  .pdB {padding-bottom: var(--pdB-mp);--pdB-xm:var(--pdB-mp);}
  .pdL {padding-left: var(--pdL-mp);--pdL-xm:var(--pdL-mp);}
  .pdR {padding-right: var(--pdR-mp);--pdR-xm:var(--pdR-mp);}
}

@media  (max-width: 350px) AND (orientation:portrait) {
  .pd {padding: var(--pd-xm);}
  .pdS {padding-top: var(--pdS-xm);padding-bottom: var(--pdS-xm);}
  .pdC {padding-left: var(--pdC-xm);padding-right: var(--pdC-xm);}
  .pdT {padding-top: var(--pdT-xm);}
  .pdB {padding-bottom: var(--pdB-xm);}
  .pdL {padding-left: var(--pdL-xm);}
  .pdR {padding-right: var(--pdR-xm);}
}
*/
