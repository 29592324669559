.hero-bkg-cont {height: 100%; }

.hero-bkg-cont > div {background-position:  var(--bkgPosL); background-size: var(--bkgSizeL); background-repeat: no-repeat;width: 100%;height: 100%;}
.hero-port-img {display: none;}
[class*="hero-grad-"] {--rgb:var(--r1),var(--g1),var(--b1);}
.hero-grad-start {background-image: linear-gradient(180deg,rgba(var(--rgb),var(--a)) ,rgba(var(--rgb),0) var(--gradStop));}
.hero-grad-end {background-image: linear-gradient(0deg,rgba(var(--rgb),var(--a)) ,rgba(var(--rgb),0) var(--gradStop));}
.hero-grad-cen {background-image: linear-gradient(180deg, rgba(var(--rgb),0) var(--gradStop),rgba(var(--rgb),var(--a)),rgba(var(--rgb),0) calc(100% - var(--gradStop)));}
.hero-grad-bar-end {background-image: linear-gradient(180deg,rgba(var(--rgb),var(--a1)),rgba(var(--rgb),0) calc(var(--top-bar-height) * 2) var(--gradStop),rgba(var(--rgb),var(--a)));}

@media  (max-width: 500px) AND (orientation:portrait) {
.hero-bkg-cont > div {background-position:  var(--bkgPosP); background-size: var(--bkgSizeP);}
.hero-port-img {display: block;background-size: cover;background-position: center;}
.hero-land-img {display: none;}
}
