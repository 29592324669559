.p-rel {position: relative;}
.p-abs {position: absolute;}
.p-abs-tl {position: absolute;left: 0;top:0;width: 100%;}
.p-abs-full {position: absolute;left: 0;top:0;width: 100%;height: 100%;}
.p-fixed {position: fixed;}
.p-sticky {position: sticky;}
.p-fixed-full {position: fixed;left: 0;top:0;width: 100%;height: 100%;}
.center-tl {transform: translate(-50%,-50%);}
.center-tr {transform: translate(50%,-50%);}
.center-bl {transform: translate(-50%,50%);}
.center-br {transform: translate(50%,50%);}
.top-0 {top:0} .left-0 {left:0} .right-0 {right:0} .bottom-0 {bottom:0}
/* Z-index 1-10 +1 ->  10-20 +2 -> 30-100 +10 -> 100-1000 +100  1000-10000 +1000*/
.z-0 {z-index: 0}.z-1 {z-index: 1} .z-2 {z-index: 2}.z-3 {z-index: 3}.z-4 {z-index: 4}.z-5 {z-index: 5}.z-6 {z-index: 6}.z-7 {z-index: 7}.z-8 {z-index: 8}.z-9 {z-index: 9} .z-10 {z-index: 10}
.z-11 {z-index: 11}.z-12 {z-index: 12}.z-13 {z-index: 13} .z-14 {z-index: 14}.z-15 {z-index: 15}.z-16 {z-index: 16}.z-17{z-index: 17} .z-18 {z-index: 18}.z-19 {z-index: 19} .z-20 {z-index: 20}
.z-30 {z-index: 30} .z-40 {z-index: 40} .z-50 {z-index: 50} .z-60 {z-index: 60} .z-70 {z-index: 70} .z-80 {z-index: 80} .z-90 {z-index: 90}
.z-100 {z-index: 100} .z-200 {z-index: 200}  .z-300 {z-index: 300} .z-400 {z-index: 400} .z-500 {z-index: 500} .z-600 {z-index: 600} .z-700 {z-index: 700}  .z-800 {z-index: 800}  .z-900 {z-index: 900}  .z-1000 {z-index: 1000}
 .z-2000 {z-index: 2000} .z-3000{z-index: 3000} .z-4000{z-index: 4000} .z-5000 {z-index: 5000} .z-6000 {z-index: 6000} .z-7000 {z-index: 7000} .z-8000 {z-index: 8000} .z-9000 {z-index: 9000}.z-10000 {z-index: 10000}

.p-no-mr {--pTr:0px;--pTrX:0px;--pTrY:0px;}
.p-no-tr {transform: none;}
.p-top-left {left: var(--pLe);top: var(--pTo);}
.p-top-right {right: var(--pRi);top: var(--pTo);}
.p-bottom-left {left: var(--pLe);bottom: var(--pBo);}
.p-bottom-right {right: var(--pRi);bottom: var(--pBo);}


.p-over-top {top: 0;left: 50%;transform: translate(-50%,calc(-100% - var(--pTr,0px)));}
.p-over-bottom { bottom: 0;left: 50%;transform: translate(-50%,calc(100% + var(--pTr,0px)));}
.p-over-left {top: 50%;left: 0;transform: translate(calc(-100% - var(--pTr,0px)),-50%);}
.p-over-right { top: 50%;right: 0;transform: translate(calc(100% + var(--pTr,0px)),-50%);}

.p-1-var {--tx:var(--pTr,0px);--ty:var(--pTr,0px);}
.p-2-vars {--tx:var(--pTrX,0px);--ty:var(--pTrY,0px);}
.p-over-avg {--xTran: 50%} .p-over-in {--xTran: calc(-1 * var(--pTrX,0px));} .p-over-in-0 {--xTran: 0px;}
.p-over-corner-1 {top: 0; left: 0; transform: translate(calc(-1 * var(--xTran)),calc(-100% - var(--ty)));}
.p-over-corner-2 {top: 0; right: 0; transform: translate(var(--xTran),calc(-100% - var(--ty)));}
.p-over-corner-3 {bottom: 0; right: 0; transform: translate(var(--xTran),calc(100% + var(--ty)));}
.p-over-corner-4 {bottom: 0; left: 0; transform: translate(calc(-1 * var(--xTran)),calc(100% + var(--ty)));}

.p-in-corner-1 {top: 0; left: 0; transform: translate(var(--tx),var(--ty));}
.p-in-corner-2 {top: 0; right: 0; transform: translate(calc(var(--tx) * -1),var(--ty));}
.p-in-corner-3 {bottom: 0; right: 0; transform: translate(calc(var(--tx) * -1),calc(var(--ty) * -1));}
.p-in-corner-4 {bottom: 0; left: 0; transform: translate(var(--tx),calc(var(--ty) * -1));}

.p-in-top {top: 0; left: 50%; transform: translate(-50%,calc(var(--pTr,0px)));}
.p-in-bottom {bottom: 0; left: 50%; transform: translate(-50%,calc(-1 * var(--pTr,0px)));}
.p-in-left {top: 50%; left: 0; transform: translate(var(--pTr,0px),-50%);}
.p-in-right {top: 50%; right: 0; transform: translate(calc(-1 * var(--pTr,0px)),-50%);}
.p-in-center {top: 50%; left: 50%; transform: translate(-50%,-50%);}

.p-btw-top {top: 0; left: 50%; transform: translate(-50%,-50%);}
.p-btw-left {top: 50%; left: 0; transform: translate(-50%,-50%);}
.p-btw-right {top: 50%; right: 0; transform: translate(50%,-50%);}
.p-btw-bottom {bottom: 0; left: 50%;transform: translate(-50%,50%);}

.p-btw-corner-1 {top: 0; left: 0; transform: translate(-50%,-50%);}
.p-btw-corner-2 {top: 0; right: 0; transform: translate(50%,-50%);}
.p-btw-corner-3 {bottom: 0; right: 0; transform: translate(50%,50%);}
.p-btw-corner-4 {bottom: 0; left: 0; transform: translate(-50%,50%);}



/*

.p-abs-topRight {position: absolute;top: 0; right: 0; }
.p-abs-topRight-t20 {position: absolute;top: 0; right: 0; transform: translate(20%,-20%);}
.p-abs-topRight-t30 {position: absolute;top: 0; right: 0; transform: translate(30%,-30%);}
.p-abs-topRight-t40 {position: absolute;top: 0; right: 0; transform: translate(40%,-40%);}
.p-abs-topRight-t50 {position: absolute;top: 0; right: 0; transform: translate(50%,-50%);}
.p-abs-topRight-t60 {position: absolute;top: 0; right: 0; transform: translate(60%,-60%);}
.p-abs-topRight-t70 {position: absolute;top: 0; right: 0; transform: translate(70%,-70%);}


*/
