.a-0 {--a: 0.0}.a-01 {--a: 0.1}.a-02 {--a: 0.2}.a-03 {--a: 0.3}.a-04 {--a: 0.4}.a-05 {--a: 0.5}.a-06 {--a: 0.6}.a-07 {--a: 0.7}.a-08 {--a: 0.8}.a-09 {--a: 0.9}.a-1 {--a: 1}
.a1-0 {--a1: 0.0}.a1-01 {--a1: 0.1}.a1-02 {--a1: 0.2}.a1-03 {--a1: 0.3}.a1-04 {--a1: 0.4}.a1-05 {--a1: 0.5}.a1-06 {--a1: 0.6}.a1-07 {--a1: 0.7}.a1-08 {--a1: 0.8}.a1-09 {--a1: 0.9}.a1-1 {--a1: 1}

.a-h-0:hover:not(.no-hover) {--a: 0.0}.a-h-01:hover:not(.no-hover) {--a: 0.1}.a-h-02:hover:not(.no-hover) {--a: 0.2}.a-h-03:hover:not(.no-hover) {--a: 0.3}
.a-h-04:hover:not(.no-hover) {--a: 0.4}.a-h-05:hover:not(.no-hover) {--a: 0.5}.a-h-06:hover:not(.no-hover) {--a: 0.6}.a-h-07:hover:not(.no-hover) {--a: 0.7}
.a-h-08:hover:not(.no-hover) {--a: 0.8}.a-h-09:hover:not(.no-hover) {--a: 0.9}.a-h-1:hover:not(.no-hover) {--a: 1}

.a-font-alice-blue {color: #f0f8ff}
.a-bkg-alice-blue {background-color: #f0f8ff}
.a-hex-alice-blue {--hex: #f0f8ff}
.a-hex1-alice-blue {--hex1: #f0f8ff}
.a-hex2-alice-blue {--hex2: #f0f8ff}
.a-hex3-alice-blue {--hex3: #f0f8ff}
.a-hsl-alice-blue {--h:208;--s:100%;--l:97%;}
.a-hsl1-alice-blue {--h1:208;--s1:100%;--l1:97%;}
.a-hsl2-alice-blue {--h2:208;--s2:100%;--l2:97%;}
.a-rgb1-alice-blue {--r1:240;--g1:248;--b1:255}
.a-btn-alice-blue {--hex: white;--h:208;--s:100%;--l:97%;--btn-color:white}

.a-font-amber {color: #ffbf00}
.a-bkg-amber {background-color: #ffbf00}
.a-hex-amber {--hex: #ffbf00}
.a-hex1-amber {--hex1: #ffbf00}
.a-hex2-amber {--hex2: #ffbf00}
.a-hex3-amber {--hex3: #ffbf00}
.a-hsl-amber {--h:45;--s:100%;--l:50%;}
.a-hsl1-amber {--h1:45;--s1:100%;--l1:50%;}
.a-hsl2-amber {--h2:45;--s2:100%;--l2:50%;}
.a-rgb1-amber {--r1:255;--g1:191;--b1:0}
.a-btn-amber {--hex: white;--h:45;--s:100%;--l:50%;--btn-color:white}

.a-font-antique-white {color: #faebd7}
.a-bkg-antique-white {background-color: #faebd7}
.a-hex-antique-white {--hex: #faebd7}
.a-hex1-antique-white {--hex1: #faebd7}
.a-hex2-antique-white {--hex2: #faebd7}
.a-hex3-antique-white {--hex3: #faebd7}
.a-hsl-antique-white {--h:34;--s:78%;--l:91%;}
.a-hsl1-antique-white {--h1:34;--s1:78%;--l1:91%;}
.a-hsl2-antique-white {--h2:34;--s2:78%;--l2:91%;}
.a-rgb1-antique-white {--r1:250;--g1:235;--b1:215}
.a-btn-antique-white {--hex: white;--h:34;--s:78%;--l:91%;--btn-color:white}

.a-font-aqua {color: #00ffff}
.a-bkg-aqua {background-color: #00ffff}
.a-hex-aqua {--hex: #00ffff}
.a-hex1-aqua {--hex1: #00ffff}
.a-hex2-aqua {--hex2: #00ffff}
.a-hex3-aqua {--hex3: #00ffff}
.a-hsl-aqua {--h:180;--s:100%;--l:50%;}
.a-hsl1-aqua {--h1:180;--s1:100%;--l1:50%;}
.a-hsl2-aqua {--h2:180;--s2:100%;--l2:50%;}
.a-rgb1-aqua {--r1:0;--g1:255;--b1:255}
.a-btn-aqua {--hex: white;--h:180;--s:100%;--l:50%;--btn-color:white}

.a-font-aqua-a {color: #039e8f}
.a-bkg-aqua-a {background-color: #039e8f}
.a-hex-aqua-a {--hex: #039e8f}
.a-hex1-aqua-a {--hex1: #039e8f}
.a-hex2-aqua-a {--hex2: #039e8f}
.a-hex3-aqua-a {--hex3: #039e8f}
.a-hsl-aqua-a {--h:174;--s:96%;--l:32%;}
.a-hsl1-aqua-a {--h1:174;--s1:96%;--l1:32%;}
.a-hsl2-aqua-a {--h2:174;--s2:96%;--l2:32%;}
.a-rgb1-aqua-a {--r1:3;--g1:158;--b1:143}
.a-btn-aqua-a {--hex: white;--h:174;--s:96%;--l:32%;--btn-color:white}

.a-font-aquamarine {color: #7fffd4}
.a-bkg-aquamarine {background-color: #7fffd4}
.a-hex-aquamarine {--hex: #7fffd4}
.a-hex1-aquamarine {--hex1: #7fffd4}
.a-hex2-aquamarine {--hex2: #7fffd4}
.a-hex3-aquamarine {--hex3: #7fffd4}
.a-hsl-aquamarine {--h:160;--s:100%;--l:75%;}
.a-hsl1-aquamarine {--h1:160;--s1:100%;--l1:75%;}
.a-hsl2-aquamarine {--h2:160;--s2:100%;--l2:75%;}
.a-rgb1-aquamarine {--r1:127;--g1:255;--b1:212}
.a-btn-aquamarine {--hex: white;--h:160;--s:100%;--l:75%;--btn-color:white}

.a-font-azure {color: #f0ffff}
.a-bkg-azure {background-color: #f0ffff}
.a-hex-azure {--hex: #f0ffff}
.a-hex1-azure {--hex1: #f0ffff}
.a-hex2-azure {--hex2: #f0ffff}
.a-hex3-azure {--hex3: #f0ffff}
.a-hsl-azure {--h:180;--s:100%;--l:97%;}
.a-hsl1-azure {--h1:180;--s1:100%;--l1:97%;}
.a-hsl2-azure {--h2:180;--s2:100%;--l2:97%;}
.a-rgb1-azure {--r1:240;--g1:255;--b1:255}
.a-btn-azure {--hex: white;--h:180;--s:100%;--l:97%;--btn-color:white}

.a-font-azure-a {color: #027fff}
.a-bkg-azure-a {background-color: #027fff}
.a-hex-azure-a {--hex: #027fff}
.a-hex1-azure-a {--hex1: #027fff}
.a-hex2-azure-a {--hex2: #027fff}
.a-hex3-azure-a {--hex3: #027fff}
.a-hsl-azure-a {--h:210;--s:100%;--l:50%;}
.a-hsl1-azure-a {--h1:210;--s1:100%;--l1:50%;}
.a-hsl2-azure-a {--h2:210;--s2:100%;--l2:50%;}
.a-rgb1-azure-a {--r1:2;--g1:127;--b1:255}
.a-btn-azure-a {--hex: white;--h:210;--s:100%;--l:50%;--btn-color:white}

.a-font-beige {color: #f5f5dc}
.a-bkg-beige {background-color: #f5f5dc}
.a-hex-beige {--hex: #f5f5dc}
.a-hex1-beige {--hex1: #f5f5dc}
.a-hex2-beige {--hex2: #f5f5dc}
.a-hex3-beige {--hex3: #f5f5dc}
.a-hsl-beige {--h:60;--s:56%;--l:91%;}
.a-hsl1-beige {--h1:60;--s1:56%;--l1:91%;}
.a-hsl2-beige {--h2:60;--s2:56%;--l2:91%;}
.a-rgb1-beige {--r1:245;--g1:245;--b1:220}
.a-btn-beige {--hex: white;--h:60;--s:56%;--l:91%;--btn-color:white}

.a-font-bisque {color: #ffe4c4}
.a-bkg-bisque {background-color: #ffe4c4}
.a-hex-bisque {--hex: #ffe4c4}
.a-hex1-bisque {--hex1: #ffe4c4}
.a-hex2-bisque {--hex2: #ffe4c4}
.a-hex3-bisque {--hex3: #ffe4c4}
.a-hsl-bisque {--h:33;--s:100%;--l:88%;}
.a-hsl1-bisque {--h1:33;--s1:100%;--l1:88%;}
.a-hsl2-bisque {--h2:33;--s2:100%;--l2:88%;}
.a-rgb1-bisque {--r1:255;--g1:228;--b1:196}
.a-btn-bisque {--hex: white;--h:33;--s:100%;--l:88%;--btn-color:white}

.a-font-black {color: #000000}
.a-bkg-black {background-color: #000000}
.a-hex-black {--hex: #000000}
.a-hex1-black {--hex1: #000000}
.a-hex2-black {--hex2: #000000}
.a-hex3-black {--hex3: #000000}
.a-hsl-black {--h:0;--s:0%;--l:0%;}
.a-hsl1-black {--h1:0;--s1:0%;--l1:0%;}
.a-hsl2-black {--h2:0;--s2:0%;--l2:0%;}
.a-rgb1-black {--r1:0;--g1:0;--b1:0}
.a-btn-black {--hex: white;--h:0;--s:0%;--l:0%;--btn-color:white}

.a-font-blanched-almond {color: #ffebcd}
.a-bkg-blanched-almond {background-color: #ffebcd}
.a-hex-blanched-almond {--hex: #ffebcd}
.a-hex1-blanched-almond {--hex1: #ffebcd}
.a-hex2-blanched-almond {--hex2: #ffebcd}
.a-hex3-blanched-almond {--hex3: #ffebcd}
.a-hsl-blanched-almond {--h:36;--s:100%;--l:90%;}
.a-hsl1-blanched-almond {--h1:36;--s1:100%;--l1:90%;}
.a-hsl2-blanched-almond {--h2:36;--s2:100%;--l2:90%;}
.a-rgb1-blanched-almond {--r1:255;--g1:235;--b1:205}
.a-btn-blanched-almond {--hex: white;--h:36;--s:100%;--l:90%;--btn-color:white}

.a-font-blue {color: #0000ff}
.a-bkg-blue {background-color: #0000ff}
.a-hex-blue {--hex: #0000ff}
.a-hex1-blue {--hex1: #0000ff}
.a-hex2-blue {--hex2: #0000ff}
.a-hex3-blue {--hex3: #0000ff}
.a-hsl-blue {--h:240;--s:100%;--l:50%;}
.a-hsl1-blue {--h1:240;--s1:100%;--l1:50%;}
.a-hsl2-blue {--h2:240;--s2:100%;--l2:50%;}
.a-rgb1-blue {--r1:0;--g1:0;--b1:255}
.a-btn-blue {--hex: white;--h:240;--s:100%;--l:50%;--btn-color:white}

.a-font-blue-1 {color: #2770c4}
.a-bkg-blue-1 {background-color: #2770c4}
.a-hex-blue-1 {--hex: #2770c4}
.a-hex1-blue-1 {--hex1: #2770c4}
.a-hex2-blue-1 {--hex2: #2770c4}
.a-hex3-blue-1 {--hex3: #2770c4}
.a-hsl-blue-1 {--h:212;--s:67%;--l:46%;}
.a-hsl1-blue-1 {--h1:212;--s1:67%;--l1:46%;}
.a-hsl2-blue-1 {--h2:212;--s2:67%;--l2:46%;}
.a-rgb1-blue-1 {--r1:39;--g1:112;--b1:196}
.a-btn-blue-1 {--hex: white;--h:212;--s:67%;--l:46%;--btn-color:white}

.a-font-blue-2 {color: #007dd6}
.a-bkg-blue-2 {background-color: #007dd6}
.a-hex-blue-2 {--hex: #007dd6}
.a-hex1-blue-2 {--hex1: #007dd6}
.a-hex2-blue-2 {--hex2: #007dd6}
.a-hex3-blue-2 {--hex3: #007dd6}
.a-hsl-blue-2 {--h:205;--s:100%;--l:42%;}
.a-hsl1-blue-2 {--h1:205;--s1:100%;--l1:42%;}
.a-hsl2-blue-2 {--h2:205;--s2:100%;--l2:42%;}
.a-rgb1-blue-2 {--r1:0;--g1:125;--b1:214}
.a-btn-blue-2 {--hex: white;--h:205;--s:100%;--l:42%;--btn-color:white}

.a-font-blue-twitter {color: #1e9cf1}
.a-bkg-blue-twitter {background-color: #1e9cf1}
.a-hex-blue-twitter {--hex: #1e9cf1}
.a-hex1-blue-twitter {--hex1: #1e9cf1}
.a-hex2-blue-twitter {--hex2: #1e9cf1}
.a-hex3-blue-twitter {--hex3: #1e9cf1}
.a-hsl-blue-twitter {--h:204;--s:88%;--l:53%;}
.a-hsl1-blue-twitter {--h1:204;--s1:88%;--l1:53%;}
.a-hsl2-blue-twitter {--h2:204;--s2:88%;--l2:53%;}
.a-rgb1-blue-twitter {--r1:30;--g1:156;--b1:241}
.a-btn-blue-twitter {--hex: white;--h:204;--s:88%;--l:53%;--btn-color:white}

.a-font-blue-violet {color: #8a2be2}
.a-bkg-blue-violet {background-color: #8a2be2}
.a-hex-blue-violet {--hex: #8a2be2}
.a-hex1-blue-violet {--hex1: #8a2be2}
.a-hex2-blue-violet {--hex2: #8a2be2}
.a-hex3-blue-violet {--hex3: #8a2be2}
.a-hsl-blue-violet {--h:271;--s:76%;--l:53%;}
.a-hsl1-blue-violet {--h1:271;--s1:76%;--l1:53%;}
.a-hsl2-blue-violet {--h2:271;--s2:76%;--l2:53%;}
.a-rgb1-blue-violet {--r1:138;--g1:43;--b1:226}
.a-btn-blue-violet {--hex: white;--h:271;--s:76%;--l:53%;--btn-color:white}

.a-font-brown {color: #a52a2a}
.a-bkg-brown {background-color: #a52a2a}
.a-hex-brown {--hex: #a52a2a}
.a-hex1-brown {--hex1: #a52a2a}
.a-hex2-brown {--hex2: #a52a2a}
.a-hex3-brown {--hex3: #a52a2a}
.a-hsl-brown {--h:0;--s:59%;--l:41%;}
.a-hsl1-brown {--h1:0;--s1:59%;--l1:41%;}
.a-hsl2-brown {--h2:0;--s2:59%;--l2:41%;}
.a-rgb1-brown {--r1:165;--g1:42;--b1:42}
.a-btn-brown {--hex: white;--h:0;--s:59%;--l:41%;--btn-color:white}

.a-font-bumblebee {color: #fce205}
.a-bkg-bumblebee {background-color: #fce205}
.a-hex-bumblebee {--hex: #fce205}
.a-hex1-bumblebee {--hex1: #fce205}
.a-hex2-bumblebee {--hex2: #fce205}
.a-hex3-bumblebee {--hex3: #fce205}
.a-hsl-bumblebee {--h:54;--s:98%;--l:50%;}
.a-hsl1-bumblebee {--h1:54;--s1:98%;--l1:50%;}
.a-hsl2-bumblebee {--h2:54;--s2:98%;--l2:50%;}
.a-rgb1-bumblebee {--r1:252;--g1:226;--b1:5}
.a-btn-bumblebee {--hex: white;--h:54;--s:98%;--l:50%;--btn-color:white}

.a-font-burly-wood {color: #deb887}
.a-bkg-burly-wood {background-color: #deb887}
.a-hex-burly-wood {--hex: #deb887}
.a-hex1-burly-wood {--hex1: #deb887}
.a-hex2-burly-wood {--hex2: #deb887}
.a-hex3-burly-wood {--hex3: #deb887}
.a-hsl-burly-wood {--h:34;--s:57%;--l:70%;}
.a-hsl1-burly-wood {--h1:34;--s1:57%;--l1:70%;}
.a-hsl2-burly-wood {--h2:34;--s2:57%;--l2:70%;}
.a-rgb1-burly-wood {--r1:222;--g1:184;--b1:135}
.a-btn-burly-wood {--hex: white;--h:34;--s:57%;--l:70%;--btn-color:white}

.a-font-cadetblue {color: #5f9ea0}
.a-bkg-cadetblue {background-color: #5f9ea0}
.a-hex-cadetblue {--hex: #5f9ea0}
.a-hex1-cadetblue {--hex1: #5f9ea0}
.a-hex2-cadetblue {--hex2: #5f9ea0}
.a-hex3-cadetblue {--hex3: #5f9ea0}
.a-hsl-cadetblue {--h:182;--s:25%;--l:50%;}
.a-hsl1-cadetblue {--h1:182;--s1:25%;--l1:50%;}
.a-hsl2-cadetblue {--h2:182;--s2:25%;--l2:50%;}
.a-rgb1-cadetblue {--r1:95;--g1:158;--b1:160}
.a-btn-cadetblue {--hex: white;--h:182;--s:25%;--l:50%;--btn-color:white}

.a-font-chartreuse {color: #7fff00}
.a-bkg-chartreuse {background-color: #7fff00}
.a-hex-chartreuse {--hex: #7fff00}
.a-hex1-chartreuse {--hex1: #7fff00}
.a-hex2-chartreuse {--hex2: #7fff00}
.a-hex3-chartreuse {--hex3: #7fff00}
.a-hsl-chartreuse {--h:90;--s:100%;--l:50%;}
.a-hsl1-chartreuse {--h1:90;--s1:100%;--l1:50%;}
.a-hsl2-chartreuse {--h2:90;--s2:100%;--l2:50%;}
.a-rgb1-chartreuse {--r1:127;--g1:255;--b1:0}
.a-btn-chartreuse {--hex: white;--h:90;--s:100%;--l:50%;--btn-color:white}

.a-font-chocolate {color: #d2691e}
.a-bkg-chocolate {background-color: #d2691e}
.a-hex-chocolate {--hex: #d2691e}
.a-hex1-chocolate {--hex1: #d2691e}
.a-hex2-chocolate {--hex2: #d2691e}
.a-hex3-chocolate {--hex3: #d2691e}
.a-hsl-chocolate {--h:25;--s:75%;--l:47%;}
.a-hsl1-chocolate {--h1:25;--s1:75%;--l1:47%;}
.a-hsl2-chocolate {--h2:25;--s2:75%;--l2:47%;}
.a-rgb1-chocolate {--r1:210;--g1:105;--b1:30}
.a-btn-chocolate {--hex: white;--h:25;--s:75%;--l:47%;--btn-color:white}

.a-font-coral {color: #ff7f50}
.a-bkg-coral {background-color: #ff7f50}
.a-hex-coral {--hex: #ff7f50}
.a-hex1-coral {--hex1: #ff7f50}
.a-hex2-coral {--hex2: #ff7f50}
.a-hex3-coral {--hex3: #ff7f50}
.a-hsl-coral {--h:16;--s:100%;--l:66%;}
.a-hsl1-coral {--h1:16;--s1:100%;--l1:66%;}
.a-hsl2-coral {--h2:16;--s2:100%;--l2:66%;}
.a-rgb1-coral {--r1:255;--g1:127;--b1:80}
.a-btn-coral {--hex: white;--h:16;--s:100%;--l:66%;--btn-color:white}

.a-font-cornflower-blue {color: #6495ed}
.a-bkg-cornflower-blue {background-color: #6495ed}
.a-hex-cornflower-blue {--hex: #6495ed}
.a-hex1-cornflower-blue {--hex1: #6495ed}
.a-hex2-cornflower-blue {--hex2: #6495ed}
.a-hex3-cornflower-blue {--hex3: #6495ed}
.a-hsl-cornflower-blue {--h:219;--s:79%;--l:66%;}
.a-hsl1-cornflower-blue {--h1:219;--s1:79%;--l1:66%;}
.a-hsl2-cornflower-blue {--h2:219;--s2:79%;--l2:66%;}
.a-rgb1-cornflower-blue {--r1:100;--g1:149;--b1:237}
.a-btn-cornflower-blue {--hex: white;--h:219;--s:79%;--l:66%;--btn-color:white}

.a-font-cornsilk {color: #fff8dc}
.a-bkg-cornsilk {background-color: #fff8dc}
.a-hex-cornsilk {--hex: #fff8dc}
.a-hex1-cornsilk {--hex1: #fff8dc}
.a-hex2-cornsilk {--hex2: #fff8dc}
.a-hex3-cornsilk {--hex3: #fff8dc}
.a-hsl-cornsilk {--h:48;--s:100%;--l:93%;}
.a-hsl1-cornsilk {--h1:48;--s1:100%;--l1:93%;}
.a-hsl2-cornsilk {--h2:48;--s2:100%;--l2:93%;}
.a-rgb1-cornsilk {--r1:255;--g1:248;--b1:220}
.a-btn-cornsilk {--hex: white;--h:48;--s:100%;--l:93%;--btn-color:white}

.a-font-crimson {color: #dc143c}
.a-bkg-crimson {background-color: #dc143c}
.a-hex-crimson {--hex: #dc143c}
.a-hex1-crimson {--hex1: #dc143c}
.a-hex2-crimson {--hex2: #dc143c}
.a-hex3-crimson {--hex3: #dc143c}
.a-hsl-crimson {--h:348;--s:83%;--l:47%;}
.a-hsl1-crimson {--h1:348;--s1:83%;--l1:47%;}
.a-hsl2-crimson {--h2:348;--s2:83%;--l2:47%;}
.a-rgb1-crimson {--r1:220;--g1:20;--b1:60}
.a-btn-crimson {--hex: white;--h:348;--s:83%;--l:47%;--btn-color:white}

.a-font-cyan {color: #00ffff}
.a-bkg-cyan {background-color: #00ffff}
.a-hex-cyan {--hex: #00ffff}
.a-hex1-cyan {--hex1: #00ffff}
.a-hex2-cyan {--hex2: #00ffff}
.a-hex3-cyan {--hex3: #00ffff}
.a-hsl-cyan {--h:180;--s:100%;--l:50%;}
.a-hsl1-cyan {--h1:180;--s1:100%;--l1:50%;}
.a-hsl2-cyan {--h2:180;--s2:100%;--l2:50%;}
.a-rgb1-cyan {--r1:0;--g1:255;--b1:255}
.a-btn-cyan {--hex: white;--h:180;--s:100%;--l:50%;--btn-color:white}

.a-font-cyber {color: #ffd300}
.a-bkg-cyber {background-color: #ffd300}
.a-hex-cyber {--hex: #ffd300}
.a-hex1-cyber {--hex1: #ffd300}
.a-hex2-cyber {--hex2: #ffd300}
.a-hex3-cyber {--hex3: #ffd300}
.a-hsl-cyber {--h:50;--s:100%;--l:50%;}
.a-hsl1-cyber {--h1:50;--s1:100%;--l1:50%;}
.a-hsl2-cyber {--h2:50;--s2:100%;--l2:50%;}
.a-rgb1-cyber {--r1:255;--g1:211;--b1:0}
.a-btn-cyber {--hex: white;--h:50;--s:100%;--l:50%;--btn-color:white}

.a-font-dark-blue {color: #00008b}
.a-bkg-dark-blue {background-color: #00008b}
.a-hex-dark-blue {--hex: #00008b}
.a-hex1-dark-blue {--hex1: #00008b}
.a-hex2-dark-blue {--hex2: #00008b}
.a-hex3-dark-blue {--hex3: #00008b}
.a-hsl-dark-blue {--h:240;--s:100%;--l:27%;}
.a-hsl1-dark-blue {--h1:240;--s1:100%;--l1:27%;}
.a-hsl2-dark-blue {--h2:240;--s2:100%;--l2:27%;}
.a-rgb1-dark-blue {--r1:0;--g1:0;--b1:139}
.a-btn-dark-blue {--hex: white;--h:240;--s:100%;--l:27%;--btn-color:white}

.a-font-dark-cyan {color: #008b8b}
.a-bkg-dark-cyan {background-color: #008b8b}
.a-hex-dark-cyan {--hex: #008b8b}
.a-hex1-dark-cyan {--hex1: #008b8b}
.a-hex2-dark-cyan {--hex2: #008b8b}
.a-hex3-dark-cyan {--hex3: #008b8b}
.a-hsl-dark-cyan {--h:180;--s:100%;--l:27%;}
.a-hsl1-dark-cyan {--h1:180;--s1:100%;--l1:27%;}
.a-hsl2-dark-cyan {--h2:180;--s2:100%;--l2:27%;}
.a-rgb1-dark-cyan {--r1:0;--g1:139;--b1:139}
.a-btn-dark-cyan {--hex: white;--h:180;--s:100%;--l:27%;--btn-color:white}

.a-font-dark-golden-rod {color: #b8860b}
.a-bkg-dark-golden-rod {background-color: #b8860b}
.a-hex-dark-golden-rod {--hex: #b8860b}
.a-hex1-dark-golden-rod {--hex1: #b8860b}
.a-hex2-dark-golden-rod {--hex2: #b8860b}
.a-hex3-dark-golden-rod {--hex3: #b8860b}
.a-hsl-dark-golden-rod {--h:43;--s:89%;--l:38%;}
.a-hsl1-dark-golden-rod {--h1:43;--s1:89%;--l1:38%;}
.a-hsl2-dark-golden-rod {--h2:43;--s2:89%;--l2:38%;}
.a-rgb1-dark-golden-rod {--r1:184;--g1:134;--b1:11}
.a-btn-dark-golden-rod {--hex: white;--h:43;--s:89%;--l:38%;--btn-color:white}

.a-font-dark-gray {color: #a9a9a9}
.a-bkg-dark-gray {background-color: #a9a9a9}
.a-hex-dark-gray {--hex: #a9a9a9}
.a-hex1-dark-gray {--hex1: #a9a9a9}
.a-hex2-dark-gray {--hex2: #a9a9a9}
.a-hex3-dark-gray {--hex3: #a9a9a9}
.a-hsl-dark-gray {--h:0;--s:0%;--l:66%;}
.a-hsl1-dark-gray {--h1:0;--s1:0%;--l1:66%;}
.a-hsl2-dark-gray {--h2:0;--s2:0%;--l2:66%;}
.a-rgb1-dark-gray {--r1:169;--g1:169;--b1:169}
.a-btn-dark-gray {--hex: white;--h:0;--s:0%;--l:66%;--btn-color:white}

.a-font-dark-green {color: #006400}
.a-bkg-dark-green {background-color: #006400}
.a-hex-dark-green {--hex: #006400}
.a-hex1-dark-green {--hex1: #006400}
.a-hex2-dark-green {--hex2: #006400}
.a-hex3-dark-green {--hex3: #006400}
.a-hsl-dark-green {--h:120;--s:100%;--l:20%;}
.a-hsl1-dark-green {--h1:120;--s1:100%;--l1:20%;}
.a-hsl2-dark-green {--h2:120;--s2:100%;--l2:20%;}
.a-rgb1-dark-green {--r1:0;--g1:100;--b1:0}
.a-btn-dark-green {--hex: white;--h:120;--s:100%;--l:20%;--btn-color:white}

.a-font-dark-grey {color: #a9a9a9}
.a-bkg-dark-grey {background-color: #a9a9a9}
.a-hex-dark-grey {--hex: #a9a9a9}
.a-hex1-dark-grey {--hex1: #a9a9a9}
.a-hex2-dark-grey {--hex2: #a9a9a9}
.a-hex3-dark-grey {--hex3: #a9a9a9}
.a-hsl-dark-grey {--h:0;--s:0%;--l:66%;}
.a-hsl1-dark-grey {--h1:0;--s1:0%;--l1:66%;}
.a-hsl2-dark-grey {--h2:0;--s2:0%;--l2:66%;}
.a-rgb1-dark-grey {--r1:169;--g1:169;--b1:169}
.a-btn-dark-grey {--hex: white;--h:0;--s:0%;--l:66%;--btn-color:white}

.a-font-dark-khaki {color: #bdb76b}
.a-bkg-dark-khaki {background-color: #bdb76b}
.a-hex-dark-khaki {--hex: #bdb76b}
.a-hex1-dark-khaki {--hex1: #bdb76b}
.a-hex2-dark-khaki {--hex2: #bdb76b}
.a-hex3-dark-khaki {--hex3: #bdb76b}
.a-hsl-dark-khaki {--h:56;--s:38%;--l:58%;}
.a-hsl1-dark-khaki {--h1:56;--s1:38%;--l1:58%;}
.a-hsl2-dark-khaki {--h2:56;--s2:38%;--l2:58%;}
.a-rgb1-dark-khaki {--r1:189;--g1:183;--b1:107}
.a-btn-dark-khaki {--hex: white;--h:56;--s:38%;--l:58%;--btn-color:white}

.a-font-dark-magenta {color: #8b008b}
.a-bkg-dark-magenta {background-color: #8b008b}
.a-hex-dark-magenta {--hex: #8b008b}
.a-hex1-dark-magenta {--hex1: #8b008b}
.a-hex2-dark-magenta {--hex2: #8b008b}
.a-hex3-dark-magenta {--hex3: #8b008b}
.a-hsl-dark-magenta {--h:300;--s:100%;--l:27%;}
.a-hsl1-dark-magenta {--h1:300;--s1:100%;--l1:27%;}
.a-hsl2-dark-magenta {--h2:300;--s2:100%;--l2:27%;}
.a-rgb1-dark-magenta {--r1:139;--g1:0;--b1:139}
.a-btn-dark-magenta {--hex: white;--h:300;--s:100%;--l:27%;--btn-color:white}

.a-font-dark-olive-green {color: #556b2f}
.a-bkg-dark-olive-green {background-color: #556b2f}
.a-hex-dark-olive-green {--hex: #556b2f}
.a-hex1-dark-olive-green {--hex1: #556b2f}
.a-hex2-dark-olive-green {--hex2: #556b2f}
.a-hex3-dark-olive-green {--hex3: #556b2f}
.a-hsl-dark-olive-green {--h:82;--s:39%;--l:30%;}
.a-hsl1-dark-olive-green {--h1:82;--s1:39%;--l1:30%;}
.a-hsl2-dark-olive-green {--h2:82;--s2:39%;--l2:30%;}
.a-rgb1-dark-olive-green {--r1:85;--g1:107;--b1:47}
.a-btn-dark-olive-green {--hex: white;--h:82;--s:39%;--l:30%;--btn-color:white}

.a-font-dark-orange {color: #ff8c00}
.a-bkg-dark-orange {background-color: #ff8c00}
.a-hex-dark-orange {--hex: #ff8c00}
.a-hex1-dark-orange {--hex1: #ff8c00}
.a-hex2-dark-orange {--hex2: #ff8c00}
.a-hex3-dark-orange {--hex3: #ff8c00}
.a-hsl-dark-orange {--h:33;--s:100%;--l:50%;}
.a-hsl1-dark-orange {--h1:33;--s1:100%;--l1:50%;}
.a-hsl2-dark-orange {--h2:33;--s2:100%;--l2:50%;}
.a-rgb1-dark-orange {--r1:255;--g1:140;--b1:0}
.a-btn-dark-orange {--hex: white;--h:33;--s:100%;--l:50%;--btn-color:white}

.a-font-dark-orchid {color: #9932cc}
.a-bkg-dark-orchid {background-color: #9932cc}
.a-hex-dark-orchid {--hex: #9932cc}
.a-hex1-dark-orchid {--hex1: #9932cc}
.a-hex2-dark-orchid {--hex2: #9932cc}
.a-hex3-dark-orchid {--hex3: #9932cc}
.a-hsl-dark-orchid {--h:280;--s:61%;--l:50%;}
.a-hsl1-dark-orchid {--h1:280;--s1:61%;--l1:50%;}
.a-hsl2-dark-orchid {--h2:280;--s2:61%;--l2:50%;}
.a-rgb1-dark-orchid {--r1:153;--g1:50;--b1:204}
.a-btn-dark-orchid {--hex: white;--h:280;--s:61%;--l:50%;--btn-color:white}

.a-font-dark-red {color: #8b0000}
.a-bkg-dark-red {background-color: #8b0000}
.a-hex-dark-red {--hex: #8b0000}
.a-hex1-dark-red {--hex1: #8b0000}
.a-hex2-dark-red {--hex2: #8b0000}
.a-hex3-dark-red {--hex3: #8b0000}
.a-hsl-dark-red {--h:0;--s:100%;--l:27%;}
.a-hsl1-dark-red {--h1:0;--s1:100%;--l1:27%;}
.a-hsl2-dark-red {--h2:0;--s2:100%;--l2:27%;}
.a-rgb1-dark-red {--r1:139;--g1:0;--b1:0}
.a-btn-dark-red {--hex: white;--h:0;--s:100%;--l:27%;--btn-color:white}

.a-font-dark-salmon {color: #e9967a}
.a-bkg-dark-salmon {background-color: #e9967a}
.a-hex-dark-salmon {--hex: #e9967a}
.a-hex1-dark-salmon {--hex1: #e9967a}
.a-hex2-dark-salmon {--hex2: #e9967a}
.a-hex3-dark-salmon {--hex3: #e9967a}
.a-hsl-dark-salmon {--h:15;--s:72%;--l:70%;}
.a-hsl1-dark-salmon {--h1:15;--s1:72%;--l1:70%;}
.a-hsl2-dark-salmon {--h2:15;--s2:72%;--l2:70%;}
.a-rgb1-dark-salmon {--r1:233;--g1:150;--b1:122}
.a-btn-dark-salmon {--hex: white;--h:15;--s:72%;--l:70%;--btn-color:white}

.a-font-dark-sea-green {color: #8fbc8f}
.a-bkg-dark-sea-green {background-color: #8fbc8f}
.a-hex-dark-sea-green {--hex: #8fbc8f}
.a-hex1-dark-sea-green {--hex1: #8fbc8f}
.a-hex2-dark-sea-green {--hex2: #8fbc8f}
.a-hex3-dark-sea-green {--hex3: #8fbc8f}
.a-hsl-dark-sea-green {--h:120;--s:25%;--l:65%;}
.a-hsl1-dark-sea-green {--h1:120;--s1:25%;--l1:65%;}
.a-hsl2-dark-sea-green {--h2:120;--s2:25%;--l2:65%;}
.a-rgb1-dark-sea-green {--r1:143;--g1:188;--b1:143}
.a-btn-dark-sea-green {--hex: white;--h:120;--s:25%;--l:65%;--btn-color:white}

.a-font-dark-slate-blue {color: #483d8b}
.a-bkg-dark-slate-blue {background-color: #483d8b}
.a-hex-dark-slate-blue {--hex: #483d8b}
.a-hex1-dark-slate-blue {--hex1: #483d8b}
.a-hex2-dark-slate-blue {--hex2: #483d8b}
.a-hex3-dark-slate-blue {--hex3: #483d8b}
.a-hsl-dark-slate-blue {--h:248;--s:39%;--l:39%;}
.a-hsl1-dark-slate-blue {--h1:248;--s1:39%;--l1:39%;}
.a-hsl2-dark-slate-blue {--h2:248;--s2:39%;--l2:39%;}
.a-rgb1-dark-slate-blue {--r1:72;--g1:61;--b1:139}
.a-btn-dark-slate-blue {--hex: white;--h:248;--s:39%;--l:39%;--btn-color:white}

.a-font-dark-slate-gray {color: #2f4f4f}
.a-bkg-dark-slate-gray {background-color: #2f4f4f}
.a-hex-dark-slate-gray {--hex: #2f4f4f}
.a-hex1-dark-slate-gray {--hex1: #2f4f4f}
.a-hex2-dark-slate-gray {--hex2: #2f4f4f}
.a-hex3-dark-slate-gray {--hex3: #2f4f4f}
.a-hsl-dark-slate-gray {--h:180;--s:25%;--l:25%;}
.a-hsl1-dark-slate-gray {--h1:180;--s1:25%;--l1:25%;}
.a-hsl2-dark-slate-gray {--h2:180;--s2:25%;--l2:25%;}
.a-rgb1-dark-slate-gray {--r1:47;--g1:79;--b1:79}
.a-btn-dark-slate-gray {--hex: white;--h:180;--s:25%;--l:25%;--btn-color:white}

.a-font-dark-slate-grey {color: #2f4f4f}
.a-bkg-dark-slate-grey {background-color: #2f4f4f}
.a-hex-dark-slate-grey {--hex: #2f4f4f}
.a-hex1-dark-slate-grey {--hex1: #2f4f4f}
.a-hex2-dark-slate-grey {--hex2: #2f4f4f}
.a-hex3-dark-slate-grey {--hex3: #2f4f4f}
.a-hsl-dark-slate-grey {--h:180;--s:25%;--l:25%;}
.a-hsl1-dark-slate-grey {--h1:180;--s1:25%;--l1:25%;}
.a-hsl2-dark-slate-grey {--h2:180;--s2:25%;--l2:25%;}
.a-rgb1-dark-slate-grey {--r1:47;--g1:79;--b1:79}
.a-btn-dark-slate-grey {--hex: white;--h:180;--s:25%;--l:25%;--btn-color:white}

.a-font-dark-turquoise {color: #00ced1}
.a-bkg-dark-turquoise {background-color: #00ced1}
.a-hex-dark-turquoise {--hex: #00ced1}
.a-hex1-dark-turquoise {--hex1: #00ced1}
.a-hex2-dark-turquoise {--hex2: #00ced1}
.a-hex3-dark-turquoise {--hex3: #00ced1}
.a-hsl-dark-turquoise {--h:181;--s:100%;--l:41%;}
.a-hsl1-dark-turquoise {--h1:181;--s1:100%;--l1:41%;}
.a-hsl2-dark-turquoise {--h2:181;--s2:100%;--l2:41%;}
.a-rgb1-dark-turquoise {--r1:0;--g1:206;--b1:209}
.a-btn-dark-turquoise {--hex: white;--h:181;--s:100%;--l:41%;--btn-color:white}

.a-font-dark-violet {color: #9400d3}
.a-bkg-dark-violet {background-color: #9400d3}
.a-hex-dark-violet {--hex: #9400d3}
.a-hex1-dark-violet {--hex1: #9400d3}
.a-hex2-dark-violet {--hex2: #9400d3}
.a-hex3-dark-violet {--hex3: #9400d3}
.a-hsl-dark-violet {--h:282;--s:100%;--l:41%;}
.a-hsl1-dark-violet {--h1:282;--s1:100%;--l1:41%;}
.a-hsl2-dark-violet {--h2:282;--s2:100%;--l2:41%;}
.a-rgb1-dark-violet {--r1:148;--g1:0;--b1:211}
.a-btn-dark-violet {--hex: white;--h:282;--s:100%;--l:41%;--btn-color:white}

.a-font-deep-pink {color: #ff1493}
.a-bkg-deep-pink {background-color: #ff1493}
.a-hex-deep-pink {--hex: #ff1493}
.a-hex1-deep-pink {--hex1: #ff1493}
.a-hex2-deep-pink {--hex2: #ff1493}
.a-hex3-deep-pink {--hex3: #ff1493}
.a-hsl-deep-pink {--h:328;--s:100%;--l:54%;}
.a-hsl1-deep-pink {--h1:328;--s1:100%;--l1:54%;}
.a-hsl2-deep-pink {--h2:328;--s2:100%;--l2:54%;}
.a-rgb1-deep-pink {--r1:255;--g1:20;--b1:147}
.a-btn-deep-pink {--hex: white;--h:328;--s:100%;--l:54%;--btn-color:white}

.a-font-deep-sky-blue {color: #00bfff}
.a-bkg-deep-sky-blue {background-color: #00bfff}
.a-hex-deep-sky-blue {--hex: #00bfff}
.a-hex1-deep-sky-blue {--hex1: #00bfff}
.a-hex2-deep-sky-blue {--hex2: #00bfff}
.a-hex3-deep-sky-blue {--hex3: #00bfff}
.a-hsl-deep-sky-blue {--h:195;--s:100%;--l:50%;}
.a-hsl1-deep-sky-blue {--h1:195;--s1:100%;--l1:50%;}
.a-hsl2-deep-sky-blue {--h2:195;--s2:100%;--l2:50%;}
.a-rgb1-deep-sky-blue {--r1:0;--g1:191;--b1:255}
.a-btn-deep-sky-blue {--hex: white;--h:195;--s:100%;--l:50%;--btn-color:white}

.a-font-dim-gray {color: #696969}
.a-bkg-dim-gray {background-color: #696969}
.a-hex-dim-gray {--hex: #696969}
.a-hex1-dim-gray {--hex1: #696969}
.a-hex2-dim-gray {--hex2: #696969}
.a-hex3-dim-gray {--hex3: #696969}
.a-hsl-dim-gray {--h:0;--s:0%;--l:41%;}
.a-hsl1-dim-gray {--h1:0;--s1:0%;--l1:41%;}
.a-hsl2-dim-gray {--h2:0;--s2:0%;--l2:41%;}
.a-rgb1-dim-gray {--r1:105;--g1:105;--b1:105}
.a-btn-dim-gray {--hex: white;--h:0;--s:0%;--l:41%;--btn-color:white}

.a-font-dim-grey {color: #696969}
.a-bkg-dim-grey {background-color: #696969}
.a-hex-dim-grey {--hex: #696969}
.a-hex1-dim-grey {--hex1: #696969}
.a-hex2-dim-grey {--hex2: #696969}
.a-hex3-dim-grey {--hex3: #696969}
.a-hsl-dim-grey {--h:0;--s:0%;--l:41%;}
.a-hsl1-dim-grey {--h1:0;--s1:0%;--l1:41%;}
.a-hsl2-dim-grey {--h2:0;--s2:0%;--l2:41%;}
.a-rgb1-dim-grey {--r1:105;--g1:105;--b1:105}
.a-btn-dim-grey {--hex: white;--h:0;--s:0%;--l:41%;--btn-color:white}

.a-font-dodger-blue {color: #1e90ff}
.a-bkg-dodger-blue {background-color: #1e90ff}
.a-hex-dodger-blue {--hex: #1e90ff}
.a-hex1-dodger-blue {--hex1: #1e90ff}
.a-hex2-dodger-blue {--hex2: #1e90ff}
.a-hex3-dodger-blue {--hex3: #1e90ff}
.a-hsl-dodger-blue {--h:210;--s:100%;--l:56%;}
.a-hsl1-dodger-blue {--h1:210;--s1:100%;--l1:56%;}
.a-hsl2-dodger-blue {--h2:210;--s2:100%;--l2:56%;}
.a-rgb1-dodger-blue {--r1:30;--g1:144;--b1:255}
.a-btn-dodger-blue {--hex: white;--h:210;--s:100%;--l:56%;--btn-color:white}

.a-font-facebook-blue {color: #4268b3}
.a-bkg-facebook-blue {background-color: #4268b3}
.a-hex-facebook-blue {--hex: #4268b3}
.a-hex1-facebook-blue {--hex1: #4268b3}
.a-hex2-facebook-blue {--hex2: #4268b3}
.a-hex3-facebook-blue {--hex3: #4268b3}
.a-hsl-facebook-blue {--h:220;--s:46%;--l:48%;}
.a-hsl1-facebook-blue {--h1:220;--s1:46%;--l1:48%;}
.a-hsl2-facebook-blue {--h2:220;--s2:46%;--l2:48%;}
.a-rgb1-facebook-blue {--r1:66;--g1:104;--b1:179}
.a-btn-facebook-blue {--hex: white;--h:220;--s:46%;--l:48%;--btn-color:white}

.a-font-fire-brick {color: #b22222}
.a-bkg-fire-brick {background-color: #b22222}
.a-hex-fire-brick {--hex: #b22222}
.a-hex1-fire-brick {--hex1: #b22222}
.a-hex2-fire-brick {--hex2: #b22222}
.a-hex3-fire-brick {--hex3: #b22222}
.a-hsl-fire-brick {--h:0;--s:68%;--l:42%;}
.a-hsl1-fire-brick {--h1:0;--s1:68%;--l1:42%;}
.a-hsl2-fire-brick {--h2:0;--s2:68%;--l2:42%;}
.a-rgb1-fire-brick {--r1:178;--g1:34;--b1:34}
.a-btn-fire-brick {--hex: white;--h:0;--s:68%;--l:42%;--btn-color:white}

.a-font-floral-white {color: #fffaf0}
.a-bkg-floral-white {background-color: #fffaf0}
.a-hex-floral-white {--hex: #fffaf0}
.a-hex1-floral-white {--hex1: #fffaf0}
.a-hex2-floral-white {--hex2: #fffaf0}
.a-hex3-floral-white {--hex3: #fffaf0}
.a-hsl-floral-white {--h:40;--s:100%;--l:97%;}
.a-hsl1-floral-white {--h1:40;--s1:100%;--l1:97%;}
.a-hsl2-floral-white {--h2:40;--s2:100%;--l2:97%;}
.a-rgb1-floral-white {--r1:255;--g1:250;--b1:240}
.a-btn-floral-white {--hex: white;--h:40;--s:100%;--l:97%;--btn-color:white}

.a-font-forest {color: #0c8346}
.a-bkg-forest {background-color: #0c8346}
.a-hex-forest {--hex: #0c8346}
.a-hex1-forest {--hex1: #0c8346}
.a-hex2-forest {--hex2: #0c8346}
.a-hex3-forest {--hex3: #0c8346}
.a-hsl-forest {--h:149;--s:83%;--l:28%;}
.a-hsl1-forest {--h1:149;--s1:83%;--l1:28%;}
.a-hsl2-forest {--h2:149;--s2:83%;--l2:28%;}
.a-rgb1-forest {--r1:12;--g1:131;--b1:70}
.a-btn-forest {--hex: white;--h:149;--s:83%;--l:28%;--btn-color:white}

.a-font-forest-green {color: #228b22}
.a-bkg-forest-green {background-color: #228b22}
.a-hex-forest-green {--hex: #228b22}
.a-hex1-forest-green {--hex1: #228b22}
.a-hex2-forest-green {--hex2: #228b22}
.a-hex3-forest-green {--hex3: #228b22}
.a-hsl-forest-green {--h:120;--s:61%;--l:34%;}
.a-hsl1-forest-green {--h1:120;--s1:61%;--l1:34%;}
.a-hsl2-forest-green {--h2:120;--s2:61%;--l2:34%;}
.a-rgb1-forest-green {--r1:34;--g1:139;--b1:34}
.a-btn-forest-green {--hex: white;--h:120;--s:61%;--l:34%;--btn-color:white}

.a-font-fuchsia {color: #ff00ff}
.a-bkg-fuchsia {background-color: #ff00ff}
.a-hex-fuchsia {--hex: #ff00ff}
.a-hex1-fuchsia {--hex1: #ff00ff}
.a-hex2-fuchsia {--hex2: #ff00ff}
.a-hex3-fuchsia {--hex3: #ff00ff}
.a-hsl-fuchsia {--h:300;--s:100%;--l:50%;}
.a-hsl1-fuchsia {--h1:300;--s1:100%;--l1:50%;}
.a-hsl2-fuchsia {--h2:300;--s2:100%;--l2:50%;}
.a-rgb1-fuchsia {--r1:255;--g1:0;--b1:255}
.a-btn-fuchsia {--hex: white;--h:300;--s:100%;--l:50%;--btn-color:white}

.a-font-gainsboro {color: #dcdcdc}
.a-bkg-gainsboro {background-color: #dcdcdc}
.a-hex-gainsboro {--hex: #dcdcdc}
.a-hex1-gainsboro {--hex1: #dcdcdc}
.a-hex2-gainsboro {--hex2: #dcdcdc}
.a-hex3-gainsboro {--hex3: #dcdcdc}
.a-hsl-gainsboro {--h:0;--s:0%;--l:86%;}
.a-hsl1-gainsboro {--h1:0;--s1:0%;--l1:86%;}
.a-hsl2-gainsboro {--h2:0;--s2:0%;--l2:86%;}
.a-rgb1-gainsboro {--r1:220;--g1:220;--b1:220}
.a-btn-gainsboro {--hex: white;--h:0;--s:0%;--l:86%;--btn-color:white}

.a-font-ghost-white {color: #f8f8ff}
.a-bkg-ghost-white {background-color: #f8f8ff}
.a-hex-ghost-white {--hex: #f8f8ff}
.a-hex1-ghost-white {--hex1: #f8f8ff}
.a-hex2-ghost-white {--hex2: #f8f8ff}
.a-hex3-ghost-white {--hex3: #f8f8ff}
.a-hsl-ghost-white {--h:240;--s:100%;--l:99%;}
.a-hsl1-ghost-white {--h1:240;--s1:100%;--l1:99%;}
.a-hsl2-ghost-white {--h2:240;--s2:100%;--l2:99%;}
.a-rgb1-ghost-white {--r1:248;--g1:248;--b1:255}
.a-btn-ghost-white {--hex: white;--h:240;--s:100%;--l:99%;--btn-color:white}

.a-font-gold {color: #ffd700}
.a-bkg-gold {background-color: #ffd700}
.a-hex-gold {--hex: #ffd700}
.a-hex1-gold {--hex1: #ffd700}
.a-hex2-gold {--hex2: #ffd700}
.a-hex3-gold {--hex3: #ffd700}
.a-hsl-gold {--h:51;--s:100%;--l:50%;}
.a-hsl1-gold {--h1:51;--s1:100%;--l1:50%;}
.a-hsl2-gold {--h2:51;--s2:100%;--l2:50%;}
.a-rgb1-gold {--r1:255;--g1:215;--b1:0}
.a-btn-gold {--hex: white;--h:51;--s:100%;--l:50%;--btn-color:white}

.a-font-golden-rod {color: #daa520}
.a-bkg-golden-rod {background-color: #daa520}
.a-hex-golden-rod {--hex: #daa520}
.a-hex1-golden-rod {--hex1: #daa520}
.a-hex2-golden-rod {--hex2: #daa520}
.a-hex3-golden-rod {--hex3: #daa520}
.a-hsl-golden-rod {--h:43;--s:74%;--l:49%;}
.a-hsl1-golden-rod {--h1:43;--s1:74%;--l1:49%;}
.a-hsl2-golden-rod {--h2:43;--s2:74%;--l2:49%;}
.a-rgb1-golden-rod {--r1:218;--g1:165;--b1:32}
.a-btn-golden-rod {--hex: white;--h:43;--s:74%;--l:49%;--btn-color:white}

.a-font-grape {color: #6529f8}
.a-bkg-grape {background-color: #6529f8}
.a-hex-grape {--hex: #6529f8}
.a-hex1-grape {--hex1: #6529f8}
.a-hex2-grape {--hex2: #6529f8}
.a-hex3-grape {--hex3: #6529f8}
.a-hsl-grape {--h:257;--s:94%;--l:57%;}
.a-hsl1-grape {--h1:257;--s1:94%;--l1:57%;}
.a-hsl2-grape {--h2:257;--s2:94%;--l2:57%;}
.a-rgb1-grape {--r1:101;--g1:41;--b1:248}
.a-btn-grape {--hex: white;--h:257;--s:94%;--l:57%;--btn-color:white}

.a-font-gray {color: #808080}
.a-bkg-gray {background-color: #808080}
.a-hex-gray {--hex: #808080}
.a-hex1-gray {--hex1: #808080}
.a-hex2-gray {--hex2: #808080}
.a-hex3-gray {--hex3: #808080}
.a-hsl-gray {--h:0;--s:0%;--l:50%;}
.a-hsl1-gray {--h1:0;--s1:0%;--l1:50%;}
.a-hsl2-gray {--h2:0;--s2:0%;--l2:50%;}
.a-rgb1-gray {--r1:128;--g1:128;--b1:128}
.a-btn-gray {--hex: white;--h:0;--s:0%;--l:50%;--btn-color:white}

.a-font-green {color: #008000}
.a-bkg-green {background-color: #008000}
.a-hex-green {--hex: #008000}
.a-hex1-green {--hex1: #008000}
.a-hex2-green {--hex2: #008000}
.a-hex3-green {--hex3: #008000}
.a-hsl-green {--h:120;--s:100%;--l:25%;}
.a-hsl1-green {--h1:120;--s1:100%;--l1:25%;}
.a-hsl2-green {--h2:120;--s2:100%;--l2:25%;}
.a-rgb1-green {--r1:0;--g1:128;--b1:0}
.a-btn-green {--hex: white;--h:120;--s:100%;--l:25%;--btn-color:white}

.a-font-green-1 {color: #71ad85}
.a-bkg-green-1 {background-color: #71ad85}
.a-hex-green-1 {--hex: #71ad85}
.a-hex1-green-1 {--hex1: #71ad85}
.a-hex2-green-1 {--hex2: #71ad85}
.a-hex3-green-1 {--hex3: #71ad85}
.a-hsl-green-1 {--h:140;--s:27%;--l:56%;}
.a-hsl1-green-1 {--h1:140;--s1:27%;--l1:56%;}
.a-hsl2-green-1 {--h2:140;--s2:27%;--l2:56%;}
.a-rgb1-green-1 {--r1:113;--g1:173;--b1:133}
.a-btn-green-1 {--hex: white;--h:140;--s:27%;--l:56%;--btn-color:white}

.a-font-green-a {color: #0c9d46}
.a-bkg-green-a {background-color: #0c9d46}
.a-hex-green-a {--hex: #0c9d46}
.a-hex1-green-a {--hex1: #0c9d46}
.a-hex2-green-a {--hex2: #0c9d46}
.a-hex3-green-a {--hex3: #0c9d46}
.a-hsl-green-a {--h:144;--s:86%;--l:33%;}
.a-hsl1-green-a {--h1:144;--s1:86%;--l1:33%;}
.a-hsl2-green-a {--h2:144;--s2:86%;--l2:33%;}
.a-rgb1-green-a {--r1:12;--g1:157;--b1:70}
.a-btn-green-a {--hex: white;--h:144;--s:86%;--l:33%;--btn-color:white}

.a-font-green-yellow {color: #adff2f}
.a-bkg-green-yellow {background-color: #adff2f}
.a-hex-green-yellow {--hex: #adff2f}
.a-hex1-green-yellow {--hex1: #adff2f}
.a-hex2-green-yellow {--hex2: #adff2f}
.a-hex3-green-yellow {--hex3: #adff2f}
.a-hsl-green-yellow {--h:84;--s:100%;--l:59%;}
.a-hsl1-green-yellow {--h1:84;--s1:100%;--l1:59%;}
.a-hsl2-green-yellow {--h2:84;--s2:100%;--l2:59%;}
.a-rgb1-green-yellow {--r1:173;--g1:255;--b1:47}
.a-btn-green-yellow {--hex: white;--h:84;--s:100%;--l:59%;--btn-color:white}

.a-font-grey {color: #808080}
.a-bkg-grey {background-color: #808080}
.a-hex-grey {--hex: #808080}
.a-hex1-grey {--hex1: #808080}
.a-hex2-grey {--hex2: #808080}
.a-hex3-grey {--hex3: #808080}
.a-hsl-grey {--h:0;--s:0%;--l:50%;}
.a-hsl1-grey {--h1:0;--s1:0%;--l1:50%;}
.a-hsl2-grey {--h2:0;--s2:0%;--l2:50%;}
.a-rgb1-grey {--r1:128;--g1:128;--b1:128}
.a-btn-grey {--hex: white;--h:0;--s:0%;--l:50%;--btn-color:white}

.a-font-honey {color: #ffc30b}
.a-bkg-honey {background-color: #ffc30b}
.a-hex-honey {--hex: #ffc30b}
.a-hex1-honey {--hex1: #ffc30b}
.a-hex2-honey {--hex2: #ffc30b}
.a-hex3-honey {--hex3: #ffc30b}
.a-hsl-honey {--h:45;--s:100%;--l:52%;}
.a-hsl1-honey {--h1:45;--s1:100%;--l1:52%;}
.a-hsl2-honey {--h2:45;--s2:100%;--l2:52%;}
.a-rgb1-honey {--r1:255;--g1:195;--b1:11}
.a-btn-honey {--hex: white;--h:45;--s:100%;--l:52%;--btn-color:white}

.a-font-honey-dew {color: #f0fff0}
.a-bkg-honey-dew {background-color: #f0fff0}
.a-hex-honey-dew {--hex: #f0fff0}
.a-hex1-honey-dew {--hex1: #f0fff0}
.a-hex2-honey-dew {--hex2: #f0fff0}
.a-hex3-honey-dew {--hex3: #f0fff0}
.a-hsl-honey-dew {--h:120;--s:100%;--l:97%;}
.a-hsl1-honey-dew {--h1:120;--s1:100%;--l1:97%;}
.a-hsl2-honey-dew {--h2:120;--s2:100%;--l2:97%;}
.a-rgb1-honey-dew {--r1:240;--g1:255;--b1:240}
.a-btn-honey-dew {--hex: white;--h:120;--s:100%;--l:97%;--btn-color:white}

.a-font-hot-pink {color: #ff69b4}
.a-bkg-hot-pink {background-color: #ff69b4}
.a-hex-hot-pink {--hex: #ff69b4}
.a-hex1-hot-pink {--hex1: #ff69b4}
.a-hex2-hot-pink {--hex2: #ff69b4}
.a-hex3-hot-pink {--hex3: #ff69b4}
.a-hsl-hot-pink {--h:330;--s:100%;--l:71%;}
.a-hsl1-hot-pink {--h1:330;--s1:100%;--l1:71%;}
.a-hsl2-hot-pink {--h2:330;--s2:100%;--l2:71%;}
.a-rgb1-hot-pink {--r1:255;--g1:105;--b1:180}
.a-btn-hot-pink {--hex: white;--h:330;--s:100%;--l:71%;--btn-color:white}

.a-font-indian-red {color: #cd5c5c}
.a-bkg-indian-red {background-color: #cd5c5c}
.a-hex-indian-red {--hex: #cd5c5c}
.a-hex1-indian-red {--hex1: #cd5c5c}
.a-hex2-indian-red {--hex2: #cd5c5c}
.a-hex3-indian-red {--hex3: #cd5c5c}
.a-hsl-indian-red {--h:0;--s:53%;--l:58%;}
.a-hsl1-indian-red {--h1:0;--s1:53%;--l1:58%;}
.a-hsl2-indian-red {--h2:0;--s2:53%;--l2:58%;}
.a-rgb1-indian-red {--r1:205;--g1:92;--b1:92}
.a-btn-indian-red {--hex: white;--h:0;--s:53%;--l:58%;--btn-color:white}

.a-font-indigo {color: #4b0082}
.a-bkg-indigo {background-color: #4b0082}
.a-hex-indigo {--hex: #4b0082}
.a-hex1-indigo {--hex1: #4b0082}
.a-hex2-indigo {--hex2: #4b0082}
.a-hex3-indigo {--hex3: #4b0082}
.a-hsl-indigo {--h:275;--s:100%;--l:25%;}
.a-hsl1-indigo {--h1:275;--s1:100%;--l1:25%;}
.a-hsl2-indigo {--h2:275;--s2:100%;--l2:25%;}
.a-rgb1-indigo {--r1:75;--g1:0;--b1:130}
.a-btn-indigo {--hex: white;--h:275;--s:100%;--l:25%;--btn-color:white}

.a-font-indigo-a {color: #622aff}
.a-bkg-indigo-a {background-color: #622aff}
.a-hex-indigo-a {--hex: #622aff}
.a-hex1-indigo-a {--hex1: #622aff}
.a-hex2-indigo-a {--hex2: #622aff}
.a-hex3-indigo-a {--hex3: #622aff}
.a-hsl-indigo-a {--h:256;--s:100%;--l:58%;}
.a-hsl1-indigo-a {--h1:256;--s1:100%;--l1:58%;}
.a-hsl2-indigo-a {--h2:256;--s2:100%;--l2:58%;}
.a-rgb1-indigo-a {--r1:98;--g1:42;--b1:255}
.a-btn-indigo-a {--hex: white;--h:256;--s:100%;--l:58%;--btn-color:white}

.a-font-ivory {color: #fffff0}
.a-bkg-ivory {background-color: #fffff0}
.a-hex-ivory {--hex: #fffff0}
.a-hex1-ivory {--hex1: #fffff0}
.a-hex2-ivory {--hex2: #fffff0}
.a-hex3-ivory {--hex3: #fffff0}
.a-hsl-ivory {--h:60;--s:100%;--l:97%;}
.a-hsl1-ivory {--h1:60;--s1:100%;--l1:97%;}
.a-hsl2-ivory {--h2:60;--s2:100%;--l2:97%;}
.a-rgb1-ivory {--r1:255;--g1:255;--b1:240}
.a-btn-ivory {--hex: white;--h:60;--s:100%;--l:97%;--btn-color:white}

.a-font-khaki {color: #f0e68c}
.a-bkg-khaki {background-color: #f0e68c}
.a-hex-khaki {--hex: #f0e68c}
.a-hex1-khaki {--hex1: #f0e68c}
.a-hex2-khaki {--hex2: #f0e68c}
.a-hex3-khaki {--hex3: #f0e68c}
.a-hsl-khaki {--h:54;--s:77%;--l:75%;}
.a-hsl1-khaki {--h1:54;--s1:77%;--l1:75%;}
.a-hsl2-khaki {--h2:54;--s2:77%;--l2:75%;}
.a-rgb1-khaki {--r1:240;--g1:230;--b1:140}
.a-btn-khaki {--hex: white;--h:54;--s:77%;--l:75%;--btn-color:white}

.a-font-lavender {color: #e6e6fa}
.a-bkg-lavender {background-color: #e6e6fa}
.a-hex-lavender {--hex: #e6e6fa}
.a-hex1-lavender {--hex1: #e6e6fa}
.a-hex2-lavender {--hex2: #e6e6fa}
.a-hex3-lavender {--hex3: #e6e6fa}
.a-hsl-lavender {--h:240;--s:67%;--l:94%;}
.a-hsl1-lavender {--h1:240;--s1:67%;--l1:94%;}
.a-hsl2-lavender {--h2:240;--s2:67%;--l2:94%;}
.a-rgb1-lavender {--r1:230;--g1:230;--b1:250}
.a-btn-lavender {--hex: white;--h:240;--s:67%;--l:94%;--btn-color:white}

.a-font-lavender-blush {color: #fff0f5}
.a-bkg-lavender-blush {background-color: #fff0f5}
.a-hex-lavender-blush {--hex: #fff0f5}
.a-hex1-lavender-blush {--hex1: #fff0f5}
.a-hex2-lavender-blush {--hex2: #fff0f5}
.a-hex3-lavender-blush {--hex3: #fff0f5}
.a-hsl-lavender-blush {--h:340;--s:100%;--l:97%;}
.a-hsl1-lavender-blush {--h1:340;--s1:100%;--l1:97%;}
.a-hsl2-lavender-blush {--h2:340;--s2:100%;--l2:97%;}
.a-rgb1-lavender-blush {--r1:255;--g1:240;--b1:245}
.a-btn-lavender-blush {--hex: white;--h:340;--s:100%;--l:97%;--btn-color:white}

.a-font-lawn-green {color: #7cfc00}
.a-bkg-lawn-green {background-color: #7cfc00}
.a-hex-lawn-green {--hex: #7cfc00}
.a-hex1-lawn-green {--hex1: #7cfc00}
.a-hex2-lawn-green {--hex2: #7cfc00}
.a-hex3-lawn-green {--hex3: #7cfc00}
.a-hsl-lawn-green {--h:90;--s:100%;--l:49%;}
.a-hsl1-lawn-green {--h1:90;--s1:100%;--l1:49%;}
.a-hsl2-lawn-green {--h2:90;--s2:100%;--l2:49%;}
.a-rgb1-lawn-green {--r1:124;--g1:252;--b1:0}
.a-btn-lawn-green {--hex: white;--h:90;--s:100%;--l:49%;--btn-color:white}

.a-font-lemon {color: #effd5f}
.a-bkg-lemon {background-color: #effd5f}
.a-hex-lemon {--hex: #effd5f}
.a-hex1-lemon {--hex1: #effd5f}
.a-hex2-lemon {--hex2: #effd5f}
.a-hex3-lemon {--hex3: #effd5f}
.a-hsl-lemon {--h:65;--s:98%;--l:68%;}
.a-hsl1-lemon {--h1:65;--s1:98%;--l1:68%;}
.a-hsl2-lemon {--h2:65;--s2:98%;--l2:68%;}
.a-rgb1-lemon {--r1:239;--g1:253;--b1:95}
.a-btn-lemon {--hex: white;--h:65;--s:98%;--l:68%;--btn-color:white}

.a-font-lemon-chiffon {color: #fffacd}
.a-bkg-lemon-chiffon {background-color: #fffacd}
.a-hex-lemon-chiffon {--hex: #fffacd}
.a-hex1-lemon-chiffon {--hex1: #fffacd}
.a-hex2-lemon-chiffon {--hex2: #fffacd}
.a-hex3-lemon-chiffon {--hex3: #fffacd}
.a-hsl-lemon-chiffon {--h:54;--s:100%;--l:90%;}
.a-hsl1-lemon-chiffon {--h1:54;--s1:100%;--l1:90%;}
.a-hsl2-lemon-chiffon {--h2:54;--s2:100%;--l2:90%;}
.a-rgb1-lemon-chiffon {--r1:255;--g1:250;--b1:205}
.a-btn-lemon-chiffon {--hex: white;--h:54;--s:100%;--l:90%;--btn-color:white}

.a-font-light-blue {color: #add8e6}
.a-bkg-light-blue {background-color: #add8e6}
.a-hex-light-blue {--hex: #add8e6}
.a-hex1-light-blue {--hex1: #add8e6}
.a-hex2-light-blue {--hex2: #add8e6}
.a-hex3-light-blue {--hex3: #add8e6}
.a-hsl-light-blue {--h:195;--s:53%;--l:79%;}
.a-hsl1-light-blue {--h1:195;--s1:53%;--l1:79%;}
.a-hsl2-light-blue {--h2:195;--s2:53%;--l2:79%;}
.a-rgb1-light-blue {--r1:173;--g1:216;--b1:230}
.a-btn-light-blue {--hex: white;--h:195;--s:53%;--l:79%;--btn-color:white}

.a-font-light-coral {color: #f08080}
.a-bkg-light-coral {background-color: #f08080}
.a-hex-light-coral {--hex: #f08080}
.a-hex1-light-coral {--hex1: #f08080}
.a-hex2-light-coral {--hex2: #f08080}
.a-hex3-light-coral {--hex3: #f08080}
.a-hsl-light-coral {--h:0;--s:79%;--l:72%;}
.a-hsl1-light-coral {--h1:0;--s1:79%;--l1:72%;}
.a-hsl2-light-coral {--h2:0;--s2:79%;--l2:72%;}
.a-rgb1-light-coral {--r1:240;--g1:128;--b1:128}
.a-btn-light-coral {--hex: white;--h:0;--s:79%;--l:72%;--btn-color:white}

.a-font-light-cyan {color: #e0ffff}
.a-bkg-light-cyan {background-color: #e0ffff}
.a-hex-light-cyan {--hex: #e0ffff}
.a-hex1-light-cyan {--hex1: #e0ffff}
.a-hex2-light-cyan {--hex2: #e0ffff}
.a-hex3-light-cyan {--hex3: #e0ffff}
.a-hsl-light-cyan {--h:180;--s:100%;--l:94%;}
.a-hsl1-light-cyan {--h1:180;--s1:100%;--l1:94%;}
.a-hsl2-light-cyan {--h2:180;--s2:100%;--l2:94%;}
.a-rgb1-light-cyan {--r1:224;--g1:255;--b1:255}
.a-btn-light-cyan {--hex: white;--h:180;--s:100%;--l:94%;--btn-color:white}

.a-font-light-golden-rod-yellow {color: #fafad2}
.a-bkg-light-golden-rod-yellow {background-color: #fafad2}
.a-hex-light-golden-rod-yellow {--hex: #fafad2}
.a-hex1-light-golden-rod-yellow {--hex1: #fafad2}
.a-hex2-light-golden-rod-yellow {--hex2: #fafad2}
.a-hex3-light-golden-rod-yellow {--hex3: #fafad2}
.a-hsl-light-golden-rod-yellow {--h:60;--s:80%;--l:90%;}
.a-hsl1-light-golden-rod-yellow {--h1:60;--s1:80%;--l1:90%;}
.a-hsl2-light-golden-rod-yellow {--h2:60;--s2:80%;--l2:90%;}
.a-rgb1-light-golden-rod-yellow {--r1:250;--g1:250;--b1:210}
.a-btn-light-golden-rod-yellow {--hex: white;--h:60;--s:80%;--l:90%;--btn-color:white}

.a-font-light-gray {color: #d3d3d3}
.a-bkg-light-gray {background-color: #d3d3d3}
.a-hex-light-gray {--hex: #d3d3d3}
.a-hex1-light-gray {--hex1: #d3d3d3}
.a-hex2-light-gray {--hex2: #d3d3d3}
.a-hex3-light-gray {--hex3: #d3d3d3}
.a-hsl-light-gray {--h:0;--s:0%;--l:83%;}
.a-hsl1-light-gray {--h1:0;--s1:0%;--l1:83%;}
.a-hsl2-light-gray {--h2:0;--s2:0%;--l2:83%;}
.a-rgb1-light-gray {--r1:211;--g1:211;--b1:211}
.a-btn-light-gray {--hex: white;--h:0;--s:0%;--l:83%;--btn-color:white}

.a-font-light-green {color: #90ee90}
.a-bkg-light-green {background-color: #90ee90}
.a-hex-light-green {--hex: #90ee90}
.a-hex1-light-green {--hex1: #90ee90}
.a-hex2-light-green {--hex2: #90ee90}
.a-hex3-light-green {--hex3: #90ee90}
.a-hsl-light-green {--h:120;--s:73%;--l:75%;}
.a-hsl1-light-green {--h1:120;--s1:73%;--l1:75%;}
.a-hsl2-light-green {--h2:120;--s2:73%;--l2:75%;}
.a-rgb1-light-green {--r1:144;--g1:238;--b1:144}
.a-btn-light-green {--hex: white;--h:120;--s:73%;--l:75%;--btn-color:white}

.a-font-light-grey {color: #d3d3d3}
.a-bkg-light-grey {background-color: #d3d3d3}
.a-hex-light-grey {--hex: #d3d3d3}
.a-hex1-light-grey {--hex1: #d3d3d3}
.a-hex2-light-grey {--hex2: #d3d3d3}
.a-hex3-light-grey {--hex3: #d3d3d3}
.a-hsl-light-grey {--h:0;--s:0%;--l:83%;}
.a-hsl1-light-grey {--h1:0;--s1:0%;--l1:83%;}
.a-hsl2-light-grey {--h2:0;--s2:0%;--l2:83%;}
.a-rgb1-light-grey {--r1:211;--g1:211;--b1:211}
.a-btn-light-grey {--hex: white;--h:0;--s:0%;--l:83%;--btn-color:white}

.a-font-light-pink {color: #ffb6c1}
.a-bkg-light-pink {background-color: #ffb6c1}
.a-hex-light-pink {--hex: #ffb6c1}
.a-hex1-light-pink {--hex1: #ffb6c1}
.a-hex2-light-pink {--hex2: #ffb6c1}
.a-hex3-light-pink {--hex3: #ffb6c1}
.a-hsl-light-pink {--h:351;--s:100%;--l:86%;}
.a-hsl1-light-pink {--h1:351;--s1:100%;--l1:86%;}
.a-hsl2-light-pink {--h2:351;--s2:100%;--l2:86%;}
.a-rgb1-light-pink {--r1:255;--g1:182;--b1:193}
.a-btn-light-pink {--hex: white;--h:351;--s:100%;--l:86%;--btn-color:white}

.a-font-light-salmon {color: #ffa07a}
.a-bkg-light-salmon {background-color: #ffa07a}
.a-hex-light-salmon {--hex: #ffa07a}
.a-hex1-light-salmon {--hex1: #ffa07a}
.a-hex2-light-salmon {--hex2: #ffa07a}
.a-hex3-light-salmon {--hex3: #ffa07a}
.a-hsl-light-salmon {--h:17;--s:100%;--l:74%;}
.a-hsl1-light-salmon {--h1:17;--s1:100%;--l1:74%;}
.a-hsl2-light-salmon {--h2:17;--s2:100%;--l2:74%;}
.a-rgb1-light-salmon {--r1:255;--g1:160;--b1:122}
.a-btn-light-salmon {--hex: white;--h:17;--s:100%;--l:74%;--btn-color:white}

.a-font-light-sea-green {color: #20b2aa}
.a-bkg-light-sea-green {background-color: #20b2aa}
.a-hex-light-sea-green {--hex: #20b2aa}
.a-hex1-light-sea-green {--hex1: #20b2aa}
.a-hex2-light-sea-green {--hex2: #20b2aa}
.a-hex3-light-sea-green {--hex3: #20b2aa}
.a-hsl-light-sea-green {--h:177;--s:70%;--l:41%;}
.a-hsl1-light-sea-green {--h1:177;--s1:70%;--l1:41%;}
.a-hsl2-light-sea-green {--h2:177;--s2:70%;--l2:41%;}
.a-rgb1-light-sea-green {--r1:32;--g1:178;--b1:170}
.a-btn-light-sea-green {--hex: white;--h:177;--s:70%;--l:41%;--btn-color:white}

.a-font-light-sky-blue {color: #87cefa}
.a-bkg-light-sky-blue {background-color: #87cefa}
.a-hex-light-sky-blue {--hex: #87cefa}
.a-hex1-light-sky-blue {--hex1: #87cefa}
.a-hex2-light-sky-blue {--hex2: #87cefa}
.a-hex3-light-sky-blue {--hex3: #87cefa}
.a-hsl-light-sky-blue {--h:203;--s:92%;--l:75%;}
.a-hsl1-light-sky-blue {--h1:203;--s1:92%;--l1:75%;}
.a-hsl2-light-sky-blue {--h2:203;--s2:92%;--l2:75%;}
.a-rgb1-light-sky-blue {--r1:135;--g1:206;--b1:250}
.a-btn-light-sky-blue {--hex: white;--h:203;--s:92%;--l:75%;--btn-color:white}

.a-font-light-slate-gray {color: #778899}
.a-bkg-light-slate-gray {background-color: #778899}
.a-hex-light-slate-gray {--hex: #778899}
.a-hex1-light-slate-gray {--hex1: #778899}
.a-hex2-light-slate-gray {--hex2: #778899}
.a-hex3-light-slate-gray {--hex3: #778899}
.a-hsl-light-slate-gray {--h:210;--s:14%;--l:53%;}
.a-hsl1-light-slate-gray {--h1:210;--s1:14%;--l1:53%;}
.a-hsl2-light-slate-gray {--h2:210;--s2:14%;--l2:53%;}
.a-rgb1-light-slate-gray {--r1:119;--g1:136;--b1:153}
.a-btn-light-slate-gray {--hex: white;--h:210;--s:14%;--l:53%;--btn-color:white}

.a-font-light-slate-grey {color: #778899}
.a-bkg-light-slate-grey {background-color: #778899}
.a-hex-light-slate-grey {--hex: #778899}
.a-hex1-light-slate-grey {--hex1: #778899}
.a-hex2-light-slate-grey {--hex2: #778899}
.a-hex3-light-slate-grey {--hex3: #778899}
.a-hsl-light-slate-grey {--h:210;--s:14%;--l:53%;}
.a-hsl1-light-slate-grey {--h1:210;--s1:14%;--l1:53%;}
.a-hsl2-light-slate-grey {--h2:210;--s2:14%;--l2:53%;}
.a-rgb1-light-slate-grey {--r1:119;--g1:136;--b1:153}
.a-btn-light-slate-grey {--hex: white;--h:210;--s:14%;--l:53%;--btn-color:white}

.a-font-light-steel-blue {color: #b0c4de}
.a-bkg-light-steel-blue {background-color: #b0c4de}
.a-hex-light-steel-blue {--hex: #b0c4de}
.a-hex1-light-steel-blue {--hex1: #b0c4de}
.a-hex2-light-steel-blue {--hex2: #b0c4de}
.a-hex3-light-steel-blue {--hex3: #b0c4de}
.a-hsl-light-steel-blue {--h:214;--s:41%;--l:78%;}
.a-hsl1-light-steel-blue {--h1:214;--s1:41%;--l1:78%;}
.a-hsl2-light-steel-blue {--h2:214;--s2:41%;--l2:78%;}
.a-rgb1-light-steel-blue {--r1:176;--g1:196;--b1:222}
.a-btn-light-steel-blue {--hex: white;--h:214;--s:41%;--l:78%;--btn-color:white}

.a-font-light-yellow {color: #ffffe0}
.a-bkg-light-yellow {background-color: #ffffe0}
.a-hex-light-yellow {--hex: #ffffe0}
.a-hex1-light-yellow {--hex1: #ffffe0}
.a-hex2-light-yellow {--hex2: #ffffe0}
.a-hex3-light-yellow {--hex3: #ffffe0}
.a-hsl-light-yellow {--h:60;--s:100%;--l:94%;}
.a-hsl1-light-yellow {--h1:60;--s1:100%;--l1:94%;}
.a-hsl2-light-yellow {--h2:60;--s2:100%;--l2:94%;}
.a-rgb1-light-yellow {--r1:255;--g1:255;--b1:224}
.a-btn-light-yellow {--hex: white;--h:60;--s:100%;--l:94%;--btn-color:white}

.a-font-lime {color: #00ff00}
.a-bkg-lime {background-color: #00ff00}
.a-hex-lime {--hex: #00ff00}
.a-hex1-lime {--hex1: #00ff00}
.a-hex2-lime {--hex2: #00ff00}
.a-hex3-lime {--hex3: #00ff00}
.a-hsl-lime {--h:120;--s:100%;--l:50%;}
.a-hsl1-lime {--h1:120;--s1:100%;--l1:50%;}
.a-hsl2-lime {--h2:120;--s2:100%;--l2:50%;}
.a-rgb1-lime {--r1:0;--g1:255;--b1:0}
.a-btn-lime {--hex: white;--h:120;--s:100%;--l:50%;--btn-color:white}

.a-font-lime-green {color: #32cd32}
.a-bkg-lime-green {background-color: #32cd32}
.a-hex-lime-green {--hex: #32cd32}
.a-hex1-lime-green {--hex1: #32cd32}
.a-hex2-lime-green {--hex2: #32cd32}
.a-hex3-lime-green {--hex3: #32cd32}
.a-hsl-lime-green {--h:120;--s:61%;--l:50%;}
.a-hsl1-lime-green {--h1:120;--s1:61%;--l1:50%;}
.a-hsl2-lime-green {--h2:120;--s2:61%;--l2:50%;}
.a-rgb1-lime-green {--r1:50;--g1:205;--b1:50}
.a-btn-lime-green {--hex: white;--h:120;--s:61%;--l:50%;--btn-color:white}

.a-font-linen {color: #faf0e6}
.a-bkg-linen {background-color: #faf0e6}
.a-hex-linen {--hex: #faf0e6}
.a-hex1-linen {--hex1: #faf0e6}
.a-hex2-linen {--hex2: #faf0e6}
.a-hex3-linen {--hex3: #faf0e6}
.a-hsl-linen {--h:30;--s:67%;--l:94%;}
.a-hsl1-linen {--h1:30;--s1:67%;--l1:94%;}
.a-hsl2-linen {--h2:30;--s2:67%;--l2:94%;}
.a-rgb1-linen {--r1:250;--g1:240;--b1:230}
.a-btn-linen {--hex: white;--h:30;--s:67%;--l:94%;--btn-color:white}

.a-font-magenta {color: #ff00ff}
.a-bkg-magenta {background-color: #ff00ff}
.a-hex-magenta {--hex: #ff00ff}
.a-hex1-magenta {--hex1: #ff00ff}
.a-hex2-magenta {--hex2: #ff00ff}
.a-hex3-magenta {--hex3: #ff00ff}
.a-hsl-magenta {--h:300;--s:100%;--l:50%;}
.a-hsl1-magenta {--h1:300;--s1:100%;--l1:50%;}
.a-hsl2-magenta {--h2:300;--s2:100%;--l2:50%;}
.a-rgb1-magenta {--r1:255;--g1:0;--b1:255}
.a-btn-magenta {--hex: white;--h:300;--s:100%;--l:50%;--btn-color:white}

.a-font-magenta-a {color: #d71bce}
.a-bkg-magenta-a {background-color: #d71bce}
.a-hex-magenta-a {--hex: #d71bce}
.a-hex1-magenta-a {--hex1: #d71bce}
.a-hex2-magenta-a {--hex2: #d71bce}
.a-hex3-magenta-a {--hex3: #d71bce}
.a-hsl-magenta-a {--h:303;--s:78%;--l:47%;}
.a-hsl1-magenta-a {--h1:303;--s1:78%;--l1:47%;}
.a-hsl2-magenta-a {--h2:303;--s2:78%;--l2:47%;}
.a-rgb1-magenta-a {--r1:215;--g1:27;--b1:206}
.a-btn-magenta-a {--hex: white;--h:303;--s:78%;--l:47%;--btn-color:white}

.a-font-maroon {color: #800000}
.a-bkg-maroon {background-color: #800000}
.a-hex-maroon {--hex: #800000}
.a-hex1-maroon {--hex1: #800000}
.a-hex2-maroon {--hex2: #800000}
.a-hex3-maroon {--hex3: #800000}
.a-hsl-maroon {--h:0;--s:100%;--l:25%;}
.a-hsl1-maroon {--h1:0;--s1:100%;--l1:25%;}
.a-hsl2-maroon {--h2:0;--s2:100%;--l2:25%;}
.a-rgb1-maroon {--r1:128;--g1:0;--b1:0}
.a-btn-maroon {--hex: white;--h:0;--s:100%;--l:25%;--btn-color:white}

.a-font-medium-aquamarine {color: #66cdaa}
.a-bkg-medium-aquamarine {background-color: #66cdaa}
.a-hex-medium-aquamarine {--hex: #66cdaa}
.a-hex1-medium-aquamarine {--hex1: #66cdaa}
.a-hex2-medium-aquamarine {--hex2: #66cdaa}
.a-hex3-medium-aquamarine {--hex3: #66cdaa}
.a-hsl-medium-aquamarine {--h:160;--s:51%;--l:60%;}
.a-hsl1-medium-aquamarine {--h1:160;--s1:51%;--l1:60%;}
.a-hsl2-medium-aquamarine {--h2:160;--s2:51%;--l2:60%;}
.a-rgb1-medium-aquamarine {--r1:102;--g1:205;--b1:170}
.a-btn-medium-aquamarine {--hex: white;--h:160;--s:51%;--l:60%;--btn-color:white}

.a-font-medium-blue {color: #0000cd}
.a-bkg-medium-blue {background-color: #0000cd}
.a-hex-medium-blue {--hex: #0000cd}
.a-hex1-medium-blue {--hex1: #0000cd}
.a-hex2-medium-blue {--hex2: #0000cd}
.a-hex3-medium-blue {--hex3: #0000cd}
.a-hsl-medium-blue {--h:240;--s:100%;--l:40%;}
.a-hsl1-medium-blue {--h1:240;--s1:100%;--l1:40%;}
.a-hsl2-medium-blue {--h2:240;--s2:100%;--l2:40%;}
.a-rgb1-medium-blue {--r1:0;--g1:0;--b1:205}
.a-btn-medium-blue {--hex: white;--h:240;--s:100%;--l:40%;--btn-color:white}

.a-font-medium-orchid {color: #ba55d3}
.a-bkg-medium-orchid {background-color: #ba55d3}
.a-hex-medium-orchid {--hex: #ba55d3}
.a-hex1-medium-orchid {--hex1: #ba55d3}
.a-hex2-medium-orchid {--hex2: #ba55d3}
.a-hex3-medium-orchid {--hex3: #ba55d3}
.a-hsl-medium-orchid {--h:288;--s:59%;--l:58%;}
.a-hsl1-medium-orchid {--h1:288;--s1:59%;--l1:58%;}
.a-hsl2-medium-orchid {--h2:288;--s2:59%;--l2:58%;}
.a-rgb1-medium-orchid {--r1:186;--g1:85;--b1:211}
.a-btn-medium-orchid {--hex: white;--h:288;--s:59%;--l:58%;--btn-color:white}

.a-font-medium-purple {color: #9370db}
.a-bkg-medium-purple {background-color: #9370db}
.a-hex-medium-purple {--hex: #9370db}
.a-hex1-medium-purple {--hex1: #9370db}
.a-hex2-medium-purple {--hex2: #9370db}
.a-hex3-medium-purple {--hex3: #9370db}
.a-hsl-medium-purple {--h:260;--s:60%;--l:65%;}
.a-hsl1-medium-purple {--h1:260;--s1:60%;--l1:65%;}
.a-hsl2-medium-purple {--h2:260;--s2:60%;--l2:65%;}
.a-rgb1-medium-purple {--r1:147;--g1:112;--b1:219}
.a-btn-medium-purple {--hex: white;--h:260;--s:60%;--l:65%;--btn-color:white}

.a-font-medium-sea-green {color: #3cb371}
.a-bkg-medium-sea-green {background-color: #3cb371}
.a-hex-medium-sea-green {--hex: #3cb371}
.a-hex1-medium-sea-green {--hex1: #3cb371}
.a-hex2-medium-sea-green {--hex2: #3cb371}
.a-hex3-medium-sea-green {--hex3: #3cb371}
.a-hsl-medium-sea-green {--h:147;--s:50%;--l:47%;}
.a-hsl1-medium-sea-green {--h1:147;--s1:50%;--l1:47%;}
.a-hsl2-medium-sea-green {--h2:147;--s2:50%;--l2:47%;}
.a-rgb1-medium-sea-green {--r1:60;--g1:179;--b1:113}
.a-btn-medium-sea-green {--hex: white;--h:147;--s:50%;--l:47%;--btn-color:white}

.a-font-medium-slate-blue {color: #7b68ee}
.a-bkg-medium-slate-blue {background-color: #7b68ee}
.a-hex-medium-slate-blue {--hex: #7b68ee}
.a-hex1-medium-slate-blue {--hex1: #7b68ee}
.a-hex2-medium-slate-blue {--hex2: #7b68ee}
.a-hex3-medium-slate-blue {--hex3: #7b68ee}
.a-hsl-medium-slate-blue {--h:249;--s:80%;--l:67%;}
.a-hsl1-medium-slate-blue {--h1:249;--s1:80%;--l1:67%;}
.a-hsl2-medium-slate-blue {--h2:249;--s2:80%;--l2:67%;}
.a-rgb1-medium-slate-blue {--r1:123;--g1:104;--b1:238}
.a-btn-medium-slate-blue {--hex: white;--h:249;--s:80%;--l:67%;--btn-color:white}

.a-font-medium-spring-green {color: #00fa9a}
.a-bkg-medium-spring-green {background-color: #00fa9a}
.a-hex-medium-spring-green {--hex: #00fa9a}
.a-hex1-medium-spring-green {--hex1: #00fa9a}
.a-hex2-medium-spring-green {--hex2: #00fa9a}
.a-hex3-medium-spring-green {--hex3: #00fa9a}
.a-hsl-medium-spring-green {--h:157;--s:100%;--l:49%;}
.a-hsl1-medium-spring-green {--h1:157;--s1:100%;--l1:49%;}
.a-hsl2-medium-spring-green {--h2:157;--s2:100%;--l2:49%;}
.a-rgb1-medium-spring-green {--r1:0;--g1:250;--b1:154}
.a-btn-medium-spring-green {--hex: white;--h:157;--s:100%;--l:49%;--btn-color:white}

.a-font-medium-turquoise {color: #48d1cc}
.a-bkg-medium-turquoise {background-color: #48d1cc}
.a-hex-medium-turquoise {--hex: #48d1cc}
.a-hex1-medium-turquoise {--hex1: #48d1cc}
.a-hex2-medium-turquoise {--hex2: #48d1cc}
.a-hex3-medium-turquoise {--hex3: #48d1cc}
.a-hsl-medium-turquoise {--h:178;--s:60%;--l:55%;}
.a-hsl1-medium-turquoise {--h1:178;--s1:60%;--l1:55%;}
.a-hsl2-medium-turquoise {--h2:178;--s2:60%;--l2:55%;}
.a-rgb1-medium-turquoise {--r1:72;--g1:209;--b1:204}
.a-btn-medium-turquoise {--hex: white;--h:178;--s:60%;--l:55%;--btn-color:white}

.a-font-medium-violet-red {color: #c71585}
.a-bkg-medium-violet-red {background-color: #c71585}
.a-hex-medium-violet-red {--hex: #c71585}
.a-hex1-medium-violet-red {--hex1: #c71585}
.a-hex2-medium-violet-red {--hex2: #c71585}
.a-hex3-medium-violet-red {--hex3: #c71585}
.a-hsl-medium-violet-red {--h:322;--s:81%;--l:43%;}
.a-hsl1-medium-violet-red {--h1:322;--s1:81%;--l1:43%;}
.a-hsl2-medium-violet-red {--h2:322;--s2:81%;--l2:43%;}
.a-rgb1-medium-violet-red {--r1:199;--g1:21;--b1:133}
.a-btn-medium-violet-red {--hex: white;--h:322;--s:81%;--l:43%;--btn-color:white}

.a-font-midnight {color: #1b23b3}
.a-bkg-midnight {background-color: #1b23b3}
.a-hex-midnight {--hex: #1b23b3}
.a-hex1-midnight {--hex1: #1b23b3}
.a-hex2-midnight {--hex2: #1b23b3}
.a-hex3-midnight {--hex3: #1b23b3}
.a-hsl-midnight {--h:237;--s:74%;--l:40%;}
.a-hsl1-midnight {--h1:237;--s1:74%;--l1:40%;}
.a-hsl2-midnight {--h2:237;--s2:74%;--l2:40%;}
.a-rgb1-midnight {--r1:27;--g1:35;--b1:179}
.a-btn-midnight {--hex: white;--h:237;--s:74%;--l:40%;--btn-color:white}

.a-font-midnight-blue {color: #191970}
.a-bkg-midnight-blue {background-color: #191970}
.a-hex-midnight-blue {--hex: #191970}
.a-hex1-midnight-blue {--hex1: #191970}
.a-hex2-midnight-blue {--hex2: #191970}
.a-hex3-midnight-blue {--hex3: #191970}
.a-hsl-midnight-blue {--h:240;--s:64%;--l:27%;}
.a-hsl1-midnight-blue {--h1:240;--s1:64%;--l1:27%;}
.a-hsl2-midnight-blue {--h2:240;--s2:64%;--l2:27%;}
.a-rgb1-midnight-blue {--r1:25;--g1:25;--b1:112}
.a-btn-midnight-blue {--hex: white;--h:240;--s:64%;--l:27%;--btn-color:white}

.a-font-mint-cream {color: #f5fffa}
.a-bkg-mint-cream {background-color: #f5fffa}
.a-hex-mint-cream {--hex: #f5fffa}
.a-hex1-mint-cream {--hex1: #f5fffa}
.a-hex2-mint-cream {--hex2: #f5fffa}
.a-hex3-mint-cream {--hex3: #f5fffa}
.a-hsl-mint-cream {--h:150;--s:100%;--l:98%;}
.a-hsl1-mint-cream {--h1:150;--s1:100%;--l1:98%;}
.a-hsl2-mint-cream {--h2:150;--s2:100%;--l2:98%;}
.a-rgb1-mint-cream {--r1:245;--g1:255;--b1:250}
.a-btn-mint-cream {--hex: white;--h:150;--s:100%;--l:98%;--btn-color:white}

.a-font-misty-rose {color: #ffe4e1}
.a-bkg-misty-rose {background-color: #ffe4e1}
.a-hex-misty-rose {--hex: #ffe4e1}
.a-hex1-misty-rose {--hex1: #ffe4e1}
.a-hex2-misty-rose {--hex2: #ffe4e1}
.a-hex3-misty-rose {--hex3: #ffe4e1}
.a-hsl-misty-rose {--h:6;--s:100%;--l:94%;}
.a-hsl1-misty-rose {--h1:6;--s1:100%;--l1:94%;}
.a-hsl2-misty-rose {--h2:6;--s2:100%;--l2:94%;}
.a-rgb1-misty-rose {--r1:255;--g1:228;--b1:225}
.a-btn-misty-rose {--hex: white;--h:6;--s:100%;--l:94%;--btn-color:white}

.a-font-moccasin {color: #ffe4b5}
.a-bkg-moccasin {background-color: #ffe4b5}
.a-hex-moccasin {--hex: #ffe4b5}
.a-hex1-moccasin {--hex1: #ffe4b5}
.a-hex2-moccasin {--hex2: #ffe4b5}
.a-hex3-moccasin {--hex3: #ffe4b5}
.a-hsl-moccasin {--h:38;--s:100%;--l:85%;}
.a-hsl1-moccasin {--h1:38;--s1:100%;--l1:85%;}
.a-hsl2-moccasin {--h2:38;--s2:100%;--l2:85%;}
.a-rgb1-moccasin {--r1:255;--g1:228;--b1:181}
.a-btn-moccasin {--hex: white;--h:38;--s:100%;--l:85%;--btn-color:white}

.a-font-moonstone {color: #2294b1}
.a-bkg-moonstone {background-color: #2294b1}
.a-hex-moonstone {--hex: #2294b1}
.a-hex1-moonstone {--hex1: #2294b1}
.a-hex2-moonstone {--hex2: #2294b1}
.a-hex3-moonstone {--hex3: #2294b1}
.a-hsl-moonstone {--h:192;--s:68%;--l:41%;}
.a-hsl1-moonstone {--h1:192;--s1:68%;--l1:41%;}
.a-hsl2-moonstone {--h2:192;--s2:68%;--l2:41%;}
.a-rgb1-moonstone {--r1:34;--g1:148;--b1:177}
.a-btn-moonstone {--hex: white;--h:192;--s:68%;--l:41%;--btn-color:white}

.a-font-navajo-white {color: #ffdead}
.a-bkg-navajo-white {background-color: #ffdead}
.a-hex-navajo-white {--hex: #ffdead}
.a-hex1-navajo-white {--hex1: #ffdead}
.a-hex2-navajo-white {--hex2: #ffdead}
.a-hex3-navajo-white {--hex3: #ffdead}
.a-hsl-navajo-white {--h:36;--s:100%;--l:84%;}
.a-hsl1-navajo-white {--h1:36;--s1:100%;--l1:84%;}
.a-hsl2-navajo-white {--h2:36;--s2:100%;--l2:84%;}
.a-rgb1-navajo-white {--r1:255;--g1:222;--b1:173}
.a-btn-navajo-white {--hex: white;--h:36;--s:100%;--l:84%;--btn-color:white}

.a-font-navy {color: #000080}
.a-bkg-navy {background-color: #000080}
.a-hex-navy {--hex: #000080}
.a-hex1-navy {--hex1: #000080}
.a-hex2-navy {--hex2: #000080}
.a-hex3-navy {--hex3: #000080}
.a-hsl-navy {--h:240;--s:100%;--l:25%;}
.a-hsl1-navy {--h1:240;--s1:100%;--l1:25%;}
.a-hsl2-navy {--h2:240;--s2:100%;--l2:25%;}
.a-rgb1-navy {--r1:0;--g1:0;--b1:128}
.a-btn-navy {--hex: white;--h:240;--s:100%;--l:25%;--btn-color:white}



.a-font-oldlace {color: #fdf5e6}
.a-bkg-oldlace {background-color: #fdf5e6}
.a-hex-oldlace {--hex: #fdf5e6}
.a-hex1-oldlace {--hex1: #fdf5e6}
.a-hex2-oldlace {--hex2: #fdf5e6}
.a-hex3-oldlace {--hex3: #fdf5e6}
.a-hsl-oldlace {--h:39;--s:85%;--l:95%;}
.a-hsl1-oldlace {--h1:39;--s1:85%;--l1:95%;}
.a-hsl2-oldlace {--h2:39;--s2:85%;--l2:95%;}
.a-rgb1-oldlace {--r1:253;--g1:245;--b1:230}
.a-btn-oldlace {--hex: white;--h:39;--s:85%;--l:95%;--btn-color:white}

.a-font-olive {color: #808000}
.a-bkg-olive {background-color: #808000}
.a-hex-olive {--hex: #808000}
.a-hex1-olive {--hex1: #808000}
.a-hex2-olive {--hex2: #808000}
.a-hex3-olive {--hex3: #808000}
.a-hsl-olive {--h:60;--s:100%;--l:25%;}
.a-hsl1-olive {--h1:60;--s1:100%;--l1:25%;}
.a-hsl2-olive {--h2:60;--s2:100%;--l2:25%;}
.a-rgb1-olive {--r1:128;--g1:128;--b1:0}
.a-btn-olive {--hex: white;--h:60;--s:100%;--l:25%;--btn-color:white}

.a-font-olive-drab {color: #6b8e23}
.a-bkg-olive-drab {background-color: #6b8e23}
.a-hex-olive-drab {--hex: #6b8e23}
.a-hex1-olive-drab {--hex1: #6b8e23}
.a-hex2-olive-drab {--hex2: #6b8e23}
.a-hex3-olive-drab {--hex3: #6b8e23}
.a-hsl-olive-drab {--h:80;--s:60%;--l:35%;}
.a-hsl1-olive-drab {--h1:80;--s1:60%;--l1:35%;}
.a-hsl2-olive-drab {--h2:80;--s2:60%;--l2:35%;}
.a-rgb1-olive-drab {--r1:107;--g1:142;--b1:35}
.a-btn-olive-drab {--hex: white;--h:80;--s:60%;--l:35%;--btn-color:white}

.a-font-orange {color: #ffa500}
.a-bkg-orange {background-color: #ffa500}
.a-hex-orange {--hex: #ffa500}
.a-hex1-orange {--hex1: #ffa500}
.a-hex2-orange {--hex2: #ffa500}
.a-hex3-orange {--hex3: #ffa500}
.a-hsl-orange {--h:39;--s:100%;--l:50%;}
.a-hsl1-orange {--h1:39;--s1:100%;--l1:50%;}
.a-hsl2-orange {--h2:39;--s2:100%;--l2:50%;}
.a-rgb1-orange {--r1:255;--g1:165;--b1:0}
.a-btn-orange {--hex: white;--h:39;--s:100%;--l:50%;--btn-color:white}

.a-font-orange-1 {color: #ff5e00}
.a-bkg-orange-1 {background-color: #ff5e00}
.a-hex-orange-1 {--hex: #ff5e00}
.a-hex1-orange-1 {--hex1: #ff5e00}
.a-hex2-orange-1 {--hex2: #ff5e00}
.a-hex3-orange-1 {--hex3: #ff5e00}
.a-hsl-orange-1 {--h:22;--s:100%;--l:50%;}
.a-hsl1-orange-1 {--h1:22;--s1:100%;--l1:50%;}
.a-hsl2-orange-1 {--h2:22;--s2:100%;--l2:50%;}
.a-rgb1-orange-1 {--r1:255;--g1:94;--b1:0}
.a-btn-orange-1 {--hex: white;--h:22;--s:100%;--l:50%;--btn-color:white}

.a-font-orange-2 {color: #f49106}
.a-bkg-orange-2 {background-color: #f49106}
.a-hex-orange-2 {--hex: #f49106}
.a-hex1-orange-2 {--hex1: #f49106}
.a-hex2-orange-2 {--hex2: #f49106}
.a-hex3-orange-2 {--hex3: #f49106}
.a-hsl-orange-2 {--h:35;--s:95%;--l:49%;}
.a-hsl1-orange-2 {--h1:35;--s1:95%;--l1:49%;}
.a-hsl2-orange-2 {--h2:35;--s2:95%;--l2:49%;}
.a-rgb1-orange-2 {--r1:244;--g1:145;--b1:6}
.a-btn-orange-2 {--hex: white;--h:35;--s:95%;--l:49%;--btn-color:white}

.a-font-orange-a {color: #e66702}
.a-bkg-orange-a {background-color: #e66702}
.a-hex-orange-a {--hex: #e66702}
.a-hex1-orange-a {--hex1: #e66702}
.a-hex2-orange-a {--hex2: #e66702}
.a-hex3-orange-a {--hex3: #e66702}
.a-hsl-orange-a {--h:27;--s:98%;--l:45%;}
.a-hsl1-orange-a {--h1:27;--s1:98%;--l1:45%;}
.a-hsl2-orange-a {--h2:27;--s2:98%;--l2:45%;}
.a-rgb1-orange-a {--r1:230;--g1:103;--b1:2}
.a-btn-orange-a {--hex: white;--h:27;--s:98%;--l:45%;--btn-color:white}

.a-font-orange-red {color: #ff4500}
.a-bkg-orange-red {background-color: #ff4500}
.a-hex-orange-red {--hex: #ff4500}
.a-hex1-orange-red {--hex1: #ff4500}
.a-hex2-orange-red {--hex2: #ff4500}
.a-hex3-orange-red {--hex3: #ff4500}
.a-hsl-orange-red {--h:16;--s:100%;--l:50%;}
.a-hsl1-orange-red {--h1:16;--s1:100%;--l1:50%;}
.a-hsl2-orange-red {--h2:16;--s2:100%;--l2:50%;}
.a-rgb1-orange-red {--r1:255;--g1:69;--b1:0}
.a-btn-orange-red {--hex: white;--h:16;--s:100%;--l:50%;--btn-color:white}

.a-font-orchid {color: #da70d6}
.a-bkg-orchid {background-color: #da70d6}
.a-hex-orchid {--hex: #da70d6}
.a-hex1-orchid {--hex1: #da70d6}
.a-hex2-orchid {--hex2: #da70d6}
.a-hex3-orchid {--hex3: #da70d6}
.a-hsl-orchid {--h:302;--s:59%;--l:65%;}
.a-hsl1-orchid {--h1:302;--s1:59%;--l1:65%;}
.a-hsl2-orchid {--h2:302;--s2:59%;--l2:65%;}
.a-rgb1-orchid {--r1:218;--g1:112;--b1:214}
.a-btn-orchid {--hex: white;--h:302;--s:59%;--l:65%;--btn-color:white}

.a-font-pale-golden-rod {color: #eee8aa}
.a-bkg-pale-golden-rod {background-color: #eee8aa}
.a-hex-pale-golden-rod {--hex: #eee8aa}
.a-hex1-pale-golden-rod {--hex1: #eee8aa}
.a-hex2-pale-golden-rod {--hex2: #eee8aa}
.a-hex3-pale-golden-rod {--hex3: #eee8aa}
.a-hsl-pale-golden-rod {--h:55;--s:67%;--l:80%;}
.a-hsl1-pale-golden-rod {--h1:55;--s1:67%;--l1:80%;}
.a-hsl2-pale-golden-rod {--h2:55;--s2:67%;--l2:80%;}
.a-rgb1-pale-golden-rod {--r1:238;--g1:232;--b1:170}
.a-btn-pale-golden-rod {--hex: white;--h:55;--s:67%;--l:80%;--btn-color:white}

.a-font-pale-green {color: #98fb98}
.a-bkg-pale-green {background-color: #98fb98}
.a-hex-pale-green {--hex: #98fb98}
.a-hex1-pale-green {--hex1: #98fb98}
.a-hex2-pale-green {--hex2: #98fb98}
.a-hex3-pale-green {--hex3: #98fb98}
.a-hsl-pale-green {--h:120;--s:93%;--l:79%;}
.a-hsl1-pale-green {--h1:120;--s1:93%;--l1:79%;}
.a-hsl2-pale-green {--h2:120;--s2:93%;--l2:79%;}
.a-rgb1-pale-green {--r1:152;--g1:251;--b1:152}
.a-btn-pale-green {--hex: white;--h:120;--s:93%;--l:79%;--btn-color:white}

.a-font-pale-turquoise {color: #afeeee}
.a-bkg-pale-turquoise {background-color: #afeeee}
.a-hex-pale-turquoise {--hex: #afeeee}
.a-hex1-pale-turquoise {--hex1: #afeeee}
.a-hex2-pale-turquoise {--hex2: #afeeee}
.a-hex3-pale-turquoise {--hex3: #afeeee}
.a-hsl-pale-turquoise {--h:180;--s:65%;--l:81%;}
.a-hsl1-pale-turquoise {--h1:180;--s1:65%;--l1:81%;}
.a-hsl2-pale-turquoise {--h2:180;--s2:65%;--l2:81%;}
.a-rgb1-pale-turquoise {--r1:175;--g1:238;--b1:238}
.a-btn-pale-turquoise {--hex: white;--h:180;--s:65%;--l:81%;--btn-color:white}

.a-font-pale-violet-red {color: #db7093}
.a-bkg-pale-violet-red {background-color: #db7093}
.a-hex-pale-violet-red {--hex: #db7093}
.a-hex1-pale-violet-red {--hex1: #db7093}
.a-hex2-pale-violet-red {--hex2: #db7093}
.a-hex3-pale-violet-red {--hex3: #db7093}
.a-hsl-pale-violet-red {--h:340;--s:60%;--l:65%;}
.a-hsl1-pale-violet-red {--h1:340;--s1:60%;--l1:65%;}
.a-hsl2-pale-violet-red {--h2:340;--s2:60%;--l2:65%;}
.a-rgb1-pale-violet-red {--r1:219;--g1:112;--b1:147}
.a-btn-pale-violet-red {--hex: white;--h:340;--s:60%;--l:65%;--btn-color:white}

.a-font-papaya-whip {color: #ffefd5}
.a-bkg-papaya-whip {background-color: #ffefd5}
.a-hex-papaya-whip {--hex: #ffefd5}
.a-hex1-papaya-whip {--hex1: #ffefd5}
.a-hex2-papaya-whip {--hex2: #ffefd5}
.a-hex3-papaya-whip {--hex3: #ffefd5}
.a-hsl-papaya-whip {--h:37;--s:100%;--l:92%;}
.a-hsl1-papaya-whip {--h1:37;--s1:100%;--l1:92%;}
.a-hsl2-papaya-whip {--h2:37;--s2:100%;--l2:92%;}
.a-rgb1-papaya-whip {--r1:255;--g1:239;--b1:213}
.a-btn-papaya-whip {--hex: white;--h:37;--s:100%;--l:92%;--btn-color:white}

.a-font-peach-puff {color: #ffdab9}
.a-bkg-peach-puff {background-color: #ffdab9}
.a-hex-peach-puff {--hex: #ffdab9}
.a-hex1-peach-puff {--hex1: #ffdab9}
.a-hex2-peach-puff {--hex2: #ffdab9}
.a-hex3-peach-puff {--hex3: #ffdab9}
.a-hsl-peach-puff {--h:28;--s:100%;--l:86%;}
.a-hsl1-peach-puff {--h1:28;--s1:100%;--l1:86%;}
.a-hsl2-peach-puff {--h2:28;--s2:100%;--l2:86%;}
.a-rgb1-peach-puff {--r1:255;--g1:218;--b1:185}
.a-btn-peach-puff {--hex: white;--h:28;--s:100%;--l:86%;--btn-color:white}

.a-font-peru {color: #cd853f}
.a-bkg-peru {background-color: #cd853f}
.a-hex-peru {--hex: #cd853f}
.a-hex1-peru {--hex1: #cd853f}
.a-hex2-peru {--hex2: #cd853f}
.a-hex3-peru {--hex3: #cd853f}
.a-hsl-peru {--h:30;--s:59%;--l:53%;}
.a-hsl1-peru {--h1:30;--s1:59%;--l1:53%;}
.a-hsl2-peru {--h2:30;--s2:59%;--l2:53%;}
.a-rgb1-peru {--r1:205;--g1:133;--b1:63}
.a-btn-peru {--hex: white;--h:30;--s:59%;--l:53%;--btn-color:white}

.a-font-pink {color: #ffc0cb}
.a-bkg-pink {background-color: #ffc0cb}
.a-hex-pink {--hex: #ffc0cb}
.a-hex1-pink {--hex1: #ffc0cb}
.a-hex2-pink {--hex2: #ffc0cb}
.a-hex3-pink {--hex3: #ffc0cb}
.a-hsl-pink {--h:350;--s:100%;--l:88%;}
.a-hsl1-pink {--h1:350;--s1:100%;--l1:88%;}
.a-hsl2-pink {--h2:350;--s2:100%;--l2:88%;}
.a-rgb1-pink {--r1:255;--g1:192;--b1:203}
.a-btn-pink {--hex: white;--h:350;--s:100%;--l:88%;--btn-color:white}

.a-font-plum {color: #dda0dd}
.a-bkg-plum {background-color: #dda0dd}
.a-hex-plum {--hex: #dda0dd}
.a-hex1-plum {--hex1: #dda0dd}
.a-hex2-plum {--hex2: #dda0dd}
.a-hex3-plum {--hex3: #dda0dd}
.a-hsl-plum {--h:300;--s:47%;--l:75%;}
.a-hsl1-plum {--h1:300;--s1:47%;--l1:75%;}
.a-hsl2-plum {--h2:300;--s2:47%;--l2:75%;}
.a-rgb1-plum {--r1:221;--g1:160;--b1:221}
.a-btn-plum {--hex: white;--h:300;--s:47%;--l:75%;--btn-color:white}

.a-font-powder-blue {color: #b0e0e6}
.a-bkg-powder-blue {background-color: #b0e0e6}
.a-hex-powder-blue {--hex: #b0e0e6}
.a-hex1-powder-blue {--hex1: #b0e0e6}
.a-hex2-powder-blue {--hex2: #b0e0e6}
.a-hex3-powder-blue {--hex3: #b0e0e6}
.a-hsl-powder-blue {--h:187;--s:52%;--l:80%;}
.a-hsl1-powder-blue {--h1:187;--s1:52%;--l1:80%;}
.a-hsl2-powder-blue {--h2:187;--s2:52%;--l2:80%;}
.a-rgb1-powder-blue {--r1:176;--g1:224;--b1:230}
.a-btn-powder-blue {--hex: white;--h:187;--s:52%;--l:80%;--btn-color:white}

.a-font-purple {color: #800080}
.a-bkg-purple {background-color: #800080}
.a-hex-purple {--hex: #800080}
.a-hex1-purple {--hex1: #800080}
.a-hex2-purple {--hex2: #800080}
.a-hex3-purple {--hex3: #800080}
.a-hsl-purple {--h:300;--s:100%;--l:25%;}
.a-hsl1-purple {--h1:300;--s1:100%;--l1:25%;}
.a-hsl2-purple {--h2:300;--s2:100%;--l2:25%;}
.a-rgb1-purple {--r1:128;--g1:0;--b1:128}
.a-btn-purple {--hex: white;--h:300;--s:100%;--l:25%;--btn-color:white}

.a-font-rebecca-purple {color: #663399}
.a-bkg-rebecca-purple {background-color: #663399}
.a-hex-rebecca-purple {--hex: #663399}
.a-hex1-rebecca-purple {--hex1: #663399}
.a-hex2-rebecca-purple {--hex2: #663399}
.a-hex3-rebecca-purple {--hex3: #663399}
.a-hsl-rebecca-purple {--h:270;--s:50%;--l:40%;}
.a-hsl1-rebecca-purple {--h1:270;--s1:50%;--l1:40%;}
.a-hsl2-rebecca-purple {--h2:270;--s2:50%;--l2:40%;}
.a-rgb1-rebecca-purple {--r1:102;--g1:51;--b1:153}
.a-btn-rebecca-purple {--hex: white;--h:270;--s:50%;--l:40%;--btn-color:white}

.a-font-red {color: #ff0000}
.a-bkg-red {background-color: #ff0000}
.a-hex-red {--hex: #ff0000}
.a-hex1-red {--hex1: #ff0000}
.a-hex2-red {--hex2: #ff0000}
.a-hex3-red {--hex3: #ff0000}
.a-hsl-red {--h:0;--s:100%;--l:50%;}
.a-hsl1-red {--h1:0;--s1:100%;--l1:50%;}
.a-hsl2-red {--h2:0;--s2:100%;--l2:50%;}
.a-rgb1-red {--r1:255;--g1:0;--b1:0}
.a-btn-red {--hex: white;--h:0;--s:100%;--l:50%;--btn-color:white}

.a-font-rosy-brown {color: #bc8f8f}
.a-bkg-rosy-brown {background-color: #bc8f8f}
.a-hex-rosy-brown {--hex: #bc8f8f}
.a-hex1-rosy-brown {--hex1: #bc8f8f}
.a-hex2-rosy-brown {--hex2: #bc8f8f}
.a-hex3-rosy-brown {--hex3: #bc8f8f}
.a-hsl-rosy-brown {--h:0;--s:25%;--l:65%;}
.a-hsl1-rosy-brown {--h1:0;--s1:25%;--l1:65%;}
.a-hsl2-rosy-brown {--h2:0;--s2:25%;--l2:65%;}
.a-rgb1-rosy-brown {--r1:188;--g1:143;--b1:143}
.a-btn-rosy-brown {--hex: white;--h:0;--s:25%;--l:65%;--btn-color:white}

.a-font-royal-blue {color: #4169e1}
.a-bkg-royal-blue {background-color: #4169e1}
.a-hex-royal-blue {--hex: #4169e1}
.a-hex1-royal-blue {--hex1: #4169e1}
.a-hex2-royal-blue {--hex2: #4169e1}
.a-hex3-royal-blue {--hex3: #4169e1}
.a-hsl-royal-blue {--h:225;--s:73%;--l:57%;}
.a-hsl1-royal-blue {--h1:225;--s1:73%;--l1:57%;}
.a-hsl2-royal-blue {--h2:225;--s2:73%;--l2:57%;}
.a-rgb1-royal-blue {--r1:65;--g1:105;--b1:225}
.a-btn-royal-blue {--hex: white;--h:225;--s:73%;--l:57%;--btn-color:white}

.a-font-saddle-brown {color: #8b4513}
.a-bkg-saddle-brown {background-color: #8b4513}
.a-hex-saddle-brown {--hex: #8b4513}
.a-hex1-saddle-brown {--hex1: #8b4513}
.a-hex2-saddle-brown {--hex2: #8b4513}
.a-hex3-saddle-brown {--hex3: #8b4513}
.a-hsl-saddle-brown {--h:25;--s:76%;--l:31%;}
.a-hsl1-saddle-brown {--h1:25;--s1:76%;--l1:31%;}
.a-hsl2-saddle-brown {--h2:25;--s2:76%;--l2:31%;}
.a-rgb1-saddle-brown {--r1:139;--g1:69;--b1:19}
.a-btn-saddle-brown {--hex: white;--h:25;--s:76%;--l:31%;--btn-color:white}

.a-font-salmon {color: #fa8072}
.a-bkg-salmon {background-color: #fa8072}
.a-hex-salmon {--hex: #fa8072}
.a-hex1-salmon {--hex1: #fa8072}
.a-hex2-salmon {--hex2: #fa8072}
.a-hex3-salmon {--hex3: #fa8072}
.a-hsl-salmon {--h:6;--s:93%;--l:71%;}
.a-hsl1-salmon {--h1:6;--s1:93%;--l1:71%;}
.a-hsl2-salmon {--h2:6;--s2:93%;--l2:71%;}
.a-rgb1-salmon {--r1:250;--g1:128;--b1:114}
.a-btn-salmon {--hex: white;--h:6;--s:93%;--l:71%;--btn-color:white}

.a-font-sandy-brown {color: #f4a460}
.a-bkg-sandy-brown {background-color: #f4a460}
.a-hex-sandy-brown {--hex: #f4a460}
.a-hex1-sandy-brown {--hex1: #f4a460}
.a-hex2-sandy-brown {--hex2: #f4a460}
.a-hex3-sandy-brown {--hex3: #f4a460}
.a-hsl-sandy-brown {--h:28;--s:87%;--l:67%;}
.a-hsl1-sandy-brown {--h1:28;--s1:87%;--l1:67%;}
.a-hsl2-sandy-brown {--h2:28;--s2:87%;--l2:67%;}
.a-rgb1-sandy-brown {--r1:244;--g1:164;--b1:96}
.a-btn-sandy-brown {--hex: white;--h:28;--s:87%;--l:67%;--btn-color:white}

.a-font-sapphire {color: #3d44c3}
.a-bkg-sapphire {background-color: #3d44c3}
.a-hex-sapphire {--hex: #3d44c3}
.a-hex1-sapphire {--hex1: #3d44c3}
.a-hex2-sapphire {--hex2: #3d44c3}
.a-hex3-sapphire {--hex3: #3d44c3}
.a-hsl-sapphire {--h:237;--s:53%;--l:50%;}
.a-hsl1-sapphire {--h1:237;--s1:53%;--l1:50%;}
.a-hsl2-sapphire {--h2:237;--s2:53%;--l2:50%;}
.a-rgb1-sapphire {--r1:61;--g1:68;--b1:195}
.a-btn-sapphire {--hex: white;--h:237;--s:53%;--l:50%;--btn-color:white}

.a-font-sea-green {color: #2e8b57}
.a-bkg-sea-green {background-color: #2e8b57}
.a-hex-sea-green {--hex: #2e8b57}
.a-hex1-sea-green {--hex1: #2e8b57}
.a-hex2-sea-green {--hex2: #2e8b57}
.a-hex3-sea-green {--hex3: #2e8b57}
.a-hsl-sea-green {--h:146;--s:50%;--l:36%;}
.a-hsl1-sea-green {--h1:146;--s1:50%;--l1:36%;}
.a-hsl2-sea-green {--h2:146;--s2:50%;--l2:36%;}
.a-rgb1-sea-green {--r1:46;--g1:139;--b1:87}
.a-btn-sea-green {--hex: white;--h:146;--s:50%;--l:36%;--btn-color:white}

.a-font-sea-green-a {color: #099b9d}
.a-bkg-sea-green-a {background-color: #099b9d}
.a-hex-sea-green-a {--hex: #099b9d}
.a-hex1-sea-green-a {--hex1: #099b9d}
.a-hex2-sea-green-a {--hex2: #099b9d}
.a-hex3-sea-green-a {--hex3: #099b9d}
.a-hsl-sea-green-a {--h:181;--s:89%;--l:33%;}
.a-hsl1-sea-green-a {--h1:181;--s1:89%;--l1:33%;}
.a-hsl2-sea-green-a {--h2:181;--s2:89%;--l2:33%;}
.a-rgb1-sea-green-a {--r1:9;--g1:155;--b1:157}
.a-btn-sea-green-a {--hex: white;--h:181;--s:89%;--l:33%;--btn-color:white}

.a-font-sea-shell {color: #fff5ee}
.a-bkg-sea-shell {background-color: #fff5ee}
.a-hex-sea-shell {--hex: #fff5ee}
.a-hex1-sea-shell {--hex1: #fff5ee}
.a-hex2-sea-shell {--hex2: #fff5ee}
.a-hex3-sea-shell {--hex3: #fff5ee}
.a-hsl-sea-shell {--h:25;--s:100%;--l:97%;}
.a-hsl1-sea-shell {--h1:25;--s1:100%;--l1:97%;}
.a-hsl2-sea-shell {--h2:25;--s2:100%;--l2:97%;}
.a-rgb1-sea-shell {--r1:255;--g1:245;--b1:238}
.a-btn-sea-shell {--hex: white;--h:25;--s:100%;--l:97%;--btn-color:white}

.a-font-sienna {color: #a0522d}
.a-bkg-sienna {background-color: #a0522d}
.a-hex-sienna {--hex: #a0522d}
.a-hex1-sienna {--hex1: #a0522d}
.a-hex2-sienna {--hex2: #a0522d}
.a-hex3-sienna {--hex3: #a0522d}
.a-hsl-sienna {--h:19;--s:56%;--l:40%;}
.a-hsl1-sienna {--h1:19;--s1:56%;--l1:40%;}
.a-hsl2-sienna {--h2:19;--s2:56%;--l2:40%;}
.a-rgb1-sienna {--r1:160;--g1:82;--b1:45}
.a-btn-sienna {--hex: white;--h:19;--s:56%;--l:40%;--btn-color:white}

.a-font-silver {color: #c0c0c0}
.a-bkg-silver {background-color: #c0c0c0}
.a-hex-silver {--hex: #c0c0c0}
.a-hex1-silver {--hex1: #c0c0c0}
.a-hex2-silver {--hex2: #c0c0c0}
.a-hex3-silver {--hex3: #c0c0c0}
.a-hsl-silver {--h:0;--s:0%;--l:75%;}
.a-hsl1-silver {--h1:0;--s1:0%;--l1:75%;}
.a-hsl2-silver {--h2:0;--s2:0%;--l2:75%;}
.a-rgb1-silver {--r1:192;--g1:192;--b1:192}
.a-btn-silver {--hex: white;--h:0;--s:0%;--l:75%;--btn-color:white}

.a-font-sky-blue {color: #87ceeb}
.a-bkg-sky-blue {background-color: #87ceeb}
.a-hex-sky-blue {--hex: #87ceeb}
.a-hex1-sky-blue {--hex1: #87ceeb}
.a-hex2-sky-blue {--hex2: #87ceeb}
.a-hex3-sky-blue {--hex3: #87ceeb}
.a-hsl-sky-blue {--h:197;--s:71%;--l:73%;}
.a-hsl1-sky-blue {--h1:197;--s1:71%;--l1:73%;}
.a-hsl2-sky-blue {--h2:197;--s2:71%;--l2:73%;}
.a-rgb1-sky-blue {--r1:135;--g1:206;--b1:235}
.a-btn-sky-blue {--hex: white;--h:197;--s:71%;--l:73%;--btn-color:white}

.a-font-slate-blue {color: #6a5acd}
.a-bkg-slate-blue {background-color: #6a5acd}
.a-hex-slate-blue {--hex: #6a5acd}
.a-hex1-slate-blue {--hex1: #6a5acd}
.a-hex2-slate-blue {--hex2: #6a5acd}
.a-hex3-slate-blue {--hex3: #6a5acd}
.a-hsl-slate-blue {--h:248;--s:53%;--l:58%;}
.a-hsl1-slate-blue {--h1:248;--s1:53%;--l1:58%;}
.a-hsl2-slate-blue {--h2:248;--s2:53%;--l2:58%;}
.a-rgb1-slate-blue {--r1:106;--g1:90;--b1:205}
.a-btn-slate-blue {--hex: white;--h:248;--s:53%;--l:58%;--btn-color:white}

.a-font-slate-gray {color: #708090}
.a-bkg-slate-gray {background-color: #708090}
.a-hex-slate-gray {--hex: #708090}
.a-hex1-slate-gray {--hex1: #708090}
.a-hex2-slate-gray {--hex2: #708090}
.a-hex3-slate-gray {--hex3: #708090}
.a-hsl-slate-gray {--h:210;--s:13%;--l:50%;}
.a-hsl1-slate-gray {--h1:210;--s1:13%;--l1:50%;}
.a-hsl2-slate-gray {--h2:210;--s2:13%;--l2:50%;}
.a-rgb1-slate-gray {--r1:112;--g1:128;--b1:144}
.a-btn-slate-gray {--hex: white;--h:210;--s:13%;--l:50%;--btn-color:white}

.a-font-slate-grey {color: #708090}
.a-bkg-slate-grey {background-color: #708090}
.a-hex-slate-grey {--hex: #708090}
.a-hex1-slate-grey {--hex1: #708090}
.a-hex2-slate-grey {--hex2: #708090}
.a-hex3-slate-grey {--hex3: #708090}
.a-hsl-slate-grey {--h:210;--s:13%;--l:50%;}
.a-hsl1-slate-grey {--h1:210;--s1:13%;--l1:50%;}
.a-hsl2-slate-grey {--h2:210;--s2:13%;--l2:50%;}
.a-rgb1-slate-grey {--r1:112;--g1:128;--b1:144}
.a-btn-slate-grey {--hex: white;--h:210;--s:13%;--l:50%;--btn-color:white}

.a-font-snow {color: #fffafa}
.a-bkg-snow {background-color: #fffafa}
.a-hex-snow {--hex: #fffafa}
.a-hex1-snow {--hex1: #fffafa}
.a-hex2-snow {--hex2: #fffafa}
.a-hex3-snow {--hex3: #fffafa}
.a-hsl-snow {--h:0;--s:100%;--l:99%;}
.a-hsl1-snow {--h1:0;--s1:100%;--l1:99%;}
.a-hsl2-snow {--h2:0;--s2:100%;--l2:99%;}
.a-rgb1-snow {--r1:255;--g1:250;--b1:250}
.a-btn-snow {--hex: white;--h:0;--s:100%;--l:99%;--btn-color:white}

.a-font-spring-green {color: #00ff7f}
.a-bkg-spring-green {background-color: #00ff7f}
.a-hex-spring-green {--hex: #00ff7f}
.a-hex1-spring-green {--hex1: #00ff7f}
.a-hex2-spring-green {--hex2: #00ff7f}
.a-hex3-spring-green {--hex3: #00ff7f}
.a-hsl-spring-green {--h:150;--s:100%;--l:50%;}
.a-hsl1-spring-green {--h1:150;--s1:100%;--l1:50%;}
.a-hsl2-spring-green {--h2:150;--s2:100%;--l2:50%;}
.a-rgb1-spring-green {--r1:0;--g1:255;--b1:127}
.a-btn-spring-green {--hex: white;--h:150;--s:100%;--l:50%;--btn-color:white}

.a-font-steel-blue {color: #4682b4}
.a-bkg-steel-blue {background-color: #4682b4}
.a-hex-steel-blue {--hex: #4682b4}
.a-hex1-steel-blue {--hex1: #4682b4}
.a-hex2-steel-blue {--hex2: #4682b4}
.a-hex3-steel-blue {--hex3: #4682b4}
.a-hsl-steel-blue {--h:207;--s:44%;--l:49%;}
.a-hsl1-steel-blue {--h1:207;--s1:44%;--l1:49%;}
.a-hsl2-steel-blue {--h2:207;--s2:44%;--l2:49%;}
.a-rgb1-steel-blue {--r1:70;--g1:130;--b1:180}
.a-btn-steel-blue {--hex: white;--h:207;--s:44%;--l:49%;--btn-color:white}

.a-font-tan {color: #d2b48c}
.a-bkg-tan {background-color: #d2b48c}
.a-hex-tan {--hex: #d2b48c}
.a-hex1-tan {--hex1: #d2b48c}
.a-hex2-tan {--hex2: #d2b48c}
.a-hex3-tan {--hex3: #d2b48c}
.a-hsl-tan {--h:34;--s:44%;--l:69%;}
.a-hsl1-tan {--h1:34;--s1:44%;--l1:69%;}
.a-hsl2-tan {--h2:34;--s2:44%;--l2:69%;}
.a-rgb1-tan {--r1:210;--g1:180;--b1:140}
.a-btn-tan {--hex: white;--h:34;--s:44%;--l:69%;--btn-color:white}

.a-font-teal {color: #008080}
.a-bkg-teal {background-color: #008080}
.a-hex-teal {--hex: #008080}
.a-hex1-teal {--hex1: #008080}
.a-hex2-teal {--hex2: #008080}
.a-hex3-teal {--hex3: #008080}
.a-hsl-teal {--h:180;--s:100%;--l:25%;}
.a-hsl1-teal {--h1:180;--s1:100%;--l1:25%;}
.a-hsl2-teal {--h2:180;--s2:100%;--l2:25%;}
.a-rgb1-teal {--r1:0;--g1:128;--b1:128}
.a-btn-teal {--hex: white;--h:180;--s:100%;--l:25%;--btn-color:white}

.a-font-teal-a {color: #049cb7}
.a-bkg-teal-a {background-color: #049cb7}
.a-hex-teal-a {--hex: #049cb7}
.a-hex1-teal-a {--hex1: #049cb7}
.a-hex2-teal-a {--hex2: #049cb7}
.a-hex3-teal-a {--hex3: #049cb7}
.a-hsl-teal-a {--h:189;--s:96%;--l:37%;}
.a-hsl1-teal-a {--h1:189;--s1:96%;--l1:37%;}
.a-hsl2-teal-a {--h2:189;--s2:96%;--l2:37%;}
.a-rgb1-teal-a {--r1:4;--g1:156;--b1:183}
.a-btn-teal-a {--hex: white;--h:189;--s:96%;--l:37%;--btn-color:white}

.a-font-thistle {color: #d8bfd8}
.a-bkg-thistle {background-color: #d8bfd8}
.a-hex-thistle {--hex: #d8bfd8}
.a-hex1-thistle {--hex1: #d8bfd8}
.a-hex2-thistle {--hex2: #d8bfd8}
.a-hex3-thistle {--hex3: #d8bfd8}
.a-hsl-thistle {--h:300;--s:24%;--l:80%;}
.a-hsl1-thistle {--h1:300;--s1:24%;--l1:80%;}
.a-hsl2-thistle {--h2:300;--s2:24%;--l2:80%;}
.a-rgb1-thistle {--r1:216;--g1:191;--b1:216}
.a-btn-thistle {--hex: white;--h:300;--s:24%;--l:80%;--btn-color:white}

.a-font-tomato {color: #ff6347}
.a-bkg-tomato {background-color: #ff6347}
.a-hex-tomato {--hex: #ff6347}
.a-hex1-tomato {--hex1: #ff6347}
.a-hex2-tomato {--hex2: #ff6347}
.a-hex3-tomato {--hex3: #ff6347}
.a-hsl-tomato {--h:9;--s:100%;--l:64%;}
.a-hsl1-tomato {--h1:9;--s1:100%;--l1:64%;}
.a-hsl2-tomato {--h2:9;--s2:100%;--l2:64%;}
.a-rgb1-tomato {--r1:255;--g1:99;--b1:71}
.a-btn-tomato {--hex: white;--h:9;--s:100%;--l:64%;--btn-color:white}

.a-font-tomato-a {color: #f63e54}
.a-bkg-tomato-a {background-color: #f63e54}
.a-hex-tomato-a {--hex: #f63e54}
.a-hex1-tomato-a {--hex1: #f63e54}
.a-hex2-tomato-a {--hex2: #f63e54}
.a-hex3-tomato-a {--hex3: #f63e54}
.a-hsl-tomato-a {--h:353;--s:91%;--l:60%;}
.a-hsl1-tomato-a {--h1:353;--s1:91%;--l1:60%;}
.a-hsl2-tomato-a {--h2:353;--s2:91%;--l2:60%;}
.a-rgb1-tomato-a {--r1:246;--g1:62;--b1:84}
.a-btn-tomato-a {--hex: white;--h:353;--s:91%;--l:60%;--btn-color:white}

.a-font-turquoise {color: #40e0d0}
.a-bkg-turquoise {background-color: #40e0d0}
.a-hex-turquoise {--hex: #40e0d0}
.a-hex1-turquoise {--hex1: #40e0d0}
.a-hex2-turquoise {--hex2: #40e0d0}
.a-hex3-turquoise {--hex3: #40e0d0}
.a-hsl-turquoise {--h:174;--s:72%;--l:56%;}
.a-hsl1-turquoise {--h1:174;--s1:72%;--l1:56%;}
.a-hsl2-turquoise {--h2:174;--s2:72%;--l2:56%;}
.a-rgb1-turquoise {--r1:64;--g1:224;--b1:208}
.a-btn-turquoise {--hex: white;--h:174;--s:72%;--l:56%;--btn-color:white}

.a-font-ukraine-flag-blue {color: #0056b8}
.a-bkg-ukraine-flag-blue {background-color: #0056b8}
.a-hex-ukraine-flag-blue {--hex: #0056b8}
.a-hex1-ukraine-flag-blue {--hex1: #0056b8}
.a-hex2-ukraine-flag-blue {--hex2: #0056b8}
.a-hex3-ukraine-flag-blue {--hex3: #0056b8}
.a-hsl-ukraine-flag-blue {--h:212;--s:100%;--l:36%;}
.a-hsl1-ukraine-flag-blue {--h1:212;--s1:100%;--l1:36%;}
.a-hsl2-ukraine-flag-blue {--h2:212;--s2:100%;--l2:36%;}
.a-rgb1-ukraine-flag-blue {--r1:0;--g1:86;--b1:184}
.a-btn-ukraine-flag-blue {--hex: white;--h:212;--s:100%;--l:36%;--btn-color:white}

.a-font-ukraine-flag-yellow {color: #FFD700}
.a-bkg-ukraine-flag-yellow {background-color: #FFD700}
.a-hex-ukraine-flag-yellow {--hex: #FFD700}
.a-hex1-ukraine-flag-yellow {--hex1: #FFD700}
.a-hex2-ukraine-flag-yellow {--hex2: #FFD700}
.a-hex3-ukraine-flag-yellow {--hex3: #FFD700}
.a-hsl-ukraine-flag-yellow {--h:51;--s:100%;--l:50%;}
.a-hsl1-ukraine-flag-yellow {--h1:51;--s1:100%;--l1:50%;}
.a-hsl2-ukraine-flag-yellow {--h2:51;--s2:100%;--l2:50%;}
.a-rgb1-ukraine-flag-yellow {--r1:255;--g1:215;--b1:0}
.a-btn-ukraine-flag-yellow {--hex: white;--h:51;--s:100%;--l:50%;--btn-color:white}

.a-font-violet {color: #ee82ee}
.a-bkg-violet {background-color: #ee82ee}
.a-hex-violet {--hex: #ee82ee}
.a-hex1-violet {--hex1: #ee82ee}
.a-hex2-violet {--hex2: #ee82ee}
.a-hex3-violet {--hex3: #ee82ee}
.a-hsl-violet {--h:300;--s:76%;--l:72%;}
.a-hsl1-violet {--h1:300;--s1:76%;--l1:72%;}
.a-hsl2-violet {--h2:300;--s2:76%;--l2:72%;}
.a-rgb1-violet {--r1:238;--g1:130;--b1:238}
.a-btn-violet {--hex: white;--h:300;--s:76%;--l:72%;--btn-color:white}

.a-font-violet-a {color: #8876fe}
.a-bkg-violet-a {background-color: #8876fe}
.a-hex-violet-a {--hex: #8876fe}
.a-hex1-violet-a {--hex1: #8876fe}
.a-hex2-violet-a {--hex2: #8876fe}
.a-hex3-violet-a {--hex3: #8876fe}
.a-hsl-violet-a {--h:248;--s:99%;--l:73%;}
.a-hsl1-violet-a {--h1:248;--s1:99%;--l1:73%;}
.a-hsl2-violet-a {--h2:248;--s2:99%;--l2:73%;}
.a-rgb1-violet-a {--r1:136;--g1:118;--b1:254}
.a-btn-violet-a {--hex: white;--h:248;--s:99%;--l:73%;--btn-color:white}

.a-font-whatsapp {color: #25d466}
.a-bkg-whatsapp {background-color: #25d466}
.a-hex-whatsapp {--hex: #25d466}
.a-hex1-whatsapp {--hex1: #25d466}
.a-hex2-whatsapp {--hex2: #25d466}
.a-hex3-whatsapp {--hex3: #25d466}
.a-hsl-whatsapp {--h:142;--s:70%;--l:49%;}
.a-hsl1-whatsapp {--h1:142;--s1:70%;--l1:49%;}
.a-hsl2-whatsapp {--h2:142;--s2:70%;--l2:49%;}
.a-rgb1-whatsapp {--r1:37;--g1:212;--b1:102}
.a-btn-whatsapp {--hex: white;--h:142;--s:70%;--l:49%;--btn-color:white}

.a-font-wheat {color: #f5deb3}
.a-bkg-wheat {background-color: #f5deb3}
.a-hex-wheat {--hex: #f5deb3}
.a-hex1-wheat {--hex1: #f5deb3}
.a-hex2-wheat {--hex2: #f5deb3}
.a-hex3-wheat {--hex3: #f5deb3}
.a-hsl-wheat {--h:39;--s:77%;--l:83%;}
.a-hsl1-wheat {--h1:39;--s1:77%;--l1:83%;}
.a-hsl2-wheat {--h2:39;--s2:77%;--l2:83%;}
.a-rgb1-wheat {--r1:245;--g1:222;--b1:179}
.a-btn-wheat {--hex: white;--h:39;--s:77%;--l:83%;--btn-color:white}

.a-font-white {color: #ffffff}
.a-bkg-white {background-color: #ffffff}
.a-hex-white {--hex: #ffffff}
.a-hex1-white {--hex1: #ffffff}
.a-hex2-white {--hex2: #ffffff}
.a-hex3-white {--hex3: #ffffff}
.a-hsl-white {--h:0;--s:0%;--l:100%;}
.a-hsl1-white {--h1:0;--s1:0%;--l1:100%;}
.a-hsl2-white {--h2:0;--s2:0%;--l2:100%;}
.a-rgb1-white {--r1:255;--g1:255;--b1:255}
.a-btn-white {--hex: white;--h:0;--s:0%;--l:100%;--btn-color:white}

.a-font-white-smoke {color: #f5f5f5}
.a-bkg-white-smoke {background-color: #f5f5f5}
.a-hex-white-smoke {--hex: #f5f5f5}
.a-hex1-white-smoke {--hex1: #f5f5f5}
.a-hex2-white-smoke {--hex2: #f5f5f5}
.a-hex3-white-smoke {--hex3: #f5f5f5}
.a-hsl-white-smoke {--h:0;--s:0%;--l:96%;}
.a-hsl1-white-smoke {--h1:0;--s1:0%;--l1:96%;}
.a-hsl2-white-smoke {--h2:0;--s2:0%;--l2:96%;}
.a-rgb1-white-smoke {--r1:245;--g1:245;--b1:245}
.a-btn-white-smoke {--hex: white;--h:0;--s:0%;--l:96%;--btn-color:white}

.a-font-yellow {color: #ffff00}
.a-bkg-yellow {background-color: #ffff00}
.a-hex-yellow {--hex: #ffff00}
.a-hex1-yellow {--hex1: #ffff00}
.a-hex2-yellow {--hex2: #ffff00}
.a-hex3-yellow {--hex3: #ffff00}
.a-hsl-yellow {--h:60;--s:100%;--l:50%;}
.a-hsl1-yellow {--h1:60;--s1:100%;--l1:50%;}
.a-hsl2-yellow {--h2:60;--s2:100%;--l2:50%;}
.a-rgb1-yellow {--r1:255;--g1:255;--b1:0}
.a-btn-yellow {--hex: white;--h:60;--s:100%;--l:50%;--btn-color:white}

.a-font-yellow-green {color: #9acd32}
.a-bkg-yellow-green {background-color: #9acd32}
.a-hex-yellow-green {--hex: #9acd32}
.a-hex1-yellow-green {--hex1: #9acd32}
.a-hex2-yellow-green {--hex2: #9acd32}
.a-hex3-yellow-green {--hex3: #9acd32}
.a-hsl-yellow-green {--h:80;--s:61%;--l:50%;}
.a-hsl1-yellow-green {--h1:80;--s1:61%;--l1:50%;}
.a-hsl2-yellow-green {--h2:80;--s2:61%;--l2:50%;}
.a-rgb1-yellow-green {--r1:154;--g1:205;--b1:50}
.a-btn-yellow-green {--hex: white;--h:80;--s:61%;--l:50%;--btn-color:white}
