
.range {-webkit-appearance: none;  height: 17px;  border-radius: 12px; background-size: 100% 100%; border: 1px solid rgb(210,210,210); --thumbColor:white;--thumbBrdColor:black;background-repeat: no-repeat;  overflow: visible;box-sizing: border-box;padding: 0;margin: 0;}
.range.contrast-black {--thumbColor:black;--thumbBrdColor:orange;}
.rg-ph-sliding.range {border:2px solid darkorange; height: 18px}

.range::-webkit-slider-thumb {  -webkit-appearance: none;height: 16px;  width: 16px;  border-radius: 50%;  background: var(--thumbColor);  cursor: pointer;  box-shadow: 0 0 2px 0 #555;  transition: background .3s ease-in-out; border:1px solid var(--thumbBrdColor); }
.range::-moz-range-thumb {height: 16px;  width: 16px;  border-radius: 50%;  background: var(--thumbColor);  cursor: pointer;  box-shadow: 0 0 2px 0 #555;  transition: background .3s ease-in-out;border:1px solid var(--thumbBrdColor);}
.range::-ms-thumb {  height: 16px;  width: 16px;  border-radius: 50%;  background: var(--thumbColor);  cursor: pointer;  box-shadow: 0 0 2px 0 #555;  transition: background .3s ease-in-out;border:1px solid var(--thumbBrdColor);}

.range::-webkit-slider-runnable-track {box-shadow: none;  border: none;  background: transparent;}
.range::-moz-range-track {  box-shadow: none;  border: none;background: transparent; }
.range::-ms-track {  box-shadow: none;  border: none;  background: transparent;}

.no-thumb .range::-webkit-slider-thumb {opacity: 0;}
.no-thumb .range::-moz-range-thumb {opacity: 0;}
.no-thumb .range::-ms-thumb {opacity: 0;}
