
.div-select-options{z-index: 50;position: absolute; bottom: 0; left: 50%;--brdRad: calc(var(--sm2) * 1px);border-radius: var(--brdRad); --xTrans:-50%;
  transform: translate(var(--xTrans),calc(100% + var(--height) * 0.8));--yTrans:calc(100% - ((var(--height) - var(--fSize)) / 2 * var(--yRatio))); --yRatio:0.3;
  opacity:0;visibility: hidden;transition: opacity .3s ease-in, transform .3s ease-in; --tipBrd:calc(var(--sm3) * 3/2 * 0.5px);}
.div-select-options.custom-yRatio {--yRatio:calc(var(--xs1) / 10);}
.div-select-options.custom-gap {--yTrans:calc(100% + var(--xs2) * 1.5px);}
.div-select-options.no-dropDown-arrow {--yTrans:calc(100% + var(--tipBrd) * 1.1);transform: translate(var(--xTrans),calc(100% + var(--height) * 0.8));}
.div-select-options.drop-overflow {--xTrans:calc(-50% + var(--e) * var(--ex) * var(--sign1) * 1px);transition: opacity .5s ease-in;}
.select-is-open.div-select-options {opacity:1;visibility: visible;transform: translate(var(--xTrans),var(--yTrans));}

.div-select-inner {border-radius: var(--brdRad);overflow: hidden; --rowContainer: calc(var(--e) * var(--ex) * var(--l-un,1px));}
.div-select-inner > div {border-radius: var(--brdRad);}
.div-select-top .div-select-options {bottom: calc(100% + 8px); top: auto;}
.div-select-row-no-height {--height:calc(var(--fSize) * 2.7);}

.div-select-option-item {height: var(--height);padding: 0 var(--rowContainer);}
.single-drop.value-selected .div-select-option-item {padding-left: var(--singleLeftPad); --singleLeftPad:calc(var(--rowContainer) + var(--checkEm));}

.div-select-option-item:last-child {border: none;}

.div-select-drop-tip {position: absolute;bottom: 100%; border-width: var(--tipBrd);border-style: solid;border-color:  transparent transparent var(--tipColor) transparent;z-index: 1;}


@media (hover: hover) {
  .multi-drop.div-select-lBkg .div-select-option-item:hover:not(.no-hover) {border-radius: var(--brdRad);}
}
