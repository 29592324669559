/* The container */
.radioRatio {--checkRatio: 0.29;} .checkBoxRatio {--checkRatio: 0.45;}
.radCh-container {
  --checkSize: calc(var(--f) * 1px);--checkBrd:calc(var(--sm3) * 1px);--checkColor: var(--hex);
  --checkBkg: hsl(var(--h),var(--s),var(--l));--checkBkgHover: hsl(var(--h),var(--s),calc(var(--l) + var(--sign) * var(--sm) * 1%));
  --checkBkgChecked: hsl(var(--h1),var(--s1), var(--l1));--checkBkgCheckedHovered: hsl(var(--h1),var(--s1),calc(var(--l1) + var(--sign1) * var(--sm1) * 1%));
  --checkedContBrd:calc(var(--sm2) * 1px);--checkInnerSize: calc(var(--checkSize) - 2 * var(--checkedContBrd));
   position: relative;   cursor: pointer; height: 100%;}
.radCh-container input {position: absolute;top:50%;opacity: 0;cursor: pointer;  height: 0;  width: 0;}/* Hide the browser's default checkbox */
.radCh-checkmark { position: relative;height: var(--checkSize);width: var(--checkSize);background-color: var(--checkBkg);
  --bulletSize: calc(var(--checkSize) * var(--checkRatio)); --bulletPos: calc((var(--checkInnerSize) - var(--bulletSize)) / 2 );
 }
.radCh-container input ~ .radCh-checkmark:hover {background-color: var(--checkBkgHover);}
.radCh-container input:checked ~ .radCh-checkmark {  background-color:var(--checkBkgChecked);border: var(--checkedContBrd) solid var(--checkColor)}
.radCh-container input:checked ~ .radCh-checkmark:hover {  background-color:var(--checkBkgCheckedHovered);}
.radCh-checkmark:after {  content: "";  position: absolute;  display: none;}
.radCh-container input:checked ~ .radCh-checkmark:after {  display: block;}
.radCh-container .radCh-check:after {
  --checkHeight: calc(var(--checkSize) * var(--checkRatio));top: var(--bulletPos); left: calc((var(--checkInnerSize) - var(--bulletSize) / 2) / 2 );
  width: calc(var(--bulletSize) / 2); height: var(--bulletSize);border: solid var(--checkColor);
  border-width: 0 var(--checkBrd) var(--checkBrd) 0;-webkit-transform: rotate(45deg);  -ms-transform: rotate(45deg);  transform: rotate(45deg);
}
.radCh-container .radCh-bullet:after {
 	top: var(--bulletPos); left: var(--bulletPos); width: var(--bulletSize); height: var(--bulletSize); border-radius: 50%;
  background: var(--checkColor);
}


/*--bulletSize: calc(var(--checkInnerSize) * var(--checkRatio));*/
