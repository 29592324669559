.wd{width: var(--wd);}
.miWd {min-width: var(--miWd);}
.maWd {max-width: var(--maWd);}
.he {height: var(--he);}
.miHe {min-height: var(--miHe);}
.maHe {max-height: var(--maHe);}

.wd-5 {width: 5%}.wd-10 {width: 10%}.wd-20 {width: 20%}.wd-30 {width: 30%}.wd-40 {width: 40%}.wd-50 {width: 50%}.wd-60 {width: 60%}.wd-70 {width: 70%}.wd-80 {width:80%}.wd-90 {width: 90%}.wd-100 {width: 100%}

.brd {border: var(--brdS) solid var(--brdC);}
.lBar {border-left: var(--barS) solid var(--barC,var(--brdC)) !important;}
.rBar {border-right: var(--barS) solid var(--barC,var(--brdC)) !important;}
.tBar {border-top: var(--barS) solid var(--barC,var(--brdC)) !important;}
.bBar {border-bottom: var(--barS) var(--barC,var(--brdC)) !important;}
.bRad {border-radius: var(--bRad)}
.bRadLbar, .bRadTbar,.bRadRbar,.bRadBbar {--bar-rad:var(--barRad);--bar-radOt:var(--bRad);}
.bRadLbar {border-radius: var(--bar-rad) var(--bar-radOt) var(--bar-radOt) var(--bar-rad)}
.bRadRbar {border-radius: var(--bar-radOt) var(--bar-rad) var(--bar-rad) var(--bar-radOt)}
.bRadTbar {border-radius: var(--bar-rad) var(--bar-rad) var(--bar-radOt) var(--bar-radOt)}
.bRadBbar {border-radius: var(--bar-radOt) var(--bar-radOt) var(--bar-rad)var(--bar-rad)}

.shA-0 {--shA:0;}.shA-1 {--shA:0.1;}.shA-2 {--shA:0.2;}.shA-3 {--shA:0.3;}.shA-4 {--shA:0.4;}.shA-5 {--shA:0.5;}.shA-6 {--shA:0.6;}.shA-7 {--shA:0.7;}.shA-8 {--shA:0.8;}.shA-9 {--shA:0.9;}.shA-10 {--shA:1;}
.shAH-0 {--shAH:0;}.shAH-1 {--shAH:0.1;}.shAH-2 {--shAH:0.2;}.shAH-3 {--shAH:0.3;}.shAH-4 {--shAH:0.4;}.shAH-5 {--shAH:0.5;}.shAH-6 {--shAH:0.6;}.shAH-7 {--shAH:0.7;}.shAH-8 {--shAH:0.8;}.shAH-9 {--shAH:0.9;}.shAH-10 {--shAH:1;}

.bShS-1 {--bShS: 1px 1px 4px}.bShS-2 {--bShS: 2px 2px 8px}.bShS-3 {--bShS: 3px 3px 10px} .bShS-4 {--bShS: 4px 4px 12px} .bShS-5 {--bShS: 5px 5px 15px}
.bShSH-1 {--bShSH: 1px 1px 4px}.bShSH-2 {--bShSH: 2px 2px 8px}.bShSH-3 {--bShSH: 3px 3px 10px} .bShSH-4 {--bShSH: 4px 4px 12px} .bShSH-5 {--bShSH: 5px 5px 15px}
.bShad {box-shadow: var(--bShS) rgb(var(--r1),var(--g1),var(--b1),var(--shA));}

@media (hover:hover) {
  .brdH:hover {border: var(--brdSHov,var(--brdS)) solid var(--brdCHov,var(--brdC));}
  .bRadH:hover {border-radius: var(--bRadH);}
  .lBarH:hover {border-left: var(--barSHov,var(--barS)) solid var(--barCHov,var(--barC)) !important;}
  .rBarH:hover {border-right:  var(--barSHov,var(--barS)) solid var(--barCHov,var(--barC)) !important;}
  .tBarH:hover {border-top:  var(--barSHov,var(--barS))  solid var(--barCHov,var(--barC)) !important;}
  .bBarH:hover {border-bottom:  var(--barSHov,var(--barS)) solid var(--barCHov,var(--barC)) !important;}
  .bRadLbarH:hover, .bRadTbarH:hover,.bRadRbarH:hover,.bRadBbarH:hover {--bar-rad:var(--barRadH);--bar-radOt:var(--bRadH);}

  .bShadH:hover {box-shadow: var(--bShSH) rgb(var(--r1),var(--g1),var(--b1),var(--shAH));}

}
