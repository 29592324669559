/* Track */

:root {scrollbar-color: #0057B8 #FFD700;}
::-webkit-scrollbar {
  width: 7px;
  -webkit-appearance: none;
}
::-webkit-scrollbar-track {
  /*box-shadow: inset 0 0 8px grey;*/
  border-radius: 10px;
  background-color: #0057B8;
}



/* Handle */
::-webkit-scrollbar-thumb {
  background: #FFD700;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /*background: hsl(35, 95%,65%);*/
}

@media (max-width:734px) {
  ::-webkit-scrollbar {
    width: 4px;
  }
}
