.sha-1 {--sha: 1px} .sha-2 {--sha: 2px} .sha-3 {--sha: 3px} .sha-4 {--sha: 4px} .sha-5 {--sha: 5px}.sha-6 {--sha: 6px}.sha-7 {--sha: 7px}.sha-8 {--sha: 8px}
.sha-b1 {--sha-blur: 1} .sha-b2 {--sha-blur: 2} .sha-b3 {--sha-blur: 3} .sha-b4 {--sha-blur: 4}.sha-b5 {--sha-blur: 5}
.sha-inc1 {--shaInc:1px;}.sha-inc2 {--shaInc:2px;}.sha-inc3 {--shaInc:3px;}.sha-inc4 {--shaInc:4px;}.sha-inc5 {--shaInc:5px;}.sha-inc6 {--shaInc:6px;}


.a-shadow-rgb,.a-shadow-hover-rgb {--shaColor: rgba(var(--r1),var(--g1),var(--b1),var(--a1));}
.a-shadow-hsl2,.a-shadow-hover-hsl2 {--shaColor: hsla(var(--h2),var(--s2),var(--l3),var(--a1));}
.a-shadow-hsl1,.a-shadow-hover-hsl1 {--shaColor: hsla(var(--h1),var(--s1),var(--l1),var(--a1));}
.a-shadow-hsl,.a-shadow-hover-hsl {--shaColor: hsla(var(--h),var(--s),var(--l),var(--a1));}
.shadow-blur-multiply{--sha-blur:calc(var(--xs5,3) * var(--sha))}
.shadow-blur-add {--sha-blur:calc(var(--xs5,3) * 1px + var(--sha))}
.a-shadow-rgb, .a-shadow-hsl {--sha:calc(var(--xs4,1) * 1px); box-shadow: var(--sha) var(--sha) var(--sha-blur) var(--shaColor, rgba(0,0,0,.3));}
.a-shadow-black {--sha:calc(var(--xs4,1) * 1px);box-shadow: var(--sha,1px) var(--sha,1px) var(--sha-blur) rgba(0,0,0,var(--a1));}
.a-shadow-black {--sha:calc(var(--xs4,1) * 1px);box-shadow: var(--sha,1px) var(--sha,1px) var(--sha-blur) rgba(255,255,255,var(--a1));}
.a-shadow-fb {box-shadow: 1px 1px 2px rgba(0,0,0,.2);}

@media (hover: hover) {
  .a-shadow-hover-rgb:hover, .a-shadow-hover-hsl:hover, .a-shadow-hover-hsl1:hover, .a-shadow-hover-hsl2:hover {--sha:calc(var(--xs4,1) * 1px);box-shadow: var(--sha,1px) var(--sha,1px) var(--sha-blur) var(--shaColor);}
  .a-shadow-hover-black:hover {--sha:calc(var(--xs4,1) * 1px);box-shadow: var(--sha,1px) var(--sha,1px) var(--sha-blur) rgba(0,0,0,var(--a1));}
  .a-shadow-hover-white:hover {--sha:calc(var(--xs4,1) * 1px);box-shadow: var(--sha,1px) var(--sha,1px) var(--sha-blur) rgba(255,255,255,var(--a1));}
  .a-shadow-hover-inc:hover {--sha: calc((var(--xs6,1) + var(--xs4,1)) * 1px);box-shadow: var(--sha) var(--sha) var(--sha-blur) var(--shaColor) ;}
}
