.f-siz {--fSize: var(--fSiz);}
.f-siz-range  {--fSize: max(min(var(--fSiz), var(--fMax)), var(--fMin));}
.fs-cl {font-size: var(--fSize);}
.f1-siz {--f1Size: var(--f1Siz);}
.f1-siz-range  {--f1Size: max(min(var(--f1Siz), var(--f1Max)), var(--f1Min));}
.f1s-cl {font-size: var(--f1Size);}
.f2-siz {--f2Size: var(--f2Siz);}
.f2-siz-range  {--f2Size: max(min(var(--f2Siz), var(--f2Max)), var(--f2Min));}
.f2s-cl {font-size: var(--f2Size);}
.f3-siz {--f3Size: var(--f3Siz);}
.f3-siz-range  {--f3Size: max(min(var(--f3Siz), var(--f3Max)), var(--f3Min));}
.f3s-cl {font-size: var(--f3Size);}
.f4-siz {--f4Size: var(--f4Siz);}
.f4-siz-range  {--f4Size: max(min(var(--f4Siz), var(--f4Max)), var(--f4Min));}
.f4s-cl {font-size: var(--f4Size);}
.f5-siz {--f5Size: var(--f5Siz);}
.f5-siz-range  {--f5Size: max(min(var(--f5Siz), var(--f5Max)), var(--f5Min));}
.f5s-cl {font-size: var(--f5Size);}

.a-font-13 {font-size: 13px}.a-font-14 {font-size: 14px}.a-font-15 {font-size: 15px}.a-font-16 {font-size: 16px}.a-font-17 {font-size: 17px}.a-font-18 {font-size: 18px}.a-font-19 {font-size: 19px}
.a-font-20 {font-size: 20px}.a-font-25 {font-size: 25px}.a-font-30 {font-size: 30px}.a-font-35 {font-size: 35px}


.lh-10 {line-height: 1;}.lh-11 {line-height: 1.1;}.lh-12 {line-height: 1.2;}.lh-13 {line-height: 1.3;}.lh-14 {line-height: 1.4;} .lh-15 {line-height: 1.5;}
.lh-16 {line-height: 1.6;}.lh-17 {line-height: 1.7;}.lh-18 {line-height: 1.8;}.lh-19 {line-height: 1.9;}.lh-20 {line-height: 2;}
/*
@media  (max-width: 1450px) AND (min-height: 501px) {
  .f-siz-xt {--fSize: var(--fSiz-xt);}
  .f-siz-range-xt  {--fSize: max(min(var(--fSiz-xt), var(--fMax-xt)), var(--fMin-xt));}
  .f1-siz-xt {--f1Size: var(--f1Siz-xt);}
  .f1-siz-range-xt  {--f1Size: max(min(var(--f1Siz-xt), var(--f1Max-xt)), var(--f1Min-xt));}
  .f2-siz-xt {--f2Size: var(--f2Siz-xt);}
  .f2-siz-range-xt  {--f2Size: max(min(var(--f2Siz-xt), var(--f2Max-xt)), var(--f2Min-xt));}
}

@media  (max-width: 1150px) AND (min-height: 501px) {
  .f-siz-t {--fSize: var(--fSiz-t);}
  .f-siz-range-t  {--fSize: max(min(var(--fSiz-t), var(--fMax-t)), var(--fMin-t));}
  .f1-siz-t {--f1Size: var(--f1Siz-t);}
  .f1-siz-range-t  {--f1Size: max(min(var(--f1Siz-t), var(--f1Max-t)), var(--f1Min-t));}
  .f2-siz-t {--f2Size: var(--f2Siz-t);}
  .f2-siz-range-t  {--f2Size: max(min(var(--f2Siz-t), var(--f2Max-t)), var(--f2Min-t));}
}

@media  (max-height: 500px) AND (max-width: 970px) AND (orientation:landscape) {
  .f-siz-ml {--fSize: var(--fSiz-ml);}
  .f-siz-range-ml  {--fSize: max(min(var(--fSiz-ml), var(--fMax-ml)), var(--fMin-ml));}
  .f1-siz-ml {--f1Size: var(--f1Siz-ml);}
  .f1-siz-range-ml  {--f1Size: max(min(var(--f1Siz-ml), var(--f1Max-ml)), var(--f1Min-ml));}
  .f2-siz-ml {--f2Size: var(--f2Siz-ml);}
  .f2-siz-range-ml  {--f2Size: max(min(var(--f2Siz-ml), var(--f2Max-ml)), var(--f2Min-ml));}
}

@media  (max-width: 450px) AND (orientation:portrait) {
  .f-siz-mp {--fSize: var(--fSiz-mp);}
  .f-siz-range-mp  {--fSize: max(min(var(--fSiz-mp), var(--fMax-mp)), var(--fMin-mp));}
  .f1-siz-mp {--f1Size: var(--f1Siz-mp);}
  .f1-siz-range-mp  {--f1Size: max(min(var(--f1Siz-mp), var(--f1Max-mp)), var(--f1Min-mp));}
  .f2-siz-mp {--f2Size: var(--f2Siz-mp);}
  .f2-siz-range-mp  {--f2Size: max(min(var(--f2Siz-mp), var(--f2Max-mp)), var(--f2Min-mp));}
}

@media  (max-width: 350px) AND (orientation:portrait) {
  .f-siz-xm {--fSize: var(--fSiz-xm);}
  .f-siz-range-xm  {--fSize: max(min(var(--fSiz-xm), var(--fMax-xm)), var(--fMin-xm));}
  .f1-siz-xm {--f1Size: var(--f1Siz-xm);}
  .f1-siz-range-xm  {--f1Size: max(min(var(--f1Siz-xm), var(--f1Max-xm)), var(--f1Min-xm));}
  .f2-siz-xm {--f2Size: var(--f2Siz-xm);}
  .f2-siz-range-xm  {--f2Size: max(min(var(--f2Siz-xm), var(--f2Max-xm)), var(--f2Min-xm));}
}
*/
