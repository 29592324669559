@import url("../vars/mrPd.css");
@import url("../vars/colors.css");
@import url("../vars/brd.css");
@import url("../vars/shadows.css");
@import url("../vars/widthHeight.css");
@import url("../vars/fontSize.css");

.a-px {--unit: 1px}
.a-pc {--unit: 1%}
.a-vw {--unit: 1vw}
.a-vh {--unit: 1vh}

.press-up:hover {transform: translateY(calc(var(--xs7) * -2px));}
.press-down:hover {transform: translateY(calc(var(--xs7) * 2px));}
/*
.press-down-right:hover {--tr: calc(var(--xs7) * 1px);transform: translate(var(--tr), var(--tr));}
.press-up-right:hover {--tr: calc(var(--xs7) * 1px);transform: translate(var(--tr),calc(-1 * var(--tr)));}
*/
.plus-sign {--sign: 1;} .minus-sign{--sign: -1;} .plus1-sign {--sign1: 1;} .minus1-sign{--sign1: -1;} .plus2-sign {--sign2: 1;} .minus2-sign{--sign2: -1;}.plus3-sign {--sign3: 1;} .minus3-sign{--sign3: -1;}
