
.bkg,.bkg-lH, .bkg-LH {background-color:hsla(var(--h),var(--s),var(--lum), var(--a, 1)); --lum:var(--l);}
.lHov-1 {--lHov: 1%} .lHov-2 {--lHov: 2%} .lHov-3 {--lHov: 3%} .lHov-4 {--lHov: 4%} .lHov-5 {--lHov: 5%} .lHov-6 {--lHov: 6%} .lHov-7 {--lHov: 7%} .lHov-8 {--lHov: 8%} .lHov-9 {--lHov: 9%} .lHov-10 {--lHov: 10%}
.lHov-11 {--lHov: 11%} .lHov-12 {--lHov: 12%} .lHov-13 {--lHov: 13%} .lHov-14 {--lHov: 14%} .lHov-15 {--lHov: 15%} .lHov-16 {--lHov: 16%} .lHov-17 {--lHov: 17%} .lHov-18 {--lHov: 18%} .lHov-19 {--lHov: 19%} .lHov-20 {--lHov: 20%}

.bkg-LH:hover{--lum:calc(var(--l) + var(--lHov))}
.bkg-lH:hover{--lum:calc(var(--l) - var(--lHov))}
.bkg-hov:hover {background-color: hsla(var(--h1),var(--s1),var(--l1), var(--a, 1)) !important;}

.w-100 {font-weight: 100}.w-200 {font-weight: 200}.w-300 {font-weight: 300}.w-400 {font-weight: 400}.w-500 {font-weight: 500}.w-600 {font-weight: 600}.w-700 {font-weight: 700}
