.form-defs {
  /* container */   /**/
  --bRadius: 8px 8px 0 0; --brdBot: 3px ; --height: 57px;  /*brdBot defines the bottom bar set 0 to disable the bar. use the color settings to define the color on typing/focus, on blur and when an error occurred*/

    /* convering label */
  --covLabelPad: 16px 24px; --covLabelSize: 15px; --cLabWeight: 500;
  /* label */
  --labelTopPad: 7px;--labelBotPad: 0; --labelContPad: 8px;  --labelSize: 12px; --labWeight: 500;
  --labelAlign: left;
  /* input */
  --inpContPad: 16px; --inpSize: 15px; --inpPadTop: 13px; --inpWeight: 500;
  /* dropdrown */
  --dropHeight: 50px;
}

.form-round-noLab {
  /* container */   /**/
  --bRadius: 21px; --brdBot: none ; --brd:none; --height: 42px;  /*brdBot defines the bottom bar set 0 to disable the bar. use the color settings to define the color on typing/focus, on blur and when an error occurred*/
    /* convering label */
  --covLabelPad: 20px ; --covLabelSize: 16px; --cLabWeight: 500;
  /* label */
  --labelTopPad: 8px;--labelBotPad: 0; --labelContPad: 8px;  --labelSize: 12px; --labWeight: 500;
  --labelAlign: left; ; --labelCol: black;
  /* input */
  --inpContPad: 22px; --inpSize: 16px; --inpPadTop: 16px; --inpWeight: 500;
  /* dropdrown */
  --dropHeight: 40px;
}

.form-noLab-large {
  /* container */   /**/
  --bRadius: 6px; --brdBot: none ; --brd:none; --height: 42px;  /*brdBot defines the bottom bar set 0 to disable the bar. use the color settings to define the color on typing/focus, on blur and when an error occurred*/

  /* input */
  --inpContPad: 20px; --inpSize: 16px; --inpPadTop: 16px; --inpWeight: 500;
  /* dropdrown */
  --dropHeight: 32px;
}

.form-noLab-med {
  /* container */   /**/
  --bRadius: 6px; --brdBot: none ; --brd:none; --height: 32px;  /*brdBot defines the bottom bar set 0 to disable the bar. use the color settings to define the color on typing/focus, on blur and when an error occurred*/

  /* input */
  --inpContPad: 16px; --inpSize: 14px; --inpPadTop: 16px; --inpWeight: 500;
  /* dropdrown */
  --dropHeight: 34px;
}

.form-noLab-small {
  /* container */   /**/
  --bRadius: 6px; --brdBot: none ; --brd:none; --height: 28px;  /*brdBot defines the bottom bar set 0 to disable the bar. use the color settings to define the color on typing/focus, on blur and when an error occurred*/

  /* input */
  --inpContPad: 12px; --inpSize: 14px; --inpPadTop: 16px; --inpWeight: 500;
  /* dropdrown */
  --dropHeight: 34px;
}

.form-noLab-xsmall {
  /* container */   /**/
  --bRadius: 6px; --brdBot: none ; --brd:none; --height: 26px;  /*brdBot defines the bottom bar set 0 to disable the bar. use the color settings to define the color on typing/focus, on blur and when an error occurred*/

  /* input */
  --inpContPad: 8px; --inpSize: 14px; --inpPadTop: 16px; --inpWeight: 500;
  /* dropdrown */
  --dropHeight: 34px;
}

.form-noLab-tiny {
  /* container */   /**/
  --bRadius: 6px; --brdBot: none ; --brd:none; --height: 24px;  /*brdBot defines the bottom bar set 0 to disable the bar. use the color settings to define the color on typing/focus, on blur and when an error occurred*/
  /* input */
  --inpContPad: 12px; --inpSize: 12px; --inpPadTop: 16px; --inpWeight: 500;
  /* dropdrown */
  --dropHeight: 28px;
}

.form-round-Lab-Med-2 {
  /* container */   /**/
  --bRadius: 23px; --brdBot: none ; --brd:none; --height: 46px;  /*brdBot defines the bottom bar set 0 to disable the bar. use the color settings to define the color on typing/focus, on blur and when an error occurred*/
    /* convering label */
  --covLabelPad: 26px ; --covLabelSize: 15px; --cLabWeight: 600;--covLabelAlign: center;
  /* label */
  --labelContPad: 26px; --labelTopPad: 4px;--labelBotPad: 0;   --labelSize: 12px; --labWeight: 300;
  --labelAlign: left; --labelCol: black;
  /* input */
  --inpContPad: 26px; --inpSize: 16px; --inpPadTop: 12px; --inpWeight: 600;
  /* dropdrown */
  --dropHeight: 40px;
}
.form-round-Lab-Med {
  /* container */   /**/
  --bRadius: 23px; --brdBot: none ; --brd:none; --height: 39px;  /*brdBot defines the bottom bar set 0 to disable the bar. use the color settings to define the color on typing/focus, on blur and when an error occurred*/
    /* convering label */
  --covLabelPad: 24px ; --covLabelSize: 14px; --cLabWeight: 600;--covLabelAlign: center;
  /* label */
  --labelContPad: 24px; --labelTopPad: 5px;--labelBotPad: 0;   --labelSize: 11px; --labWeight: 300;
  --labelAlign: left; --labelCol: black;
  /* input */
  --inpContPad: 24px; --inpSize: 14px; --inpPadTop: 11px; --inpWeight: 600;
  /* dropdrown */
  --dropHeight: 40px;
}

.form-def-col {
  --focusBkgColor: rgb(221, 255, 221); --focusColor: black; --errorBkgColor: #ffdddd; --errorColor: black; --blurBkgColor: rgb(241, 241, 241); --blurColor: black;
  --dropBkgColor: hsl(35,95%,63%);--labelColor: rgb(80,80,80); --dropColor: white;--dropBkgHoverColor: hsl(35,95%,69%); --dropHoverColor: white;
  /* Border botttom colors */
  --brdBotCol: blue;--brdBotErrCol:red;--brdBotFocusCol:green;
}
.form-col-orange {
  --focusBkgColor: hsl(35,95%,48%); --focusColor: white; --errorBkgColor: #ffdddd; --errorColor: black; --blurBkgColor: hsl(35,95%,51%); --blurColor: white;
  --dropBkgColor: hsl(35,95%,63%); --labelColor: white; --dropColor: white;--dropBkgHoverColor: hsl(35,95%,69%); --dropHoverColor: white;
  /* Border botttom colors */
  --brdBotCol: blue;--brdBotErrCol:red;--brdBotFocusCol:green;
}
.form-col-orange:hover {--blurBkgColor: var(--focusBkgColor)}
