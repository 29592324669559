.no-scr-bar {-ms-overflow-style: none;  /* IE and Edge */scrollbar-width: none;  /* Firefox */;}
.no-scr-bar::-webkit-scrollbar {display: none;}
.scroll-x-auto {width: 100%!important; overflow-x: auto; overflow-y: visible;}
.scroll-y-auto {height: 100%!important; overflow-y: auto; overflow-x: visible;}
.scroll-xy-auto {height: 100%;width: 100%; overflow: auto;}
.overflow-auto {overflow: auto;}
.overflow-x-auto {overflow-x: auto;}
.overflow-y-auto {overflow-y: auto;}
.no-scroll {height: 100vh; overflow: hidden;}
.no-over-scroll {overscroll-behavior-y: none;}

.a-btn div::-webkit-scrollbar {display: none;}
.a-btn div {-ms-overflow-style: none;  /* IE and Edge */scrollbar-width: none;  /* Firefox */;}
