.a-font {--fSize: calc(var(--b) * 1px);}
.a-font-vw {--fSize: calc(var(--b) * 0.1vw);}
.a-font-vw-range {--fSize: max(min(var(--b) * 0.1vw, var(--d) * 1px),var(--c) * 1px);}  /* b: vwidth, --c: min value in px, --d: max value in px*/

.a-font-class {font-size: var(--fSize);}

.lHeight {line-height: calc(var(--sm1) / 10 )}
.fWeight {font-weight: calc(var(--xs) * 100)}
@media (hover: hover) {.fWeight-hov:hover:not(.no-hover) {font-weight: calc(var(--xs8) * 100)}}

@media (max-width:1450px) {
  .a-font-xm {--fSize: calc(var(--b) * 1px)}
  .a-font-vw-xm {--fSize: calc(var(--b) * 0.1vw)}
  .a-font-vw-range-xm {--fSize: max(min(var(--b) * 0.1vw, var(--d) * 1px),var(--c) * 1px)}  /* b: vwidth, --c: min value in px, --d: max value in px*/

}

@media (max-width:1200px) {
  .a-font-t {--fSize: calc(var(--b) * 1px)}
  .a-font-vw-t {--fSize: calc(var(--b) * 0.1vw)}
  .a-font-vw-range-t {--fSize: max(min(var(--b) * 0.1vw, var(--d) * 1px),var(--c) * 1px)}  /* b: vwidth, --c: min value in px, --d: max value in px*/

}

@media (max-width:734px) {
  .a-font-s {--fSize: calc(var(--b) * 1px)}
  .a-font-vw-s {--fSize: calc(var(--b) * 0.1vw)}
  .a-font-vw-range-s {--fSize: max(min(var(--b) * 0.1vw, var(--d) * 1px),var(--c) * 1px)}  /* b: vwidth, --c: min value in px, --d: max value in px*/

}

@media (max-width:340px) {
  .a-font-xs {--fSize: calc(var(--b) * 1px)}
  .a-font-vw-xs {--fSize: calc(var(--b) * 0.1vw)}
  .a-font-vw-range-xs {--fSize: max(min(var(--b) * 0.1vw, var(--d) * 1px),var(--c) * 1px)}  /* b: vwidth, --c: min value in px, --d: max value in px*/

}
