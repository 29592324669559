.a-lBar-width {border-left-width: calc(var(--sm) * 1px) !important;}
.a-rBar-width {border-right-width: calc(var(--sm) * 1px) !important;}
.a-tBar-width {border-top-width: calc(var(--sm) * 1px) !important;}
.a-bBar-width {border-bottom-width: calc(var(--sm) * 1px) !important;}


.brd {border: calc(var(--sm) * 1px) solid var(--hex);}
.brd1 {border: calc(var(--sm1) * 1px) solid var(--hex1);}
.brd2 {border: calc(var(--sm2) * 1px) solid var(--hex2);}

.a-bord {border: calc(var(--xs) * 1px) solid var(--hex);}
.a-bord-h:hover {border: calc(var(--xs1) * 1px) solid var(--hex1);}
.a-left-bar {border-left: calc((var(--xs2) + 10 * var(--xs3)) * 1px) solid var(--hex2) !important;}
.a-right-bar {border-right: calc((var(--xs2) + 10 * var(--xs3)) * 1px) solid var(--hex2) !important;}
.a-top-bar {border-top: calc((var(--xs2) + 10 * var(--xs3)) * 1px)  solid var(--hex2) !important;}
.a-bottom-bar {border-bottom: calc((var(--xs2) + 10 * var(--xs3)) * 1px) solid var(--hex2) !important;}
.a-left-bar-h:hover {border-left: calc((var(--xs12) + 10 * var(--xs13)) * 1px) solid var(--hex3) !important;}
.a-right-bar-h:hover {border-right: calc((var(--xs12) + 10 * var(--xs13)) * 1px) solid var(--hex3) !important;}
.a-top-bar-h:hover {border-top: calc((var(--xs12) + 10 * var(--xs13)) * 1px)  solid var(--hex3) !important;}
.a-bottom-bar-h:hover {border-bottom: calc((var(--xs12) + 10 * var(--xs13)) * 1px) solid var(--hex3) !important;}


.rad-px {--pRad-u: 2px}


.b-rad-px {--bRadU: 2.5px}
.b-rad-pc {--bRadU: 2.5%}
.b-rad-pcHe {--bRadU: calc(0.025 * var(--height))}
.a-bRad {border-radius: calc(var(--sm) * var(--bRadU))}
.a-bRad-h:hover {border-radius: calc(var(--sm1) * var(--bRadU))}
.a-bRad-lBar, .a-bRad-rBar,.a-bRad-rBar,.a-bRad-bBar {--barRad:calc(var(--sm2) * var(--bRadU));--barRadOt:calc(var(--sm) * var(--bRadU));}
.a-bRad-lBar-h:hover, .a-bRad-rBar-h:hover,.a-bRad-rBar-h:hover,.a-bRad-bBar-h:hover {--barRad:calc(var(--sm3) * var(--bRadU));--barRadOt:calc(var(--sm1) * var(--bRadU));}
.a-bRad-lBar {border-radius: var(--barRad) var(--barRadOt) var(--barRadOt) var(--barRad)}
.a-bRad-rBar {border-radius: var(--barRadOt) var(--barRad) var(--barRad) var(--barRadOt)}
.a-bRad-tBar {border-radius: var(--barRad) var(--barRad) var(--barRadOt) var(--barRadOt)}
.a-bRad-bBar {border-radius: var(--barRadOt) var(--barRadOt) var(--barRad)var(--barRad)}



.a-brd {border: var(--brdSize, 1px) var(--brdStyle, solid) var(--hex)}
.a-brd1  {border: var(--brdSize, 1px) var(--brdStyle, solid) var(--hex1)}
.a-brd2  {border: var(--brdSize, 1px) var(--brdStyle, solid) var(--hex2)}

.a-brd-b {border-bottom: var(--brdSize, 1px) var(--brdStyle, solid) var(--hex)}
.a-brd-b1 {border-bottom: var(--brdSize, 1px) var(--brdStyle, solid) var(--hex1)}
.a-brd-b2 {border-bottom: var(--brdSize, 1px) var(--brdStyle, solid) var(--hex2)}

.a-brd-t {border-top: var(--brdSize, 1px) var(--brdStyle, solid) var(--hex)}
.a-brd-t1 {border-top: var(--brdSize, 1px) var(--brdStyle, solid) var(--hex1)}
.a-brd-t2 {border-top: var(--brdSize, 1px) var(--brdStyle, solid) var(--hex2)}

@media (hover: hover) {
  .a-brd-h:hover {border: var(--brdSize, 1px) var(--brdStyle, solid) var(--hex) !important;}
  .a-brd1-h:hover {border: var(--brdSize, 1px) var(--brdStyle, solid) var(--hex1) !important;}
  .a-brd2-h:hover {border: var(--brdSize, 1px) var(--brdStyle, solid) var(--hex2) !important;}

  .a-brd-b-h:hover {border-bottom: var(--brdSize, 1px) var(--brdStyle, solid) var(--hex) !important;}
  .a-brd-b1-h:hover {border-bottom: var(--brdSize, 1px) var(--brdStyle, solid) var(--hex1) !important;}
  .a-brd-b2-h:hover {border-bottom: var(--brdSize, 1px) var(--brdStyle, solid) var(--hex2) !important;}

  .a-brd-t-h:hover {border-top: var(--brdSize, 1px) var(--brdStyle, solid) var(--hex) !important;}
  .a-brd-t1-h:hover {border-top: var(--brdSize, 1px) var(--brdStyle, solid) var(--hex1) !important;}
  .a-brd-t2-h:hover {border-top: var(--brdSize, 1px) var(--brdStyle, solid) var(--hex2) !important;}

}
